import DineroSettings from "Pages/Dinero/Components/DineroSettings";
import Header from "Components/Header";
import FlexRow from "Components/FlexRow";
import { useTranslation } from "react-i18next";
import PremiumTrialAnnouncement from "Components/PremiumTrialAnnouncement";

const DineroSettingsPage = () => {
    
    const { t } = useTranslation();
    
    return (
        <>
            <FlexRow>
                <Header text={t('dinero:settings.title')} />
            </FlexRow>
            
            <PremiumTrialAnnouncement>
                {t('premiumAnnouncements.integrationsIsAPartOfTimeOpsPremium')}
            </PremiumTrialAnnouncement>
            
            <DineroSettings />
        </>
    )
}

export default DineroSettingsPage
