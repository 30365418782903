import { useEffect, useState } from "react";
import { useNavigate, useLocation } from "react-router-dom";

const useQueryParam = (name: string): [string, Function] => {
    
    const navigate = useNavigate();
    const location = useLocation();
    
    const [value, setInternalValue] = useState<string>('');
    
    useEffect(() => {
        setInternalValue(new URLSearchParams(location.search).get(name) ?? '')
    }, [name, location.search]);
    
    const setValue = (newValue: string) => {
        const queryParams = new URLSearchParams(location.search)
        
        setInternalValue(newValue);
        
        if (newValue) {
            queryParams.set(name, newValue);
        } else {
            queryParams.delete(name);
        }
    
        navigate({
            pathname: location.pathname,
            search: queryParams.toString()
        })
    }
    
    return [value, setValue]
}

export default useQueryParam
