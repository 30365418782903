import { useTags } from "Apis/TagApi";
import List from "Components/List/List";
import TagInactive from "Assets/Icons/TagInactive";
import { useTranslation } from "react-i18next";
import Header from "Components/Header";
import FlexRow from "Components/FlexRow";
import Button from "Components/Button/Button";
import { useEffect, useRef, useState } from "react";
import useQueryParam from "Hooks/UseQueryParam";
import TagModal from "Pages/Tags/Components/TagModal";
import { Tag } from "Apis/Models/Tag";
import Tooltip from "Components/Tooltip";
import Edit from "Assets/Icons/Edit";
import InputV2 from "Components/InputV2/InputV2";

const TagListPage = () => {

    const { t } = useTranslation();
    
    const [searchFilter, setSearchFilter] = useQueryParam('query');
    
    const { data: tags, isLoading } = useTags();
    
    const searchRef = useRef<HTMLInputElement>(null);
    
    const [tagModalVisible, setTagModalVisible] = useState<boolean>(false);
    const [selectedTag, setSelectedTag] = useState<Tag | undefined>();
    
    useEffect(() => {
        if (searchRef.current) {
            searchRef.current.focus();
        }
    }, [searchRef])
    
    
    const getTags = () => {
        let filteredCustomers = tags;
        
        if (searchFilter) {
            const lowerCaseSearch = searchFilter.toLowerCase();
            
            filteredCustomers = tags?.filter(x => x.text.toLowerCase().includes(lowerCaseSearch))
        }
        
        return filteredCustomers?.sort((a,b) => a.text.toLowerCase()! > b.text.toLowerCase()! ? 1 : -1)
    }
    
    const editTag = (tag: Tag) => {
        setSelectedTag(tag);
        setTagModalVisible(true)
    }
    
    const closeTagModal = (tag: Tag) => {
        setSelectedTag(undefined)
        setTagModalVisible(false);
    }
    
    return (
        <>
    
            <Header text={t('registrations:tags')} />
    
            <FlexRow justify={"space-between"} style={{marginBottom: 10}}>
                <div  style={{width: '100%', maxWidth: '280px'}}>
                    <InputV2 inputRef={searchRef} placeholder={t('registrations:tagSearch')} type="text" value={searchFilter} onChange={e => setSearchFilter(e)} containerStyle={{backgroundColor: 'white'}} />
                </div>
        
                <div>
                    <Button color={"primary"} onClick={() => setTagModalVisible(true)}>
                        + {t('registrations:createTag')}
                    </Button>
                </div>
            </FlexRow>
            
            <List columns={[
                {
                    dataIndex: "text",
                    icon: <TagInactive includeFill={false} />,
                    title: t('registrations:tag')
                },
                {
                    size: 'min-content',
                    customRender: (_, record) => {
                        return (
                            <FlexRow justify={"center"} align={"center"}>
                                <Tooltip title={t('registrations:editTag')}>
                                    <div className={"flex w-100 align-center clickable"} onClick={() => editTag(record)}>
                                        <Edit />
                                    </div>
                                </Tooltip>
                            </FlexRow>
                        )
                    }
                }, ]}
                keyDataIndex={"id"}
                data={getTags()}
                loading={isLoading}
                emptyText={t('registrations:noTags')}
            />
            
            <TagModal visible={tagModalVisible} tag={selectedTag} onOk={closeTagModal} onCancel={closeTagModal}  />
        </>
    )
}

export default TagListPage
