declare var gtag: any;

export type ConversionType = 'CreateOrganization' | 'CreateUser' | 'CreateSubscription';
const ConversionTypeIds = {
    CreateOrganization: 'AW-725455255/850oCPzG64kCEJej9tkC',
    CreateUser: 'AW-725455255/j3WICJ-TyeEBEJej9tkC',
    CreateSubscription: 'AW-725455255/RLsKCKjJ64kCEJej9tkC'
}
export const reportConversion = (conversionType: ConversionType) => {
    if (!window.location.host.includes('localhost')) {
        gtag('event', 'conversion', {
            'send_to': ConversionTypeIds[conversionType],
        });
    }
}
