import * as React from "react"
import { SVGProps } from "react"

const MoneyBagIcon = (props: SVGProps<SVGSVGElement>) => (
    <svg xmlns="http://www.w3.org/2000/svg" width={29.5}
         height={35.566} {...props} viewBox={"0 0 30 36"}>
        <g data-name="002-money">
            <g data-name="Group 1086">
                <g data-name="Group 1079">
                    <path
                        data-name="Path 1199"
                        d="M17.169 23.626a1.691 1.691 0 0 0-.475-.345 5.726 5.726 0 0 0-1.2-.425v3.181a2.26 2.26 0 0 0 1.781-.939 1.454 1.454 0 0 0 .132-.993 1 1 0 0 0-.238-.479Z"
                        fill={props.fill ?? "#fff"}
                    />
                </g>
                <g data-name="Group 1080">
                    <path data-name="Path 1200" d="M17.266 25.104v-.006Z" fill={props.fill ?? "#fff"} />
                </g>
                <g data-name="Group 1081">
                    <path
                        data-name="Path 1201"
                        d="M12.367 18.54a1.193 1.193 0 0 0-.206.559 1.341 1.341 0 0 0 .068.618.992.992 0 0 0 .385.424 3.005 3.005 0 0 0 .572.3c.167.067.355.131.557.194v-2.879a2.2 2.2 0 0 0-1.376.784Z"
                        fill={props.fill ?? "#fff"}
                    />
                </g>
                <g data-name="Group 1082">
                    <path
                        data-name="Path 1202"
                        d="m17.287 25.074-.013.023Z"
                        fill={props.fill ?? "#fff"}
                    />
                </g>
                <g data-name="Group 1083">
                    <path data-name="Path 1203" d="M17.3 25.045Z" fill={props.fill ?? "#fff"} />
                </g>
                <g data-name="Group 1084">
                    <path
                        data-name="Path 1204"
                        d="M16.613 7.739c2.706-2.273 4.513-7.393 3.4-7.617-1.478-.3-4.688 1.008-6.24 1.238-2.2.266-4.6-2.386-5.943-.908-1.094 1.2.784 5.573 3.723 7.477-8.765 4.308-21.091 25.937.409 27.507 29.743 2.171 14.85-23.499 4.651-27.697ZM19.168 24.8a3.127 3.127 0 0 1-1.206 2.12 4.61 4.61 0 0 1-2.469.872v.932a.875.875 0 1 1-1.75 0v-1.018a8.302 8.302 0 0 1-.448-.1 4.3 4.3 0 0 1-2.14-1.317 3.858 3.858 0 0 1-.66-1.09c-.041-.1-.077-.21-.109-.317a1.624 1.624 0 0 1-.069-.3.875.875 0 0 1 1.7-.363c.033.1.056.2.093.3a1.949 1.949 0 0 0 .134.287 2.23 2.23 0 0 0 .384.492 2.6 2.6 0 0 0 1.113.607v-3.457a6.226 6.226 0 0 1-2.294-.992 2.669 2.669 0 0 1-.774-.93 2.912 2.912 0 0 1-.27-1.252 2.984 2.984 0 0 1 .262-1.268 3.092 3.092 0 0 1 .718-.985 4.382 4.382 0 0 1 2.359-1.045v-.967a.875.875 0 1 1 1.75 0v.962q.172.022.342.052a4.321 4.321 0 0 1 2.284 1.109 3.338 3.338 0 0 1 .7 1.005 3.205 3.205 0 0 1 .124.32 1.944 1.944 0 0 1 .082.312.875.875 0 0 1-1.682.442c-.037-.1-.055-.2-.092-.292a1.541 1.541 0 0 0-.143-.275 1.669 1.669 0 0 0-.413-.433 2.836 2.836 0 0 0-1.2-.469v3.313a11.107 11.107 0 0 1 1.494.444 3.493 3.493 0 0 1 1.824 1.428c-.062-.109-.122-.214 0 0s.064.112 0 0a3.121 3.121 0 0 1 .356 1.873Z"
                        fill={props.fill ?? "#fff"}
                    />
                </g>
                <g data-name="Group 1085">
                    <path data-name="Path 1205" d="m17.261 25.12-.013.023Z" fill={props.fill ?? "#fff"} />
                </g>
            </g>
        </g>
    </svg>
)

export default MoneyBagIcon
