import { useEffect, useRef } from "react";
import DatePicker from "antd/lib/date-picker";
import Tooltip from "antd/lib/tooltip";
import { deserialize, Registration } from "Apis/Models/Registration.type";
import { useTranslation } from "react-i18next";
import { Controller, useForm } from "react-hook-form";
import moment from "moment";
import { DateUtils } from "Utils/DateUtils";
import TimeField from "react-simple-timefield";
import { getInputClasses } from "Utils/FormUtils";
import { RegistrationUtils } from "Utils/RegistrationUtils";
import { useDeleteRegistration, useSaveRegistration } from "Apis/RegistrationsApi";
import Button  from "Components/Button/Button";
import { openErrorNotification } from "Utils/NotificationUtils";
import FlexRow from "Components/FlexRow";
import ProjectSelect from "Components/ProjectSelect";
import { useCustomers } from "Apis/CustomerApi";
import RequiresPremiumModal from "Components/RequiresPremiumModal";
import BillableActive from "Assets/Icons/BillableActive";
import BillableInactive from "Assets/Icons/BillableInactive";
import { useAuthProvider } from "Providers/AuthProvider";
import TagSelect from "Components/TagSelect";
import Modal from "Components/Modal";
import Space from "Components/Space";
import PopConfirm from "./PopConfirm";

interface Props {
    visible: boolean;
    registration?: Partial<Registration>;
    onOk?: Function;
    onCancel?: Function;
}
const RegistrationModalV2 = (props: Props) => {
    
    const { t } = useTranslation();
    
    const { data: customers } = useCustomers();
    const { userContext } = useAuthProvider();
    
    const { mutateAsync: saveRegistrationMutation, isLoading: isSavingRegistration } = useSaveRegistration();
    const { mutateAsync: deleteRegistrationMutation, isLoading: isDeletingRegistration } = useDeleteRegistration();
    
    const { register, control, errors, handleSubmit, reset, watch, setValue, getValues, trigger } = useForm<Registration>();
    
    const descriptionRef = useRef<HTMLInputElement>();
    
    useEffect(() => {
        register('id');
        register('startedAt');
        register('stoppedAt');
    }, [register])
    
    useEffect(() => {
        if (props.visible && descriptionRef.current) {
            descriptionRef.current.focus();
        }
    }, [descriptionRef, props.visible])
    
    useEffect(() => {
        if (props.registration) {
            let registration = {...props.registration};
            
            if (!props.registration.startedAt) {
                registration.startedAt = new Date();
            }
            
            reset(deserialize(registration))
        } else {
            reset({startedAt: new Date()})
        }
    }, [props.registration, reset, props.visible])
    
    const onOk = async (data: Registration, close: boolean) => {
        
        if (!data.startedAt) {
            openErrorNotification(t('errors:waitAMinute'), t('registrations:noStartDate'))
            return;
        }
        
        await saveRegistrationMutation(data)
        
        if (props.onOk) {
            props.onOk();
        }
    
        onCancel(close);
    }
    
    const onCancel = (close: boolean = true) => {
        
        reset({})
        
        if (close && props.onCancel) {
            props.onCancel();
        }
    }
    
    const deleteRegistration = async () => {
        await deleteRegistrationMutation(props.registration!.id!);
        
        onCancel();
    }
    
    const updateRegistrationSetStartedAt = (timeString: string) => {
        const registration = {
            ...getValues(),
        }
        
        RegistrationUtils.setStartedAtTimeFromString(registration, timeString);
        
        setValue('startedAt', registration.startedAt);
    }
    
    const updateRegistrationSetStoppedAt = (timeString: string) => {
        const registration = {
            ...getValues(),
        }
        
        RegistrationUtils.setStoppedAtTimeFromString(registration, timeString);
    
        setValue('stoppedAt', registration.stoppedAt);
    }
    
    const updateRegistrationSetDate = (date: any) => {
        const registration = {
            ...getValues(),
        }
        
        RegistrationUtils.setNewDate(registration, date);
    
        setValue('startedAt', registration.startedAt);
        setValue('stoppedAt', registration.stoppedAt);
        
    }
    
    const projectSelected = (event, callback) => {
        const project = customers?.flatMap(x => x.projects).find(x => x.id === event);
        
        setValue('projectId', event.id);
        
        if (project && userContext?.hasActiveLicense) {
            setValue('billable', project.billable)
        }
        
        if (callback) {
            callback(event)
        }
        
        trigger();
    }
    
    return (
        <Modal
            title={props.registration?.id ? t('registrations:updateRegistration') : t('registrations:createRegistration')}
            visible={props.visible}
            onCancel={onCancel}
        >
    
            <form>
                <Space size={"medium"}>
                    
                    <div>
                        <label>{t('description')}</label>
                        <input name="description" placeholder={t('description')} className={getInputClasses(errors.description)} ref={r => {
                            register(r);
                            descriptionRef.current = r!
                        }} />
                    </div>
                    
                    <div>
                        <label>{t('project')}</label>
                        <FlexRow>
                            <Controller name={"projectId"} control={control} defaultValue={null} render={(props) => (
                                <ProjectSelect className={"w-100"} onChange={e => projectSelected(e, props.onChange)} value={props.value} />
                            )} />
                                <Controller name={"billable"} defaultValue={false} control={control} render={_ => (
                                    <div style={{width: 60}}>
                                        <RequiresPremiumModal enabled={!userContext?.hasActiveLicense} title={t('registrations:billableRegistrations')}>
                                            <Tooltip title={_.value ? t('registrations:billable') : t('registrations:notBillable')} >
                                                <FlexRow className={"clickable"} justify={"center"} onClick={() => {
                                                    if (userContext?.hasActiveLicense) {
                                                        _.onChange(!_.value);
                                                    }
                                                }}>
                                                    {_.value ? (
                                                        <BillableActive />
                                                    ) : (
                                                        <BillableInactive />
                                                    )}
                                                </FlexRow>
                                            </Tooltip>
                                        </RequiresPremiumModal>
                                    </div>
                                )} />
                        </FlexRow>
                    </div>
                    <div>
                        <label>{t('registrations:tags')}</label>
                        <Controller name={"tags"} control={control} defaultValue={[]} render={props => (
                            <TagSelect {...props} className={"w-100"} />
                        )} />
                    </div>
                    <div>
                        <label>{t('date')}</label>
                        <Controller
                            control={control}
                            name={"startedAt"}
                            defaultValue={new Date()}
                            render={props => (
                                <DatePicker
                                    picker="date"
                                    className={"w-100"}
                                    allowClear={false}
                                    format={DateUtils.getDateMomentFormat()}
                                    onChange={e => {
                                        props.onChange(e!.set({ hours: moment(getValues('startedAt')).get('hours'), minutes: moment(getValues('startedAt')).get('minutes') }).toDate())
                                        updateRegistrationSetDate(e);
                                    }}
                                    value={moment(props.value)}
                                />
                            )}
                        />
                    </div>
                    
                    <FlexRow justify={"space-between"}>
                        <div style={{width: '48.5%'}}>
                            <label>{t('start')}</label>
                            <TimeField
                                value={watch('startedAt') ? moment(watch('startedAt')).format("HH:mm") : undefined}
                                onChange={(e, value) => updateRegistrationSetStartedAt(value)}
                                input={<input type="text" className={getInputClasses(errors.startedAt)} />}
                            />
                        </div>
                        <div style={{width: '48.5%'}}>
                            <label>{t('stop')}</label>
                            {!watch('stoppedAt') ? (
                                <Button
                                    style={{backgroundColor: 'white', border: '1px solid rgba(2, 17, 47, 0.4)'}}
                                    block
                                    onClick={() => updateRegistrationSetStoppedAt(moment().format('HH:mm'))}
                                    onFocus={() => updateRegistrationSetStoppedAt(moment().format('HH:mm'))}
                                >{t('stop')}</Button>
                            ) : (
                                <TimeField
                                    value={watch('stoppedAt') ? moment(watch('stoppedAt')).format("HH:mm") : undefined}
                                    onChange={(e, value) => updateRegistrationSetStoppedAt(value)}
                                    input={<input type="text" className={getInputClasses(errors.stoppedAt)} />}
                                />
                            )}
                        </div>
                    </FlexRow>
    
                    <div style={{display: 'flex', justifyContent: 'space-between'}}>
                        <div>
                            {props.registration?.id && (
                                <PopConfirm title={t('areYouSure')} onConfirm={deleteRegistration}>
                                    <Button loading={isDeletingRegistration} color="danger">{t('delete')}</Button>
                                </PopConfirm>
                            )}
                        </div>
                        <Space direction={"horizontal"}>
                            <Button key="cancel" onClick={props.onCancel}>{t('cancel')}</Button>
                            {!props.registration?.id && (
                                <Button
                                    loading={isSavingRegistration}
                                    onClick={() => handleSubmit((data) => onOk(data, false))()}
                                >
                                    {t('saveAndNew')}
                                </Button>
                            )}
                            <Button
                                color="primary"
                                loading={isSavingRegistration}
                                onClick={() => handleSubmit((data) => onOk(data, true))()}
                            >
                                {t('save')}
                            </Button>
                        </Space>
                    </div>
                </Space>
            </form>
        </Modal>
    )
}

export default RegistrationModalV2
