import { CSSProperties } from "react";
import styles from './Button.module.scss';
import Spinner from "../Spinner";

type Props = {
    block?: boolean
    loading?: boolean;
    color?: 'primary' | 'danger';
    onClick?: any;
    onFocus?: any;
    type?: 'button' | 'submit';
    children?: any;
    disabled?: boolean
    style?: CSSProperties;
}

export const Button = (props: Props) => {

    const classes = [styles.button];

    if (props.color) {
        classes.push(styles[props.color]);
    } else {
        classes.push(styles.default)
    }

    if (props.block) {
        classes.push(styles.block);
    }

    return (
        <button type={props.type ?? 'button'} disabled={props.disabled} className={classes.join(' ')} onClick={props.onClick} style={props.style} onFocus={props.onFocus}>
            {props.loading && <Spinner style={props.color ? {color: 'white', paddingRight: '10px'} : {}} size="small" />}
            {props.children}
        </button>
    )
}

export default Button;
