import Select from "antd/lib/select";
import Space from "antd/lib/space";
import Button from "Components/Button/Button";
import {useTranslation} from "react-i18next";
import { useDineroIntegration, useDineroOrganizations, useSelectDineroOrganization } from "Pages/Dinero/Api/DineroIntegrationApi";
import { Controller, useForm } from "react-hook-form";
import { openSuccessNotification } from "Utils/NotificationUtils";
import Empty from "Components/Empty";
import * as React from "react";

const SelectDineroIntegration = () => {
    
    const { t } = useTranslation();
    const {control, handleSubmit} = useForm<{id: number}>()
    
    const { isLoading: isLoadingIntegration } = useDineroIntegration();
    const { data: dineroOrganizations, isLoading: isLoadingOrganizations } = useDineroOrganizations();
    
    const { mutateAsync: selectDineroOrganizationMutation, isLoading: isUpdatingDineroId} = useSelectDineroOrganization();
    
    const setDineroId = async (data) => {
        await selectDineroOrganizationMutation(data.id);
        
        openSuccessNotification(t('success'), t('dinero:onboarding.organizationSelected'))
    }
    
    return(
        <form onSubmit={handleSubmit(setDineroId)}>
            <Space size={"large"} direction={"vertical"} className={"w-100"} >
                
                <div>
                    <label>
                        {t('dinero:integration.selectOrganization')}
                    </label>
                    <Controller name={"id"} control={control} defaultValue={null} rules={{required: true}} render={props => (
                        <Select value={props.value} onChange={props.onChange} className={"w-100"} loading={isLoadingIntegration || isLoadingOrganizations || !dineroOrganizations}>
                            {dineroOrganizations?.map((item) => {
                                return (
                                    <Select.Option value={item.dineroOrganizationId}>{t('dinero:integration.organization')}: {item.name} (Firma Id: {item.dineroOrganizationId}</Select.Option>
                                )
                            })}
                            {dineroOrganizations?.length === 0 && (
                                <Empty children={t('dinero:integration.noProOrganizations')} />
                            )}
                        </Select>
                    )} />
                </div>
                
                <Button block color={"primary"} disabled={isUpdatingDineroId} loading={isUpdatingDineroId} type={"submit"}>{t('dinero:integration.selectOrganization')}</Button>
            </Space>
        </form>
    )
}

export default SelectDineroIntegration;
