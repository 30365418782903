import Empty from "Components/Empty";
import Button from "Components/Button/Button";
import { useEffect, useState } from "react";
import {useOwnRegistrations} from "Apis/RegistrationsApi";
import Header from "Components/Header";
import RegistrationCardListItem from "./RegistrationCardListItem";
import moment from "moment";
import {DashboardTypeKey} from "Pages/Registrations/Dashboard";
import {useTranslation} from "react-i18next";

const RegistrationCardList = () => {

    const { t } = useTranslation();
    
    const {data: ownRegistrations, fetchNextPage, isLoading, isFetchingNextPage, hasNextPage} = useOwnRegistrations();

    const [registrationCards, setRegistrationCards] = useState<any[]>();

    useEffect(() => {
        localStorage.setItem(DashboardTypeKey, 'List');
    }, [])

    useEffect(() => {
        if (ownRegistrations) {
            const newCards:any[] = [];
    
            const groupedBydate = []
            
            ownRegistrations?.pages.forEach(group => {
                group.registrationList.forEach(x => {
                    const startedAt = moment(x.startedAt).startOf('date').toISOString()
            
                    if (!groupedBydate[startedAt]) {
                        groupedBydate[startedAt] = [];
                    }
            
                    groupedBydate[startedAt].push(x)
                })
            })
    
            const groupedByMonth: any = [];
    
            Object.keys(groupedBydate).forEach(group => {
                const month = moment(group).startOf('month').toISOString().toString();
        
                if (!groupedByMonth[month]) {
                    groupedByMonth[month] = [];
                }
        
                groupedByMonth[month].push(group)
            })
    
            for (let month in groupedByMonth) {
                if (groupedByMonth.hasOwnProperty(month)) {
                    let cards: any[] = [];
                    let date;
        
                    groupedByMonth[month].forEach(dateString => {
                        date = dateString;
                        cards.push(
                            <RegistrationCardListItem key={dateString} date={date} registrations={groupedBydate[dateString]}/>
                        );
                    });
        
                    if (date) {
                        newCards.push((
                            <div key={month} style={{width: '100%', marginBottom: 30}}>
                                <Header text={moment(date).format('MMMM')}/>
                                {cards}
                            </div>
                        ))
                    }
                }
            }
            
            setRegistrationCards(newCards);
        }

    }, [ownRegistrations])

    return (
        <>
            <div className={"w-100"}>
                {isLoading && (
                    <div style={{width: '100%'}}>
                        <div>
                            <Header text={t('loading')}/>
                        </div>
                        <RegistrationCardListItem loading={true}/>
                    </div>
                )}
                {!isLoading && registrationCards?.length === 0 && (
                    <div style={{backgroundColor: 'white'}} className="box dont-hover padding30">
                        <Empty children={t('registrations:noRegistrations')} />
                    </div>
                )}
                {registrationCards}
            </div>

            {hasNextPage && (
                <div className={"flex justify-center w-100 padding30"}>
                    <Button loading={isFetchingNextPage} onClick={fetchNextPage}>{t('registrations:loadMore')}</Button>
                </div>
            )}
        </>
    )
}

export default RegistrationCardList;
