import notification from "antd/lib/notification";
import { NotificationPlacement } from "antd/lib/notification";

export const openSuccessNotification = (title: string, description: string, placement: NotificationPlacement = 'bottomRight') => {
    notification.open({
        message: title,
        description: description,
        className: 'notification notification-success',
        placement
    });
};

export const openErrorNotification = (title: string, description: string, placement: NotificationPlacement = 'bottomRight') => {
    notification.open({
        message: title,
        description: description,
        className: 'notification notification-error',
        placement
    });
};
