import { createContext, useState } from "react";
import * as React from "react";
import moment from "moment";
import { useOwnRegistrationsByPeriod } from "Apis/RegistrationsApi";
import { Registration } from "Apis/Models/Registration.type";

type ContextProps = {
    setTo: Function;
    setFrom: Function;
    registrations: Registration[];
    now: Date;
}
export const CalendarRegistrationContext = createContext<ContextProps>({
    setTo: () => {},
    setFrom: () => {},
    registrations: [],
    now: new Date(),
});

interface Props {
    children: React.ReactNode;
}
const CalendarRegistrationProvider = (props: Props) => {
    
    const [now] = useState<Date>(new Date());
    
    const [from, setFrom] = useState<Date>(moment().startOf('isoWeek').toDate());
    const [to, setTo] = useState<Date>(moment().endOf('isoWeek').toDate());
    
    const {data: fetchedRegistrations} = useOwnRegistrationsByPeriod(from, to);
    
    return (
        <CalendarRegistrationContext.Provider value={{setFrom, setTo, registrations: fetchedRegistrations ?? [], now}}>
            {props.children}
        </CalendarRegistrationContext.Provider>
    )
}

export default CalendarRegistrationProvider
