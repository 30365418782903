import * as RadixCheckbox from '@radix-ui/react-checkbox';
import styles from './Checkbox.module.scss';
import FlexRow from "Components/FlexRow";
import React from "react";
import { Controller } from "react-hook-form";
import { useTranslation } from "react-i18next";
import CheckmarkIcon from "Assets/Icons/CheckmarkIcon";

interface Props {
    checked?: boolean;
    defaultChecked?: boolean;
    name: string;
    label?: string;
    control: any;
    rules?: any;
    errors?: any;
}
const Checkbox = (props: Props) => {
    
    const { t } = useTranslation();
    
    return (
        <Controller
            name={props.name}
            rules={props.rules}
            control={props.control}
            defaultValue={props.defaultChecked}
            render={(
                { onChange, value },
            ) => (
                <FlexRow align={"center"}>
                    <RadixCheckbox.Root id={props.name} className={`${styles.checkboxRoot} ${value && styles.checked} `} checked={value} onCheckedChange={onChange}>
                        <RadixCheckbox.Indicator className={styles.checkboxIndicator}>
                            <CheckmarkIcon />
                        </RadixCheckbox.Indicator>
                    </RadixCheckbox.Root>
                    <label htmlFor={props.name} className={`${styles.label} ${props.rules?.required && "required"}`}>
                        {props.label}
                        {props.errors && (
                            props.errors.type === 'required' && (<><br /><span style={{color: 'red'}}>{t('required')}</span></>)
                        )}
                    </label>
                </FlexRow>
            )}
        />
    )
}

export default Checkbox
