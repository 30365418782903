import FlexRow from "Components/FlexRow";
import Space from "antd/lib/space";
import Spinner from "Components/Spinner";
import styles from './PageLoader.module.scss';

interface Props {
    shown: boolean;
    children: any;
}
const PageLoader = ({shown, children}: Props) => {
    
    return (
        <>
            <div className={`${styles.pageLoader} ${!shown ? styles.hidden :''}`}>
                <FlexRow justify={'center'} direction={"column"} align={"center"}>
                    <Space direction={"vertical"} size={"large"}>
                        <div style={{width: '200px'}}>
                            <svg id="Layer_1" data-name="Layer 1" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 735.49 183.61179">
                                <path fill={"#4882c3"} d="M476.6083,124.18277h-5.5a3.25441,3.25441,0,0,1-3.1-3.1v-6.4a20.7771,20.7771,0,0,1-4.5-1.9l-2.7,2.7-1.7,1.7a3.31208,3.31208,0,0,1-2.4,1,3.08331,3.08331,0,0,1-2.3-1l-.8-.8-4.8-4.8a3.25527,3.25527,0,0,1-1-2.3,3.08331,3.08331,0,0,1,1-2.3c.8-.8,1.6-1.7,2.5-2.5.6-.5999,1.3-1.3,1.9-1.9a22.52555,22.52555,0,0,1-1.9-4.5h-6.5a3.44537,3.44537,0,0,1-2.3-1,3.37586,3.37586,0,0,1-.9-2.5v-7.6a3.2951,3.2951,0,0,1,.9-2.4,3.21185,3.21185,0,0,1,2.2-1h6.40009a27.33127,27.33127,0,0,1,1.9-4.5q-1.35-1.35-2.7-2.7l-1.7-1.7a3.25527,3.25527,0,0,1-1-2.3,3.1017,3.1017,0,0,1,1-2.4q2.55-2.55,5.09991-5.1l.6-.6a3.16792,3.16792,0,0,1,2.3-.9,3.492,3.492,0,0,1,2.4,1c.7.7,3.6,3.6,4.40009,4.4a22.25579,22.25579,0,0,1,4.5-1.8v-6.2a3.19757,3.19757,0,0,1,1.79991-3,3.77647,3.77647,0,0,1,2-.4h7.6a3.25564,3.25564,0,0,1,3.1,3.1v6.5a28.82,28.82,0,0,1,4.5,1.9l4.4-4.4a3.25527,3.25527,0,0,1,2.3-1,2.89589,2.89589,0,0,1,2.3,1c1.9,1.9,3.8,3.7,5.6,5.6a2.81385,2.81385,0,0,1,.9,2.3,3.08331,3.08331,0,0,1-1,2.3l-.6.6-3.8,3.8a46.8379,46.8379,0,0,1,1.90009,4.5h6.39991a3.25527,3.25527,0,0,1,2.3,1,3.2951,3.2951,0,0,1,.9,2.4v7.7a3.2951,3.2951,0,0,1-.9,2.4,2.83592,2.83592,0,0,1-2.2,1h-6.5a25.28564,25.28564,0,0,1-1.9,4.5l4.4,4.4a3.25545,3.25545,0,0,1,1,2.3,3.1017,3.1017,0,0,1-1,2.4c-1.9,1.9001-3.7,3.8-5.6,5.6a3.16792,3.16792,0,0,1-2.3.9,3.492,3.492,0,0,1-2.4-1l-4.4-4.4a22.52031,22.52031,0,0,1-4.5,1.9v6.2001a3.1534,3.1534,0,0,1-1.6,2.9,3.86708,3.86708,0,0,1-2.3.5h-.3C478.3083,124.18277,477.2083,124.18277,476.6083,124.18277Z"/>
                                <path fill={"#248ffb"} d="M638.1083,58.68277c6.3,7.9,9.4,19.6,9.4,35.1,0,10.1-1.8,18.9-5.5,26.6a40.73094,40.73094,0,0,1-38.4,24.1c-4.8,0-10.7-1-17.7-2.9v40.3h-25.4v-97.4c0-9.3-.4-16-1.1-20.1a24.62437,24.62437,0,0,0-4.5-10.7,45.9266,45.9266,0,0,1,10.2-4.3,38.1654,38.1654,0,0,1,10.2-1.5,7.54325,7.54325,0,0,1,5.8,2.4c1.5,1.6,2.6,4.1,3.4,7.4a34.05314,34.05314,0,0,1,25.7-10.8C622.5083,46.78277,631.8083,50.78277,638.1083,58.68277Zm-21.9,60.6c3.7-5.8,5.6-14.3,5.6-25.5,0-10.6-1.40012-18.4-4.1-23.4s-7.1-7.5-13-7.5a23.373,23.373,0,0,0-10.6,2.6,23.77455,23.77455,0,0,0-8.1,6.3v53.1001a34.24082,34.24082,0,0,0,14.1,3.0999A17.58677,17.58677,0,0,0,616.2083,119.28277Z"/>
                                <path fill={"#248ffb"} d="M667.8083,139.38277c-6.5-3.4-9.7-7.8-9.7-13.2a11.01242,11.01242,0,0,1,3.3-8.1c2.2-2.2,5.5-3.5,9.7-3.7a25.31913,25.31913,0,0,0,9.3,10.3c3.8,2.3,8.7,3.4,14.5,3.4,10.1,0,15.1-3.8,15.1-11.3a7.51674,7.51674,0,0,0-4.3-7,89.99044,89.99044,0,0,0-14.1-5.7,159.91512,159.91512,0,0,1-16.4-6.3,32.00322,32.00322,0,0,1-11.1-9c-3.1-3.9-4.6-9.1-4.6-15.3a21.08788,21.08788,0,0,1,5.1-14.1,31.99491,31.99491,0,0,1,13.4-9.3,50.61872,50.61872,0,0,1,18.1-3.2c8.3,0,15.7,1.6,21.9,4.8a37.05838,37.05838,0,0,1,14.7,12.9,24.29589,24.29589,0,0,1-6.09992,7.5c-2.6,2.1-4.90008,3.2-6.90008,3.2a6.52766,6.52766,0,0,1-2.9-.6,8.49264,8.49264,0,0,1-2.4-2q-7.65-9.6-17.7-9.6c-4.3,0-7.7.8-10,2.5a8.6523,8.6523,0,0,0-3.5,7.5,9.04193,9.04193,0,0,0,4.7,8.1c3.1,1.8,8.1,3.7,14.8,5.7a104.11808,104.11808,0,0,1,15.9,5.4,27.90349,27.90349,0,0,1,10.6,8.4c2.9,3.7,4.4,8.6,4.4,14.7q0,14.25-10.2,21.9c-6.8,5.1-16.5,7.6-29.2,7.6C683.1083,144.48277,674.3083,142.78277,667.8083,139.38277Z"/>
                                <path fill={"#248ffb"} d="M89.1083,30.28277c0,3.9-.6,6.7-1.9,8.6a6.90577,6.90577,0,0,1-6,2.7h-23v101.2h-26.1v-101.1h-30.2v-18.9h86.4A34.87988,34.87988,0,0,1,89.1083,30.28277Z"/>
                                <path fill={"#248ffb"} d="M101.1083,17.78277v-2.6c0-8.9,4.5-13.4,13.4-13.4h2.4c8.9,0,13.4,4.5,13.4,13.4v2.6c0,8.9-4.5,13.4-13.4,13.4h-2.4C105.6083,31.18277,101.1083,26.68277,101.1083,17.78277Zm1.9,30.7h25.4v94.3h-25.4Z"/>
                                <path fill={"#248ffb"} d="M276.8083,54.78277c5.2,5.3,7.8,12.7,7.8,22v66h-25.5v-65.5c0-5.6-.8-9.4-2.3-11.4s-4.5-3-8.8-3c-6.5,0-12,2.9-16.5,8.6v71.4001h-25.4V75.68277c0-4.7999-.7-8.1-2-9.8999s-3.7-2.7001-7.3-2.7001c-6.2,0-12.4,3.7001-18.7,11.1v68.8h-25.4v-60.5q0-24.3-2.1-30a33.39019,33.39019,0,0,1,8.2-3.2,34.40546,34.40546,0,0,1,9.1-1.3q4.65,0,6.9,2.7t3,9a39.0953,39.0953,0,0,1,12.9-9.7,36.05766,36.05766,0,0,1,14.9-3,28.29894,28.29894,0,0,1,13.7,3.2,20.83337,20.83337,0,0,1,8.9,9.2001c6.4-8.2001,15.4-12.3,27.1-12.3C264.5083,46.78277,271.6083,49.48277,276.8083,54.78277Z"/>
                                <path fill={"#248ffb"} d="M390.2083,100.28277h-60.7c.6,8.7,2.7,15.4,6.3,20.2a16.82519,16.82519,0,0,0,14.1,7.2,18.33885,18.33885,0,0,0,6.6-1,17.14945,17.14945,0,0,0,5.5-3.7,81.33364,81.33364,0,0,0,7.5-8.7c.6-.9001,1.8-1.4001,3.6-1.4001a17.9255,17.9255,0,0,1,7.1,2,51.75112,51.75112,0,0,1,9,5.6q-11.1,24-39.3,24c-14.4,0-25.7-4.4-33.9-13.1s-12.3-20.7-12.3-35.9c0-9.8999,1.9-18.6,5.6-25.8999a39.944,39.944,0,0,1,15.9-16.9c6.9-3.9,15.1-5.9,24.6-5.9,13.1,0,23.3,3.7,30.5,11.1s10.8,17.9,10.8,31.5C391.1083,91.08277,390.8083,94.68277,390.2083,100.28277Zm-24.8-14.4c0-15.3-5.4-23-16.1-23-5.5,0-9.9,1.9-13.2,5.8s-5.4,9.6-6.2,17.1h35.5Z"/>
                                <path fill={"#248ffb"} d="M546.7083,64.48277a5.45575,5.45575,0,0,0-3.3-1.1,5.63867,5.63867,0,0,0-3.8,1.5,70.83684,70.83684,0,0,0-29.3-35.1,2.18589,2.18589,0,0,0-.5-.3,76.53133,76.53133,0,0,0-9.9-5,69.18226,69.18226,0,0,0-25.2-5.2h-1.7a68.60371,68.60371,0,0,0-12,1c-.8.1-1.5.3-2.3.4a.438.438,0,0,0-.3.1c-.5.1-1,.2-1.4.3a26.80765,26.80765,0,0,0-2.7.7c-.8.2-1.7.5-2.5.7a66.71143,66.71143,0,0,0-15.2,6.8,68.21279,68.21279,0,0,0-18.1,15.3,71.91763,71.91763,0,0,0-16.7,39.8,70.9016,70.9016,0,0,0,6.59989,36.9,72.08925,72.08925,0,0,0,32.40011,33.3,70.98686,70.98686,0,0,0,31.9,7.5999h.2a5.30678,5.30678,0,0,0,4.2-2,5.50068,5.50068,0,0,0,1.1-4.8,5.09453,5.09453,0,0,0-.7-1.7,5.59936,5.59936,0,0,0-4.7-2.5h0a59.01631,59.01631,0,0,1-23.2-4.7,61.51144,61.51144,0,0,1-21.70011-15.4,60.77257,60.77257,0,0,1-12.09989-20.5,59.14144,59.14144,0,0,1-2.1-32.3,60.74991,60.74991,0,0,1,21.1-34.6,60.05847,60.05847,0,0,1,34.09989-13.4c1.3-.1,2.70011-.1,4.1-.1a60.59,60.59,0,0,1,55.60011,36.6h-.6a5.45089,5.45089,0,0,0-3.8,1.5,4.80022,4.80022,0,0,0-1.6,3.9,5.52669,5.52669,0,0,0,1.6,3.8c1.9,1.8999,3.7,3.7999,5.6,5.7l3.59989,3.7a6.28767,6.28767,0,0,0,4.6,2,6.96285,6.96285,0,0,0,2.5-.5,2.18589,2.18589,0,0,0,.5-.3,6.30748,6.30748,0,0,0,3-3.6c1.2-3.3,2.3-6.6,3.5-9.9l.8-2.3A5.118,5.118,0,0,0,546.7083,64.48277Z"/>
                                <path fill={"#248ffb"} d="M470.2083,58.58277a6.44772,6.44772,0,0,1,2-.3h7.2a2.364,2.364,0,0,1,2.2,2.2v7.2a22.7877,22.7877,0,0,1,5.6,2.3c1.6-1.7,3.3-3.3,4.9-4.9a2.35342,2.35342,0,0,1,3.3,0q2.85,2.85,5.7,5.7001a2.3533,2.3533,0,0,1,0,3.2999l-4.9,4.9a30.47193,30.47193,0,0,1,2.4,5.6h7.1a2.26261,2.26261,0,0,1,2.2,2.4v7.7a2.2625,2.2625,0,0,1-2.2,2.4h-7.2a24.49915,24.49915,0,0,1-2.3,5.6c1.6,1.6,3.3,3.3,4.9,4.9001a2.35342,2.35342,0,0,1,0,3.3q-2.85,2.85-5.7,5.7a2.35342,2.35342,0,0,1-3.3,0c-1.7-1.6-3.3-3.3-4.9-4.9001a22.7877,22.7877,0,0,1-5.6,2.3v6.9001a2.47586,2.47586,0,0,1-1.1,2.0999,3.73239,3.73239,0,0,1-2.09991.3H471.2083a2.36419,2.36419,0,0,1-2.2-2.2v-7.2a27.47245,27.47245,0,0,1-5.59991-2.3c-1.6,1.7-3.3,3.3-4.90009,4.9a2.354,2.354,0,0,1-3.3,0c-1.9-1.9-3.79991-3.7-5.59991-5.5999a2.35325,2.35325,0,0,1,0-3.3c1.59991-1.7,3.3-3.3,4.9-5a22.7877,22.7877,0,0,1-2.3-5.6h-7.1a2.26256,2.26256,0,0,1-2.2-2.4v-7.7a2.2625,2.2625,0,0,1,2.2-2.4h7.1a28.33322,28.33322,0,0,1,2.3-5.7c-1.7-1.6001-3.3-3.3-4.9-4.9a2.35326,2.35326,0,0,1,0-3.3c1.9-1.9,3.8-3.8,5.59991-5.6a2.35342,2.35342,0,0,1,3.3,0c1.7,1.6,3.3,3.3,4.90009,4.9a21.29665,21.29665,0,0,1,5.59991-2.3v-6.9A2.00868,2.00868,0,0,1,470.2083,58.58277Z"/>
                                <path fill={"#faba45"} d="M473.2083,50.38277a3.00853,3.00853,0,0,0,3-3v-7.2a3.11767,3.11767,0,0,0-1.6-2.4,2.81672,2.81672,0,0,0-2.2-.3,6.89,6.89,0,0,0-.8.3,2.85152,2.85152,0,0,0-1.5,1.9c-.2,2.6-.1,5.1-.1,7.7A3.05456,3.05456,0,0,0,473.2083,50.38277Z"/>
                                <path fill={"#faba45"} d="M448.7083,54.68277a3.21674,3.21674,0,0,0,4.2,1.2,3.03554,3.03554,0,0,0,1.2-4.2c-1.1-2-2.3-3.9-3.4-5.9a3.50787,3.50787,0,0,0-2.3-1.6,4.102,4.102,0,0,0-1.1.1,3.06971,3.06971,0,0,0-2.1,4.4C446.4083,50.68277,447.5083,52.68277,448.7083,54.68277Z"/>
                                <path fill={"#faba45"} d="M493.1083,55.68277a3.11516,3.11516,0,0,0,4.4-.9c1.1-1.9,2.3-3.9,3.40009-5.9a3.10112,3.10112,0,0,0,.4-2.3,1.854,1.854,0,0,0-.2-.7,3.088,3.088,0,0,0-3.40009-1.7c-.09991,0-.09991,0-.2.1a2.94685,2.94685,0,0,0-2,1.6q-1.64987,2.85-3.3,5.7A3.047,3.047,0,0,0,493.1083,55.68277Z"/>
                                <path fill={"#faba45"} d="M428.1083,68.38277c1.9,1.1,3.9,2.3,5.9,3.4a3.183,3.183,0,0,0,4.2-1.1,3.05413,3.05413,0,0,0-.9-4.2c-1.7-1-3.5-2-5.2-3a4.75893,4.75893,0,0,0-2.5-.9,1.26834,1.26834,0,0,0-.6.1,2.9775,2.9775,0,0,0-2.3,2.7,2.827,2.827,0,0,0,.40009,1.8A1.94538,1.94538,0,0,0,428.1083,68.38277Z"/>
                                <path fill={"#faba45"} d="M512.4083,71.68277c1.9-1.1,3.9-2.2,5.9-3.4a2.41006,2.41006,0,0,0,1-1,3.20267,3.20267,0,0,0,.3-2.7,3.12357,3.12357,0,0,0-3-2.1,2.20185,2.20185,0,0,0-.8.1c-1.2.5-2.3,1.3-3.5,1.9-1.1.7-2.3,1.3-3.4,2a3.13618,3.13618,0,0,0,3.5,5.2Z"/>
                                <path fill={"#faba45"} d="M432.7083,90.28277a3.10557,3.10557,0,0,0-3.4-2.6c-2.5.1-4.90011-.2-7.40011.1h0a3.14811,3.14811,0,0,0-1.9,4.1,3.07337,3.07337,0,0,0,2.1,1.9,2.20185,2.20185,0,0,0,.8.1h6.9A3.08368,3.08368,0,0,0,432.7083,90.28277Z"/>
                                <path fill={"#faba45"} d="M524.0083,87.68277c-.2,0-.4-.1-.6-.1h-6.1a4.67822,4.67822,0,0,0-1.7.2,3.19129,3.19129,0,0,0-1.6,4.7,3.44907,3.44907,0,0,0,3.3,1.4h6.4a3.04833,3.04833,0,0,0,2.7-3.1A3.10372,3.10372,0,0,0,524.0083,87.68277Z"/>
                                <path fill={"#faba45"} d="M434.3083,109.68277c-2.1,1.1-4.1,2.3-6.1,3.5h-.09991a3.11015,3.11015,0,0,0,2.9,5.5l.6-.3c1.8-1,3.59991-2.1,5.4-3.1a3.16265,3.16265,0,0,0,1.3-3.9A2.93852,2.93852,0,0,0,434.3083,109.68277Z"/>
                                <path fill={"#faba45"} d="M518.2083,113.18277c-.2-.1-.3-.2-.5-.3-1.8-1.1-3.6-2.1-5.4-3.1a3.20267,3.20267,0,0,0-2.7-.3,3.13367,3.13367,0,0,0-.90011,5.3c1.6,1.1,3.3,2,5,3a2.09049,2.09049,0,0,1,.6.4,4.18654,4.18654,0,0,0,2.1.8,3.01889,3.01889,0,0,0,3.1-2.3A2.91558,2.91558,0,0,0,518.2083,113.18277Z"/>
                                <path fill={"#faba45"} d="M450.4083,125.48277a3.20086,3.20086,0,0,0-1.9,1.7c-.9,1.6-1.8,3.1-2.7,4.6-.2.3-.40011.7-.6,1a3.06815,3.06815,0,0,0,1.2,4.0999,3.283,3.283,0,0,0,4.2-1c.2-.4.4-.7.6-1.0999.9-1.6,1.8-3.2,2.8-4.7a3.17917,3.17917,0,0,0,0-3.5A3.13205,3.13205,0,0,0,450.4083,125.48277Z"/>
                                <path fill={"#faba45"} d="M500.4083,131.68277c-.9-1.7-1.9-3.3-2.9-5a3.18179,3.18179,0,0,0-3.7-1.3,3.03741,3.03741,0,0,0-2,3,4.911,4.911,0,0,0,1,2.5c.8,1.3,1.5,2.6,2.3,3.9a4.12931,4.12931,0,0,0,.7,1.1,3.07727,3.07727,0,0,0,4.3.8,3.15512,3.15512,0,0,0,1-4.1A1.59584,1.59584,0,0,1,500.4083,131.68277Z"/>
                                <path fill={"#faba45"} d="M472.5083,131.18277a3.08042,3.08042,0,0,0-2.5,2.9v7a3.20316,3.20316,0,0,0,3.2,3.1,3.166,3.166,0,0,0,3.1-3v-7A3.26888,3.26888,0,0,0,472.5083,131.18277Z"/>
                            </svg>
                        </div>
                        <Spinner size={"large"} />
                    </Space>
                </FlexRow>
            </div>
            <div className={`${styles.content} ${shown ? styles.hidden :''}`}>
                {!shown && (
                    <>
                        {children}
                    </>
                )}
            </div>
        </>
    )
}

export default PageLoader
