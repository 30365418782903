import { Navigate, Route, Routes, useLocation, useNavigate } from "react-router-dom";
import InvoicesPage from "Pages/Dinero/InvoicesPage";
import EditInvoicePage from "Pages/Dinero/EditInvoicePage";
import {useDineroIntegration} from "Pages/Dinero/Api/DineroIntegrationApi";
import FullPageSpinner from "Components/FullPageSpinner";
import OnboardingPage from "Pages/Dinero/OnboardingPage";
import DineroSettingsPage from "Pages/Dinero/DineroSettingsPage";
import DineroContactsPage from "Pages/Dinero/DineroContactsPage";
import DineroReconnectPage from "Pages/Dinero/DineroReconnectPage";

const DineroRoutes = () => {
    
    const { data: dineroIntegration, isLoading} = useDineroIntegration();

    const { pathname } = useLocation();
    const navigate = useNavigate();

    if (isLoading) {
        return (
            <FullPageSpinner />
        )
    }

    if (!pathname.startsWith("/dinero/onboarding") && (!dineroIntegration || dineroIntegration?.integrationStatus < 3)) {
        navigate("/dinero/onboarding");
    }
    
    if (!pathname.startsWith("/dinero/reconnect") && dineroIntegration?.integrationStatus === 5) {
        navigate("/dinero/reconnect");
    }
    
    return (
        <Routes>
            <Route path={`invoices`} element={<InvoicesPage />} />
            
            <Route path={`invoices/:guid`} element={<EditInvoicePage />} />

            <Route path={`contacts`} element={<DineroContactsPage />} />
            
            <Route path={`settings`} element={<DineroSettingsPage />} />
            
            <Route path={`reconnect`} element={<DineroReconnectPage />} />
            
            <Route path={`onboarding`} element={<OnboardingPage />} />

            <Route path={"*"} element={<Navigate to={`${pathname}/invoices`}/>} />
        </Routes>
    )
}

export default DineroRoutes
