const GoogleIcon = () => {
    
    return (
        <div className={"h-100 flex align-center"}>
            <svg id="google" xmlns="http://www.w3.org/2000/svg" width="31.71" height="31.71" viewBox="0 0 31.71 31.71">
                <path id="Path_657" data-name="Path 657" d="M62.519,43.979H47.289v6.272h8.478a9.426,9.426,0,1,1-3.843-11.31l5.136-4.205a15.826,15.826,0,1,0,5.458,9.243Z" transform="translate(-31.086 -31.086)" fill="#fbbb00"/>
                <path id="Path_658" data-name="Path 658" d="M81.782,311.979h0a15.86,15.86,0,0,1-23.891-4.851l5.138-4.206a9.429,9.429,0,0,0,13.588,4.828Z" transform="translate(-56.001 -283.76)" fill="#28b446"/>
                <path id="Path_659" data-name="Path 659" d="M276.172,213.983a15.849,15.849,0,0,1-5.652,15.326h0l-5.164-4.228a9.45,9.45,0,0,0,4.066-4.825h-8.478v-6.272h15.23Z" transform="translate(-244.739 -201.09)" fill="#518ef8"/>
                <path id="Path_660" data-name="Path 660" d="M80.436,34.736,75.3,38.941a9.428,9.428,0,0,0-13.9,4.937L56.235,39.65h0a15.858,15.858,0,0,1,24.2-4.913Z" transform="translate(-54.461 -31.086)" fill="#fc3f1d"/>
            </svg>
        </div>
    )
}

export default GoogleIcon
