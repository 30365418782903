import * as React from "react";
import styles from './ListTitle.module.scss';

interface Props {
    children: any
}
const ListTitle = (props: Props) => {
    
    return (
        <span className={styles.title}>
            {props.children}
        </span>
    )
}

export default React.memo(ListTitle)
