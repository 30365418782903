import * as React from "react"

const Delete = (props: React.SVGProps<SVGSVGElement>) => {
    return (
        <svg
            xmlns="http://www.w3.org/2000/svg"
            width={32}
            height={32}
            viewBox="0 0 32 32"
            {...props}
        >
            <defs>
                <clipPath id="prefix__a">
                    <path
                        data-name="Rectangle 242"
                        transform="translate(1711 216)"
                        fill="none"
                        stroke="#707070"
                        d="M0 0h32v32H0z"
                    />
                </clipPath>
            </defs>
            <g
                data-name="Mask Group 9"
                transform="translate(-1711 -216)"
                clipPath="url(#prefix__a)"
            >
                <path
                    data-name="plus (1)"
                    d="M1715.486 220.804a15.833 15.833 0 1027.013 11.691 15.628 15.628 0 00-4.62-11.692 15.851 15.851 0 00-22.393.001zm17.027 7.231l-3.965 3.965 3.965 3.965a1.32 1.32 0 11-1.866 1.866l-3.965-3.965-3.966 3.966a1.32 1.32 0 11-1.866-1.867l3.966-3.965-3.966-3.965a1.32 1.32 0 111.866-1.867l3.966 3.966 3.965-3.965a1.32 1.32 0 111.866 1.866zm0 0"
                    fill="#f74425"
                />
            </g>
        </svg>
    )
}

export default Delete
