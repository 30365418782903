import * as React from "react"
import { SVGProps } from "react"
const PaperCSVIcon = (props: SVGProps<SVGSVGElement>) => (
    <svg xmlns="http://www.w3.org/2000/svg" width={16}
         height={16}
         viewBox={"0 0 16 16"} {...props}>
        <g fill={props.fill ?? "#4d576d"} data-name="Group 1111">
            <path
                d="M14.594 7.562h-.5V4.219a.473.473 0 0 0-.137-.331L10.206.137A.473.473 0 0 0 9.875 0H3.313a1.408 1.408 0 0 0-1.407 1.406v6.156h-.5A1.408 1.408 0 0 0 0 8.969v5.625A1.408 1.408 0 0 0 1.406 16h13.188A1.408 1.408 0 0 0 16 14.594V8.969a1.408 1.408 0 0 0-1.406-1.407ZM10.344 1.6l2.15 2.15h-1.681a.469.469 0 0 1-.469-.469Zm-7.5-.194a.469.469 0 0 1 .469-.469h6.093v2.344a1.408 1.408 0 0 0 1.406 1.406h2.344v2.875H2.844Zm12.219 13.188a.469.469 0 0 1-.469.469H1.406a.469.469 0 0 1-.469-.469V8.969a.469.469 0 0 1 .469-.469h13.188a.469.469 0 0 1 .469.469Z"
                data-name="Path 1213"
            />
            <path
                d="M4.25 10.375a.469.469 0 0 1 .469.469.469.469 0 0 0 .938 0 1.406 1.406 0 0 0-2.812 0v1.875a1.407 1.407 0 0 0 2.813 0 .469.469 0 0 0-.937 0 .469.469 0 0 1-.937 0v-1.875a.469.469 0 0 1 .466-.469Z"
                data-name="Path 1214"
            />
            <path
                d="M8 11.312a.469.469 0 1 1 .331-.8.469.469 0 1 0 .663-.663 1.406 1.406 0 1 0-.994 2.4.469.469 0 1 1-.331.8.469.469 0 1 0-.663.663 1.406 1.406 0 1 0 .994-2.4Z"
                data-name="Path 1215"
            />
            <path
                d="M12.801 9.452a.469.469 0 0 0-.568.341l-.483 1.931-.483-1.931a.469.469 0 0 0-.909.227l.938 3.75a.469.469 0 0 0 .909 0l.938-3.75a.469.469 0 0 0-.342-.568Z"
                data-name="Path 1216"
            />
        </g>
    </svg>
)
export default PaperCSVIcon
