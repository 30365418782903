const unitTranslationKeys = {
    day: "dinero:unit.day",
    shipment: "dinero:unit.shipment",
    carton: "dinero:unit.carton",
    box: "dinero:unit.box",
    kilogram: "dinero:unit.kilogram",
    km: "dinero:unit.km",
    cubicMetre: "dinero:unit.cubicMetre",
    squareMetre: "dinero:unit.squareMetre",
    litre: "dinero:unit.litre",
    month: "dinero:unit.month",
    metre: "dinero:unit.metre",
    package: "dinero:unit.package",
    session: "dinero:unit.session",
    parts: "dinero:unit.parts",
    case: "dinero:unit.case",
    set: "dinero:unit.set",
    hours: "dinero:unit.hours",
    tonne: "dinero:unit.tonne",
    week: "dinero:unit.week",
}

export const getUnitTextFromUnit = (unit) => {
    return unitTranslationKeys[unit]
}
