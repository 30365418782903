import * as React from "react"
import { SVGProps } from "react"

const PlusIcon = (props: SVGProps<SVGSVGElement>) => (
    <svg xmlns="http://www.w3.org/2000/svg" width="25" height="25" viewBox={"0 0 25 25"} {...props}>
        <g data-name="Group 1457">
            <g data-name="Group 1059" fill="#0d60f8">
                <path
                    data-name="Line 77"
                    d="M25.028 12.514a1.078 1.078 0 0 1-1.078 1.078H1.078a1.078 1.078 0 0 1 0-2.156H23.95a1.078 1.078 0 0 1 1.078 1.078Z"
                />
                <path
                    data-name="Line 78"
                    d="M12.514 25.028a1.078 1.078 0 0 1-1.078-1.078V1.078a1.078 1.078 0 0 1 2.156 0V23.95a1.078 1.078 0 0 1-1.078 1.078Z"
                />
                <path
                    data-name="Line 192"
                    d="M12.513 25.028a1.078 1.078 0 0 1-1.078-1.078V1.078a1.078 1.078 0 0 1 2.156 0V23.95a1.078 1.078 0 0 1-1.078 1.078Z"
                />
            </g>
        </g>
    </svg>
)

export default PlusIcon
