import { useCallback, useEffect, useState } from "react";
import { useNavigate, useParams } from "react-router-dom";
import {
    useBookInvoice, useDefaultInvoiceSettings, useDeleteInvoice,
    useGetInvoiceTotals,
    useInvoice,
    useUpdateInvoice
} from "Pages/Dinero/Api/DineroInvoiceApi";
import Header from "Components/Header";
import Spinner from "Components/Spinner";
import { createInvoiceLineFromReport, getInvoiceNumber, getInvoiceStatusAsText } from "Pages/Dinero/Utils/InvoiceUtils";
import FlexRow from "Components/FlexRow";
import moment from "moment";
import Col  from "antd/lib/col";
import DatePicker from "antd/lib/date-picker";
import Row from "antd/lib/row";
import Select from "antd/lib/select";
import Space from "antd/lib/space";
import { Controller, useFieldArray, useForm } from "react-hook-form";
import TextArea from "antd/lib/input/TextArea";
import { getNumberAsCurrency } from "Utils/NumberUtils";
import Button  from "Components/Button/Button";
import {
    DineroInvoiceModel,
    DineroInvoiceTotalLineModel
} from "Pages/Dinero/Api/Models/DineroInvoiceModel";
import _ from "lodash";
import { openErrorNotification, openSuccessNotification } from "Utils/NotificationUtils";
import { useTranslation } from "react-i18next";
import FindRegistrationsModal from "Pages/Dinero/Components/FindRegistrationsModal";
import { ReportModel } from "Apis/Models/ReportModel";
import { ReportSearchParams } from "Hooks/UseReporting";
import styles from './EditInvoicePage.module.scss';
import InvoiceLine from "Pages/Dinero/Components/InvoiceLine";
import PaperLayout from "Pages/Dinero/Components/PaperLayout";
import SendInvoiceModal from "Pages/Dinero/Components/SendInvoiceModal";
import { useDineroContacts } from "Pages/Dinero/Api/DineroIntegrationApi";
import SendInvoiceWithEANModal from "Pages/Dinero/Components/SendInvoiceWithEANModal";
import { getInputClasses } from "Utils/FormUtils";
import { useAddTagsToRegistrations } from "Apis/RegistrationsApi";

const EditInvoicePage = () => {
    
    const { guid } = useParams();
    
    const { t } = useTranslation();
    
    const navigate = useNavigate();
    
    const [ invoiceTotals, setInvoiceTotals ] = useState<DineroInvoiceTotalLineModel[]>([]);
    const [ invoiceModel, setInvoiceModel ] = useState<DineroInvoiceModel>();
    
    const [ showSendModal, setShowSendModal ] = useState<boolean>(false);
    const [ showSendWithEanModal, setShowSendWithEanModal ] = useState<boolean>(false);
    const [ showFindRegistrationsModal, setShowFindRegistrationsModal ] = useState<boolean>(false);
    
    const { data: invoice, isLoading: isLoadingInvoice } = useInvoice(guid!);
    const { data: contacts, isLoading: isLoadingCustomers } = useDineroContacts();
    const { data: defaultInvoiceSettings } = useDefaultInvoiceSettings();
    
    const { mutateAsync: getInvoiceTotalsMutation, isLoading: loadingInvoiceTotals } = useGetInvoiceTotals();
    const { mutateAsync: updateInvoiceMutation, isLoading: isSavingInvoice } = useUpdateInvoice();
    const { mutateAsync: bookInvoiceMutation, isLoading: isBookingInvoice } = useBookInvoice();
    const { mutateAsync: addTagsToRegistrationsMutation } = useAddTagsToRegistrations();
    const { mutateAsync: deleteInvoiceMutation, isLoading: isDeletingInvoice } = useDeleteInvoice();
    
    const { register, control, reset, handleSubmit, errors: invoiceErrors, getValues, trigger } = useForm<DineroInvoiceModel>();
    const { fields, append, remove } = useFieldArray({
        keyName: 'idKey',
        control: control,
        name: "productLines",
    });
    
    useEffect(() => {
        if (invoice) {
            setInvoiceTotals(invoice.totalLines);
            setInvoiceModel(invoice);
            reset(invoice);
        }
    }, [ invoice, reset ])
    
    const getInvoiceTotals = async() => {
        if (await trigger()) {
            const data = getValues();
            
            const invoiceTotals = await getInvoiceTotalsMutation(data);
            
            setInvoiceModel(invoiceTotals);
            setInvoiceTotals(invoiceTotals!.totalLines);
        }
    }
    
    const updateInvoice = async() => {
        await handleSubmit(async(data: any) => {
            
            data = {
                ...invoice,
                ...data,
            }
            
            if (!data.contactGuid) {
                openErrorNotification(t('dinero:edit.infoMissing'), t('dinero:edit.customerMissing'))
                return;
            }
            
            if (data.productLines?.length === 0) {
                openErrorNotification(t('dinero:edit.infoMissing'), t('dinero:edit.atLeastOneLine'))
                return;
            }
            
            await updateInvoiceMutation(data);
            
            openSuccessNotification(t('success'), t('dinero:edit.draftSaved'))
        })()
    }
    
    const bookInvoice = async() => {
        await updateInvoice();
        
        await bookInvoiceMutation(invoice!.guid)
    
        openSuccessNotification(t('success'), t('dinero:edit.invoiceBooked'))
    }
    
    const addSelectedEntriesAsLines = async(report: ReportModel, selectedEntries: number[], searchParams: ReportSearchParams, tags: number[]) => {
        const invoiceLines = createInvoiceLineFromReport(report, selectedEntries, searchParams, defaultInvoiceSettings!)
        
        for (let i = 0; i < invoiceLines.length; i++) {
            await append(invoiceLines[i])
        }
    
        if (tags?.length > 0) {
            let timeEntries = selectedEntries;
            
            if (searchParams.groupBy) {
                timeEntries = report.groupedRegistrations.filter(x => selectedEntries.includes(x.groupKey)).flatMap(group => group.registrations.map(registration => registration.id!)) ?? [];
            }
            
            await addTagsToRegistrationsMutation({
                registrationIds: timeEntries,
                tagIds: tags
            });
        }
        
        getInvoiceTotals();
        setShowFindRegistrationsModal(false)
    }
    
    const addNewLine = () => {
        append({
            baseAmountValue: 0,
            quantity: 1,
            discount: 0,
            unit: "hours"
        })
    }
    
    const removeLine = async(index: number) => {
        await remove(index);
        
        getInvoiceTotals();
    }
    
    const deleteInvoice = async () => {
        await deleteInvoiceMutation(guid!)
        
        openSuccessNotification(t('success'), t('dinero:invoiceDeleted'))
        
        navigate(`/dinero/invoices`)
    }
    
    const filterOption = (value, option) => {
        const { children } = option;
        
        if (option.options) {
            return option.options.includes((child) => child.props.children.toLowerCase().indexOf(value.toLowerCase()) >= 0);
        }
        
        return children.toLowerCase().indexOf(value.toLowerCase()) >= 0;
    };
    
    const selectProps = {
        showSearch: true,
        filterOption: filterOption
    }
    
    // eslint-disable-next-line react-hooks/exhaustive-deps
    const debouncedGetInvoiceTotals = useCallback(_.debounce(getInvoiceTotals, 300), []);
    
    if (!invoice || isLoadingInvoice || isLoadingCustomers) {
        return <Spinner/>
    }
    
    return (
        <>
            <FlexRow justify={"space-between"}>
                <Header text={`${t('dinero:invoice')} ${getInvoiceNumber(invoice.number)} - ${getInvoiceStatusAsText(invoice.status)}`} />
                
                <Space>
                    {invoice.status === "Draft" && (
                        <>
                            <Button onClick={deleteInvoice} color={"danger"} loading={isDeletingInvoice} disabled={isDeletingInvoice}>{t('dinero:edit.deleteInvoice')}</Button>
                            <Button onClick={updateInvoice} loading={isSavingInvoice} disabled={isSavingInvoice || isBookingInvoice}>{t('dinero:edit.saveDraft')}</Button>
                            <Button color={"primary"} onClick={bookInvoice} loading={isBookingInvoice} disabled={isSavingInvoice || isBookingInvoice}>{t('dinero:edit.approve')}</Button>
                        </>
                    )}
                    {invoice.status !== "Draft" && (
                        <>
                            <Button onClick={() => setShowSendWithEanModal(true)}>{t('dinero:edit.sendWithEan')}</Button>
                            <Button color={"primary"} onClick={() => setShowSendModal(true)}>{t('dinero:edit.sendInvoice')}</Button>
                        </>
                    )}
                </Space>
            </FlexRow>
            <PaperLayout>
                <div>
                    <Space direction={"vertical"} className={"w-100"} style={{ maxWidth: '300px' }}>
                        {invoice.status === "Draft" ? (
                            <>
                                <Controller name={"contactGuid"} control={control} defaultValue={null} render={(props) => (
                                    <Select
                                        {...selectProps}
                                        placeholder={"Vælg kunde"}
                                        value={props.value}
                                        onChange={props.onChange}
                                        style={{ width: '100%', borderColor: 'blue' }}
                                        loading={isLoadingCustomers}
                                    >
                                        <Select.Option value={""} style={{ width: '100%' }}>
                                            {t('dinero:edit.selectCustomer')}
                                        </Select.Option>
                                        {contacts?.map((contacts) => (
                                            <Select.Option value={contacts.id} key={contacts.id}>
                                                {contacts.name}
                                            </Select.Option>
                                        ))}
                                    </Select>
                                )}/>
                                
                                <Controller name={"address"} control={control} defaultValue={null} render={(props) => (
                                    <TextArea rows={4} onChange={props.onChange} value={props.value}/>
                                )}/>
                            </>
                        ) : (
                            <div>
                                <div>
                                    <b>
                                        {invoice.contactName}
                                    </b>
                                </div>
                                <div style={{ whiteSpace: 'pre-line' }}>
                                    {invoice.address}
                                </div>
                            </div>
                        )}
                    </Space>
                </div>
                
                <FlexRow direction={"column"} justify={"center"} align={"start"} className={styles.dateAndCommentContainer}>
                    <div className={styles.dateContainer}>
                        <FlexRow justify={"space-between"}>
                            {invoice.status === "Draft" ? (
                                <Controller
                                    name={"date"}
                                    control={control}
                                    defaultValue={moment()}
                                    render={(props) => (
                                        <DatePicker
                                            
                                            value={moment(props.value)}
                                            onChange={e => {
                                                props.onChange(e!.format('YYYY-MM-DD'))
                                            }}
                                            picker="date"
                                            format="DD/MM/YYYY"
                                        />
                                    )}/>
                            ) : (
                                <div className={"flex"}>
                                    {t('dinero:edit.date')}:
                                    <div style={{ fontWeight: 'bold' }}>
                                        {moment(invoice!.date).format('DD/MM/YYYY')}
                                    </div>
                                </div>
                            )}
                            <div className={"flex"}>
                                {t('dinero:invoiceNumber')}:
                                <div style={{ fontWeight: 'bold' }}>
                                    {invoice.number}
                                </div>
                            </div>
                        </FlexRow>
                    </div>
                    
                    <div>
                        {invoice.status === "Draft" ? (
                            <div className={styles.commentContainer}>
                                <Controller name={"comment"} control={control} defaultValue={null} render={(props) => (
                                    <TextArea placeholder={t('dinero:edit.comment')} onChange={props.onChange} value={props.value}/>
                                )}/>
                            </div>
                        ) : (
                            invoice.comment && (
                                <div className={styles.commentContainer}>
                                    <div style={{ whiteSpace: 'pre-line' }}>
                                        {invoice.comment}
                                    </div>
                                </div>
                            )
                        )}
                    </div>
                </FlexRow>
                
                <div>
                    <Row gutter={[ 5, 3 ]}>
                        <Col span={24}>
                            {invoice.status === "Draft" ? (
                                <Controller
                                    name={`description`}
                                    control={control}
                                    defaultValue={invoice.description}
                                    rules={{required: true}}
                                    render={(props) => (
                                        <input value={props.value} className={getInputClasses(_.get(invoiceErrors, `description`))} onChange={e => {
                                            props.onChange(e);
                                        }}/>
                                    )}
                                />
                            ) : (
                                <div style={{ fontSize: '1.2em', fontWeight: 'bold' }}>
                                    {t('dinero:invoice')}
                                </div>
                            )}
                        </Col>
                        <Col span={24}>
                            <hr/>
                        </Col>
                        <Col span={8}>
                            {t('dinero:edit.description')}
                        </Col>
                        <Col span={3}>
                            <FlexRow justify={"end"} align={"center"} className={"h-100"}>
                                {t('dinero:edit.quantity')}
                            </FlexRow>
                        </Col>
                        <Col span={3}>
                            {t('dinero:edit.unit')}
                        </Col>
                        <Col span={3}>
                            {t('dinero:edit.unitPrice')}
                        </Col>
                        <Col span={3}>
                            {t('dinero:edit.discount')} %
                        </Col>
                        <Col span={3}>
                            <FlexRow justify={"end"}>
                                {t('dinero:edit.price')}
                            </FlexRow>
                        </Col>
                        <Col span={24}>
                            <hr/>
                        </Col>
                        {fields.map((line, index) => (
                            <InvoiceLine
                                key={line.idKey}
                                status={invoice.status}
                                control={control}
                                errors={invoiceErrors}
                                line={line}
                                index={index}
                                getInvoiceTotals={debouncedGetInvoiceTotals}
                                invoiceModel={invoiceModel}
                                register={register}
                                loadingInvoiceTotals={loadingInvoiceTotals}
                                removeLine={removeLine}
                            />
                        ))}
                        
                        
                        {invoice.status === "Draft" && (
                            <Col span={24}>
                                
                                <Space>
                                    <Button onClick={addNewLine}>+ {t('dinero:edit.newInvoiceLine')}</Button>
                                    
                                    <Button onClick={() => setShowFindRegistrationsModal(true)}>{t('dinero:edit.findRegistrations')}</Button>
                                </Space>
                            </Col>
                        )}
                        
                        <Col span={24}>
                            <hr />
                        </Col>
                    </Row>
                    
                    <FlexRow justify={"end"}>
                        <div className={styles.totalContainer}>
                            {invoiceTotals?.sort((a, b) => a.position - b.position).map(line => (
                                <FlexRow justify={"space-between"} key={line.label}>
                                    {line.label}
                                    <b>
                                        {loadingInvoiceTotals ? (
                                            <Spinner/>
                                        ) : (
                                            getNumberAsCurrency(line.totalAmount)
                                        )}
                                    </b>
                                </FlexRow>
                            ))}
                        </div>
                    </FlexRow>
                </div>
            </PaperLayout>
            
            {invoice.status !== "Draft" ? (
                <>
                    <SendInvoiceWithEANModal invoiceGuid={guid!} visible={showSendWithEanModal} close={() => setShowSendWithEanModal(false)} contactGuid={invoice.contactGuid} />
                    <SendInvoiceModal visible={showSendModal} close={() => setShowSendModal(false)} invoiceGuid={guid!} />
                </>
            ) : (
                <FindRegistrationsModal visible={showFindRegistrationsModal} onOk={addSelectedEntriesAsLines} onCancel={() => {
                    setShowFindRegistrationsModal(false)
                }} initialValues={{ customerId: contacts?.find(x => x.id === invoiceModel?.contactGuid)?.customerId, groupBy: 'project', billable: 'Billable' }}/>
            )}
            
        </>
    )
}

export default EditInvoicePage
