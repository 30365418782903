import { useContext, useEffect, useState } from 'react';
import moment from "moment";
import {Registration} from "Apis/Models/Registration.type";
import {useSaveRegistration} from "Apis/RegistrationsApi";
import RegistrationModalV2 from "Components/RegistrationModalV2";
import { Calendar, momentLocalizer } from 'react-big-calendar'
import withDragAndDrop from "react-big-calendar/lib/addons/dragAndDrop";
import WeekCalendarHeader from "Pages/Registrations/Components/WeekCalendarHeader";
import WeekCalendarTImeGutterHeader from "Pages/Registrations/Components/WeekCalendarTImeGutterHeader";
import {TimeSlotContextProvider} from "Pages/Registrations/Components/WeekCalendarTimeSlotProvider";
import {Project} from "Apis/Models/Project.type";
import WeekCalendarToolbar from "Pages/Registrations/Components/WeekCalendarToolbar";
import {DashboardTypeKey} from "Pages/Registrations/Dashboard";
import CalendarEvent from "Pages/Registrations/Components/CalendarEvent";
import { CalendarRegistrationContext } from "Pages/Registrations/Components/Providers/CalendarRegistrationProvider";

const DnDCalendar = withDragAndDrop(Calendar);

const localizer = momentLocalizer(moment)

const WeekCalendar = () => {
    
    const timeSlotProvider = useContext(TimeSlotContextProvider);
    const { setFrom, setTo, registrations, now } = useContext(CalendarRegistrationContext);

    const [modalOpen, setModalOpen] = useState<boolean>(false);
    const [selectedRegistration, setSelectedRegistration] = useState<Registration>();

    const [registrationClones, setRegistrationClones] = useState<Registration[]>([]);

    const { mutateAsync: saveRegistrationMutation } = useSaveRegistration();

    useEffect(() => {
        localStorage.setItem(DashboardTypeKey, 'Week');
    }, [])

    useEffect(() => {
        setRegistrationClones(registrations ?? [])
    }, [registrations])

    const onRangeChanged = async (dates: Date[]) => {
        setFrom(dates[0])
        setTo(dates[dates.length - 1])
    }

    const onEventDrop = async (event: any) => {

        const registration: Registration = {
            ...event.event,
            startedAt: event.start,
            stoppedAt: event.event.stoppedAt === null ? null : event.end,
        };

        // Update the internal registration Clones - We do this to make sure the dragged registraion doesnt jump around
        const clones = registrationClones.slice().filter(x => x.id !== registration.id);

        clones.push(registration);

        setRegistrationClones(clones)

        await saveRegistrationMutation(registration);
    }

    const eventSelected = (event) => {
        setSelectedRegistration(event);
        setModalOpen(true)
    }

    const closeModal = () => {
        setModalOpen(false);
        setSelectedRegistration(undefined)
    }

    const createNewRegistration = event => {
        setSelectedRegistration({
            description: '',
            startedAt: event.start,
            stoppedAt: event.end,
            billable: false
        });
        setModalOpen(true)
    }

    return (
        <div style={{height: '100%'}}>
            <DnDCalendar
                views={['week']}
                localizer={localizer}
                defaultView={'week'}
                onRangeChange={onRangeChanged}
                showMultiDayTimes
                showAllDay={false}
                onEventDrop={onEventDrop}
                onEventResize={onEventDrop}
                onSelectEvent={eventSelected}
                resizable
                events={registrationClones}
                slotGroupPropGetter={() => {
                    return {
                        style: {
                            minHeight: '80px'
                        }
                    }
                }}
                endAccessor={(event: Registration) => event.stoppedAt ?? new Date()}
                startAccessor={(event: Registration) => event.startedAt}
                titleAccessor={(event: Registration) => event?.description}
                scrollToTime={moment(now).subtract('30', 'minutes').toDate()}
                tooltipAccessor={(event: Registration) => event.description}
                selectable
                timeslots={timeSlotProvider.numberOfSlots}
                eventPropGetter={e => e.stoppedAt == null ? {className: "rbc-timeops-running-event"} : {}}
                resourceIdAccessor={(resource: Project) => resource.id}
                resourceTitleAccessor={(resource: Project) => resource?.name}
                resourceAccessor={(event: Registration) => event.projectId}
                step={5}
                formats={{
                    eventTimeRangeFormat: props => ''
                }}
                components={{
                    timeGutterHeader: WeekCalendarTImeGutterHeader,
                    toolbar: WeekCalendarToolbar,
                    day: {
                        header: WeekCalendarHeader,
                        event: CalendarEvent,
                    },
                    week: {
                        header: WeekCalendarHeader,
                        event: CalendarEvent,
                    }
                    
                }}
                onSelectSlot={createNewRegistration}
            />
            <RegistrationModalV2
                visible={modalOpen}
                registration={selectedRegistration}
                onCancel={closeModal}
            />
        </div>
    );
}

export default WeekCalendar
