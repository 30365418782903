import { useTranslation } from "react-i18next";
import React, { useEffect, useRef, useState } from "react";
import { useForm, Controller } from "react-hook-form";
import Space from "antd/lib/space";
import FlexRow from "Components/FlexRow";
import Button from "Components/Button/Button";
import { User } from "Apis/Models/User.type";
import { useDeactivateUser, useDeleteUser, useSaveUser } from "Apis/UsersApi";
import RequiresPremiumModal from "Components/RequiresPremiumModal";
import { openSuccessNotification } from "Utils/NotificationUtils";
import { useAuthProvider } from "Providers/AuthProvider";
import Modal from "Components/Modal";
import ControlledInput from "Components/InputV2";
import SelectV2 from "Components/SelectV2";
import PopConfirm from "Components/PopConfirm";


interface Props {
    visible: boolean;
    user?: Partial<User>;
    onOk?: Function;
    onCancel?: Function;
}
const UserModal = (props: Props) => {
    
    const { t } = useTranslation();
    const { userContext } = useAuthProvider()
    
    const nameRef = useRef<HTMLInputElement>();
    const [isUpdate, setIsUpdate] = useState<boolean>();
    
    const { register, control, handleSubmit, errors, reset } = useForm<User>();
    
    const { mutateAsync: saveUserMutation, isLoading: isSavingProject } = useSaveUser();
    const { mutateAsync: deleteUserMutation, isLoading: isDeletingUser } = useDeleteUser();
    const { mutateAsync: deactivateUserMutation, isLoading: isDeactivatingUser } = useDeactivateUser();
    
    useEffect(() => {
        if (props.visible && nameRef.current) {
            nameRef.current.focus();
        }
    }, [nameRef, props.visible])
    
    useEffect(() => {
        if (props.user) {
            reset(props.user)
        }
        
        setIsUpdate(!!props.user)
    }, [props.user, reset])
    
    const onOk = async (data: User) => {
        
        const response = await saveUserMutation(data);
        
        if (isUpdate) {
            openSuccessNotification(t('success'), t('organization:users.userUpdated'))
        } else {
            openSuccessNotification(t('success'), t('organization:users.userCreated'))
        }
        
        reset({});
        
        if (props.onOk) {
            props.onOk(response);
        }
    }
    
    const onCancel = () => {
        reset({});
        
        if (props.onCancel) {
            props.onCancel();
        }
    }
    
    const deleteUser = async () => {
        await deleteUserMutation(props.user!.id!);
        
        onCancel();
    }
    
    const deactivateUser = async () => {
        await deactivateUserMutation(props.user!.id!);
        
        onCancel();
    }
    
    return (
        <Modal visible={props.visible}  onCancel={onCancel} title={isUpdate ? t('organization:users.updateUser') : t('organization:users.createUser')}>
            {!isUpdate && (
                <div style={{borderRadius: 3, padding: '20px', marginBottom: 20, backgroundColor: '#E3E3E3'}}>
                    {t('organization:users.createUserDescription')}
                </div>
            )}
            <form onSubmit={handleSubmit(onOk)}>
                <input type="text" style={{display: 'none'}} ref={register} name={"id"} />
                <Space className={"w-100"} size={"large"} direction={"vertical"}>
                    <Space className={"w-100"} size={"middle"} direction={"vertical"}>
                        
                        <ControlledInput placeholder={t('name')} name={"name"} control={control} rules={{required: true}} errors={errors.name} />
                        
                        <ControlledInput disabled={isUpdate} placeholder={t('email')} name={"eMail"} control={control} rules={{required: true}} errors={errors.eMail} />
    
                        <Controller name={"accessLevel"} control={control} defaultValue={200} render={props => (
                            <RequiresPremiumModal enabled={!userContext?.hasActiveLicense} title={t('organization:users.accessLevels')}>
                                <SelectV2 disabled={!userContext?.hasActiveLicense} name={"accessLevel"} onChange={props.onChange} placeholder={t('organization:users.accessLevel')} value={props.value}>
                                    <SelectV2.Option label={t('organization:users.admin')} value={200} />
                                    <SelectV2.Option label={t('organization:users.projectManager')} value={150} />
                                    <SelectV2.Option label={t('organization:users.employee')} value={100} />
                                </SelectV2>
                            </RequiresPremiumModal>
                        )} />
    
                        <RequiresPremiumModal enabled={!userContext?.hasActiveLicense} title={t('organization:users.hourlyRate')}>
                            <ControlledInput name={"hourlyRate"} control={control} placeholder={t('rate')} type={"number"}  />
                        </RequiresPremiumModal>
                    
                    </Space>
                    <FlexRow justify={"space-between"}>
                        <div>
                            {isUpdate && (
                                <Space>
                                    <PopConfirm
                                        title={t('areYouSure')}
                                        onConfirm={() => deleteUser()}
                                    >
                                        <Button loading={isDeletingUser} color={"danger"}>{t('delete')}</Button>
                                    </PopConfirm>
                                    <PopConfirm
                                        title={t('areYouSure')}
                                        onConfirm={() => deactivateUser()}
                                    >
                                        <Button loading={isDeactivatingUser} color={props.user?.deactivatedAt ? 'primary' : 'danger'}>{props.user?.deactivatedAt ? t('organization:users.activate') : t('deactivate')}</Button>
                                    </PopConfirm>
                                </Space>
                            )}
                        </div>
                        <Space>
                            <Button onClick={onCancel}>{t('cancel')}</Button>
                            <Button color={"primary"} type={'submit'} loading={isSavingProject} >{t('save')}</Button>
                        </Space>
                    </FlexRow>
                </Space>
            </form>
        </Modal>
    )
}

export default UserModal
