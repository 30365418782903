import { useEffect } from "react";
import Select from "antd/lib/select";
import Space from "antd/lib/space";
import Button from "Components/Button/Button";
import {useTranslation} from "react-i18next";
import { useDineroIntegration, useSaveDineroIntegration } from "Pages/Dinero/Api/DineroIntegrationApi";
import { useForm, Controller } from "react-hook-form";
import { DineroIntegrationReadModel } from "Pages/Dinero/Api/Models/DineroIntegrationReadModel";
import { openSuccessNotification } from "Utils/NotificationUtils";
import FlexRow from "Components/FlexRow";
import TagSelect from "Components/TagSelect";

const DineroSettings = () => {
    
    const { t } = useTranslation();
    
    const { control, handleSubmit, reset } = useForm<DineroIntegrationReadModel>();
    
    const { data } = useDineroIntegration();
    const { mutateAsync: saveIntegrationMutation, isLoading: isSaving} = useSaveDineroIntegration();
    
    useEffect(() => {
        if (data) {
            reset(data)
        }
    }, [data, reset])
    
    const saveIntegration = async (data) => {
        await saveIntegrationMutation(data);
    
        openSuccessNotification(t('success'), t('dinero:settings.settingsUpdated'))
    }
    
    return (
        <form onSubmit={handleSubmit(saveIntegration)}>
            
            <Space direction={"vertical"} className={"w-100"}>
                
                <div className={"flex column"}>
                    <label>
                        {t('dinero:settings.customersToSynchronize')}
                    </label>
                    <Controller name={"customerImportSetting"} control={control} defaultValue={null} rules={{required: true}} render={props => (
                        <Select value={props.value} onChange={props.onChange} className={"w-100"}>
                            <Select.Option value={0}>{t('dinero:settings.allCustomers')}</Select.Option>
                            <Select.Option value={1}>{t('dinero:settings.onlyCustomers')}</Select.Option>
                            <Select.Option value={2}>{t('dinero:settings.manually')}</Select.Option>
                        </Select>
                    )} />
                </div>
    
                <div className={"flex column"}>
                    <label>
                        {t('dinero:settings.autoTagRegistrations')}
                    </label>
                    <Controller name={"autoTagRegistrations"} control={control} defaultValue={[]} render={props => (
                        <TagSelect onChange={props.onChange} value={props.value} className={"w-100"} />
                    )} />
                </div>
                
                <FlexRow justify={"end"}>
                    <Button loading={isSaving} disabled={isSaving} type={"submit"} color={"primary"}>{t('save')}</Button>
                </FlexRow>
            </Space>
        </form>
    )
}

export default DineroSettings;
