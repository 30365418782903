import {request} from "./BaseApi";
import {CreateOrganization, CreateOrganizationV2, Organization} from "./Models/Organization.type";
import {useMutation, useQuery} from "react-query";
import { OrganizationCreatedResponse } from "Apis/Models/OrganizationCreatedResponse";

export const useCreateOrganization = () => {
    return useMutation(async (organization: CreateOrganization) => {
        return await request<Organization>({
            url: '/organization',
            method: 'POST',
            data: organization
        })
    }, {
        onError: () => {}
    })
}

export const useCreateOrganizationV2 = () => {
    return useMutation(async (organization: CreateOrganizationV2) => {
        return await request<OrganizationCreatedResponse>({
            url: '/organization/v2',
            method: 'POST',
            data: organization
        })
    }, {
        onError: () => {}
    })
}

export const ORGANIZATION_CACHE_KEY = 'ORGANIZATION_CACHE_KEY';
export const useOrganization = () => {
    return useQuery(ORGANIZATION_CACHE_KEY, async () => {
        return request<Organization>({
            url: '/organization',
            method: 'GET'
        })
    })
}

export const useSaveOrganization = () => {
    return useMutation(async (organization: Organization) => {
        return await request<Organization>({
            url: '/organization',
            method: 'PUT',
            data: organization
        })
    })
}


export const useDeleteOrganization = () => {
    return useMutation(async (organization: {organizationName: string}) => {
        return await request<Organization>({
            url: `/organization?organizationName=${organization.organizationName}`,
            method: 'delete',
        })
    })
}
