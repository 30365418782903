import { useMutation, useQuery, useQueryClient } from "react-query";
import {request} from "Apis/BaseApi";

export const ECONOMIC_INTEGRATION_CACHE_KEY = "ECONOMIC_INTEGRATION_CACHE_KEY"
export const useEconomicIntegration = () => {
    return useQuery([ECONOMIC_INTEGRATION_CACHE_KEY], async () => {
        return await request<any>({
            url: `/integration/economic`,
            method: 'GET',
        })
    })
}

export const ECONOMIC_CUSTOMERS_CACHE_KEY = "ECONOMIC_CUSTOMERS_CACHE_KEY"
export const useEconomicCustomers = () => {
    return useQuery([ECONOMIC_CUSTOMERS_CACHE_KEY], async () => {
        return await request<any>({
            url: `/integration/economic/customers`,
            method: 'GET',
        })
    })
}

export const useEconomicTokenCallback = () => {
    return useMutation(async (token: string) => {
        return await request({
            url: `/integration/economic/token`,
            method: 'POST',
            data: {
                token
            }
        })
    })
}

export const useChangeShouldBeSynchronized = () => {
    const queryClient = useQueryClient();
    
    return useMutation(async (customerNumber: number) => {
        return await request({
            url: `/integration/economic/customers/${customerNumber}/shouldBeSynchronized`,
            method: 'PUT'
        })
    }, {
        onSuccess: () => {
            queryClient.invalidateQueries(ECONOMIC_CUSTOMERS_CACHE_KEY)
        }
    })
}

export const useStartEconomicIntegration = () => {
    return useMutation(async () => {
        return await request({
            url: `/integration/economic/start`,
            method: 'PUT'
        })
    })
}
