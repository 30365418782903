import Progress from "antd/lib/progress";
import {RegistrationUtils} from "Utils/RegistrationUtils";
import styles from './Duration.module.scss';
import { CSSProperties, memo, useEffect, useState } from "react";

interface Props {
    duration?: number;
    showSpinner?: boolean;
    style?: CSSProperties;
    timeType?: 'Hours' | 'Decimal';
}
const Duration = ({ duration, showSpinner, style, timeType }: Props) => {
    
    const [localDuration, setLocalDuration] = useState<number>(0);
    
    useEffect(() => {
        setLocalDuration(duration ?? 0)
    }, [duration])

    return (
        <div className={styles.container} style={{...style}}>
            {RegistrationUtils.getFormattedDurationString(localDuration, timeType)}
            {showSpinner && <Progress style={{marginLeft: '5px'}} width={25} type="circle" showInfo={false} strokeWidth={12} percent={(100 / 60) * RegistrationUtils.getSecondsFromDuration(localDuration)}/>}
        </div>
    );

}

export default memo(Duration);
