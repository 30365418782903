import styles from './Components/Layout.module.scss';
import Button from "Components/Button/Button";
import { Link } from 'react-router-dom';
import ControlledInput from "Components/InputV2/ControlledInput";
import { useForm } from "react-hook-form";
import Divider from "antd/lib/divider";
import Space from "antd/lib/space";
import { useState } from "react";
import {signInWithGoogle} from 'FirebaseSetup/auth';
import { useTranslation } from "react-i18next";
import { openErrorNotification } from "Utils/NotificationUtils";
import Layout from "Pages/Auth/Components/Layout";
import { useAuthProvider } from "Providers/AuthProvider";
import SignUpSteps from "Pages/Auth/Components/SignUpSteps";
import GoogleIcon from "Assets/Icons/GoogleIcon";


interface SignupFormFields {
    name: string;
    email: string;
    password: string;
}
const SignupPage = () => {
    
    const { t } = useTranslation();
    
    const { handleSubmit, control, errors } = useForm<SignupFormFields>();
    const { signUp } = useAuthProvider();
    
    const [isCreatingUser, setIsCreatingUser] = useState<boolean>(false);
    
    const onSubmit = (data: SignupFormFields) => {
        setIsCreatingUser(true)
    
        signUp(data).catch(e => {
            let error;
    
            if (e.code === 'auth/email-already-in-use') {
                error = t('auth:userExists');
            }
            if (e.code === 'auth/weak-password') {
                error = t('auth:weekPassword');
            }
            if (e.code === 'auth/invalid-email') {
                error = t('auth:invalidEmail');
            }
            if (e.code === 'auth/network-request-failed') {
                error = t('auth:unexpectedError');
            }
    
            openErrorNotification(t('errors:anErrorOccurred'), error, 'topLeft');
            setIsCreatingUser(false)
        });
    }
    
    return (
        <Layout
            preHeader={t('auth:welcome')}
            header={t('auth:signupTitle')}
            imgUrl={"/images/appMockupV2.png"}
            steps={<SignUpSteps activeStep={0} />}
        >
            <form onSubmit={handleSubmit(onSubmit)}>
                <Space size={"large"} direction={"vertical"} className={"w-100"}>
    
                    <ControlledInput name={"name"} control={control} errors={errors} placeholder={t('auth:nameLabel')} rules={{required: true}} />
                    <ControlledInput name={"email"} control={control} errors={errors} placeholder={t('auth:emailLabel')} rules={{required: true}} suffix={'@'} />
                    <ControlledInput name={"password"} control={control} errors={errors} placeholder={t('auth:passwordLabel')} type={"password"} rules={{required: true, minLength: 6}} />
            
                    <Button loading={isCreatingUser} type={"submit"} color={"primary"} block><span style={{fontSize: '1.5em', fontWeight: 600}}>{t('auth:createUser')}</span></Button>
                </Space>
            </form>
    
            <Divider plain style={{color: '#9AAAC9'}}>{t('auth:or')}</Divider>
    
            <Button block onClick={signInWithGoogle}>
                <GoogleIcon />
                <span style={{fontSize: '1.5em', fontWeight: 600}}>
                    {t('auth:signupWithGoogle')}
                </span>
            </Button>
    
            <div className={styles.link}>{t('auth:alreadyHaveAnAccount')} <Link to={"/auth/login"}>{t('auth:login')}</Link></div>
        </Layout>
    )
}

export default SignupPage
