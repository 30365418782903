import { useMutation, useQuery, useQueryClient } from "react-query";
import {request} from "Apis/BaseApi";
import {
    DineroCollectionModel,
    DineroInvoiceEmailTemplateModel,
    DineroInvoiceModel
} from "Pages/Dinero/Api/Models/DineroInvoiceModel";
import { DineroSendInvoiceWithEanModel } from "Pages/Dinero/Api/Models/DineroSendInvoiceWithEanModel";
import { RawDineroContactModel } from "Pages/Dinero/Api/Models/RawDineroContactModel";
import { DefaultInvoiceSettings } from "Pages/Dinero/Api/Models/DefaultInvoiceSettings";

export const DINERO_INVOICES_CACHE_KEY = 'DINERO_INVOICES_CACHE_KEY';
export const useInvoices = () => {
    return useQuery(DINERO_INVOICES_CACHE_KEY, async () => {
        return await request<DineroCollectionModel<DineroInvoiceModel>>({
            url: `/integration/dinero/invoice`,
            method: 'GET'
        });
    })
}

export const useInvoice = (guid: string) => {
    return useQuery([DINERO_INVOICES_CACHE_KEY, guid], async () => {
        return await request<DineroInvoiceModel>({
            url: `/integration/dinero/invoice/${guid}`,
            method: 'GET'
        });
    }, {
        refetchOnWindowFocus: false
    })
}

export const DINERO_INVOICE_EMAIL_TEMPLATE_CACHE_KEY = 'DINERO_INVOICE_EMAIL_TEMPLATE_CACHE_KEY';
export const useInvoiceEmailTemplate = (guid: string) => {
    return useQuery([DINERO_INVOICES_CACHE_KEY, DINERO_INVOICE_EMAIL_TEMPLATE_CACHE_KEY, guid], async () => {
        return await request<DineroInvoiceEmailTemplateModel>({
            url: `/integration/dinero/invoice/${guid}/emailtemplate`,
            method: 'GET'
        });
    }, {
        refetchOnWindowFocus: false
    })
}

export const useGetInvoiceTotals = () => {
    return useMutation(async (invoice: DineroInvoiceModel): Promise<DineroInvoiceModel> => {
        return await request<DineroInvoiceModel>({
            url: `/integration/dinero/invoice/totals`,
            method: 'POST',
            data: invoice
        })
    })
}

export const useCreateInvoice = () => {
    
    const queryClient = useQueryClient();
    
    return useMutation(async (invoice: Partial<DineroInvoiceModel>): Promise<{guid}> => {
        return await request<{guid}>({
            url: `/integration/dinero/invoice`,
            method: 'POST',
            data: invoice
        })
    }, {
        onSuccess: () => {
            queryClient.invalidateQueries(DINERO_INVOICES_CACHE_KEY)
        }
    })
}

export const useUpdateInvoice = () => {
    
    const queryClient = useQueryClient();
    
    return useMutation(async (invoice: DineroInvoiceModel): Promise<DineroInvoiceModel> => {
        return await request<DineroInvoiceModel>({
            url: `/integration/dinero/invoice/${invoice.guid}`,
            method: 'PUT',
            data: invoice
        })
    }, {
        onSuccess: () => {
            queryClient.invalidateQueries(DINERO_INVOICES_CACHE_KEY)
        }
    })
}

export const useBookInvoice = () => {
    
    const queryClient = useQueryClient();
    
    return useMutation(async (guid: string): Promise<DineroInvoiceModel> => {
        return await request<DineroInvoiceModel>({
            url: `/integration/dinero/invoice/${guid}/book`,
            method: 'POST',
        })
    }, {
        onSuccess: () => {
            queryClient.invalidateQueries(DINERO_INVOICES_CACHE_KEY)
        }
    })
}

export const useSendInvoice = () => {
    
    const queryClient = useQueryClient();
    
    return useMutation(async ({guid, emailOptions}: {guid: string, emailOptions: DineroInvoiceEmailTemplateModel}): Promise<DineroInvoiceModel> => {
        return await request<DineroInvoiceModel>({
            url: `/integration/dinero/invoice/${guid}/send`,
            method: 'POST',
            data: emailOptions
        })
    }, {
        onSuccess: () => {
            queryClient.invalidateQueries(DINERO_INVOICES_CACHE_KEY)
        }
    })
}

export const useSendInvoiceWithEan = () => {
    
    const queryClient = useQueryClient();
    
    return useMutation(async ({guid, emailOptions}: {guid: string, emailOptions: DineroSendInvoiceWithEanModel}): Promise<DineroInvoiceModel> => {
        return await request({
            url: `/integration/dinero/invoice/${guid}/send/ean`,
            method: 'POST',
            data: emailOptions
        })
    }, {
        onSuccess: () => {
            queryClient.invalidateQueries(DINERO_INVOICES_CACHE_KEY)
        }
    })
}

export const DINERO_RAW_CONTACT_KEY = 'DINERO_RAW_CONTACT_KEY'
export const useDineroContact = (guid: string) => {
    return useQuery<RawDineroContactModel>([DINERO_RAW_CONTACT_KEY, guid], async () => {
        return await request<RawDineroContactModel>({
            url: `/integration/dinero/invoice/contact/${guid}`,
        })
    })
}

export const DINERO_DEFAULT_INVOICE_SETTINGS = 'DINERO_DEFAULT_INVOICE_SETTINGS'
export const useDefaultInvoiceSettings = () => {
    return useQuery<DefaultInvoiceSettings>(DINERO_DEFAULT_INVOICE_SETTINGS, async () => {
        return await request<DefaultInvoiceSettings>({
            url: `/integration/dinero/invoice/settings`,
        })
    })
}

export const useDeleteInvoice = () => {
    const queryClient = useQueryClient();
    
    return useMutation(async (invoiceGuid: string) => {
        return await request({
            url: `/integration/dinero/invoice/${invoiceGuid}`,
            method: 'DELETE'
        })
    }, {
        onSuccess: () => {
            queryClient.invalidateQueries(DINERO_INVOICES_CACHE_KEY);
        }
    })
}
