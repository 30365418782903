import HostingIssues from "Assets/Icons/HostingIssues";
import FlexRow from "Components/FlexRow";

const SystemError = () => {
    
    return (
        <FlexRow justify={"center"} align={"center"} direction={"column"} className={"h-100"}>
            <div style={{width: 400}}>
                <HostingIssues />
                <div>
                    <h1 style={{color: '#adadad'}}>Der skete en fejl</h1>
                
                    <h3>
                        Prøv at genindlæse siden, hvis problemer fortsætter så tag et kig på vores status side
                    </h3>
                
                    <a href="https://timeops.statuspage.io">Status side</a>
                </div>
            </div>
        </FlexRow>
    )
}

export default SystemError
