import styles from './Default.module.scss';
import { useLocation, useNavigate } from 'react-router-dom';
import Menu from "Navigation/Menu";
import {useTranslation} from "react-i18next";
import Timer from "Components/Timer";
import { useScrollContainerProvider } from "Providers/ScrollContainerProvider";
import { memo, useEffect, useState } from "react";
import FlexRow from "Components/FlexRow";
import Button from "Components/Button/Button";
import Modal from "Components/Modal";
import Space from "antd/lib/space";
import EditRegistrationModalProvider from "Pages/Registrations/Components/Providers/EditRegistrationModalProvider";
type Props = {
    children: any
}

const DefaultLayout = (props: Props) => {

    const { t } = useTranslation();
    const [showWelcomeModal, setShowWelcomeModal] = useState<boolean>(false);
    
    const navigate = useNavigate();
    const location = useLocation();
    
    const { setScrollContainerRef } = useScrollContainerProvider()
    
    useEffect(() => {
        let query = new URLSearchParams(location.search).get('tutorialStep');
    
        setShowWelcomeModal(query === '0');
        
    }, [location])

    return (
        <div style={{height: '100vh'}}>
            <EditRegistrationModalProvider>
                <div className={styles.container}>
                    <div className={styles.menuContainer}>
                        <Menu />
                    </div>
                    <div className={styles.contentContainer}>
                        <Timer />
                        <div className={styles.contentInnerContainer} ref={e => setScrollContainerRef(e)}>
                            {props.children}
                        </div>
                    </div>
                </div>
            </EditRegistrationModalProvider>

            <Modal
                title={t('welcome.title')}
                visible={showWelcomeModal}
                onCancel={() => setShowWelcomeModal(false)}
            >
                <h1>{t('welcome.headline')}</h1>
                <p>{t('welcome.description1')}</p>
                <p>{t('welcome.description2')}</p>
                
                <Space direction={"vertical"} size={"large"}>
                    <div className={"flex"}>
                        <div style={{width: '30%'}}>
                            <img width={'100%'} style={{borderRadius: 100, }} src="/images/morten.jpg" alt="Morten Ricki Rasmussen"/>
                        </div>
                        <div className={"flex align-center justify-center"} style={{marginLeft: 40}}>
                            <span>
                                {t('welcome.kindRegards')}<br/>
                                <b>Morten Ricki Rasmussen</b><br/>
                                {t('welcome.creatorOfTimeOps')}
                            </span>
                        </div>
                    </div>
                    
                    <FlexRow justify={"end"}>
                        <Space >
                            <Button onClick={() => navigate('/registrations/dashboard')}>{t('welcome.cancel')}</Button>
                            <Button onClick={() => navigate('/registrations/dashboard?tutorialStep=1')} color={"primary"}>{t('welcome.continue')}</Button>
                        </Space>
                    </FlexRow>
                </Space>
            </Modal>
        </div>
    )
}

export default memo(DefaultLayout);
