import { Customer } from "Apis/Models/Customer.type";

import Space from "antd/lib/space";
import { useEffect, useRef, useState } from "react";
import { useForm } from "react-hook-form";
import { useDeleteCustomer, useSaveCustomer } from "Apis/CustomerApi";
import { useTranslation } from "react-i18next";
import FlexRow from "Components/FlexRow";
import Button from "Components/Button/Button";
import { useAuthProvider } from "Providers/AuthProvider";
import ControlledInput from "Components/InputV2/ControlledInput";
import Modal from "Components/Modal";
import PopConfirm from "Components/PopConfirm";

interface Props {
    visible: boolean;
    customer?: Customer;
    onOk?: Function;
    onCancel?: Function;
    initialValues?: Partial<Customer>
}
const CustomerModal = (props: Props) => {
    
    const { t } = useTranslation();
    
    const nameRef = useRef<HTMLInputElement>(null);
    const [isUpdate, setIsUpdate] = useState<boolean>();
    
    const { userContext } = useAuthProvider();
    const { register, handleSubmit, reset, control } = useForm<Customer>();
    
    const { mutateAsync: saveCustomerMutation, isLoading: isSavingCustomer } = useSaveCustomer();
    const { mutateAsync: deleteCustomerMutation, isLoading: isDeletingCustomer } = useDeleteCustomer();
    
    useEffect(() => {
        if (props.visible && nameRef.current) {
            nameRef.current.focus();
        }
    }, [nameRef, props.visible])
    
    useEffect(() => {
        if (props.customer) {
            reset(props.customer)
        } else if (props.initialValues) {
            reset(props.initialValues)
        }
        
        setIsUpdate(!!props.customer)
    }, [props.customer, props.initialValues, reset, props.visible])
    
    const deleteCustomer = async () => {
        await deleteCustomerMutation(props.customer!);
        
        onCancel();
    }
    
    const onOk = async (data: Customer) => {
        
        const customer = await saveCustomerMutation(data);
        
        reset({});
        
        if (props.onOk) {
            props.onOk(customer);
        }
    }
    
    const onCancel = () => {
        reset({});
        
        if (props.onCancel) {
            props.onCancel();
        }
    }
    
    return (
        <Modal visible={props.visible} onCancel={onCancel} title={isUpdate ? t('customers:updateCustomer') : t('customers:createCustomer')}>
            <form onSubmit={handleSubmit(onOk)}>
                <input type="text" style={{display: 'none'}} ref={register} name={"id"} />
                <Space className={"w-100"} size={"large"} direction={"vertical"}>
                    <Space className={"w-100"} size={"middle"} direction={"vertical"}>
                    
                    <ControlledInput name={"name"} inputRef={nameRef} rules={{required: true}} control={control} placeholder={t('name')} />
                    
                    <ControlledInput name={"vatNumber"} control={control} placeholder={t('vatNumber')} />
    
                    <ControlledInput containerStyle={{display: userContext?.hasActiveLicense ? undefined : 'none'}} name={"defaultRate"} number control={control} placeholder={t('customers:defaultRate')} />

                </Space>
                <FlexRow justify={"space-between"}>
                    <div>
                        {isUpdate && (
                            <PopConfirm
                                title={t('areYouSure')}
                                onConfirm={() => deleteCustomer()}
                            >
                                <Button loading={isDeletingCustomer} color={"danger"}>{t('delete')}</Button>
                            </PopConfirm>
                        )}
                    </div>
                    <Space>
                        <Button onClick={onCancel}>{t('cancel')}</Button>
                        <Button color={"primary"} type={'submit'} loading={isSavingCustomer} >{t('save')}</Button>
                    </Space>
                </FlexRow>
            </Space>
            </form>
        </Modal>
    )
}

export default CustomerModal
