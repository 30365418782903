import Header from "Components/Header";
import { useTranslation } from "react-i18next";
import { useEffect, useRef, useState } from "react";
import { useCustomers } from "Apis/CustomerApi";
import User from "Assets/Icons/User";
import Edit from "Assets/Icons/Edit";
import Button from "Components/Button/Button";
import FlexRow from "Components/FlexRow";
import CustomerModal from "Pages/Customers/Components/CustomerModal";
import { Customer } from "Apis/Models/Customer.type";
import { useNavigate } from "react-router-dom";
import List from "Components/List/List";
import useQueryParam from "Hooks/UseQueryParam";
import Tooltip from "Components/Tooltip";
import CaretRight from "Assets/Icons/CaretRight";
import Add from "Assets/Icons/Add";
import ProjectModal from "Pages/Customers/Components/ProjectModal";
import InputV2 from "Components/InputV2/InputV2";

const CustomersPage = () => {
    
    const { t } = useTranslation();
    const navigate = useNavigate();
    
    const searchRef = useRef<HTMLInputElement>(null);
    
    const [customerModalVisible, setCustomerModalVisible] = useState<boolean>(false);
    const [projectModalVisible, setProjectModalVisible] = useState<boolean>(false);
    const [searchFilter, setSearchFilter] = useQueryParam('query');
    const [selectedCustomer, setSelectedCustomer] = useState<Customer | undefined>();
    
    const { data: customers, isLoading } = useCustomers();
    
    useEffect(() => {
        if (searchRef.current) {
            searchRef.current.focus();
        }
    }, [searchRef])
    
    const getCustomers = () => {
        let filteredCustomers = customers;
        
        if (searchFilter) {
            const lowerCaseSearch = searchFilter.toLowerCase();
            
            filteredCustomers = customers?.filter(x => x.name.toLowerCase().includes(lowerCaseSearch) || x.vatNumber?.toString().includes(lowerCaseSearch))
        }
        
        return filteredCustomers?.sort((a,b) => a.name.toLowerCase()! > b.name.toLowerCase()! ? 1 : -1)
    }
    
    const addProject = (customer: Customer) => {
        setSelectedCustomer(customer);
        setProjectModalVisible(true)
    }
    
    const editCustomer = (customer: Customer) => {
        setSelectedCustomer(customer);
        setCustomerModalVisible(true)
    }
    
    const closeCustomerModal = (customer: Customer) => {
        setSelectedCustomer(undefined)
        setCustomerModalVisible(false);
        
        if (customer) {
            navigate(`/customers/${customer.id}/projects`)
            
        }
    }
    
    const closeProjectModal = () => {
        setSelectedCustomer(undefined)
        setProjectModalVisible(false);
    }
    
    return (
        <div>
            <Header text={t('customers:customers')} />
            
            <FlexRow justify={"space-between"} style={{marginBottom: 10}}>
                <div style={{width: '100%', maxWidth: '280px'}}>
                    <InputV2 inputRef={searchRef} placeholder={t('customers:customerSearch')} type="text" value={searchFilter} onChange={e => setSearchFilter(e)} containerStyle={{backgroundColor: 'white'}} />
                </div>
                
                <div>
                    <Button color={"primary"} onClick={() => setCustomerModalVisible(true)}>
                        + {t('customers:createCustomer')}
                    </Button>
                </div>
            </FlexRow>
            
            <List loading={isLoading} data={getCustomers()} emptyText={!!searchFilter ? `${t('customers:noCustomersFoundSearch')} ${searchFilter}` : t('customers:noCustomersFound')} keyDataIndex={'id'} columns={[
                {
                    icon: <User />,
                    title: t('customers:customer'),
                    dataIndex: 'name'
                },
                {
                    title: t('customers:defaultRate'),
                    dataIndex: 'defaultRate',
                    textRender: (_, record) => { return !record.defaultRate ? t('customers:noDefaultRate') : record.defaultRate}
                },
                {
                    size: 'min-content',
                    customRender: (_, record) => {
                        return (
                            <FlexRow justify={"center"} align={"center"}>
                                <Tooltip title={t('customers:editCustomer')}>
                                    <div className={"flex w-100 align-center clickable"} onClick={() => editCustomer(record)}>
                                        <Edit />
                                    </div>
                                </Tooltip>
                            </FlexRow>
                        )
                    }
                },
                {
                    size: 'min-content',
                    customRender: (_, record) => {
                        return (
                            <div className={"flex w-100 align-center clickable ellipsis"} onClick={() => navigate(`/customers/projects/${record.id}`)}>
                                <span>
                                    {t('customers:showProjects')}: <b>{record.projects.length}</b>
                                </span>
                                <CaretRight height={15} width={15} fill={"#bdbdbd"} />
                            </div>
                        )
                    }
                },
                {
                    size: 'min-content',
                    customRender: (_, record) => {
                        return (
                            <FlexRow justify={"center"} align={"center"}>
                                <Tooltip title={t('customers:createProject')}>
                                    <div className={"flex w-100 align-center clickable"} onClick={() => addProject(record)}>
                                        <Add />
                                    </div>
                                </Tooltip>
                            </FlexRow>
                        )
                    }
                },
            ]} />
            
            <CustomerModal visible={customerModalVisible} customer={selectedCustomer} onOk={closeCustomerModal} onCancel={closeCustomerModal} />
            <ProjectModal visible={projectModalVisible} onOk={closeProjectModal} onCancel={closeProjectModal} initialValues={{customerId: selectedCustomer?.id}} />
        </div>
    )
}

export default CustomersPage
