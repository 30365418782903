import { useTranslation } from "react-i18next";
import { useEffect, useRef, useState } from "react";
import { useCustomers } from "Apis/CustomerApi";
import FlexRow from "Components/FlexRow";
import Button from "Components/Button/Button";
import List from "Components/List/List";
import Edit from "Assets/Icons/Edit";
import { useNavigate, useParams } from "react-router-dom";
import { Project } from "Apis/Models/Project.type";
import ProjectModal from "Pages/Customers/Components/ProjectModal";
import Space from "antd/lib/space";
import Tooltip from "antd/lib/tooltip";
import ListTitle from "Components/List/ListTitle";
import ListText from "Components/List/ListText";
import Delete from "Assets/Icons/Delete";
import { useDeleteProject } from "Apis/ProjectsApi";
import useQueryParam from "Hooks/UseQueryParam";
import CustomerSelect from "Components/CustomerSelect";
import styles from './CustomerProjectsPage.module.scss';
import InputV2 from "Components/InputV2/InputV2";
import PopConfirm from "Components/PopConfirm";


const CustomerProjectsPage = () => {
    
    const { t } = useTranslation();
    const navigate = useNavigate();
    
    const { customerId } = useParams();
    const [ customerIdValues, setCustomerIdValues ] = useState<number | undefined>();
    
    const searchRef = useRef<HTMLInputElement>(null);
    
    const [modalVisible, setModalVisible] = useState<boolean>(false);
    const [searchFilter, setSearchFilter] = useQueryParam('query');
    const [selectedProject, setSelectedProject] = useState<Project | undefined>();
    
    const { data: customers, isLoading: isLoadingCustomers } = useCustomers();
    const { mutateAsync: deleteProjectMutation } = useDeleteProject();
    
    useEffect(() => {
        if (searchRef.current) {
            searchRef.current.focus();
        }
    }, [searchRef])
    
    useEffect(() => {
        if (customerId) {
            setCustomerIdValues(parseInt(customerId))
        } else {
            setCustomerIdValues(undefined)
        }
    }, [customerId])
    
    const getCustomer = () => customers?.find(x => x.id === customerIdValues)
    
    const getProjects = () => {
        let projectList = customers?.flatMap(c => c.projects);
        
        if (customerIdValues) {
            projectList = getCustomer()?.projects;
        }
        
        if (projectList) {
            
            if (searchFilter) {
                const lowerCaseSearch = searchFilter.toLowerCase();
                
                projectList = projectList?.filter(x => x.name.toLowerCase().includes(lowerCaseSearch))
                
                return projectList?.sort((a,b) => a.name.toLowerCase()! > b.name.toLowerCase()! ? 1 : -1)
            } else {
                const activeProjects = projectList.filter(x => !x.finishedAt).sort((a,b) => a.name.toLowerCase()! > b.name.toLowerCase()! ? 1 : -1)
                const inActiveProjects = projectList.filter(x => !!x.finishedAt).sort((a,b) => a.name.toLowerCase()! > b.name.toLowerCase()! ? 1 : -1)
                
                return activeProjects.concat(inActiveProjects);
            }
        }
        
        return [];
    }
    
    const selectProject = (project: Project) => {
        setSelectedProject(project);
        setModalVisible(true)
    }
    
    const closeModal = () => {
        setSelectedProject(undefined)
        setModalVisible(false);
    }
    
    const deleteProject = async (project: Project) => {
        await deleteProjectMutation(project.id);
    }
    
    return (
        <div>
            <div className={"flex"}>
                    <span style={{fontSize: '2em', color: '#485059', fontWeight: 600, display: 'flex', marginBottom: '10px'}}>
                        {t('customers:projectsFor')}
                    </span>
                    <CustomerSelect includeNull={true} showArrow={false} className={styles.projectSelect} style={{fontSize: '2em', minWidth: '300px'}} fullWidth={false} bordered={false} value={customerId ? parseInt(customerId) : ''} onChange={(value) => navigate(`/customers/projects/${value}`)} />
            </div>
            
            <FlexRow justify={"space-between"} style={{marginBottom: 10}}>
                <div style={{width: '100%', maxWidth: '280px'}}>
                    <InputV2 inputRef={searchRef} placeholder={t('customers:projectSearch')} type="text" value={searchFilter} onChange={e => setSearchFilter(e)} containerStyle={{backgroundColor: 'white'}} />
                </div>
                
                <div>
                    <Button color={"primary"} onClick={() => setModalVisible(true)}>
                        + {t('customers:createProject')}
                    </Button>
                </div>
            </FlexRow>
    
            <List loading={isLoadingCustomers} emptyText={!!searchFilter ? `${t('customers:noProjectsFoundSearch')} ${searchFilter}` : `${t('customers:noProjectsFound')} ${getCustomer()?.name}`} keyDataIndex={'id'} data={getProjects()} columns={[
                {
                    title: t('customers:project'),
                    dataIndex: 'name'
                },
                {
                    title: t('customers:customer'),
                    textRender: (_, project: Project) => project.customer?.name ?? ''
                },
                {
                    title: t('customers:rate'),
                    dataIndex: 'rate',
                    textRender: (_, record) => { return !record.rate ? !record.customer.defaultRate ? t('customers:noRate') : record.customer.defaultRate : record.rate }
                },
                {
                    customRender: (_, record) => {
                        return (
                            <div className={"flex column h-100"} onClick={() => selectProject(record)}>
                                <ListTitle>
                                    {t('customers:status')}
                                </ListTitle>
                                <ListText>
                                    {record.finishedAt ? t('customers:finished') : t('customers:open')}
                                </ListText>
                            </div>
                        )
                    }
                },
                {
                    size: 'min-content',
                    customRender: (_, record) => {
                        return (
                            <Space size={"large"}>
                                <div className={"flex align-center clickable"} onClick={() => selectProject(record)}>
                                    <Tooltip title={t('customers:editProject')}>
                                    <Edit />
                                    </Tooltip>
                                </div>
                                <div className={"flex align-center clickable"}>
                                    <Tooltip title={t('customers:deleteProject')}>
                                        <PopConfirm
                                            title={t('areYouSure')}
                                            onConfirm={() => deleteProject(record)}
                                        >
                                            <Delete />
                                        </PopConfirm>
                                    </Tooltip>
                                </div>
                            </Space>
                        )
                    }
                },
            ]} />

            
            <ProjectModal visible={modalVisible} project={selectedProject} onOk={closeModal} onCancel={closeModal} initialValues={{ customerId: customerIdValues }} />
        </div>
    )
}

export default CustomerProjectsPage
