import * as React from "react"

const PlayIcon = (props: React.SVGProps<SVGSVGElement>) => {
    
    return (
        <svg viewBox={"0 0 20 20"} width={props.width ?? 35} height={props.height ?? 35} {...props}>
            <path
                d="M10.001 0a10 10 0 1010 10 10 10 0 00-10-10zm3.975 10.35L8.143 14.1a.417.417 0 01-.642-.35v-7.5a.417.417 0 01.642-.35l5.833 3.75a.416.416 0 010 .7z"
                fill="#1951b9"
            />
        </svg>
    )
}

export default PlayIcon


