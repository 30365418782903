import { useEffect, useRef, useState } from "react";
import { useForm } from "react-hook-form";
import { useTranslation } from "react-i18next";
import FlexRow from "Components/FlexRow";
import Button from "Components/Button/Button";
import { Tag } from "Apis/Models/Tag";
import { useDeleteTag, useSaveTag } from "Apis/TagApi";
import ControlledInput from "Components/InputV2/ControlledInput";
import Modal from "Components/Modal";
import Space from "Components/Space";
import PopConfirm from "Components/PopConfirm";

interface Props {
    visible: boolean;
    tag?: Tag;
    onOk?: Function;
    onCancel?: Function;
    initialValues?: Partial<Tag>
}
const TagModal = (props: Props) => {
    
    const { t } = useTranslation();
    
    const nameRef = useRef<HTMLInputElement>();
    const [isUpdate, setIsUpdate] = useState<boolean>();
    
    const { register, handleSubmit, reset, control } = useForm<Tag>();
    
    const { mutateAsync: saveTagMutation, isLoading: isSavingTag } = useSaveTag();
    const { mutateAsync: deleteTagMutation, isLoading: isDeletingTag } = useDeleteTag();
    
    useEffect(() => {
        if (props.visible && nameRef.current) {
            nameRef.current.focus();
        }
    }, [nameRef, props.visible])
    
    useEffect(() => {
        if (props.tag) {
            reset(props.tag)
        } else if (props.initialValues) {
            reset(props.initialValues)
        }
        
        setIsUpdate(!!props.tag)
    }, [props.tag, props.initialValues, reset, props.visible])
    
    const deleteTag = async () => {
        await deleteTagMutation(props.tag!);
        
        onCancel();
    }
    
    const onOk = async (data: Tag) => {
        
        const tag = await saveTagMutation(data);
        
        reset({});
        
        if (props.onOk) {
            props.onOk(tag);
        }
    }
    
    const onCancel = () => {
        reset({});
        
        if (props.onCancel) {
            props.onCancel();
        }
    }
    
    return (
        <Modal visible={props.visible} onCancel={onCancel} title={isUpdate ? t('registrations:editTag') : t('registrations:createTag')}>
            <form onSubmit={handleSubmit(onOk)}>
                <input type="text" style={{display: 'none'}} ref={register} name={"id"} />
                <Space size={"large"} direction={"vertical"}>
                    <Space size={"medium"} direction={"vertical"}>
                    
                        <ControlledInput name={"text"} control={control} placeholder={t('registrations:tag')}  />
    
                    </Space>
                    
                    <FlexRow justify={"space-between"}>
                        <div>
                            {isUpdate && (
                                <PopConfirm
                                    title={t('areYouSure')}
                                    onConfirm={() => deleteTag()}
                                >
                                    <Button loading={isDeletingTag} color={"danger"}>{t('delete')}</Button>
                                </PopConfirm>
                            )}
                        </div>
                        <Space direction={"horizontal"}>
                            <Button onClick={onCancel}>{t('cancel')}</Button>
                            <Button color={"primary"} type={'submit'} loading={isSavingTag} >{t('save')}</Button>
                        </Space>
                    </FlexRow>
                </Space>
            </form>
        </Modal>
    )
}

export default TagModal
