import styles from "./Tabs.module.scss";
import React, { useRef, useState } from "react";
import TabHeader from "./TabHeader";
import TabContent from "./TabContent";

export interface Tab {
    key: string;
    label: string;
    icon: React.ReactElement
    content: React.ReactElement
    subLabel?: string;
    active?: boolean;
    allowClear?: boolean;
    onClear?: () => void;
}

interface Props {
    tabs: Tab[];
}
const Tabs = ({tabs}: Props) => {
    
    const contentRef = useRef<HTMLDivElement>();
    
    const [activeTabIndex, setActiveTabIndex] = useState<number>(0);
    
    const scrollToIndex = (newIndex: number) => {
        setActiveTabIndex(newIndex)
    }
    
    const scrollToPrevious = () => {
        if (activeTabIndex === 0) {
            return;
        }
        
        scrollToIndex(activeTabIndex - 1);
    }
    
    const scrollToNext = () => {
        if (activeTabIndex === tabs.length - 1) {
            return;
        }
        
        scrollToIndex(activeTabIndex + 1);
    }
    
    return (
        <div className={styles.container}>
            <div className={styles.tabs}>
                {tabs.map((tab, index) => (
                    <TabHeader active={index === activeTabIndex} allowClear={tab.allowClear} onClear={tab.onClear} hasPrevious={index !== 0} hasNext={index + 1 !== tabs.length} hasValue={tab.active} key={tab.key} tabKey={tab.key} label={tab.label} subLabel={tab.subLabel} icon={tab.icon} onClick={() => setActiveTabIndex(index)} />
                ))}
            </div>
            <div ref={e => contentRef.current = e!} className={styles.tabContentContainer}>
                <div className={styles.tabContentInnerContainer}>
                    <TabContent key={tabs[activeTabIndex].key} hasPrevious={activeTabIndex !== 0} hasNext={activeTabIndex + 1 !== tabs.length} scrollToPrevious={scrollToPrevious} scrollToNext={scrollToNext} >
                        {tabs[activeTabIndex].content}
                    </TabContent>
                </div>
            </div>
        </div>
    )
}

export default Tabs
