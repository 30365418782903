import * as React from "react"

const CaretRight = (props: React.SVGProps<SVGSVGElement>) => {
    return (
        <svg
            xmlns="http://www.w3.org/2000/svg"
            width={16.971}
            height={16.97}
            viewBox="0 0 16.971 16.97"
            {...props}
        >
            <path
                data-name="Path 158"
                d="M8.483 15.559l7.071-7.072-7.07-7.07"
                fill="none"
                stroke="#4d576d"
                strokeLinecap="round"
                strokeWidth={2}
            />
        </svg>
    )
}

export default CaretRight
