//import { StrictMode } from 'react';
import { createRoot } from 'react-dom/client';
import './index.scss';
import './index.less';
import {BrowserRouter} from 'react-router-dom';
import moment from 'moment';
import ConfigProvider from "antd/lib/config-provider";
import antdLocaleDa from 'antd/lib/locale-provider/da_DK';
import antdLocaleEn from 'antd/lib/locale-provider/en_GB';
import TimeOpsRoutes from "Pages/TimeOpsRoutes";
import './Translations';
import {openErrorNotification} from "Utils/NotificationUtils";
import _ from 'lodash';
import { QueryClient, QueryClientProvider } from "react-query";
import i18n from "i18next";
import AuthProvider from "Providers/AuthProvider";
import TranslationProvider from "Providers/TranslationProvider";
import ScrollContainerProvider from "Providers/ScrollContainerProvider";
import { StrictMode } from 'react';
import 'react-day-picker/dist/style.css';

const queryClient = new QueryClient({
    defaultOptions: {
    queries: {
        retry: false,
        refetchOnWindowFocus: false,
        onError: (error: any) => {
            let message;
            let title = i18n.t('errors:anErrorOccurred');

            if (error.status === 404) {
                message = i18n.t('errors:notFound');
            } else if (error.status === 402) {
                title = i18n.t('errors:licenseRequired');
                message = i18n.t('errors:licenseRequiredMessage');
            } else if (error.status === 400) {
                title = i18n.t('errors:badRequest');
    
                if (error.data?.message) {
                    title = i18n.t('errors:anErrorOccurred');
                    message = i18n.t(error.data.message)
                }

                const errors = _.get(error, 'data.errors');

                const keys = Object.keys(errors ?? {});

                if (errors && keys.length > 0) {
                    message = errors[keys[0]];
                }
            } else {
                message = error.data;
            }

            console.error(error);

            openErrorNotification(title, message)
        }
    },
    mutations: {
        onError: (error: any) => {
            let message;
            let title = i18n.t('errors:anErrorOccurred');

            if (error.status === 404) {
                message = i18n.t('errors:notFound');
            } else if (error.status === 402) {
                title = i18n.t('errors:licenseRequired');
                message = i18n.t('errors:licenseRequiredMessage');
            } else if (error.status === 400) {
                title = i18n.t('errors:badRequest');

                if (error.data?.message) {
                    title = i18n.t('errors:anErrorOccurred');
                    message = i18n.t(error.data.message)
                }

                const errors = _.get(error, 'data.errors');

                const keys = Object.keys(errors ?? {});

                if (errors && keys.length > 0) {
                    message = errors[keys[0]];
                }
            } else {
                message = error.data;
            }

            console.error(error);

            openErrorNotification(title, message)
        }
    }}
})

if (i18n.language === 'da') {
    moment.locale("da-dk", {
        week: {
            dow: 1,
            doy: 4
        },
        months: ['Januar', 'Februar', 'Marts', 'April', 'Maj', 'Juni', 'Juli', 'August', 'September', 'Oktober', 'November', 'December'],
        monthsShort: ['Jan', 'Feb', 'Mar', 'Apr', 'Maj', 'Jun', 'Jul', 'Aug', 'Sep', 'Okt', 'Nov', 'Dec'],
        weekdays: ['Søndag', 'Mandag', 'Tirsdag', 'Onsdag', 'Torsdag', 'Fredag', 'Lørdag'],
        weekdaysShort: ['Søn', 'Man', 'Tirs', 'Ons', 'Tors', 'Fre', 'Lør'],
        weekdaysMin: ['Søn', 'Man', 'Tirs', 'Ons', 'Tors', 'Fre', 'Lør'],
        longDateFormat : {
            LT : 'HH:mm',
            LTS : 'HH:mm:ss',
            L : 'DD/MM/YYYY',
            LL : 'D MMMM YYYY',
            LLL : 'D MMMM YYYY HH:mm',
            LLLL : 'dddd D MMMM YYYY HH:mm'
        },
    });
} else {
    moment.locale('en-gb', {
        week: {
            dow: 1,
            doy: 4
        }
    })
}

const container = document.getElementById('root');
const root = createRoot(container!);

const render = Component => {
    return root.render(
        <StrictMode>
            <QueryClientProvider client={queryClient}>
                <TranslationProvider>
                    <AuthProvider>
                        <ScrollContainerProvider>
                            <ConfigProvider locale={i18n.language === 'da' ? antdLocaleDa : antdLocaleEn }>
                                <BrowserRouter>
                                    <Component />
                                </BrowserRouter>
                            </ConfigProvider>
                        </ScrollContainerProvider>
                    </AuthProvider>
                </TranslationProvider>
            </QueryClientProvider>
        </StrictMode>
    );
};

render(TimeOpsRoutes);

if (module.hot) {
    module.hot.accept('./Pages/TimeOpsRoutes', () => {
        const NextApp = require('Pages/TimeOpsRoutes').default;
        render(NextApp);
    });
}
