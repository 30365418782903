import { useTranslation } from "react-i18next";
import styles from './SignUpSteps.module.scss';


interface Props {
    activeStep: number;
}
const SignUpSteps = ({activeStep}: Props) => {
    
    const { t } = useTranslation();
    
    const steps = [
        {
            "title": t('auth:signUpSteps.step1'),
        },
        {
            "title": t('auth:signUpSteps.step2')
        }
    ]
    
    return (
        <div className={styles.container}>
            {steps.map((step, index) => (
                <div key={index} className={`${styles.step} ${index === activeStep && styles.active}`}>
                    {step.title}
                </div>
            ))}
        </div>
    )
}

export default SignUpSteps
