import Switch from "antd/lib/switch";
import Table from "antd/lib/table";
import {useCustomers} from "Apis/CustomerApi";
import {useChangeShouldBeSynchronized, useEconomicCustomers, useEconomicIntegration} from "Apis/EconomicApi";
import {useTranslation} from "react-i18next";

const CustomerSelector = () => {

    const { t } = useTranslation();
    const { isLoading: isLoadingIntegration} = useEconomicIntegration()
    const { isLoading: isLoadingContact, data: economicCustomers } = useEconomicCustomers()
    const { isLoading: isLoadingCustomers, data: customers } = useCustomers()

    const { mutateAsync, isLoading: isSaving} = useChangeShouldBeSynchronized()

    const shouldBeSynchronizedHandler = async (checked, contact) => {
        await mutateAsync(contact.customerNumber);
    }
    
    return (
        <div>
            <p>
                {t('economic:customers.selectCustomers')}
            </p>
            <Table loading={isLoadingIntegration || isLoadingContact || isLoadingCustomers} dataSource={economicCustomers} pagination={false}>
                {customers && economicCustomers && (
                    <>
                        {/* @ts-ignore */}
                        <Table.Column title={t('name')} dataIndex={"name"} />
                        {/* @ts-ignore */}
                        <Table.Column title={t('vatNumber')} dataIndex={"vatNumber"} />
                        {/* @ts-ignore */}
                        <Table.Column title={t('match')} dataIndex={"customerId"} render={(customerId) => (
                            <div>
                                {customerId ? <b>{t('economic:customers.matchFound')}</b> : <p>{t('economic:customers.noMatch')}</p>}
                            </div>
                        )}/>
                        {/* @ts-ignore */}
                        <Table.Column title={t('economic:customers.shouldSynchronize')} dataIndex={"shouldBeSynchronized"} render={(shouldBeSynchronized, record) => (
                            <Switch loading={isSaving} disabled={isSaving} checked={shouldBeSynchronized} onChange={(checked) => shouldBeSynchronizedHandler(checked, record)} />
                        )}/>
                    </>
                )}
            </Table>
        </div>
    )
}

export default CustomerSelector;
