import environment from "Config";
import firebase from "firebase/app";
import "firebase/analytics";
import "firebase/auth";

firebase.initializeApp(environment.firebaseConfig);

let analytics;

if (environment.firebaseConfig.appId) {
    // eslint-disable-next-line no-unused-vars
    analytics = firebase.analytics();
}

export const auth = firebase.auth();

const provider = new firebase.auth.GoogleAuthProvider();

export const signInWithGoogle = () => {
    auth.signInWithPopup(provider);
};
