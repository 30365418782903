import WeekCalendar from "Pages/Registrations/Components/WeekCalendar";
import {TimeSlotProvider} from "Pages/Registrations/Components/WeekCalendarTimeSlotProvider";
import CalendarRegistrationProvider from "Pages/Registrations/Components/Providers/CalendarRegistrationProvider";


export const WeekCalendarPage = () => {

    return (
        <TimeSlotProvider>
            <CalendarRegistrationProvider>
                <WeekCalendar />
            </CalendarRegistrationProvider>
        </TimeSlotProvider>
    )
}

