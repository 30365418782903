import React from "react";
import styles from './Space.module.scss';

interface Props {
    size?: 'small' | 'medium' | 'large';
    direction?: 'horizontal' | 'vertical';
    children: any;
    align?: 'center';
    styles?: React.CSSProperties;
}
const Space = (props: Props) => {
    
    const childArray = React.Children.toArray(props.children).filter(x => !!x).flatMap((child: any) => child.type === React.Fragment ? child.props.children : child);
    
    return (
        <div className={styles[`container-${props.direction}`]} style={props.styles}>
            {childArray.filter(x => !!x).map((child, index) => <div key={index} className={`${styles[`space-${props.direction}-${props.size}`]} ${props.align && styles[`space-align-${props.align}`]}`}>{child}</div>)}
        </div>
    )
}

Space.defaultProps = {
    direction: 'vertical',
    size: 'small'
}

export default Space
