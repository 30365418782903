import * as React from "react"
import { SVGProps } from "react"

const TagActive = (props: SVGProps<SVGSVGElement>) => (
    <svg
        xmlns="http://www.w3.org/2000/svg"
         width={25}
         height={25}
         viewBox="0 0 25 25"
         {...props}>
        <path
            data-name="003-price-tag"
            d="M10.716 25a2.91 2.91 0 0 1-2.071-.86l-7.789-7.8a2.934 2.934 0 0 1 0-4.14l10.5-10.519A5.689 5.689 0 0 1 15.407 0h6.659a2.933 2.933 0 0 1 2.93 2.93v6.64a5.689 5.689 0 0 1-1.68 4.052L12.787 24.143a2.911 2.911 0 0 1-2.071.857Zm4.691-23.047a3.749 3.749 0 0 0-2.671 1.108L2.237 13.58a.978.978 0 0 0 0 1.38l7.789 7.8a.97.97 0 0 0 .69.287.97.97 0 0 0 .69-.285l10.532-10.521a3.749 3.749 0 0 0 1.107-2.67V2.93a.978.978 0 0 0-.977-.977Zm2.511 7.959a2.93 2.93 0 1 1 2.93-2.93 2.933 2.933 0 0 1-2.93 2.93Zm0-3.906a.977.977 0 1 0 .977.977.978.978 0 0 0-.977-.977Zm0 0"
            fill="#0d60f8"
        />
    </svg>
)

export default TagActive
