import FlexRow from "Components/FlexRow";
import Button from "Components/Button/Button";
import { useTranslation } from "react-i18next";
import { useNavigate } from "react-router-dom";
import DineroConnected from "Assets/Icons/DineroConnected";
import Space from "antd/lib/space";

const DineroSetupFinished = () => {
    
    const { t } = useTranslation();
    const navigate = useNavigate();
    
    return (
        <FlexRow justify={"center"} align={"center"} className={"h-100"}>
            <Space direction={"vertical"} align={"center"} size={"large"}>
                <DineroConnected width={400} />
                
                <div>
                    {t('dinero:settings.integrationIsRunning')}
                </div>
                
                <Button color={"primary"} onClick={() => navigate('/dinero/invoices')}>{t('dinero:settings.showInvoices')}</Button>
            </Space>
        </FlexRow>
    )
}

export default DineroSetupFinished
