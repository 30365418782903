import Col from "antd/lib/col";
import Row from "antd/lib/row";
import moment from "moment";
import {useTranslation} from "react-i18next";
import Header from "Components/Header";
import Button from "Components/Button/Button";
import FlexRow from "Components/FlexRow";
import Space from "Components/Space";
import React from "react";
import DatePicker from "Components/DatePicker";

const WeekCalendarToolbar = (props) => {

    const { t } = useTranslation();
    
    const onDateSelected = (value) => {
        props.onNavigate('', value)
    }
    
    const getHeaderText = (): string => {
        const startDate = moment(props.date).startOf('week');
        const endDate = moment(props.date).endOf('week');

        return `${startDate.format('DD MMM')} - ${endDate.format('DD MMM')}`
    }

    return (
        <Row align={'middle'} gutter={[20, 20]}>
            <Col flex={"auto"}>
                <FlexRow justify={"space-between"} style={{paddingBottom: 10}}>
                    <Header text={getHeaderText()} />
                    <Space direction={"horizontal"} align={"center"}>
                        <Button onClick={() => onDateSelected(moment(props.date).subtract(1, props.view))}>
                            {t('registrations:calendar.previous')}
                        </Button>
                        <DatePicker name={"week"} placeholder={t('selectWeek')} mode={"single"} singleValue={moment(props.date).toDate()} onChange={onDateSelected}  />
                        <Button onClick={() => onDateSelected(moment(props.date).add(1, props.view))}>
                            {t('registrations:calendar.next')}
                        </Button>
                    </Space>
                </FlexRow>
            </Col>
        </Row>

    )
}

export default WeekCalendarToolbar
