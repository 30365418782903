import { Navigate, Route, Routes } from "react-router-dom";
import TagListPage from "Pages/Tags/TagListPage";
import AccessLevelRoute from "Components/AccessLevelRoute";

const TagRoutes = () => {
    
    return (
        <Routes>
            <Route path={`/`} element={
                <AccessLevelRoute requiredAccessLevel={200}>
                    <TagListPage />
                </AccessLevelRoute>
            } />
            
            <Route path={"*"} element={<Navigate to={`/tags`}/>} />
        </Routes>
    )
}

export default TagRoutes
