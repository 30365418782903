
import Space from "antd/lib/space";
import Switch from "antd/lib/switch";
import React, { useEffect, useRef, useState } from "react";
import { Controller, useForm } from "react-hook-form";
import { useTranslation } from "react-i18next";
import { Project } from "Apis/Models/Project.type";
import { useDeleteProject, useSaveProject } from "Apis/ProjectsApi";
import FlexRow from "Components/FlexRow";
import Button from "Components/Button/Button";
import RequiresPremiumModal from "Components/RequiresPremiumModal";
import { useCustomers } from "Apis/CustomerApi";
import { Customer } from "Apis/Models/Customer.type";
import { useAuthProvider } from "Providers/AuthProvider";
import Modal from "Components/Modal";
import ControlledInput from "Components/InputV2/ControlledInput";
import SelectV2 from "Components/SelectV2";
import { openErrorNotification } from "Utils/NotificationUtils";
import PopConfirm from "Components/PopConfirm";

interface Props {
    visible: boolean;
    project?: Partial<Project>;
    onOk?: Function;
    onCancel?: Function;
    initialValues?: Partial<Project>
}
const ProjectModal = (props: Props) => {
    
    const { t } = useTranslation();
    const { userContext } = useAuthProvider();
    const { data: customers } = useCustomers();
    
    const nameRef = useRef<HTMLInputElement>(null);
    const [isUpdate, setIsUpdate] = useState<boolean>();
    const [selectedCustomer, setSelectedCustomer] = useState<Customer | undefined>();
    const [customerFilterValue, setCustomerFilterValue] = useState<string>('');
    
    const { register, control, handleSubmit, errors, reset, watch } = useForm<Project>();
    
    const { mutateAsync: saveProjectMutation, isLoading: isSavingProject } = useSaveProject();
    const { mutateAsync: deleteProjectMutation, isLoading: isDeletingProject } = useDeleteProject();
    
    useEffect(() => {
        if (props.visible && nameRef.current) {
            nameRef.current.focus();
        }
    }, [nameRef, props.visible])
    
    useEffect(() => {
        if (props.project) {
            reset(props.project);
        } else if (props.initialValues) {
            const initialValues = {
                billable: !!userContext?.hasActiveLicense,
                ...props.initialValues,
            }
            reset(initialValues)
        }
    
        setIsUpdate(!!props.project)
    }, [userContext, props.project, props.initialValues, reset, props.visible])
    
    useEffect(() => {
        if (customers) {
            if (props.initialValues?.customerId) {
                setSelectedCustomer(customers?.find(x => x.id === props.initialValues?.customerId))
            } else if (props.project?.customerId) {
                setSelectedCustomer(customers?.find(x => x.id === props.project?.customerId))
            }
        }
    }, [props, customers])
    
    const onOk = async (data: Project) => {
        
        const response = await saveProjectMutation(data);
        
        reset({});
        
        if (props.onOk) {
            props.onOk(response);
        }
    }
    
    const onCancel = () => {
        reset({});
        
        if (props.onCancel) {
            props.onCancel();
        }
    }
    
    const deleteCustomer = async () => {
        await deleteProjectMutation(props.project!.id!);
        
        onCancel();
    }
    
    const showErrorMessage = () => {
        openErrorNotification(t('errors:anErrorOccurred'),t('customers:nameAndCustomerRequired'))
    }
    
    const customerOptions = () => {
        return customers?.filter(x => x.name.toLowerCase().includes(customerFilterValue)).map((customer) =>  <SelectV2.Option label={customer.name} key={customer.id} value={customer.id} />)
    }
    
    return (
        <Modal visible={props.visible} onCancel={onCancel} title={isUpdate ? t('customers:updateProject') : t('customers:createProject')}>
            <form onSubmit={handleSubmit(onOk, showErrorMessage)}>
                <input type="text" style={{display: 'none'}} ref={register} name={"id"} />
                <Space className={"w-100"} size={"large"} direction={"vertical"}>
                    <Space className={"w-100"} size={"middle"} direction={"vertical"}>
                        
                        <ControlledInput control={control} placeholder={t('name')} name={"name"} inputRef={nameRef} errors={errors} />
                        
                        <Controller name={"customerId"} defaultValue={null} control={control} rules={{required: true}} render={(props) => (
                            <SelectV2 placeholder={t('customer')} onFilter={setCustomerFilterValue} name={"customerId"} onChange={props.onChange} value={props.value} errors={errors.customerId}>
                                {customerOptions()}
                            </SelectV2>
                        )} />
                        
                        <FlexRow justify={"space-between"}>
                            <label>{t('customers:finished')}</label>
                            <Controller name={"finishedAt"} defaultValue={null} control={control} render={(props) => (
                                <Switch checked={!!props.value} onChange={(value) => props.onChange(value ? new Date() : null)} />
                            )} />
                        </FlexRow>
    
                        <FlexRow justify={"space-between"}>
                            <label>{t('billable')}</label>
                            <div>
                                <RequiresPremiumModal enabled={!userContext?.hasActiveLicense} title={t('registrations:billableRegistrations')}>
                                    <Controller name={"billable"} control={control} render={(props) => (
                                        <Switch disabled={!userContext?.hasActiveLicense} checked={!!props.value} onChange={(value) => {
                                            if (userContext?.hasActiveLicense) {
                                                props.onChange(value)
                                            }
                                        }} />
                                    )} />
                                </RequiresPremiumModal>
                            </div>
                        </FlexRow>
                        
                        <Controller name={"rate"} defaultValue={null} control={control} render={(renderProps) => (
                            <div style={{display: watch('billable') ? 'block' : 'none'}}>
                                <div>
                                    <RequiresPremiumModal enabled={!userContext?.hasActiveLicense} title={t('registrations:billableRates')}>
                                        <ControlledInput name={"rate"} control={control} placeholder={`${t('rate')} ${!!selectedCustomer?.defaultRate ? ` - ${t('customers:defaultCustomerRate')} ${selectedCustomer?.defaultRate}` : ''}`} type={"number"}  />
                                    </RequiresPremiumModal>
                                </div>
                            </div>
                        )} />
                        
                    </Space>
                    <FlexRow justify={"space-between"}>
                        <div>
                            {isUpdate && (
                                <PopConfirm
                                    title={t('areYouSure')}
                                    onConfirm={() => deleteCustomer()}
                                >
                                    <Button loading={isDeletingProject} color={"danger"}>{t('delete')}</Button>
                                </PopConfirm>
                            )}
                        </div>
                        <Space>
                            <Button onClick={onCancel}>{t('cancel')}</Button>
                            <Button color={"primary"} type={'submit'} loading={isSavingProject} >{t('save')}</Button>
                        </Space>
                    </FlexRow>
                </Space>
            </form>
        </Modal>
    )
}

export default ProjectModal
