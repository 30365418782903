import * as React from "react";
import styles from './PaperLayout.module.scss';
import FlexRow from "Components/FlexRow";
import { useAuthProvider } from "Providers/AuthProvider";

interface Props {
    children: React.ReactNode;
}
const PaperLayout = (props: Props) => {
    
    const {userContext} = useAuthProvider();
    
    return (
        <div className={styles.paperContainer}>
            <div className={styles.paper}>
                <div>
                    {props.children}
                </div>
                <FlexRow justify={"center"} direction={"column"} className={styles.footer}>
                    <div>
                        <b>{userContext?.organization?.name}</b> - CVR: {userContext?.organization?.vatNumber}
                    </div>
                </FlexRow>
            </div>
        </div>
    )
}

export default PaperLayout
