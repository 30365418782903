import React, { ChangeEventHandler, useEffect, useState } from "react";
import styles from './DatePicker.module.scss';
import { ClassNames, DateRange, DayPicker, SelectRangeEventHandler } from "react-day-picker";
import _ from "lodash";
import Dropdown from "Components/Dropdown";
import da from 'date-fns/locale/da';
import CloseIcon from "Assets/Icons/CloseIcon";
import { format, isAfter, isBefore, isValid, parse } from 'date-fns';
import default_styles from 'react-day-picker/dist/style.module.css';

interface Props {
    name: string;
    placeholder?: string;
    singleValue?: Date | null;
    dateRange?: DateRange
    errors?: any;
    onChange: (newValue: any) => void;
    mode?: 'single' | 'range';
    allowClear?: boolean
}
const DatePicker = (props: Props) => {
    
    const [isOpen, setIsOpen] = useState<boolean>(false);
    
    const [selected, setSelectedDate] = useState<Date | null | undefined>();
    const [inputValue, setSingleValue] = useState<string>('');
    const [selectedRange, setSelectedRange] = useState<DateRange>();
    const [fromValue, setRangeFromValue] = useState<string>('');
    const [toValue, setRangeToValue] = useState<string>('');
    
    const classNames: ClassNames = {
        ...default_styles,
        head_cell: styles.head_cell,
        caption_label: styles.caption_label,
        day_selected: styles.daySelected,
        day_range_start: styles.dayRangeStart,
        day_range_middle: styles.dayRangeMiddle,
        day_range_end: styles.dayRangeEnd,
        day: styles.day,
        button: styles.button,
        button_reset: styles.buttonReset,
        months: styles.months,
        month: styles.month,
    };
    
    useEffect(() => {
        setSelectedRange(props.dateRange);
        
        if (props.dateRange) {
            if (props.dateRange.from) {
                setRangeFromValue(format(props.dateRange.from!, 'dd-MM-y'));
            }
            
            if (props.dateRange.to) {
                setRangeToValue(format(props.dateRange.to!, 'dd-MM-y'));
            }
        }
    }, [props.dateRange])
    
    useEffect(() => {
        setSelectedDate(props.singleValue);
    
        if (props.singleValue) {
            setSingleValue(format(props.singleValue, 'dd-MM-y'));
        }
    }, [props.singleValue])
    
    const handleFromChange: ChangeEventHandler<HTMLInputElement> = (e) => {
        setRangeFromValue(e.target.value);
        const date = parse(e.target.value, 'dd-MM-y', new Date());
        
        if (!isValid(date)) {
            return;
        }
        
        if (selectedRange?.to && isAfter(date, selectedRange.to)) {
            let newValue = { from: selectedRange.to, to: date };
            setSelectedRange(newValue);
            onChange(newValue);
        } else {
            let newValue = { from: date, to: selectedRange?.to };
            setSelectedRange(newValue);
            onChange(newValue);
        }
    };
    
    const handleToChange: ChangeEventHandler<HTMLInputElement> = (e) => {
        setRangeToValue(e.target.value);
        const date = parse(e.target.value, 'dd-MM-y', new Date());
        
        if (!isValid(date)) {
            return;
        }
        
        if (selectedRange?.from && isBefore(date, selectedRange.from)) {
            let newValue = { from: date, to: selectedRange.from }
            setSelectedRange(newValue);
            onChange(newValue)
        } else {
            let newValue = { from: selectedRange?.from, to: date };
            setSelectedRange(newValue);
            onChange(newValue)
        }
    };
    
    const handleRangeSelect: SelectRangeEventHandler = (range: DateRange | undefined) => {
        setSelectedRange(range);
        
        if (range?.from) {
            setRangeFromValue(format(range.from, 'dd-MM-y'));
            onChange(range);
            setIsOpen(current => !current);
        } else {
            setRangeFromValue('');
        }
        
        if (range?.to) {
            setRangeToValue(format(range.to, 'dd-MM-y'));
            onChange(range);
            setIsOpen(current => !current);
        } else {
            setRangeToValue('');
        }
    };
    
    const onChange = (newValue) => {
        props.onChange(newValue)
    }
    
    const handleDaySelect = (date: Date) => {
        setSelectedDate(date);
        if (date) {
            setSingleValue(format(date, 'dd-MM-y'));
            onChange(date);
            setIsOpen(current => !current);
        } else {
            setSingleValue('');
        }
    
    };
    
    const handleInputChange: ChangeEventHandler<HTMLInputElement> = (e) => {
        setSingleValue(e.currentTarget.value);
        const date = parse(e.currentTarget.value, 'dd-MM-y', new Date());
        
        if (isValid(date)) {
            setSelectedDate(date);
            onChange(date)
        } else {
            setSelectedDate(undefined);
        }
    };
    
    const hasValue = () => {
        if (props.mode === "range") {
            return !!props.dateRange
        } else {
            return !!props.singleValue
        }
    }
    
    const clear = () => {
        setSelectedDate(undefined);
        setSelectedRange(undefined);
        setRangeFromValue('');
        setRangeToValue('')
        setSingleValue('')
        onChange('');
    }
    
    return (
        <Dropdown changeState={isOpen} stayOpenAfterClick={true} position={'Under Left'} toggle={
            <div className={`${styles.trigger} ${isOpen && styles.active} ${_.get(props.errors, props.name) ? styles.error : ''}`}>
                {props.mode === "range" ? (
                    <>
                        <div>
                            <label className={`${styles.label} text-ellipsis ${styles.fieldActive}`}>
                                {props.placeholder}
                            </label>
                            <input
                                className={`${styles.input} ${styles.floatingLabel}`}
                                value={fromValue}
                                onChange={handleFromChange}
                            />
                        </div>
                        <div>
                            <label className={`${styles.label} text-ellipsis ${styles.fieldActive}`}>
                                {props.placeholder}
                            </label>
                            <input
                                className={`${styles.input} ${styles.floatingLabel}`}
                                value={toValue}
                                onChange={handleToChange}
                            />
                        </div>
                    </>
                ) : (
                    <>
                        <label className={`${styles.label} text-ellipsis ${styles.fieldActive}`}>
                            {props.placeholder}
                        </label>
                        <input
                            className={`${styles.input} ${styles.floatingLabel} ${!isOpen && styles.hidden}`}
                            value={inputValue}
                            onChange={handleInputChange}
                        />
                    </>
                )}

                <div className={styles.clear}>
                    {props.allowClear && hasValue() && (
                        <CloseIcon fill={"#F74425"} onClick={(e) => {
                            e.stopPropagation();
                            clear()
                        }} style={{cursor: 'pointer'}} />
                    )}
                </div>
            </div>
        }>
            {props.mode === 'single' ?
                <DayPicker classNames={classNames} selected={selected === null ? undefined : selected} mode={'single'} locale={da} onSelect={e => handleDaySelect(e!)} /> :
                <DayPicker classNames={classNames} numberOfMonths={2} selected={selectedRange} mode={'range'} locale={da} onSelect={handleRangeSelect} />
            }
        </Dropdown>
    )
}

export default DatePicker
