import Layout from "Pages/Auth/Components/Layout";
import { useTranslation } from "react-i18next";
import { useForm } from "react-hook-form";
import { reportConversion } from "Utils/GoogleAdsUtils";
import { CreateOrganization } from "Apis/Models/Organization.type";
import { useCreateOrganization } from "Apis/OrganizationApi.ts";
import { useAuthProvider } from "Providers/AuthProvider";
import { getCookieByName } from "Utils/CookieUtils";
import ControlledInput from "Components/InputV2/ControlledInput";
import Col from "antd/lib/col";
import Row from "antd/lib/row";
import Space from "antd/lib/space";
import Button from "Components/Button/Button";
import CountrySelect from "Components/CountrySelect";
import { useEffect } from "react";
import SignUpSteps from "Pages/Auth/Components/SignUpSteps";
import { useNavigate } from "react-router-dom";

declare var ga: any;
declare var fbq: any;
declare var lintrk: any;

const CreateOrganizationPage = () => {
    
    const navigate = useNavigate();
    const { t } = useTranslation();
    
    const { userContext, forceReload } = useAuthProvider();
    
    const {control, handleSubmit, errors} = useForm<CreateOrganization>({mode: 'all'});
    
    const { mutateAsync: createOrganizationMutation, isLoading} = useCreateOrganization();
    
    useEffect(() => {
        if (userContext?.organization) {
            navigate('/registrations/dashboard?welcome')
        }
    }, [navigate, userContext])
    
    const onSubmitHandler = async (organization: CreateOrganization) => {
        const partnerAdsCookie = getCookieByName('partner_ads');

        if (partnerAdsCookie) {
            const parts = partnerAdsCookie!.split(";");

            const paid = parts.find(x => x.startsWith("paid"))!.split("=")[1];
            const pacid = parts.find(x => x.startsWith("pacid"))!.split("=")[1];

            organization = {
                ...organization,
                paid,
                pacid
            }
        }
        
        organization.signupSource = "Web";
        
        await createOrganizationMutation(organization)
        
        if (!window.location.host.includes('localhost')) {
            reportConversion("CreateOrganization");
            
            ga('send', {
                hitType: 'event',
                eventCategory: 'organization',
                eventAction: 'created',
            });
            
            fbq('track', 'CompleteRegistration');
    
            lintrk('track', { conversion_id: 9872361 });
        }
        
        forceReload();
        navigate('/registrations/dashboard?welcome')
    }
    
    return (
        <Layout
            preHeader={t('organization:createOrganization.information')}
            header={t('organization:createOrganization.title')}
            imgUrl={"/images/appMockupV2.png"}
            steps={<SignUpSteps activeStep={1} />}
        >
            <form onSubmit={handleSubmit(onSubmitHandler)}>
                <Space size={"large"} direction={"vertical"} className={"w-100"}>
                    <ControlledInput placeholder={t('workspaceName')} control={control} name={"name"} errors={errors} rules={{required: true}} />
                    <ControlledInput placeholder={t('vatNumber')} control={control} name={"vatNumber"} errors={errors} />
                    <CountrySelect placeholder={t('country')} errors={errors} name={"country"} defaultValue={"DK"} control={control} rules={{required: true}} />
                    <ControlledInput placeholder={t('address')} control={control} name={"address"} errors={errors} rules={{required: true}} />
                    
                    <Row gutter={10}>
                        <Col span={12}>
                            <ControlledInput placeholder={t('zipCode')} control={control} name={"zipCode"} errors={errors} rules={{required: true}} />
                        </Col>
                        <Col span={12}>
                            <ControlledInput placeholder={t('city')} control={control} name={"city"} errors={errors} rules={{required: true}} />
                        </Col>
                    </Row>
    
                    <Button loading={isLoading} color={"primary"} block type={"submit"}><span style={{fontSize: '1.5em', fontWeight: 600}}>{t('organization:createOrganization.create')}</span></Button>
                </Space>
            </form>
        
        </Layout>
    )
}

export default CreateOrganizationPage
