import {Registration} from "Apis/Models/Registration.type";
import moment from 'moment';

export class RegistrationUtils {

    static getDateForRegistrationAsString(date: Date) {
        return `${date.getFullYear()}-${("0" + (date.getMonth() + 1)).slice(-2)}-${date.getDate()}T00:00:00+00:00`;
    }

    static setStartedAtTimeFromString(registration: Registration, timeString: string) {
        if (!registration.startedAt) {
            registration.startedAt = new Date();
        }

        if (typeof (registration.startedAt) === 'string') {
            registration.startedAt = moment(registration.startedAt).toDate();
        }

        registration.startedAt = RegistrationUtils.setNewTimeOnDateFromString(registration.startedAt, timeString);

        RegistrationUtils.resetSeconds(registration);
    }

    static setStoppedAtTimeFromString(registration: Registration, time: string) {
        let stoppedAt;
        
        if (!registration.startedAt) {
            registration.startedAt = new Date();
        }

        if (typeof (registration.startedAt) === 'string') {
            registration.startedAt = moment(registration.startedAt).toDate();
        }

        stoppedAt = RegistrationUtils.setNewTimeOnDateFromString(registration.startedAt, time);

        if (RegistrationUtils.checkIfStoppedTimeIsBeforeStartedAt(stoppedAt, registration.startedAt)) {

            stoppedAt.setDate(stoppedAt.getDate() + 1);
        }

        registration.stoppedAt = stoppedAt;

        RegistrationUtils.resetSeconds(registration);
    }

    static setNewTimeOnDateFromString(date: Date | null, time: string): Date {
        if (date) {
            const dateClone = new Date(date.getTime());

            const hours = parseInt(time.split(":")[0]);
            const minutes = parseInt(time.split(":")[1]);

            dateClone.setHours(hours);
            dateClone.setMinutes(minutes);

            return dateClone;
        }

        throw Error("Date must not be null")
    }

    static checkIfStoppedTimeIsBeforeStartedAt(from: Date, to: Date | null) {
        const stoppedAt = moment(from);
        const startedAt = moment(to);

        return stoppedAt.isBefore(startedAt);
    }


    static setNewDate(registration: Registration, moment: any) {
        registration.startedAt = RegistrationUtils.cloneTimeToDate(moment.toDate(), registration.startedAt!);

        if (registration.stoppedAt) {
            registration.stoppedAt = RegistrationUtils.cloneTimeToDate(moment.toDate(), registration.stoppedAt);
        }

        if (registration.stoppedAt) {
            let stoppedAt = registration.stoppedAt;

            if (RegistrationUtils.checkIfStoppedTimeIsBeforeStartedAt(stoppedAt, registration.startedAt)) {
                stoppedAt.setDate(stoppedAt.getDate() + 1);
            }

            registration.stoppedAt = stoppedAt;
        }

        RegistrationUtils.resetSeconds(registration);
    }

    static cloneTimeToDate(cloneTo: Date, cloneFrom: Date | null): Date {
        const newDate = new Date(cloneTo.getTime());

        if (!cloneFrom) {
            cloneFrom = new Date();
        }

        newDate.setSeconds(cloneFrom.getSeconds());
        newDate.setMinutes(cloneFrom.getMinutes());
        newDate.setHours(cloneFrom.getHours());

        return newDate;
    }

    static resetSeconds(registration: Registration) {
        if (registration.stoppedAt) {
            if (typeof (registration.stoppedAt) === 'string') {
                registration.stoppedAt = moment(registration.stoppedAt).toDate();
            }

            registration.stoppedAt.setSeconds(0);
            registration.stoppedAt.setMilliseconds(0);
        }
        if (registration.startedAt) {
            if (typeof (registration.startedAt) === 'string') {
                registration.startedAt = moment(registration.startedAt).toDate();
            }

            registration.startedAt.setSeconds(0);
            registration.startedAt.setMilliseconds(0);
        }
    }

    static getFormattedDurationString(duration: number, timeType: 'Hours' | 'Decimal' = 'Hours') {
        if (timeType === 'Hours') {
            const getTime = (time) => {
                return time < 10 ? '0' + time : time;
            }

            let unusedDuration = duration;

            const hours = Math.floor(unusedDuration / 3600);

            unusedDuration = unusedDuration % 3600;

            const minutes = Math.floor(unusedDuration / 60);

            const seconds = unusedDuration % 60;

            return `${getTime(hours)}:${getTime(minutes)}:${getTime(seconds)}`
        } else {
            return moment.duration(duration, 'seconds').asHours().toPrecision(4);
        }
    }

    static getDurationAsHoursValue(totalDuration: number) {
        const hoursWithoutRounding = moment.duration(RegistrationUtils.getFormattedDurationString(totalDuration)).asHours() + Number.EPSILON;

        return Math.round(hoursWithoutRounding * 100) / 100
    }

    static getSecondsFromDuration(duration: number) {
        let unusedDuration = duration;

        unusedDuration = unusedDuration % 3600;

        return unusedDuration % 60;
    }

}
