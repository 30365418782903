import * as React from "react";
import styles from './List.module.scss';
import Skeleton from "antd/lib/skeleton";
import FlexRow from "Components/FlexRow";
import DefaultColumn from "Components/List/DefaultColumn";
import ListTitle from "Components/List/ListTitle";
import { useMemo } from "react";
import Empty from "Components/Empty";

export interface Column {
    icon?: React.ReactElement
    dataIndex?: string,
    customRender?: (text?, record?) => React.ReactElement,
    textRender?: (text?, record?) => string,
    title?: string;
    size?: 'auto' | 'max-content' | 'min-content' | string
}

interface Props {
    loading?: boolean;
    data?: any[];
    columns: Column[];
    emptyContent?: React.ReactElement;
    emptyText?: string;
    keyDataIndex: string;
    compact?: boolean;
}
const List = ({loading, data, columns, emptyContent, emptyText, keyDataIndex, compact}: Props) => {
    
    const getGridCss = () => {
        return columns.map(x => x.size ?? '1fr').join(' ')
    }
    
    const renderColumn = (column: Column, record: any, index) => {
        
        const element = column.customRender!(column.dataIndex ? record[column.dataIndex] : '', record)
        
        return React.cloneElement(
            element,
            {
                className: `${element.props.className} ${index !== 0 ? styles.separator : ''}`
            }
        )
    }
    
    const rows = useMemo(() => {
        return data?.map(record => (
            <div className={styles.row} key={record[keyDataIndex]}>
                {columns.map((column, index) => (
                    <div className={`flex w-100 ${styles.column} ${compact && styles.compact} ${index === 0 && styles.first} ${index === columns.length - 1 && styles.last}`} key={index}>
                        {column.customRender ?
                            <>
                                {column.icon && (
                                    <div className={styles.icon}>
                                        {column.icon}
                                    </div>
                                )}
                                {renderColumn(column, record, index)}
                            </>
                        : (
                            <DefaultColumn key={index} column={column} index={index} record={record} compact={compact} />
                        )}
                    </div>
                ))}
            </div>
        ))
    }, [columns, data, compact, keyDataIndex])
    
    if (loading) {
        return (
            <>
                {compact ? (
                    <div className={styles.container} style={{gridTemplateColumns: getGridCss()}}>
                        {compact && columns.map((column, index) => (
                            <ListTitle key={index}>
                                {column.title}
                            </ListTitle>
                        ))}
                        {columns.map((column, index) => (
                            <Skeleton key={index} active paragraph={{rows: 0, style: {padding: 0, margin: 0}}} />
                        ))}
                    </div>
                ) : (
                    <div className={`${styles.loadingContainer}`}>
                        <FlexRow justify={"center"} direction={"column"} className={"h-100"}>
                            <Skeleton active paragraph={{rows: 0, style: {padding: 0, margin: 0}}} />
                        </FlexRow>
                    </div>
                )}
            </>
        )
    }
    
    if (data?.length === 0) {
        return (
            <FlexRow justify={"center"} align={"center"} className={"h-100"}>
                {emptyContent ? emptyContent : (
                    <Empty className={styles.empty} children={emptyText} />
                )}
            </FlexRow>
        )
    }
    
    return (
        <div className={styles.container} style={{gridTemplateColumns: getGridCss()}}>
            {compact && columns.map((column, index) => (
                <ListTitle key={index}>
                    {column.title}
                </ListTitle>
            ))}
            {rows}
        </div>
    )
}

export default List
