import { useState } from "react";
import {Registration} from "Apis/Models/Registration.type";
import styles from './RegistrationCardRow.module.scss';
import { useTranslation } from "react-i18next";
import { useEditRegistrationModal } from "Pages/Registrations/Components/Providers/EditRegistrationModalProvider";
import { useCloneRegistration } from "Apis/RegistrationsApi";
import Duration from "Components/Duration";
import PlayIcon from "Assets/Icons/PlayIcon";

interface Props {
    registration: Registration,
}
const RegistrationCardRow = (props: Props) => {

    const { t } = useTranslation();
    const [isShown, setIsShown] = useState(false);
    
    const { selectRegistration } = useEditRegistrationModal();
    const { mutateAsync: cloneRegistrationMutation }  = useCloneRegistration();
    
    const editRegistration = () => {
        selectRegistration(props.registration);
    }
    
    const cloneRegistrationHandler = async () => {
        await cloneRegistrationMutation(props.registration);
    }

    return (
        <div className={styles.container}>
            <div className={`${styles.textColumn} clickable`} onClick={editRegistration}>
                {props.registration.project ? (
                    <div className={styles.customerText}>{props.registration.project.customer!.name} - {props.registration.project.name}</div>
                ) : (
                    <div className={`${styles.customerText} ${styles.blank}`}>
                        {t('registrations:noProjectSelected')}
                    </div>
                )}
                {props.registration.description ? (
                    <div className={styles.descriptionText} key={props.registration.id}>{props.registration.description}</div>
                ) : (
                    <div className={`${styles.descriptionText} ${styles.blank}`}>
                        {t('registrations:noDescriptionEntered')}
                    </div>
                )}
            </div>
            <div className={`${styles.durationColumn}`} onMouseEnter={() => setIsShown(true)} onMouseLeave={() => setIsShown(false)}>
                {!isShown ? (
                    <Duration style={{fontSize: '1.1em', fontWeight: 700, color: '#4D576D'}} duration={props.registration.duration} />
                ) : (
                    <PlayIcon style={{cursor: 'pointer'}} onClick={cloneRegistrationHandler} />
                )}
            </div>
        </div>
    )
}

export default RegistrationCardRow;
