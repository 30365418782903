import * as React from "react"
import { SVGProps } from "react"

const StopWatchIcon = (props: SVGProps<SVGSVGElement>) => (
    <svg xmlns="http://www.w3.org/2000/svg" width="1em" height="1em" viewBox={"0 0 29 34"} {...props}>
        <g data-name="62-Stopwatch" fill={props.fill ??"#0d60f8"}>
            <path
                data-name="Path 1197"
                d="m25.46 9.937 1.877-1.865a1.208 1.208 0 1 0-1.708-1.708l-1.865 1.877a14.316 14.316 0 0 0-8.109-3.369V2.406h2.406a1.203 1.203 0 1 0 0-2.406h-7.218a1.203 1.203 0 0 0 0 2.406h2.406v2.466A14.437 14.437 0 1 0 25.46 9.937ZM14.452 31.28a12.031 12.031 0 1 1 12.031-12.031A12.031 12.031 0 0 1 14.452 31.28Z"
            />
            <path
                data-name="Path 1198"
                d="M14.452 9.624a9.624 9.624 0 1 0 9.624 9.624 9.624 9.624 0 0 0-9.624-9.624Zm4.463 14.088a1.2 1.2 0 0 1-1.708 0l-3.609-3.609a1.2 1.2 0 0 1-.349-.854v-6.016a1.203 1.203 0 0 1 2.406 0v5.522l3.26 3.248a1.2 1.2 0 0 1 0 1.708Z"
            />
        </g>
    </svg>
)

export default StopWatchIcon
