import * as React from "react"

interface Props extends React.SVGProps<SVGSVGElement> {
    fill?: string;
}
const CheckmarkFilled = (props: Props) => {
    return (
        <svg
            xmlns="http://www.w3.org/2000/svg"
            width={38}
            height={38}
            viewBox="0 0 38 38"
            {...props}
        >
            <g data-name="Group 337" transform="translate(10361.389 -4533.612)">
                <circle
                    data-name="Ellipse 43"
                    cx={19}
                    cy={19}
                    r={19}
                    transform="translate(-10361.389 4533.612)"
                    fill={props.fill ? props.fill : "#0d60f8"}
                />
                <path
                    data-name="Path 471"
                    d="M-10351.538 4551.909l6.88 6.88 11.204-11.203"
                    fill="none"
                    stroke="#fff"
                    strokeWidth={5}
                />
            </g>
        </svg>
    )
}

export default CheckmarkFilled
