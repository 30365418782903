import {Link, useLocation, useMatch} from "react-router-dom";
import FlexRow from "Components/FlexRow";
import styles from './SubMenuLink.module.scss';

type Props = {
    to: string;
    children: any;
    onClick?: any;
    exact?: boolean;
}
const SubMenuLink = ({to, children, onClick, exact}: Props) => {

    const location = useLocation();

    let match = useMatch({ path: to, end: exact});

    const isMatch = match ?? to === `${location.pathname}${location.search}`;

    return (
        <Link to={to} onClick={onClick}>
            <FlexRow justify={'center'} style={{paddingBottom: '.7em'}}>
                <div className={styles.subMenuLink}>
                    <div className={styles.subMenuLinkIcon}>
                        <svg height="26" width="26" style={isMatch ? {} : {display: 'none'}}>
                            <circle cx="13" cy="13" r="4" stroke="white" strokeWidth="2" fill={"white"} />
                        </svg>
                    </div>
                    <div className={styles.subMenuLinkContent} style={isMatch ? {fontWeight: 600} : {}}>
                        {children}
                    </div>
                </div>
            </FlexRow>
        </Link>
    )
}

export default SubMenuLink;
