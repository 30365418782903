import { Navigate, Route, Routes } from "react-router-dom";
import AccessLevelRoute from "../../Components/AccessLevelRoute";
import CustomersPage from "Pages/Customers/CustomersPage";
import CustomerProjectsPage from "Pages/Customers/CustomerProjectsPage";

const CustomerRoutes = () => {
    
    return (
        <Routes>
            <Route path={"/"} element={
                <AccessLevelRoute requiredAccessLevel={150}>
                    <CustomersPage />
                </AccessLevelRoute>
            } />
            
            <Route path={`projects`} element={
                <AccessLevelRoute requiredAccessLevel={150}>
                    <CustomerProjectsPage />
                </AccessLevelRoute>
            } />
            
            <Route path={`projects/:customerId`} element={
                <AccessLevelRoute requiredAccessLevel={150}>
                    <CustomerProjectsPage />
                </AccessLevelRoute>
            } />
            
            <Route path={"*"} element={<Navigate to={"/customers"} />}  />
            
        </Routes>
    )
}

export default CustomerRoutes;
