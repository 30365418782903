import React from "react";
import styles from './TabHeader.module.scss';
import { CSSTransition } from "react-transition-group";
import CloseIcon from "Assets/Icons/CloseIcon";


interface Props {
    tabKey: string;
    active?: boolean;
    filled?: boolean;
    icon: any;
    label?: string;
    subLabel?: string;
    onClick?: (string) => void;
    hasPrevious?: boolean;
    hasNext?: boolean;
    hasValue?: boolean;
    allowClear?: boolean;
    onClear?: () => void;
}
const TabHeader = (props: Props) => {
    
    const onClick = () => {
        if (props.onClick) {
            props.onClick(props.tabKey);
        }
    }
    
    return (
        <span onClick={onClick} className={`${styles.container} ${props.active && styles.active} ${props.hasValue && styles.hasValue}`}>
            <div className={styles.contentContainer}>
            {React.cloneElement(props.icon, {
                width: '30px',
                height: '30px',
                className: styles.icon,
                fill: props.active || props.hasValue ? '#0D60F8' : '#BED5FF',
            })}
                <CSSTransition
                    in={props.active ||props.hasValue}
                    mountOnEnter
                    appear={true}
                    timeout={{ enter: 0, exit: 300 }}
                    classNames={{
                        enterDone: styles.enterDone,
                        exit: styles.exit,
                    }}
                >
                    <div className={styles.labelContainer}>
                        <div className={styles.label}>
                            {props.label}
                        </div>
                        <div className={styles.subLabel}>
                            {props.subLabel}
                        </div>
                    </div>
                </CSSTransition>
                {(props.allowClear && props.hasValue) && (
                    <CloseIcon fill={"#F74425"} onClick={(e) => {
                        e.stopPropagation();
                        props.onClear!();
                    }} style={{cursor: 'pointer', position: 'absolute', top: 10, right: -5}} />
                )}
            </div>
        </span>
    )
}

export default TabHeader;
