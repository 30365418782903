import {request} from "./BaseApi";
import {Project} from "./Models/Project.type";
import { useMutation, useQueryClient } from "react-query";
import {CUSTOMER_CACHE_KEY, CUSTOMERS_CACHE_KEY} from "Apis/CustomerApi";
import {openSuccessNotification} from "Utils/NotificationUtils";
import {useTranslation} from "react-i18next";

export const useDeleteProject = () => {

    const { t } = useTranslation();
    
    const queryClient = useQueryClient();
    
    return useMutation(async (id :number): Promise<Project> => {
        return await request<Project>({
            url: `/project/${id}`,
            method: 'DELETE'
        })
    }, {
        onSuccess: (project) => {
            openSuccessNotification(t('success'), t('customers:projectDeleted'))
            queryClient.invalidateQueries(CUSTOMER_CACHE_KEY)
            queryClient.invalidateQueries(CUSTOMERS_CACHE_KEY)
        }
    })
}

export const useToggleProjectFinished = () => {

    const { t } = useTranslation();
    
    const queryClient = useQueryClient();
    
    return useMutation(async (project: Project) => {
        return await request<Project>({
            url: `/project/finish/${project.id}`,
            method: 'PUT',
        })
    }, {
        onSuccess: (project) => {
            openSuccessNotification(t('success'), `${t('customers:projectWas')} ${project.finishedAt ? t('customers:finished') : t('customers:reopened') }`)
            queryClient.invalidateQueries(CUSTOMER_CACHE_KEY)
            queryClient.invalidateQueries(CUSTOMERS_CACHE_KEY)
        }
    })}

export const useSaveProject = () => {

    const { t } = useTranslation();
    
    const queryClient = useQueryClient();
    
    return useMutation(async (project: Project): Promise<Project> => {
        if (!project.id) {
            return await request<Project>({
                url: `/project`,
                method: 'POST',
                data: project
            })
        } else {
            return await request<Project>({
                url: `/project/${project.id}`,
                method: 'PUT',
                data: project
            })
        }
    }, {
        onSuccess: (project) => {
            openSuccessNotification(t('success'), t('customers:projectSaved'))
            queryClient.invalidateQueries(CUSTOMER_CACHE_KEY)
            queryClient.invalidateQueries(CUSTOMERS_CACHE_KEY)
        }
    })
}
