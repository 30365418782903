import Header from "Components/Header";
import {Trans, useTranslation } from "react-i18next";
import { useEffect, useRef, useState } from "react";
import { default as UserIcon } from "Assets/Icons/User";
import Edit from "Assets/Icons/Edit";
import Button from "Components/Button/Button";
import FlexRow from "Components/FlexRow";
import List from "Components/List/List";
import useQueryParam from "Hooks/UseQueryParam";
import Tooltip from "Components/Tooltip";
import { User } from 'Apis/Models/User.type'
import UserModal from "Pages/Organization/Components/UserModal";
import { useUsers } from "Apis/UsersApi";
import RequiresPremiumModal from "Components/RequiresPremiumModal";
import { useAuthProvider } from "Providers/AuthProvider";
import PremiumTrialAnnouncement from "Components/PremiumTrialAnnouncement";
import InputV2 from "Components/InputV2/InputV2";

const UsersPage = () => {
    
    const { t } = useTranslation();
    
    const { userContext } = useAuthProvider()
    
    const searchRef = useRef<HTMLInputElement>(null);
    
    const [userModalVisible, setUserModalVisible] = useState<boolean>(false);
    const [searchFilter, setSearchFilter] = useQueryParam('query');
    const [selectedUser, setSelectedUser] = useState<User | undefined>();
    
    const { data: users, isLoading } = useUsers();
    
    useEffect(() => {
        if (searchRef.current) {
            searchRef.current.focus();
        }
    }, [searchRef])
    
    const getUsers = () => {
        let filteredCustomers = users;
        
        if (searchFilter) {
            const lowerCaseSearch = searchFilter.toLowerCase();
            
            filteredCustomers = users?.filter(x => x.name?.toLowerCase().includes(lowerCaseSearch) || x.eMail?.toString().includes(lowerCaseSearch))
        }
        
        return filteredCustomers?.sort((a,b) => a.name?.toLowerCase()! > b.name?.toLowerCase()! ? 1 : -1).sort(x => x.deactivatedAt  ? 1 : -1)
    }
    
    const editUser = (user: User) => {
        setSelectedUser(user);
        setUserModalVisible(true)
    }
    
    const closeUserModal = () => {
        setUserModalVisible(false);
        setSelectedUser(undefined)
    }
    
    const canAddNewUser = () => {
        if (users && users.length >= (userContext?.organization?.allowedFreeUserCount ?? 3)) {
           return userContext?.hasActiveLicense;
        }
        
        return true
    }
    
    return (
        <>
            <Header text={t('organization:users.users')} />
            
            <FlexRow justify={"space-between"} style={{marginBottom: 10}}>
                <div  style={{width: '100%', maxWidth: '280px'}}>
                    <InputV2 inputRef={searchRef} placeholder={t('organization:users.userSearch')} type="text" value={searchFilter} onChange={e => setSearchFilter(e)} containerStyle={{backgroundColor: 'white'}} />
                </div>
                
                <div>
                    <RequiresPremiumModal enabled={!canAddNewUser()} title={t('organization:users.userLimitHit')}>
                        <Button onClick={() => setUserModalVisible(canAddNewUser())} color="primary">+ {t('organization:users.addUser')}</Button>
                    </RequiresPremiumModal>
                </div>
            </FlexRow>
    
            <PremiumTrialAnnouncement>
                <Trans i18nKey="premiumAnnouncements.usersAnnouncement" values={{ userCount: userContext?.organization?.allowedFreeUserCount }}  />
            </PremiumTrialAnnouncement>
            
            <List loading={isLoading} data={getUsers()} emptyText={!!searchFilter ? `${t('organization:users.noUsersFoundSearch')} ${searchFilter}` : t('organization:users.noUsersFound')} keyDataIndex={'id'} columns={[
                {
                    size: 'min-content',
                    icon: <UserIcon />,
                    title: t('name'),
                    dataIndex: 'name'
                },
                {
                    title: t('email'),
                    dataIndex: 'eMail'
                },
                {
                    title: t('organization:users.accessLevel'),
                    textRender: (_, record) => {
                        return `${record.accessLevel === 200 ? t('organization:users.admin') : record.accessLevel === 150 ? t('organization:users.projectManager') : t('organization:users.employee')}`
                    }
                },
                {
                    title: t('organization:users.isActive'),
                    textRender: (_, record) => { return !record.deactivatedAt ? t('organization:users.active') : t('organization:users.deactivated')}
                },
                {
                    title: t('organization:users.hourlyRate'),
                    dataIndex: 'hourlyRate',
                    textRender: (_, record) => { return !record.hourlyRate ? t('organization:users.noHourlyRate') : record.hourlyRate}
                },
            {
                    size: 'min-content',
                    customRender: (_, record) => {
                        return (
                            <FlexRow justify={"center"} align={"center"}>
                                <Tooltip title={t('organization:users.updateUser')}>
                                    <div className={"flex w-100 align-center justify-end clickable"} onClick={() => editUser(record)}>
                                        <Edit />
                                    </div>
                                </Tooltip>
                            </FlexRow>
                        )
                    }
                },
            ]} />
            
            <UserModal user={selectedUser} visible={userModalVisible} onOk={closeUserModal} onCancel={closeUserModal} />
            
        </>
    )
}

export default UsersPage
