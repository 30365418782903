import * as React from "react"

const Add = (props: React.SVGProps<SVGSVGElement>) => {
    return (
        <svg
            xmlns="http://www.w3.org/2000/svg"
            width={31.666}
            height={31.666}
            viewBox="0 0 31.666 31.666"
            {...props}
        >
            <defs>
                <linearGradient
                    id="prefix__a"
                    x1={0.5}
                    x2={0.5}
                    y2={1}
                    gradientUnits="objectBoundingBox"
                >
                    <stop offset={0} stopColor="#041e4d" />
                    <stop offset={1} stopColor="#01060f" />
                </linearGradient>
            </defs>
            <path
                data-name="plus (1)"
                d="M15.833 0a15.833 15.833 0 1010.835 27.368 15.628 15.628 0 005-11.535A15.851 15.851 0 0015.833 0zm6.927 17.153h-5.607v5.607a1.319 1.319 0 11-2.639 0v-5.607H8.906a1.319 1.319 0 110-2.639h5.608V8.906a1.319 1.319 0 112.639 0v5.608h5.607a1.319 1.319 0 110 2.639zm0 0"
                fill="url(#prefix__a)"
            />
        </svg>
    )
}

export default Add
