export class DateUtils {
    static getDateMomentFormat() {
        return 'DD/MM/YYYY'
    }

    static getDateAsString(date: Date) {
        return date.toLocaleString('da-dk', { month: 'long', day: 'numeric' });
    }

    static getMonthAsString(date: Date) {
        return date.toLocaleString('da-dk', { month: 'long' });
    }

    static getDayAsString(date: Date) {
        return date.toLocaleString('da-dk', { weekday: 'long'});
    }

    static getDateInEpoch(date: Date) {
        return new Date(date.getFullYear(), date.getMonth(), date.getDate()).getTime();
    }

    static getDateOnlyString(date: Date) {
        if (!date) {
            return "";
        }
        
        return `${date.getFullYear()}-${date.getMonth() + 1}-${date.getDate()}`;
    }

    static addDays(date: Date, days: number) {
        date.setDate(date.getDate() + days);

        return date;
    }
}
