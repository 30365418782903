import { createContext, useContext, useRef } from "react";
import * as React from "react";

interface ContextProps {
    scrollContainerRef;
    setScrollContainerRef;
}

export const ScrollContainerContextProvider = createContext<ContextProps>({
    scrollContainerRef: null,
    setScrollContainerRef: null,
});

interface Props {
    children: React.ReactNode;
}
const ScrollContainerProvider = (props: Props) => {
    
    const scrollContainerRef = useRef();
    
    return (
        <ScrollContainerContextProvider.Provider value={{scrollContainerRef, setScrollContainerRef: (e) => scrollContainerRef.current = e}}>
            {props.children}
        </ScrollContainerContextProvider.Provider>
    )
}

export default ScrollContainerProvider

export const useScrollContainerProvider = () => {
    return useContext(ScrollContainerContextProvider);
}
