import { useMutation, useQuery, useQueryClient } from "react-query";
import { request } from "Apis/BaseApi";
import { ApiKeyCreatedModel, ApiKeyModel} from "Apis/Models/ApiKeyModel";
import { Customer } from "Apis/Models/Customer.type";

export const API_KEY_CACHE_KEY = "API_KEY_CACHE_KEY"
export const useApiKeys = () => {
    return useQuery(API_KEY_CACHE_KEY, async () => {
        return request<ApiKeyModel[]>({
            url: '/apikey',
            method: 'GET'
        })
    })
}

export const useSaveApiKey = () => {
    const queryClient = useQueryClient();
    
    return useMutation(async (model: ApiKeyModel): Promise<ApiKeyModel> => {
        if (model.id) {
            return await request<ApiKeyCreatedModel>({
                url: `/apikey/${model.id}`,
                method: 'PUT',
                data: model
            })
        } else {
            return await request<ApiKeyModel>({
                url: `/apikey`,
                method: 'POST',
                data: model
            })
        }
    }, {
        onSuccess: () => {
            queryClient.invalidateQueries(API_KEY_CACHE_KEY);
        }
    })
}

export const useDeleteApiKey = () => {
    const queryClient = useQueryClient();
    
    return useMutation(async (apiKeyModel: ApiKeyModel) => {
        return await request<Customer>({
            url: `/apikey/${apiKeyModel.id}`,
            method: 'DELETE'
        })
    }, {
        onSuccess: () => {
            queryClient.invalidateQueries(API_KEY_CACHE_KEY);
        }
    })
}
