import styles from './Modal.module.scss';
import { CSSTransition } from "react-transition-group";
import { createPortal } from "react-dom";
import { CSSProperties, useEffect } from "react";
import CloseIcon from "Assets/Icons/CloseIcon";

interface Props {
    visible: boolean;
    onCancel?: () => void;
    title?: string;
    children?: any;
    modalStyle?: CSSProperties;
}

const Modal = ({ visible, onCancel, title, children, modalStyle }: Props) => {
    
    useEffect(() => {
        const closeOnEscapeKeyDown = e => {
            if ((e.charCode || e.keyCode) === 27) {
                if (onCancel) {
                    onCancel()
                }
            }
        };
        
        document.body.addEventListener("keydown", closeOnEscapeKeyDown);
        
        return () => {
            document.body.removeEventListener("keydown", closeOnEscapeKeyDown);
        }
    }, [onCancel]);
    
    return createPortal(
        <CSSTransition
            in={visible}
            unmountOnExit
            timeout={{ enter: 0, exit: 300 }}
            classNames={{
                enterDone: styles.enterDone,
                enterActive: styles.enterActive,
                exit: styles.exit,
                exitActive: styles.exitActive
            }}
        >
            <div className={styles.modal} onMouseDown={onCancel}>
                <div className={styles.content} onMouseDown={e => e.stopPropagation()} style={modalStyle}>
                    <div className={styles.header}>
                        <span>
                            {title}
                        </span>
                        <CloseIcon onClick={onCancel} style={{cursor: 'pointer'}} />
                    </div>
                    <div className={styles.body}>
                        {children}
                    </div>
                </div>
            </div>
        </CSSTransition>
    , document.getElementById('root')!)
}

export default Modal;
