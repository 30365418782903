import { DineroInvoiceModel } from "Pages/Dinero/Api/Models/DineroInvoiceModel";
import Col from "antd/lib/col"
import FlexRow from "Components/FlexRow";
import { getUnitTextFromUnit } from "Pages/Dinero/Utils/UnitUtils";
import { getNumberAsCurrency } from "Utils/NumberUtils";
import Spinner from "Components/Spinner";
import { useState } from "react";
import { useTranslation } from "react-i18next";
import { getInputClasses } from "Utils/FormUtils";
import _ from "lodash";
import { Controller } from "react-hook-form";
import UnitSelect from "Pages/Dinero/Components/UnitSelect";
import { CloseCircleFilled } from "@ant-design/icons";
import TextArea from "antd/lib/input/TextArea";

interface Props {
    index: number;
    line: any;
    status: string;
    loadingInvoiceTotals: boolean;
    invoiceModel?: DineroInvoiceModel;
    getInvoiceTotals: Function;
    errors: any;
    control: any;
    register: any;
    removeLine: Function;
}

const InvoiceLine = ({ index, line, status, loadingInvoiceTotals, invoiceModel, getInvoiceTotals, errors, control, register, removeLine }: Props) => {
    
    const { t } = useTranslation();
    const [showComments, setShowComments] = useState<boolean>(!!line.comments);
    
    if (status !== 'Draft') {
        return (
            <>
                <Col span={8}>
                    <div>
                        {line.description}
                    </div>
                    <div style={{fontSize: '.8em'}}>
                        {line.comments}
                    </div>
                </Col>
                <Col span={3}>
                    <FlexRow justify={"end"} align={"start"} className={"h-100"}>
                        {line.quantity}
                    </FlexRow>
                </Col>
                <Col span={3}>
                    {t(getUnitTextFromUnit(line.unit))}
                </Col>
                <Col span={3}>
                    {getNumberAsCurrency(line.baseAmountValue)}
                </Col>
                <Col span={3}>
                    {line.discount && line.discount}
                </Col>
                <Col span={4}>
                    <FlexRow justify={"end"} align={"center"} className={"h-100"}>
                        {loadingInvoiceTotals ? <Spinner/> : getNumberAsCurrency(invoiceModel?.productLines[index]?.totalAmount ?? 0)}
                    </FlexRow>
                </Col>
            </>
        )
    }
    
    return (
        <>
            <Col span={8}>
                <input defaultValue={line.index} style={{ display: 'none' }} ref={register()} name={`productLines[${index}].index`}/>
                <input defaultValue={line.description ?? ''} className={getInputClasses(_.get(errors, `productLines[${index}].description`))} name={`productLines[${index}].description`} ref={register({ required: true })}/>
            </Col>
            <Col span={3}>
                <Controller
                    name={`productLines.${index}.quantity`}
                    control={control}
                    defaultValue={line.quantity}
                    rules={{ valueAsNumber: true, required: true }}
                    render={(props) => (
                        <input type={"number"} value={props.value} className={getInputClasses(_.get(errors, `productLines[${index}].quantity`))} onChange={e => {
                            props.onChange(e);
                            getInvoiceTotals();
                        }}/>
                    )}
                />
            </Col>
            <Col span={3}>
                <Controller
                    name={`productLines[${index}].unit`}
                    control={control}
                    defaultValue={line.unit ?? 'parts'}
                    render={(props) => (
                        <UnitSelect onChange={props.onChange} value={props.value}/>
                    )}
                />
            </Col>
            <Col span={3}>
                <Controller
                    name={`productLines[${index}].baseAmountValue`}
                    control={control}
                    defaultValue={line.baseAmountValue ?? 0}
                    rules={{ required: true, valueAsNumber: true }}
                    render={(props) => (
                        <input type={"number"} value={props.value} className={getInputClasses(_.get(errors, `productLines[${index}].baseAmountValue`))} onChange={e => {
                            props.onChange(e);
                            getInvoiceTotals();
                        }}/>
                    )}
                />
            </Col>
            <Col span={3}>
                <Controller
                    name={`productLines[${index}].discount`}
                    control={control}
                    defaultValue={line.discount ?? 0}
                    render={(props) => (
                        <input type={"number"} value={props.value} className={getInputClasses(_.get(errors, `productLines[${index}].discount`))} onChange={e => {
                            props.onChange(e);
                            getInvoiceTotals();
                        }}/>
                    )}
                />
            </Col>
            <Col span={3}>
                <FlexRow justify={"end"} align={"center"} className={"h-100"}>
                    {loadingInvoiceTotals ? <Spinner/> : getNumberAsCurrency(invoiceModel?.productLines[index]?.totalAmount ?? 0)}
                </FlexRow>
            </Col>
            <Col span={1}>
                <FlexRow justify={"end"} align={"center"} className={"h-100 clickable"} onClick={() => removeLine(index)}>
                    <CloseCircleFilled/>
                </FlexRow>
            </Col>
            <Col span={8}>
                {showComments && (
                    <Controller
                        name={`productLines[${index}].comments`}
                        control={control}
                        defaultValue={line.comments ?? ''}
                        render={(props) => (
                            <TextArea placeholder={t('dinero:edit.lineComment')} value={props.value} onChange={props.onChange} className={getInputClasses(_.get(errors, `productLines[${index}].comments`))}/>
                        )}
                    />
                )}
                <div style={{fontSize: '.8em', cursor: 'pointer'}} onClick={() => setShowComments(value => !value)}>
                    {showComments ? (
                        <>
                            {t('dinero:closeComments')}
                        </>
                    ) : (
                        <>
                            {t('dinero:showComments')}
                        </>
                    )}
                </div>
            </Col>
            <Col flex={"auto"}/>
            <Col span={24}>
                <hr/>
            </Col>
        </>
    )
}

export default InvoiceLine
