import Select from "antd/lib/select";
import {useTranslation} from "react-i18next";
import { getUnitTextFromUnit } from "Pages/Dinero/Utils/UnitUtils";

interface Props {
    onChange: any;
    value: any
}
const UnitSelect = (props: Props) => {

    const { t } = useTranslation();
    
    const filterOption = (value, option) => {
        const { children } = option;

        if (option.options) {
            return option.options.includes((child) => child.props.children.toLowerCase().indexOf(value.toLowerCase()) >= 0);
        }

        return children.toLowerCase().indexOf(value.toLowerCase()) >= 0;
    };

    const selectProps = {
        showSearch: true,
        filterOption: filterOption
    }

    return (
        <Select
            {...selectProps}
            {...props}
            placeholder={t('dinero:unit.selectUnit')}
            className={"w-100"}
            showArrow={false}
            defaultValue={"hours"}
        >
            <Select.Option value="day">{t(getUnitTextFromUnit("day"))}</Select.Option>
            <Select.Option value="shipment">{t(getUnitTextFromUnit("shipment"))}</Select.Option>
            <Select.Option value="carton">{t(getUnitTextFromUnit("carton"))}</Select.Option>
            <Select.Option value="box">{t(getUnitTextFromUnit("box"))}</Select.Option>
            <Select.Option value="kilogram">{t(getUnitTextFromUnit("kilogram"))}</Select.Option>
            <Select.Option value="km">{t(getUnitTextFromUnit("km"))}</Select.Option>
            <Select.Option value="cubicMetre">{t(getUnitTextFromUnit("cubicMetre"))}</Select.Option>
            <Select.Option value="squareMetre">{t(getUnitTextFromUnit("squareMetre"))}</Select.Option>
            <Select.Option value="litre">{t(getUnitTextFromUnit("litre"))}</Select.Option>
            <Select.Option value="month">{t(getUnitTextFromUnit("month"))}</Select.Option>
            <Select.Option value="metre">{t(getUnitTextFromUnit("metre"))}</Select.Option>
            <Select.Option value="package">{t(getUnitTextFromUnit("package"))}</Select.Option>
            <Select.Option value="session">{t(getUnitTextFromUnit("session"))}</Select.Option>
            <Select.Option value="parts">{t(getUnitTextFromUnit("parts"))}</Select.Option>
            <Select.Option value="set">{t(getUnitTextFromUnit("set"))}</Select.Option>
            <Select.Option value="hours">{t(getUnitTextFromUnit("hours"))}</Select.Option>
            <Select.Option value="tonne">{t(getUnitTextFromUnit("tonne"))}</Select.Option>
            <Select.Option value="week">{t(getUnitTextFromUnit("week"))}</Select.Option>
            <Select.Option value="case">{t(getUnitTextFromUnit("case"))}</Select.Option>
        </Select>
    )
}

export default UnitSelect
