import {UserContext} from "Apis/Models/UserContext.type";

export const hasAccess = (requiredAccess: number, userContext: UserContext): boolean => {
    // If there is no valid license then we have no access checks
    if (!userContext.hasActiveLicense) {
        return true;
    }

    return userContext.accessLevel >= requiredAccess;
}
