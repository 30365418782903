import Spinner from "Components/Spinner";
import RequiresPremiumModal from "Components/RequiresPremiumModal";
import Button from "Components/Button/Button";
import {useNavigate} from "react-router-dom";
import {useEconomicIntegration} from "Apis/EconomicApi";
import FlexRow from "Components/FlexRow";
import {useTranslation} from "react-i18next";
import { useAuthProvider } from "Providers/AuthProvider";

const economicInstallUrl = "https://secure.e-conomic.com/secure/api1/requestaccess.aspx?appPublicToken=Rm1KVCMyhePTdMDgJjt2NP4nwRZQ7atjoWWQOPzLer81&redirectUrl=https%3A%2F%2Fapp.timeops.dk%2Forganization%2Fintegrations%2Feconomic%2Fcallback"

const EconomicIntegrationOverview = () => {

    const { t } = useTranslation();
    const navigate = useNavigate();
    const { isLoading, data } = useEconomicIntegration();

    const { userContext } = useAuthProvider();

    let button;

    if (isLoading) {
        button = (
            <Spinner />
        )
    } else if (!userContext?.hasActiveLicense) {
        button = (
            <RequiresPremiumModal enabled={!userContext?.hasActiveLicense} title={t('economic:overview.title')}>
                <Button block color={"primary"}>{t('economic:overview.connectToEconomic')}</Button>
            </RequiresPremiumModal>
        )
    } else if (!data) {
        button = (
            <Button block onClick={() => window.location.href = economicInstallUrl} color={"primary"}>{t('economic:overview.connectToEconomic')}</Button>
        )
    } else {
        button = (
            <Button block color={"primary"} onClick={() => navigate('/integrations/economic')}>
                {t('economic:overview.updateIntegration')}
            </Button>
        )
    }

    return (
        <FlexRow className="box white dont-hover padding30 h-100" direction={"column"} justify={"space-between"}>
            <img src={"/icons/economic.jpg"} alt={"E-conomic logo"} style={{paddingBottom: '30px', maxWidth: '100px'}} />
            <div className={"w-100"}>
                <b>{t('economic:overview.title')}</b>
                <p>
                    {t('economic:overview.description')}
                </p>
            </div>
            {button}
        </FlexRow>
    )
}

export default EconomicIntegrationOverview
