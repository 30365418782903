import PremiumTrialAnnouncement from "Components/PremiumTrialAnnouncement";
import { useTranslation } from "react-i18next";

const ZapierPage = () => {
    
    const { t } = useTranslation();
    
    return (
        <>
            <PremiumTrialAnnouncement>
                {t('premiumAnnouncements.integrationsIsAPartOfTimeOpsPremium')}
            </PremiumTrialAnnouncement>
            
            <zapier-app-directory
                app="timeops"
                link-target="same-frame"
                theme="light"
            />
        </>
    )
}

export default ZapierPage
