import * as React from "react"
import { SVGProps } from "react"

const ClientsIcon = (props: SVGProps<SVGSVGElement>) => (
    <svg xmlns="http://www.w3.org/2000/svg" width="1em" height="1em" viewBox="0 0 43.429 35.445" {...props}>
        <path
            data-name="003-group"
            d="M40.532 21.838a13.3 13.3 0 0 0-4.345-3.4 10.188 10.188 0 0 0 4.206-8.245c.026-8.922-10.883-13.566-17.266-7.335a10.246 10.246 0 0 0-2.825 0 10.19 10.19 0 1 0-13.059 15.58A12.719 12.719 0 0 0 0 29.752v1.666a1.272 1.272 0 0 0 2.545 0v-1.666a10.333 10.333 0 0 1 7.505-9.695 1.272 1.272 0 0 0 .243-.1 10.222 10.222 0 0 0 4.347.334 10.268 10.268 0 0 0 1.085.91 12.719 12.719 0 0 0-7.242 11.314v1.665a1.272 1.272 0 0 0 2.545 0v-1.665a10.263 10.263 0 0 1 6.387-9.291v.014a4.3 4.3 0 0 0 8.6 0v-.02a10.249 10.249 0 0 1 6.385 9.296v1.665a1.272 1.272 0 0 0 2.545 0v-1.665a12.725 12.725 0 0 0-7.241-11.315 10.273 10.273 0 0 0 1.084-.91 10.2 10.2 0 0 0 4.29-.317 10.239 10.239 0 0 1 7.8 9.779v1.667a1.272 1.272 0 0 0 2.545 0v-1.666a12.4 12.4 0 0 0-2.891-7.914ZM30.2 2.538c9.868.362 10.258 14.339.471 15.288a10.244 10.244 0 0 0-4.584-14.085A7.563 7.563 0 0 1 30.2 2.538Zm-8.485 18.069A7.662 7.662 0 0 1 17.5 6.574a10.223 10.223 0 0 0 11.855 5.974 7.666 7.666 0 0 1-7.64 8.059ZM28.8 10.065a7.7 7.7 0 0 1-8.975-4.527 7.7 7.7 0 0 1 8.975 4.527Zm-23.217.128a7.667 7.667 0 0 1 11.755-6.445 10.244 10.244 0 0 0-4.577 14.082 7.661 7.661 0 0 1-7.18-7.637Zm16.133 14.8a1.757 1.757 0 0 1-1.757-1.755v-.243a10.27 10.27 0 0 0 3.511 0v.243a1.757 1.757 0 0 1-1.755 1.76Z"
            fill={props.fill ?? "#bed5ff"}
        />
    </svg>
)

export default ClientsIcon
