import SignUpSteps from "Pages/Auth/Components/SignUpSteps";
import Space from "antd/lib/space";
import ControlledInput from "Components/InputV2";
import Button from "Components/Button";
import styles from './SignupPageV2.module.scss';
import Layout from "Pages/Auth/Components/Layout";
import { useTranslation } from "react-i18next";
import { useForm } from "react-hook-form";
import React, { useCallback, useEffect, useRef, useState } from "react";
import CountrySelect from "Components/CountrySelect";
import Row from "antd/lib/row";
import Col from "antd/lib/col";
import { CreateOrganizationV2 } from "Apis/Models/Organization.type";
import { useCreateOrganizationV2 } from "Apis/OrganizationApi.ts";
import SelectV2 from "Components/SelectV2";
import { useVatInformationSearch } from "Apis/CVRApi";
import _ from "lodash";
import { auth } from "FirebaseSetup/auth";
import { useAuthProvider } from "Providers/AuthProvider";
import { Link, useNavigate } from "react-router-dom";
import { openErrorNotification } from "Utils/NotificationUtils";
import { reportConversion } from "Utils/GoogleAdsUtils";
import Checkbox from "Components/Checkbox";
import useQueryParam from "Hooks/UseQueryParam";
import { getCookieByName } from "Utils/CookieUtils";

declare var ga: any;
declare var fbq: any;
declare var lintrk: any;

const SignupPageV2 = () => {
    
    const { t } = useTranslation();
    const navigate = useNavigate();
    
    const [activeForm, setActiveForm] = useQueryParam('part');

    const { mutateAsync: createOrganizationMutation, isLoading} = useCreateOrganizationV2();
    
    const { forceReload } = useAuthProvider();
    
    useEffect(() => {
        if (!activeForm) {
            setActiveForm('User')
        }
    }, [activeForm, setActiveForm])
    
    const { reset, handleSubmit, control, errors, setValue, getValues } = useForm<CreateOrganizationV2>();
    
    const vatInformationSearchRef = useRef<HTMLInputElement>();
    
    const [manualSetup, setManualSetup] = useState<boolean>(false);
    const [vatNumberSelected, setVatNumberSelected] = useState<boolean>(false);
    
    const [vatInformationQuery, setVatInformationQuery] = useState<string>('');
    
    const { data: vatInformation, isLoading: isVatLoading  } = useVatInformationSearch(vatInformationQuery);
    
    // eslint-disable-next-line react-hooks/exhaustive-deps
    const setVatInformationQueryDebounced = useCallback(_.debounce(async (query: string) => {
        setVatInformationQuery(query)
    }, 300), [])
    
    const gotoOrganizationForm = () => {

        const data = getValues();

        if (data.name === '') {
            openErrorNotification(t('errors:waitAMinute'), t('auth:nameIsRequired'), 'topLeft');
            return;
        }

        if (data.email.length < 5 || !data.email.includes('@')) {
            openErrorNotification(t('errors:anErrorOccurred'), t('auth:enterAValidEmail'), 'topLeft');
            return;
        }

        if (data.password.length < 6) {
            openErrorNotification(t('errors:anErrorOccurred'), t('auth:weekPassword'), 'topLeft');
            return;
        }

        if (!data.hasAcceptedTerms) {
            openErrorNotification(t('errors:anErrorOccurred'), t('auth:mustAcceptTerms'), 'topLeft');
            return;
        }
        
        setActiveForm('Organization');
        vatInformationSearchRef.current!.focus();
    }
    
    const createOrganization = async (organization: CreateOrganizationV2) => {
        const partnerAdsCookie = getCookieByName('partner_ads');

        if (partnerAdsCookie) {
            const parts = partnerAdsCookie!.split(";");

            const paid = parts.find(x => x.startsWith("paid"))!.split("=")[1];
            const pacid = parts.find(x => x.startsWith("pacid"))!.split("=")[1];

            organization = {
                ...organization,
                paid,
                pacid
            }
        }
        
        organization.signupSource = "Web";

        const fields = [
            organization.city.length < 2,
            organization.address.length < 2,
            organization.zipCode.length <= 3,
            organization.companyName.length < 2,
        ]

        if (fields.filter(x => x).length > 0) {
            openErrorNotification(t('errors:anErrorOccurred'), t('auth:allFieldsMustAreRequired'), 'topLeft');
            return;
        }

        if (organization.vatNumber.length < 8) {
            openErrorNotification(t('errors:anErrorOccurred'), t('auth:vatNumberMustBeAtLease8Numbers'), 'topLeft');
            return;
        }
        
        try {
            const response = await createOrganizationMutation(organization);
            
            if (response.customToken) {
                await auth.signInWithCustomToken(response.customToken);
    
                if (!window.location.hostname.includes('localhost')) {
                    reportConversion("CreateUser");
        
                    ga('send', {
                        hitType: 'event',
                        eventCategory: 'account',
                        eventAction: 'created',
                    });
    
                    reportConversion("CreateOrganization");
    
                    ga('send', {
                        hitType: 'event',
                        eventCategory: 'organization',
                        eventAction: 'created',
                    });
    
                    fbq('track', 'CompleteRegistration');
    
                    lintrk('track', { conversion_id: 9872361 });
                }
                
                forceReload();
                
                navigate('/registrations/dashboard?welcome')
            }
        }
        catch (e: any) {
            switch (e.data) {
                case "USER/EMAIL_ALREADY_EXISTS":
                    openErrorNotification(t('errors:anErrorOccurred'), t('auth:userExists'), 'topLeft');
                    break;
                case "USER/INVALID_EMAIL":
                    openErrorNotification(t('errors:anErrorOccurred'), t('auth:invalidEmail'), 'topLeft');
                    break;
                case "USER/UNEXPECTED_ERROR":
                    openErrorNotification(t('errors:anErrorOccurred'), t('auth:unexpectedError'), 'topLeft');
                    break;
                case "USER/WEEK_PASSWORD":
                    openErrorNotification(t('errors:anErrorOccurred'), t('auth:weekPassword'), 'topLeft');
                    break;
            }
            
            if (e.data.includes('USER/')) {
                setActiveForm('User')
            }
            console.error(e)
        }
    }
    
    const selectVatNumber = (vatNumber: string) => {
        if (vatNumber === null) {
            return;
        }
        
        const selectedVatNumber = vatInformation?.find(x => x.vatNumber === vatNumber);
    
        setValue('vatNumber', selectedVatNumber?.vatNumber);
        setValue('companyName', selectedVatNumber?.name);
        setValue('country', selectedVatNumber?.countryCode);
        setValue('address', selectedVatNumber?.street);
        setValue('city', selectedVatNumber?.city);
        setValue('zipCode', selectedVatNumber?.zipCode);
    
        setManualSetup(false);
        setVatNumberSelected(true)
    }
    
    const resetSearch = () => {
        setManualSetup(false);
        setVatNumberSelected(false);
        
        reset({
            vatNumber: '',
            companyName: '',
            country: 'DK',
            address: '',
            city: '',
            zipCode: '',
        })
    }
    
    
    return (
        <Layout
            preHeader={activeForm === 'User' ? t('auth:welcome') : t('organization:createOrganization.information')}
            header={activeForm === 'User' ? t('auth:signupTitle') : t('organization:createOrganization.title')}
            imgUrl={"/images/appMockupV2.png"}
            steps={<SignUpSteps activeStep={activeForm === 'User' ? 0 : 1} />}
        >
            <form onSubmit={handleSubmit(createOrganization)}>
            <div className={styles.formContainer} style={{minHeight: 600}}>
                <div className={`${styles.form} ${activeForm === 'User' && styles.active}`}>
                        <Space size={"large"} direction={"vertical"} className={"w-100"}>
                
                            <ControlledInput name={"name"} control={control} errors={errors} placeholder={t('auth:nameLabel')} rules={{required: true}} />
                            <ControlledInput name={"email"} control={control} errors={errors} placeholder={t('auth:emailLabel')} rules={{required: true}} suffix={'@'} />
                            <ControlledInput name={"password"} control={control} errors={errors} placeholder={t('auth:passwordLabel')} type={"password"} rules={{required: true, minLength: 6}} />
                            
                            <Checkbox control={control} name={"hasAcceptedTerms"} label={t('auth:acceptTerms')} rules={{required: true}}  />
                
                            <Button type={"button"} onClick={gotoOrganizationForm} color={"primary"} block><span style={{fontSize: '1.5em', fontWeight: 600}}>{t('auth:createUser')}</span></Button>
    
                            <div className={styles.link}>{t('auth:alreadyHaveAnAccount')} <Link to={"/auth/login"}>{t('auth:login')}</Link></div>
                        </Space>
                </div>
        
                <div className={`${styles.form} ${activeForm === 'Organization' && styles.active}`}>
                        <Space size={"large"} direction={"vertical"} className={"w-100"}>
    
    
                            {!vatNumberSelected ? (
                                <SelectV2 placeholder={t('auth:searchByVatInformationPlaceholder')} name={"vatInformation"} onFilter={setVatInformationQueryDebounced} onChange={selectVatNumber} suffix={{label: "Indtast manuelt", onClick: () => setManualSetup(current => !current), isShown: true, active: true}}>
                                    <>
                                        {isVatLoading && <SelectV2.Option value={null} label={t('loading')} />}
                                        {vatInformation?.length === 0 && <SelectV2.Option value={null} label={t('noResults')} />}
                                        {vatInformation?.map(vat => (
                                            <SelectV2.Option value={vat.vatNumber} key={vat.vatNumber} label={`${vat.name} - ${vat.vatNumber}`} />
                                        ))}
                                    </>
                                </SelectV2>
                            ) :  (
                                <div>
                                    <b>
                                        {getValues('companyName')}
                                    </b>
                                    <div>
                                        {getValues('address')}
                                    </div>
                                    <div>
                                        {getValues('zipCode')} {getValues('city')} - {getValues('country')}
                                    </div>
                                    <div>
                                        CVR: {getValues('vatNumber')}
                                    </div>
                                    
                                    <div className={styles.link} onClick={resetSearch}>
                                        {t('auth:notYourCompany')}?
                                    </div>
                                </div>
                            )}
                            
                            <div style={{display: manualSetup ? 'block' : 'none', borderTop: '1px solid #B2B7C0', paddingTop: 30}}>
                                <Space size={"large"} direction={"vertical"} className={"w-100"}>
                                    <ControlledInput placeholder={t('workspaceName')} control={control} name={"companyName"} errors={errors} />
                                    <ControlledInput placeholder={t('vatNumber')} control={control} name={"vatNumber"} errors={errors} />
                                    <CountrySelect placeholder={t('country')} errors={errors} name={"country"} defaultValue={"DK"} control={control} />
                                    <ControlledInput placeholder={t('address')} control={control} name={"address"} errors={errors} />
    
                                    <Row gutter={10}>
                                        <Col span={12}>
                                            <ControlledInput placeholder={t('zipCode')} control={control} name={"zipCode"} errors={errors} />
                                        </Col>
                                        <Col span={12}>
                                            <ControlledInput placeholder={t('city')} control={control} name={"city"} errors={errors} />
                                        </Col>
                                    </Row>
                                </Space>
                            </div>
                            
                            <Button loading={isLoading} color={"primary"} block type={"submit"}><span style={{fontSize: '1.5em', fontWeight: 600}}>{t('organization:createOrganization.create')}</span></Button>
                        </Space>
                </div>

            </div>
            </form>

        </Layout>
    )
}

export default SignupPageV2
