import styles from './Components/Layout.module.scss';
import Button from "Components/Button/Button";
import { Link } from 'react-router-dom';
import ControlledInput from "Components/InputV2/ControlledInput";
import { useForm } from "react-hook-form";
import Space from "antd/lib/space";
import { useState } from "react";
import {signInWithGoogle} from 'FirebaseSetup/auth';
import { useTranslation } from "react-i18next";
import { openErrorNotification } from "Utils/NotificationUtils";
import Layout from "Pages/Auth/Components/Layout";
import { useAuthProvider } from "Providers/AuthProvider";
import GoogleIcon from "Assets/Icons/GoogleIcon";

interface LoginFormFields {
    email: string;
    password: string;
}

const LoginPage = () => {
    
    const { t } = useTranslation();
    
    const { signIn } = useAuthProvider();
    
    const { handleSubmit, control, errors } = useForm<LoginFormFields>();
    
    const [isSigningIn, setIsSigningIn] = useState<boolean>(false);
    
    const onSubmit = (data: LoginFormFields) => {
        setIsSigningIn(true)
        
        signIn(data).catch(e => {
            let error;
            
            switch (e.code) {
                case 'auth/user-not-found':
                case 'auth/wrong-password':
                    error = t('auth:invalidCredentials');
                    break;
                case 'auth/user-disabled':
                    error = t('auth:userDeactivated');
                    break;
                case 'auth/invalid-email':
                    error = t('auth:invalidEmail');
                    break;
                default:
                    error = t('auth:unexpectedError');
                    break;
            }
            
            openErrorNotification(t('errors:anErrorOccurred'), error, 'topLeft');
            setIsSigningIn(false)
        });
    }
    
    return (
        <Layout
            preHeader={t('auth:loginTitle')}
            header={t('auth:login')}
            imgUrl={"/images/appMockupV2.png"}
        >
            <form onSubmit={handleSubmit(onSubmit)}>
                <Space size={"large"} direction={"vertical"} className={"w-100"}>
            
                    <ControlledInput name={"email"} control={control} errors={errors} placeholder={t('auth:emailLabel')} rules={{required: true}} suffix={'@'} />
                    <ControlledInput name={"password"} control={control} errors={errors} placeholder={t('auth:passwordLabel')} type={"password"} rules={{required: true, minLength: 6}} />
            
                    <Button loading={isSigningIn} type={"submit"} color={"primary"} block><span style={{fontSize: '1.5em', fontWeight: 600}}>{t('auth:login')}</span></Button>

                    <Button block onClick={signInWithGoogle}>
                        <GoogleIcon />
                        <span style={{fontSize: '1.5em', fontWeight: 600}}>
                            {t('auth:loginWithGoogle')}
                        </span>
                    </Button>

                </Space>
            </form>

            <div className={styles.link}>{t('auth:dontHaveAccount')} <Link to={"/auth/signup"}>{t('auth:createAccount')}</Link></div>
            <div className={styles.link}>{t('auth:cantRememberYourPassword')} <Link to={"/auth/resetPassword"}>{t('auth:resetPassword')}</Link></div>
        </Layout>
    )
}

export default LoginPage
