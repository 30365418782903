import { CSSProperties, useRef, useState } from "react";
import { useTranslation } from "react-i18next";
import { useTags } from "Apis/TagApi";
import Dropdown from "Components/Dropdown";
import TagInactive from "Assets/Icons/TagInactive";
import styles from 'Components/TagDropdown.module.scss';
import { Tag } from "Apis/Models/Tag";
import TagActive from "Assets/Icons/TagActive";
import FlexRow from "Components/FlexRow";
import InputV2 from "Components/InputV2/InputV2";

interface Props {
    value: number[];
    onChange: any;
    style?: CSSProperties;
    bordered?: boolean;
    className?: string;
}
const TagDropdown = (props: Props) => {
    
    const { t } = useTranslation();
    
    const { data: tags } = useTags();
    const [filter, setFilter] = useState<string>('');
    const inputRef = useRef<HTMLInputElement>();
    
    const getFilteredTags = () => {
        if (!filter) {
            return tags;
        }
        
        return tags?.filter((tag) => tag.text.toLowerCase().includes(filter));
    };
    
    const selectTag = (tag: Tag) => {
        if (props.value.includes(tag.id)) {
            props.onChange(props.value.filter((id) => id !== tag.id));
        } else {
            props.onChange([...props.value, tag.id]);
        }
    }
    
    
    const toggle= () => {
        return (
            <FlexRow>
                {props.value?.length > 0 ? <TagActive /> : <TagInactive/>}
            </FlexRow>
        )
    }
    
    const focusInput = () => {
        inputRef.current?.focus();
    }
    
    return (
        <Dropdown toggle={toggle()} onToggleOpen={focusInput}>
            <div className={styles.content}>
                <InputV2 inputRef={e => inputRef.current = e!} onChange={e => setFilter(e.toLowerCase())} containerStyle={{margin: '15px 0', borderRadius: 0, borderRightWidth: 0, borderLeftWidth: 0, backgroundColor: '#FAFCFF'}} placeholder={t('registrations:filterTags')}  />
                {getFilteredTags()?.map((tag) => {
                    return (
                        <div key={tag.id} className={`${styles.tag} ${props.value?.includes(tag.id) && styles.selected}`} onClick={() => selectTag(tag)}>
                            {tag.text}
                        </div>
                    )
                })}
                {getFilteredTags()?.length === 0 && (
                    <div style={{margin: '15px 0'}}>
                        {t('registrations:noTags')}
                    </div>
                )}
            </div>
        </Dropdown>
    )
}

export default TagDropdown
