import styles from "Components/List/List.module.scss";
import ListTitle from "Components/List/ListTitle";
import ListText from "Components/List/ListText";
import * as React from "react";
import { Column } from "Components/List/List";

interface Props {
    column: Column;
    record: any;
    index: number;
    compact?: boolean;
}
const DefaultColumn = ({column, record, index, compact}: Props) => {
    
    return (
        <>
            {column.icon && (
                <div className={styles.icon}>
                    {column.icon}
                </div>
            )}
            <div className={`flex column justify-center w-100 h-100 ${index !== 0 ? styles.separator : ''}`}>
                {!compact && (
                    <ListTitle>
                        {column.title}
                    </ListTitle>
                )}
                <ListText compact={compact}>
                    {column.textRender ? column.textRender(record[column.dataIndex!], record) : record[column.dataIndex!]}
                </ListText>
            </div>
        </>
    )
}

export default DefaultColumn
