import Select from "antd/lib/select";
import { Fragment, CSSProperties, useState, useMemo } from "react";
import { useCustomers } from "Apis/CustomerApi";
import { SizeType } from "antd/lib/config-provider/SizeContext";
import { useTranslation } from "react-i18next";
import CustomerModal from "Pages/Customers/Components/CustomerModal";
import ProjectModal from "Pages/Customers/Components/ProjectModal";
import { Project } from "Apis/Models/Project.type";
import styles from './ProjectSelect.module.scss';
import { hasAccess } from "Utils/AccessUtils";
import { useAuthProvider } from "Providers/AuthProvider";


interface Props {
    value: any;
    onChange: any;
    style?: CSSProperties;
    size?: SizeType;
    bordered?: boolean;
    className?: string;
}
const ProjectSelect = (props: Props) => {
    
    const { t } = useTranslation();
    const { userContext } = useAuthProvider();
    
    const { data: customers, isLoading: isLoadingCustomers } = useCustomers();
    
    const [searchFilter, setSearchFilter] = useState<string>('');
    const [customerModalVisible, setCustomerModalVisible] = useState<boolean>(false);
    const [projectModalVisible, setProjectModalVisible] = useState<boolean>(false);
    
    const onSearch = (value) => {
        setSearchFilter(value)
    }
    
    const onChange = (event) => {
        if (event === "createCustomer") {
            setCustomerModalVisible(true);
            props.onChange(null)
        } else if (event === "createProject") {
            setProjectModalVisible(true);
            props.onChange(null)
        } else {
            props.onChange(event)
        }
    }
    
    const onCustomerOk = () => {
        setSearchFilter('');
        setCustomerModalVisible(false);
    }
    
    const onCustomerCancel = () => {
        setSearchFilter('');
        setCustomerModalVisible(false);
    }
    
    const onProjectOk = (newProject: Project) => {
        props.onChange(newProject.id)
        setSearchFilter('');
        setProjectModalVisible(false);
    }
    
    const onProjectCancel = () => {
        setSearchFilter('');
        setProjectModalVisible(false);
    }
    
    const getInitialValues = useMemo(() => {
        return { name: searchFilter }
    }, [searchFilter])
    
    const projectOptions = useMemo(() => customers?.map((customer) => {
        
        let unfinishedProjects = customer.projects?.filter(x => !x.finishedAt) ?? [];
        const lowerCase = searchFilter.toLowerCase();
        
        if (searchFilter !== '') {
            unfinishedProjects = unfinishedProjects.filter(x => x.customer!.name.toLowerCase().includes(lowerCase) || x.name.toLowerCase().includes(lowerCase));
        }
        
        if (unfinishedProjects.length === 0) {
            return <Fragment key={customer.id} />;
        }
        
        const options = unfinishedProjects.sort((a,b) => a.name > b.name ? 1 : -1).map((project) => {
            return (
                <Select.Option label={`${project.name} - ${customers?.find(x => x.id === project.customerId)?.name}`} key={project.id} value={project.id}>{project.name}</Select.Option>
            )
        })
        
        if (options.length === 0) {
            return <></>
        }
        
        return (
            <Select.OptGroup key={customer.id} label={customer.name}>
                {options}
            </Select.OptGroup>
        )
    }), [searchFilter, customers])
    
    const createOptions = () => {
        if (hasAccess(150, userContext!)) {
            return (
                <Select.OptGroup key={"create"} label={t('create')}>
                    <Select.Option value={"createCustomer"} key={"createCustomer"}>{`+ ${t('registrations:createNewCustomer')}`}</Select.Option>
                    <Select.Option value={"createProject"} key={"createProject"}>{`+ ${t('registrations:createNewProject')}`}</Select.Option>
                </Select.OptGroup>
            )
        } else {
            return
        }
    }
    
    return (
        <>
            <Select
                optionLabelProp={"label"}
                loading={isLoadingCustomers}
                value={props.value}
                className={props.className}
                style={props.style}
                size={props.size}
                showSearch
                showArrow={false}
                bordered={props.bordered}
                placeholder={t('selectProject')}
                optionFilterProp="children"
                onChange={onChange}
                onSearch={onSearch}
                filterOption={(input, option: any) => {
                    return true;
                }}
                notFoundContent={hasAccess(150, userContext!) && (
                    <>
                        <div className={`${styles.createItem} clickable`} onClick={() => setCustomerModalVisible(true)}>
                            + {t('projectSelect.createTheCustomer')} <b>{searchFilter}</b>
                        </div>
                        <div className={`${styles.createItem} clickable`} onClick={() => setProjectModalVisible(true)}>
                            + {t('projectSelect.createTheProject')} <b>{searchFilter}</b>
                        </div>
                    </>
                )}
            >
                {projectOptions}
                {createOptions()}
            </Select>
            
            <CustomerModal visible={customerModalVisible} onOk={onCustomerOk} onCancel={onCustomerCancel} initialValues={getInitialValues} />
            
            <ProjectModal visible={projectModalVisible} onOk={onProjectOk} onCancel={onProjectCancel} initialValues={getInitialValues} />
        </>
    )
}

export default ProjectSelect
