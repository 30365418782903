import * as React from "react"
import { SVGProps } from "react"

const DownloadIcon = (props: SVGProps<SVGSVGElement>) => (
    <svg
        xmlns="http://www.w3.org/2000/svg"
        width={29.173}
        height={27.404}
        viewBox="0 0 29.173 27.404"
        {...props}
    >
        <g fill="#0d60f8" stroke="#0d60f8" strokeWidth={0.2}>
            <path
                data-name="Path 1191"
                d="M23.499 7.408a9.063 9.063 0 0 0-17.776-.006 6.344 6.344 0 0 0 .731 12.647h4.531a.907.907 0 0 0 0-1.813H6.454a4.532 4.532 0 1 1 .051-9.063.906.906 0 0 0 .9-.807 7.251 7.251 0 0 1 14.405 0 .961.961 0 0 0 .954.807 4.532 4.532 0 1 1 0 9.063h-4.528a.907.907 0 0 0 0 1.813h4.532a6.344 6.344 0 0 0 .731-12.642Zm0 0"
            />
            <path
                data-name="Path 1192"
                d="m17.814 21.907-2.3 2.3V12.798a.906.906 0 1 0-1.812 0v11.407l-2.3-2.3a.906.906 0 0 0-1.3 1.259l.022.022 3.844 3.845a.91.91 0 0 0 .293.2.9.9 0 0 0 .7 0 .906.906 0 0 0 .293-.2l3.844-3.845a.906.906 0 1 0-1.259-1.3Zm0 0"
            />
        </g>
    </svg>
)

export default DownloadIcon
