import Col from "antd/lib/col";
import Empty from "Components/Empty";
import Row from "antd/lib/row";
import Skeleton from "antd/lib/skeleton";
import Space from "antd/lib/space";
import FlexRow from "Components/FlexRow";
import Duration from "Components/Duration";
import { useCallback, useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import { ReportSearchParams, useReporting } from "Hooks/UseReporting";
import { ReportModel } from "Apis/Models/ReportModel";
import Header from "Components/Header";
import Button from "Components/Button/Button";
import moment from "moment";
import styles from './FindRegistrationsModal.module.scss';
import CheckmarkFilled from "Assets/Icons/CheckmarkFilled";
import CheckmarkUnfilled from "Assets/Icons/CheckmarkUnfilled";
import * as React from "react";
import TagSelect from "Components/TagSelect";
import { useDineroIntegration } from "Pages/Dinero/Api/DineroIntegrationApi";
import Modal from "Components/Modal";

interface Props {
    visible: boolean,
    onOk: (report: ReportModel, selected: number[], searchParams: ReportSearchParams, tags: number[]) => void,
    onCancel: () => void,
    initialValues: ReportSearchParams;
    isOkLoading?: boolean;
}
const FindRegistrationsModal = ({visible, onOk, onCancel, initialValues, isOkLoading}: Props) => {
    
    const { t } = useTranslation();
    
    const { data: integration } = useDineroIntegration();
    const { reportMarkup, report, isLoadingReport, searchParams} = useReporting(initialValues);
    
    const [selectedTags, setSelectedTags] = useState<number[]>([]);
    
    const [isAllSelected, setIsAllSelected] = useState<boolean>(false);
    const [selectedItems, setSelectedItems] = useState<any[]>([]);
    
    const getAvailableItems = useCallback(() => {
        return report?.groupedRegistrations?.length ? report?.groupedRegistrations.map(x => x.groupKey) : report?.registrations.map(x => x.id) ?? []
    }, [report]);
    
    const toggleSelected = (id: any) => {
        if (selectedItems.findIndex(x => x === id) > -1) {
            setSelectedItems(selectedItems.filter(x => x !== id));
        } else {
            setSelectedItems([id].concat(selectedItems))
        }
        setIsAllSelected(false)
    }
    
    useEffect(() => {
        setSelectedTags(integration?.autoTagRegistrations ?? []);
    }, [integration])
    
    const toggleAllSelected = () => {
        if (isAllSelected) {
            setIsAllSelected(false);
            setSelectedItems([]);
        } else {
            setIsAllSelected(true);
            setSelectedItems(getAvailableItems);
        }
    }
    
    const isSelected = (number: any) => {
        return selectedItems.findIndex(x => x === number) > -1
    }
    
    useEffect(() => {
        setSelectedItems(getAvailableItems);
        setIsAllSelected(true)
    }, [report, getAvailableItems])
    
    return (
        <Modal
            title={t('dinero:edit.findRegistrations')}
            visible={visible}
            modalStyle={{width: '80%'}}
            onCancel={onCancel}
        >
        
            <Row gutter={[10, 10]}>
                <Col span={24}>
                    {reportMarkup}
                </Col>
                
                {!searchParams.customerId ? (
                    <Col span={24}>
                        <FlexRow justify={"center"} className={"box padding15"}>
                            <Empty children={t('dinero:edit.noCustomerSelected')} />
                        </FlexRow>
                    </Col>
                ) : (
                    <>
    
                        <Col span={24}>
                            <FlexRow justify={"space-between"}>
                                <Header text={t('registrations')} />
                                <div>
                                    <Button onClick={() => toggleAllSelected()}>{isAllSelected ? t('dinero:deselectAll') : t('dinero:selectAll')}</Button>
                                </div>
                            </FlexRow>
                        </Col>
                        
                        {isLoadingReport && (
                            <Col span={24}>
                                <div className={`box padding15 white`}>
                                    <Skeleton active paragraph={{rows: 0, style: {padding: 0, margin: 0}}} />
                                </div>
                            </Col>
                        )}
                        
                        {report?.registrations.length === 0 ? (
                            <Col span={24}>
                                <FlexRow justify={"center"} className={"box padding15"}>
                                    <Empty children={t('dinero:edit.noRegistrationsFound')} />
                                </FlexRow>
                            </Col>
                        ) : (
                            <>
                                {!searchParams.groupBy ? (
                                    <>
                                        {report?.registrations?.map((registration) => (
                                            <Col span={24} key={registration.id} onClick={() => toggleSelected(registration.id)}>
                                                <div className={`box padding15 ${isSelected(registration.id) ? styles.active : 'white'} `}>
                                                    <FlexRow align={"center"} justify={"space-between"}>
                                                        <Space size={"large"} align={"center"}>
                                                            <FlexRow direction={"column"} align={"start"}>
                                                                {isSelected(registration.id) ? <CheckmarkFilled /> : <CheckmarkUnfilled />}
                                                            </FlexRow>
                                                            <FlexRow direction={"column"} align={"start"}>
                                                                <div className={styles.groupSubLabel}>
                                                                    {moment(registration.startedAt).format('DD/MM/YYYY')} - {registration.project?.name}
                                                                </div>
                                                                <div className={styles.groupLabel}>
                                                                    {registration.description}
                                                                </div>
                                                            </FlexRow>
                                                        </Space>
                                                        <div>
                                                            <Duration style={{fontWeight: 700}} duration={registration.duration}/>
                                                        </div>
                                                    </FlexRow>
                                                </div>
                                            </Col>
                                        ))}
                                    </>
                                ) : (
                                    <>
                                        {report?.groupedRegistrations?.map((grouped) => (
                                            <Col span={24} key={grouped.groupKey} onClick={() => toggleSelected(grouped.groupKey)}>
                                                <div className={`box padding15 ${isSelected(grouped.groupKey) ? styles.active : 'white'} `}>
                                                    <FlexRow key={grouped.groupKey} align={"center"} justify={"space-between"}>
                                                        <Space size={"large"} align={"center"}>
                                                            <FlexRow direction={"column"} align={"start"}>
                                                                {isSelected(grouped.groupKey) ? <CheckmarkFilled /> : <CheckmarkUnfilled />}
                                                            </FlexRow>
                                                            <FlexRow direction={"column"} align={"start"}>
                                                                <div className={styles.groupSubLabel}>
                                                                    {grouped.subLabel}
                                                                </div>
                                                                <div className={styles.groupLabel}>
                                                                    {grouped.label}
                                                                </div>
                                                            </FlexRow>
                                                        </Space>
                                                        <div>
                                                            <Duration style={{fontWeight: 700}} duration={grouped.totalDuration}/>
                                                        </div>
                                                    </FlexRow>
                                                </div>
                                            </Col>
                                        ))}
                                    </>
                                )}
                            </>
                        )}
                    </>
                )}
                
                <Col span={24}>
                    <FlexRow justify={"end"}>
                        <div style={{display: 'flex', justifyContent: 'flex-end'}}>
                            <Space>
                                <TagSelect placeholder={t('registrations:addTagsToRegistrations')} style={{width: '300px'}} value={selectedTags} onChange={t => setSelectedTags(t)} />
                                <Button onClick={() => onCancel()}>{t('cancel')}</Button>
                                <Button loading={isOkLoading} onClick={() => onOk(report!, selectedItems, searchParams, selectedTags)} color={"primary"} type={"submit"}>{t('dinero:selectLines')}</Button>
                            </Space>
                        </div>
                    </FlexRow>
                </Col>
            </Row>
        </Modal>
    )
    
    
}

export default FindRegistrationsModal
