import FlexRow from "Components/FlexRow";
import styles from './CalendarEvent.module.scss';
import Duration from "Components/Duration";
import moment from "moment";
import { useEffect, useState } from "react";


const CalendarEvent = (props) => {
    
    const [duration, setDuration] = useState<number>(props.event.duration);
    
    useEffect(() => {
        setDuration(props.event.duration)
    }, [props.event.duration])
    
    useEffect(() => {
        const interval = setInterval(() => {
            if (!props.event.stoppedAt) {
                setDuration(Math.floor((moment().diff(moment(props.event.startedAt))) / 1000));
            }
        }, 1000)
        
        return () => {
            clearInterval(interval)
        }
    }, [props.event])
    
    return (
        <FlexRow justify={'space-between'} direction={'column'} align={"start"} className={styles.container}>
            <div>
                <div className={styles.descriptionText}>
                    {props.event.description}
                </div>
                <div className={styles.customerText}>
                    {props.event.project?.name}
                </div>
            </div>
            <div>
                <Duration duration={duration} />
            </div>
        </FlexRow>
    )
}

export default CalendarEvent
