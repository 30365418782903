import * as RadixTooltip from '@radix-ui/react-tooltip';
import { ReactNode } from "react";
import styles from './Tooltip.module.scss';

interface Props {
    children: ReactNode,
    title: string;
}
const Tooltip = (props: Props) => {
    
    return (
        <RadixTooltip.Provider>
            <RadixTooltip.Root delayDuration={0}>
                <RadixTooltip.Trigger asChild>
                    {props.children}
                </RadixTooltip.Trigger>
                <RadixTooltip.Portal>
                    <RadixTooltip.Content align={"center"} className={styles.tooltipContent} forceMount>
                        {props.title}
                        <RadixTooltip.Arrow height={15} width={15} className={styles.tooltipArrow} />
                    </RadixTooltip.Content>
                </RadixTooltip.Portal>
            </RadixTooltip.Root>
        </RadixTooltip.Provider>
    )
}

export default Tooltip
