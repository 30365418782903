import EconomicIntegrationOverview from "Components/Integrations/Economic/EconomicIntegrationOverview";
import {useTranslation} from "react-i18next";
import Header from "Components/Header";
import DineroIntegrationOverview from "Pages/Organization/Components/DineroIntegrationOverview";
import { useApiKeys, useDeleteApiKey, useSaveApiKey } from "Apis/ApiKeyApi";
import Button from "Components/Button/Button";
import FlexRow from "Components/FlexRow";
import Spinner from "Components/Spinner";
import NoResults from "Components/NoResults";
import { useState } from "react";
import ControlledInput from "Components/InputV2/ControlledInput";
import { useForm } from "react-hook-form";
import { ApiKeyCreatedModel, ApiKeyModel } from "Apis/Models/ApiKeyModel";
import { openSuccessNotification } from "Utils/NotificationUtils";
import RequiresPremiumModal from "Components/RequiresPremiumModal";
import { useAuthProvider } from "Providers/AuthProvider";
import { useNavigate } from "react-router-dom";
import Modal from "Components/Modal";
import PremiumTrialAnnouncement from "Components/PremiumTrialAnnouncement";
import styles from './IntegrationsPage.module.scss';
import Space from "Components/Space";


const IntegrationsPage = () => {

    const { t } = useTranslation();
    
    const navigate = useNavigate();
    
    const [showApiKeyModal, setShowApiKeyModal] = useState<boolean>(false);
    const [newApiKey, setNewApiKey] = useState<ApiKeyCreatedModel>();
    const [selectedApiKey, setSelectedApiKey] = useState<ApiKeyModel>();
    const { register, control, handleSubmit, errors, reset, trigger } = useForm<{id?: number, name: string}>();
    const { userContext } = useAuthProvider();
    
    const { data: apiKeys, isLoading: isLoadingApiKeys } = useApiKeys();
    const { mutateAsync: createApiKeyMutation, isLoading: isCreatingApiKey} = useSaveApiKey();
    const { mutateAsync: deleteApiKeyMutation, isLoading: isDeletingApiKey} = useDeleteApiKey();
    
    const saveApiKey = async (data) => {
        const response = await createApiKeyMutation(data);
        
        openSuccessNotification(t('success'), t('integrations.apiKeySaved'))
        
        if (!data.id) {
            setNewApiKey(response as ApiKeyCreatedModel);
        } else {
            closeApiKeyModal();
        }
    }
    
    const closeApiKeyModal = () => {
        reset({});
        setShowApiKeyModal(false);
        setNewApiKey(undefined);
        setSelectedApiKey(undefined)
    }
    
    const deleteApiKey = async () => {
        if (selectedApiKey) {
            await deleteApiKeyMutation(selectedApiKey);
            
            openSuccessNotification(t('success'), t('integrations.apiKeyDeleted'))
            
            closeApiKeyModal();
        }
    }
    
    const selectApiKey = async (key: ApiKeyModel) => {
        setSelectedApiKey(key);
        await reset(key);
        setShowApiKeyModal(true);
        await trigger();
    }
    
    const openZapierPage = () => {
        navigate('/integrations/zapier')
    }
    
    const openTimeOpsPage = () => {
        window.open("https://api.timeops.dk", "_blank")
    }
    
    
    return (
        <div>
            <Header text={t('integrations.title')} />
    
            <PremiumTrialAnnouncement>
                {t('premiumAnnouncements.integrationsIsAPartOfTimeOpsPremium')}
            </PremiumTrialAnnouncement>
            
            <div className={styles.contentContainer}>
                <div className={styles.grid}>
                    <DineroIntegrationOverview />
                    
                    <EconomicIntegrationOverview />
                    
                    <FlexRow className="box white dont-hover padding30 h-100" direction={"column"} justify={"space-between"}>
                        <img src={"/icons/zapier.png"} alt={"Zapier logo"} style={{maxWidth: '90%'}} />
                        <div className={"w-100"}>
                            <b>{t('integrations.zapier.title')}</b>
                            <p>
                                {t('integrations.zapier.description')}
                            </p>
                        </div>
                        <Button  block color={"primary"} onClick={openZapierPage}>{t('integrations.zapier.gotoZapier')}</Button>
                    </FlexRow>
                    
                    <FlexRow className="box white dont-hover padding30 h-100" direction={"column"} justify={"space-between"}>
                        <img src={"/icons/timeops.png"} alt={"TimeOps logo"} style={{maxWidth: '40%', paddingBottom: '30px',}} />
                        <div className={"w-100"}>
                            <b>{t('integrations.timeops.title')}</b>
                            <p>
                                {t('integrations.timeops.description')}
                            </p>
                        </div>
                        <Button  block color={"primary"} onClick={openTimeOpsPage}>{t('integrations.timeops.gotoDocumentation')}</Button>
                    </FlexRow>
                </div>

                <div>
                    <div className={`box white padding15 dont-hover`}>
                        <Header text={t('integration.yourApiKeys')} style={{fontSize: '1.3em'}} />
                        {isLoadingApiKeys && (
                            <FlexRow>
                                <Spinner />
                            </FlexRow>
                        )}
                        {!isLoadingApiKeys && apiKeys?.length === 0 && (
                            <NoResults text={t('integrations.noApiKeys')} padding={30} />
                        )}
                        {apiKeys?.map(key => (
                            <div key={key.id}>
                                <FlexRow justify={"space-between"} onClick={() => selectApiKey(key)}>
                                    <div>
                                        {key.name}
                                    </div>
                                    <div style={{color: '#CCC', fontSize: '.9em'}}>
                                        {t('update')}
                                    </div>
                                </FlexRow>
                                <hr style={{margin: '10px 0px'}} />
                            </div>
                        ))}
                        <FlexRow justify={"end"} style={{marginTop: 20}}>
                            <RequiresPremiumModal enabled={!userContext?.hasActiveLicense} title={t('integrations:apiKeys')}>
                                <Button color={"primary"} onClick={() => setShowApiKeyModal(true)}>
                                    {t('integrations.createApiKey')}
                                </Button>
                            </RequiresPremiumModal>
                        </FlexRow>
                    </div>
                </div>
                
            </div>
            
            <Modal
                title={t('integrations.apiKey')}
                visible={showApiKeyModal}
                onCancel={closeApiKeyModal}
            >
                    {!newApiKey ? (
                        <form onSubmit={handleSubmit(saveApiKey)}>
                            <input type="text" name={"id"} style={{display: 'none'}} ref={register} />
                            <Space size={"large"} direction={"vertical"}>
                                <ControlledInput name={"name"} control={control} errors={errors} placeholder={t('integrations.createApiKeyName')} rules={{required: true}} />
                                
                                <FlexRow justify={"space-between"}>
                                    {selectedApiKey && (
                                        <Button loading={isDeletingApiKey} onClick={deleteApiKey} color={"danger"}>{t('integrations.removeApiKey')}</Button>
                                    )}
                                    <Button loading={isCreatingApiKey} type={"submit"} color={"primary"} block={!selectedApiKey}>{t('integrations.saveApiKey')}</Button>
                                </FlexRow>
                            </Space>
                        </form>
                    ) : (
                        <>
                            <div>
                                {t('integrations.apiKeyCreatedMessage')}
                            </div>
                            <FlexRow justify={"center"} style={{fontSize: '1.5em', padding: '20px 0', fontWeight: 'bold'}}>
                                {newApiKey.key}
                            </FlexRow>
                            <div>
                                {t('integrations.storeApiKeySafelyMessage')}
                            </div>
                            
                            <FlexRow justify={"end"} style={{paddingTop: 20}}>
                                <Button onClick={closeApiKeyModal} color={"danger"}>{t('integrations.closeModal')}</Button>
                            </FlexRow>
                        </>
                    )}
            </Modal>
        </div>
    )
}

export default IntegrationsPage;
