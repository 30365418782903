import styles from './SelectV2.module.scss';
import _ from "lodash";
import React, { CSSProperties, useEffect, useMemo, useRef, useState } from "react";
import { CSSTransition } from "react-transition-group";
import { createPortal } from "react-dom";
import useOuterClick from "Hooks/UseOuterClick";
import Option from "./Option";
import Group from "./Group";
import CloseIcon from "Assets/Icons/CloseIcon";

const INPUT_CONTAINER_HEIGHT = 56;
const CHILD_HEIGHT = 32;

export const SelectV2Context = React.createContext<{
    onChange: (value: string | number | null, isSelected: boolean) => void,
    isValueSelected: (value: string | number) => boolean,
    focusedKey: string | number;
}>({
    onChange: (value: string | number | null, isSelected: boolean) => {},
    isValueSelected: (value: any) => false,
    focusedKey: 0
})

interface Props {
    name: string;
    placeholder?: string;
    value?: string | number | any[] | boolean | null;
    errors?: any;
    onChange: (newValue: any) => void;
    children: any;
    onFilter?: (newFiler: string) => void;
    multiple?: boolean;
    suffix?: {
        label: string;
        onClick: () => void;
        isShown: boolean;
        active: boolean;
    },
    allowClear?: boolean;
    disabled?: boolean;
    inputRef?: any;
    styles?: CSSProperties;
}

const SelectV2: React.FC<Props> & {
    Option: typeof Option;
    Group: typeof Group;
} = (props: Props) => {
    
    const filterInputRef = useRef<HTMLInputElement>();
    const dropdownRef = useRef<HTMLDivElement>();
    
    const containerRef = useOuterClick(() => {
        setIsOpen(false);
    })
    
    const [isOpen, setIsOpen] = useState<boolean>(false);
    const [focusKey, setFocusKey] = useState<any>();
    
    const [inputFilterValue, setInputFilterValue] = useState<string>('');
    
    const [optionValues, setOptionValues] = useState<any[]>([]);
    
    useEffect(() => {
        if (optionValues.length > 0) {
            setFocusKey(optionValues[0].value);
        }
    }, [optionValues])
    
    const toggleOpen = () => {
        if (props.disabled) {
            return;
        }
        
        setIsOpen(current => !current);
        
        if (!isOpen) {
            setFocusKey(props.value ?? 0);
            filterInputRef.current?.focus();
            scrollToFocused('auto');
        }
    }
    
    const setFilterValue = (newFilter: string) => {
        setInputFilterValue(newFilter);
        if (props.onFilter) {
            props.onFilter(newFilter);
        }
    }
    
    const getMappedChildren = useMemo(() => props.children?.length > 0 ? props.children.filter(x => !!x).map(child => {
            return Array.isArray(child) ? child.flatMap(x => x) : [child];
        }).flatMap(x => x).filter(x => !!x) : [], [props.children]);
    
    useEffect(() => {
        let newOptionElements: any[] = [];
        
        getMappedChildren.forEach((child: any) => {
            if (child.type.displayName === 'Group') {
                child.props.children.forEach((groupChild: any) => {
                    if (groupChild.type.displayName === 'Option') {
                        newOptionElements.push(groupChild);
                    }
                })
            }
        
            if (child.type.displayName === 'Option') {
                newOptionElements.push(child);
            }
        });
        
        newOptionElements = newOptionElements.flatMap(x => x);
        
        const newOptionValues = newOptionElements.map((child: any) => {
            return {
                value: child.props.value,
                label: child.props.label,
            }
        });
        
        setOptionValues(newOptionValues);
    }, [getMappedChildren, props.children])
    
    const isValueSelected = (value: string | number | boolean): boolean => {
        if (props.multiple && Array.isArray(props.value)) {
            return props.value.includes(value);
        } else {
            return props.value === value;
        }
    }
    
    const getMultipleValues = () => {
        if (props.multiple) {
            if (!props.value) {
                return [];
            }
            
            if (!Array.isArray(props.value)) {
                return [];
            }
            
            let localValues = props.value as any[];
            
            return optionValues.filter(value => localValues.includes(value.value)).map(x => x.label);
        } else {
            console.error("You are using a single value select, but calls the multiple value function");
        }
    }
    
    const getValue = () => {
        if (props.multiple) {
            console.error("You are using a multiple value select, but calls the single value function");
        } else {
            return optionValues.find(value => value.value === props.value)?.label
        }
    }
    
    const onChange = (value: string | number | null, isSelected: boolean) => {
        setFilterValue('')
        
        if (!props.allowClear && value === '') {
            return;
        }
        
        if (props.multiple) {
            let localValues: unknown[] = [];
            
            if (Array.isArray(props.value)) {
                localValues = props.value as unknown[];
            }
            
            if (!value) {
                localValues = [];
            } else if (isSelected) {
                localValues.push(value);
            } else {
                localValues = localValues.filter(x => x !== value);
            }
            
            props.onChange(localValues);
        } else {
            if (isSelected) {
                props.onChange(value);
            } else {
                props.onChange(null);
            }
        }
        
        setFocusKey(value)
        
        if (props.onFilter) {
            props.onFilter('');
        }
    }
    
    const handleKeyDown = (e: React.KeyboardEvent<HTMLInputElement>) => {
        if (e.key === "Escape") {
            toggleOpen();
        }
        
        if (e.key === "Enter") {
            e.preventDefault();
            onChange(focusKey, !isValueSelected(focusKey));
            toggleOpen();
        }
        
        if (e.key !== "ArrowDown" && e.key !== "ArrowUp") {
            return;
        }
    
        let currentlyFocusedOptionIndex = optionValues.findIndex(value => value.value === focusKey);
        
        if (e.key === "ArrowDown") {
            currentlyFocusedOptionIndex++;
        } else {
            currentlyFocusedOptionIndex--;
        }
        
        if (optionValues[currentlyFocusedOptionIndex]?.value) {
            setFocusKey(optionValues[currentlyFocusedOptionIndex]?.value);
        }
        
        scrollToFocused();
    }
    
    const scrollToFocused = ( behavior: ScrollBehavior = 'smooth' ) => {
    
        const allChildren = getMappedChildren.map((child: any) => {
            if (child.type.displayName === "Group") {
                return [child, child.props.children];
            }
        
            return child;
        }).flatMap(x => x).flatMap(x => x);
        
        let focusedChildIndex = allChildren.findIndex(child => child.type.displayName === "Option" && child.props.value === focusKey);
    
        if (focusedChildIndex < allChildren.length - 2) {
            dropdownRef.current?.scrollTo({
                top: (focusedChildIndex - 2)  * CHILD_HEIGHT,
                behavior: behavior
            })
        }
    }
    
    return (
        <SelectV2Context.Provider value={{
            onChange: onChange,
            isValueSelected: isValueSelected,
            focusedKey: focusKey,
        }}>
            <div ref={e => containerRef.current = e!} className={styles.container}>
                <div onClick={toggleOpen} className={`${styles.trigger} ${props.disabled && styles.disabled} ${isOpen && styles.active} ${_.get(props.errors, props.name) ? styles.error : ''}`}>
                    <label className={`${styles.label} text-ellipsis ${styles.fieldActive}`}>
                        {props.placeholder}
                    </label>
                    <div className={styles.valueContainer}>
                        
                        <input
                            ref={e => {
                                filterInputRef.current = e!;
                                if (props.inputRef) {
                                    props.inputRef.current = e!
                                }
                            }}
                            className={`${styles.input} ${styles.floatingLabel} ${!isOpen && styles.hidden}`}
                            onChange={(e) => setFilterValue(e.target.value)}
                            onKeyDown={handleKeyDown}
                            value={inputFilterValue}
                        />
                        
                        <div className={`${styles.value} ${isOpen ? styles.hidden : ''} ${props.disabled ? styles.disabled : ''}`}>
                            {props.multiple ? getMultipleValues()?.join(', ') : getValue()}
                        </div>
    
                        {props.suffix?.isShown && (
                            <div className={`${styles.suffix} ${props.suffix.active && styles.active}`} onClick={(e) => {
                                e.stopPropagation();
                                if (props.suffix?.active) {
                                    props.suffix!.onClick();
                                }
                            }}>
                                {props.suffix.label}
                            </div>
                        )}
    
                        <div className={styles.clear}>
                            {props.allowClear && (Array.isArray(props.value) ? props.value.length > 0 : !!props.value) ? (
                                <CloseIcon fill={"#F74425"} onClick={(e) => {
                                    e.stopPropagation();
                                    onChange('', false);
                                }} style={{cursor: 'pointer'}} />
                            ) : <></>}
                        </div>
                    </div>
                </div>
        
                {createPortal(
                    <CSSTransition
                        in={isOpen}
                        timeout={{ enter: 0, exit: 150 }}
                        unmountOnExit
                        onEntered={() => scrollToFocused('auto')}
                        classNames={{
                            enterDone: styles.open,
                            exit: styles.closed,
                        }}
                    >
                        <div className={styles.dropdown}
                             ref={e => dropdownRef.current = e!}
                         style={{
                            top: containerRef.current?.getBoundingClientRect().top! + INPUT_CONTAINER_HEIGHT,
                            left: containerRef.current?.getBoundingClientRect().x,
                            width: containerRef.current?.getBoundingClientRect().width,
                        }}>
                            {props.children}
                        </div>
                    </CSSTransition>
                    ,document.getElementById('root')!)}
            </div>
        </SelectV2Context.Provider>
    )
}

SelectV2.Option = Option;
SelectV2.Group = Group;

export default SelectV2
