import { saveAs } from 'file-saver';
import {request} from "Apis/BaseApi";
import { useMutation, useQuery, useQueryClient } from "react-query";
import { ReportModel } from "Apis/Models/ReportModel";
import { ReportFilterModel, ReportSearchFilter } from "Apis/Models/ReportFilterModel";
import { DefaultReportSettings } from "Apis/Models/DefaultReportSettings";

export const REPORTS_FILTERS_CACHE_KEY = 'REPORTS_FILTERS_CACHE_KEY';
export const useReportsFilters = () => {
    return useQuery(REPORTS_FILTERS_CACHE_KEY, async () => {
        return await request<ReportFilterModel>({
            url: '/reports/filters',
            method: 'GET'
        });
    }, {
        refetchOnWindowFocus: false
    })
}

export const REPORT_HISTORY_CACHE_KEY = 'REPORT_HISTORY_CACHE_KEY';
export const useReportHistory = () => {
    return useQuery(REPORT_HISTORY_CACHE_KEY, async () => {
        return await request<ReportSearchFilter[]>({
            url: '/reports/history',
            method: 'GET'
        });
    }, {
        refetchOnWindowFocus: false
    })
}

export const useSaveReportDefaults = () => {
    const queryClient = useQueryClient();
    
    return useMutation(async (defaultSettings: DefaultReportSettings) => {
        return await request({
            url: `/reports/defaults`,
            method: 'put',
            data: defaultSettings
        })
    }, {
        onSuccess: () => {
            queryClient.invalidateQueries(REPORTS_FILTERS_CACHE_KEY);
        }
    })
}

export const REPORTS_CACHE_KEY = "REPORTS_CACHE_KEY";
export const useReport = ({fromDate, toDate, customerId, projectId, userId, billable, groupBy, rounding, tags, reverseTagSearch}: any) => {
    return useQuery<ReportModel>([REPORTS_CACHE_KEY, fromDate, toDate, customerId, projectId, userId, billable, groupBy, rounding, tags, reverseTagSearch], async () => {
        
        const queryBuilder = new URLSearchParams();
        
        const values = {
            fromDate,
            toDate,
            customerId,
            projectId,
            userId,
            billable,
            groupBy,
            rounding,
            tags,
            reverseTagSearch
        }
    
        Object.keys(values).forEach((key) => {
            let keyValue = values[key];
            if (keyValue) {
                
                if (Array.isArray(keyValue)) {
                    keyValue.forEach((value) => {
                        queryBuilder.append(key, value);
                    });
                } else {
                    queryBuilder.set(key, values[key]);
                }
            }
        })

        return request<ReportModel>({
            url: `/reports?${queryBuilder.toString()}`,
            method: 'GET'
        })
    }, {
        retry: false,
        enabled: !!fromDate && !!toDate,
    })
}

export const REPORTSV2_CACHE_KEY = "REPORTSV2_CACHE_KEY";
export const useReportV2 = ({fromDate, toDate, customerId, projectId, userId, billable, groupBy, rounding, tags, reverseTagSearch, enabled}: any) => {
    const queryClient = useQueryClient();
    
    return useQuery<ReportModel>([REPORTSV2_CACHE_KEY, fromDate, toDate, customerId, projectId, userId, billable, groupBy, rounding, tags, reverseTagSearch], async () => {
        
        const queryBuilder = new URLSearchParams();
        
        const values = {
            fromDate,
            toDate,
            customerId,
            projectId,
            userId,
            billable,
            groupBy,
            rounding,
            tags,
            reverseTagSearch,
        }
        
        Object.keys(values).forEach((key) => {
            let keyValue = values[key];
            if (keyValue) {
                
                if (Array.isArray(keyValue)) {
                    keyValue.forEach((value) => {
                        queryBuilder.append(key, value);
                    });
                } else {
                    queryBuilder.set(key, values[key]);
                }
            }
        })
        
        return request<ReportModel>({
            url: `/reports/v2?${queryBuilder.toString()}`,
            method: 'GET'
        })
    }, {
        retry: false,
        refetchOnWindowFocus: false,
        enabled: !!fromDate && !!toDate && enabled,
        onSuccess: () => {
            queryClient.invalidateQueries(REPORT_HISTORY_CACHE_KEY)
        }
    })
}

export const getAdvancedReportPdf = async (values: {fromDate?, toDate?, customerId?, projectId?, userId?, groupBy?, rounding?, language: 'DA' | 'EN', detailed?: boolean},  fileName): Promise<any> => {
    const queryBuilder = new URLSearchParams();
    
    Object.keys(values).forEach((key) => {
        let keyValue = values[key];
        if (keyValue) {
            
            if (Array.isArray(keyValue)) {
                keyValue.forEach((value) => {
                    queryBuilder.append(key, value);
                });
            } else {
                queryBuilder.set(key, values[key]);
            }
        }
    })

    const responseBlob = await request<Blob>({
        responseType: 'blob',
        url: `/reports/pdf?${queryBuilder.toString()}`,
        method: 'GET',
    });

    const blob = new Blob([responseBlob], {type: 'application/pdf' });

    saveAs(blob, fileName);
}

export const getAdvancedReportCSV = async (values: {fromDate?, toDate?, customerId?, projectId?, userId?, groupBy?, rounding?, timeFormat: 'Hour' | 'Decimal' | 'Second'},  fileName): Promise<any> => {
    const queryBuilder = new URLSearchParams();

    Object.keys(values).forEach((key) => {
        let keyValue = values[key];
        if (keyValue) {

            if (Array.isArray(keyValue)) {
                keyValue.forEach((value) => {
                    queryBuilder.append(key, value);
                });
            } else {
                queryBuilder.set(key, values[key]);
            }
        }
    })

    const responseBlob = await request<Blob>({
        responseType: 'blob',
        url: `/reports/csv?${queryBuilder.toString()}`,
        method: 'GET',
    });

    const blob = new Blob([responseBlob], {type: 'application/csv' });

    saveAs(blob, fileName);
}
