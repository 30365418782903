import Header from "Components/Header";
import { useOrganization } from "Apis/OrganizationApi.ts";
import styles from './SubscriptionPage.module.scss';
import Checkbox from "antd/lib/checkbox";
import Col from "antd/lib/col";
import Row from "antd/lib/row";
import Space from "antd/lib/space";
import { useTranslation } from "react-i18next";
import Button from "Components/Button/Button";
import FlexRow from "Components/FlexRow";
import User from "Assets/Icons/User";
import { useEffect, useState } from "react";
import FeatureListItem from "Pages/Organization/Components/FeatureListItem";
import { useCreateStripePortalSession, useCreateStripeSession, useMoveToMonthlyPrice, useMoveToYearlyPrice, useResumeStripeSubscription, useStripeSubscription, useUpcommingInvoice } from "Apis/StripeApi";
import { loadStripe } from "@stripe/stripe-js/pure";
import config from "Config";
import { openSuccessNotification } from "Utils/NotificationUtils";
import FullPageSpinner from "Components/FullPageSpinner";
import moment from "moment";
import { reportConversion } from "Utils/GoogleAdsUtils";
import { useLocation } from "react-router-dom";
import Modal from "Components/Modal";

const stripePromise = loadStripe(config.stripe.key);


const SubscriptionPage = () => {
    
    const { t } = useTranslation();
    const [searchParams] = useState(new URLSearchParams(useLocation().search));
    
    const [retryStripeSubscription, setRetryStripeSubscription] = useState<false | number>(false);
    
    
    const [hasAcceptedCharge, setHasAcceptedCharge] = useState<boolean>(false);
    const [amountDue, setAmountDue] = useState<number>(0);
    const [showChangeIntervalModal, setShowChangeIntervalModal] = useState<boolean>(false);
    
    const [billingPeriod, setBillingPeriod] = useState<'Yearly' | 'Monthly'>('Yearly');
    
    const {data: organization} = useOrganization();
    const {data: stripeSubscription, isLoading: isLoadingSubscription} = useStripeSubscription(retryStripeSubscription);
    
    const { mutateAsync: createStripeSession, isLoading: isCreatingSession} = useCreateStripeSession();
    const { mutateAsync: resumeStripeSubscriptionMutation, isLoading: isResumingSubscription } = useResumeStripeSubscription();
    const { mutateAsync: fetchUpcommingInvoice, isLoading: isFetchingUpcommingInvoice} = useUpcommingInvoice();
    const { mutateAsync: moveToYearlyPriceMutation, isLoading: isMovingToYearly} = useMoveToYearlyPrice();
    const { mutateAsync: moveToMonthlyMutation, isLoading: isMovingToMonthly} = useMoveToMonthlyPrice();
    const { mutateAsync: createStripePortalSession, isLoading: isLoadingStripePortal} = useCreateStripePortalSession();
    
    useEffect(() => {
        if (stripeSubscription) {
            setBillingPeriod(stripeSubscription?.interval === 'year' ? 'Yearly' : 'Monthly')
        }
    }, [stripeSubscription])
    
    useEffect(() => {
        const status = searchParams.get('status');
        
        if (stripeSubscription) {
            setRetryStripeSubscription(false);
            return;
        }
        
        if (status === 'success') {
            setRetryStripeSubscription(1000);
            
            reportConversion("CreateSubscription");
            
            return;
        }
        
    }, [searchParams, stripeSubscription])
    
    const getUserCount = () => {
        return organization?.users.filter(x => x.deactivatedAt == null).length ?? 0;
    }
    
    const getPremiumUnitPrice = () => {
        return billingPeriod === 'Yearly' ? 69 : 79;
    }
    
    const getTotalPrice = () => {
        return getPremiumUnitPrice() * getUserCount();
    }
    
    const createSession = async () => {
        const session = await createStripeSession(billingPeriod);
        
        const stripe = await stripePromise;
        
        stripe?.redirectToCheckout({
            sessionId: session?.sessionId!
        })
    }
    
    const openChangeIntervalModal = async () => {
        const response = await fetchUpcommingInvoice(billingPeriod);
        
        setAmountDue(response.amountDue / 100)
        
        setShowChangeIntervalModal(true)
    }
    
    const closeChangeIntervalModal = () => {
        setHasAcceptedCharge(false)
        setShowChangeIntervalModal(false);
        setAmountDue(0);
    }
    
    const changeInterval = async () => {
        if (!hasAcceptedCharge) {
            return;
        }
        
        let response;
        
        if (billingPeriod === "Yearly") {
            response = await moveToYearlyPriceMutation();
        } else {
            response = await moveToMonthlyMutation();
        }
    
        if (response?.clientSecret) {
            const stripe = await stripePromise;
            
            await stripe!.confirmCardPayment(response.clientSecret);
        } else {
            openSuccessNotification(t('success'), t('organization:subscription.billingIntervalUpdated'))
        }
        
        closeChangeIntervalModal();
    }
    
   
    const shouldShowChangeIntervalButton = () => {
        return (stripeSubscription?.interval === 'year' && billingPeriod !== 'Yearly') || (stripeSubscription?.interval === 'month' && billingPeriod !== 'Monthly')
    }
    
    const resumeSubscription = async () => {
        await resumeStripeSubscriptionMutation();
        
        openSuccessNotification(t('success'), t('subscription:subscriptionResumed'))
    }
    
    const openStripeCustomerPortal = async () => {
        const portalSession = await createStripePortalSession();
        
        window.location.href = portalSession!.url;
    }
    
    if (isLoadingSubscription) {
        return (
            <FullPageSpinner />
        )
    }
    
    return (
        <div style={{position: 'relative'}}>
            <Header text={t('organization:subscription.title')}/>
            {stripeSubscription?.cancelAtPeriodEnd && (
                <FlexRow justify={"space-between"} className={styles.alert}>
                    <div>
                        <div>
                            {t('organization:subscription.subscriptionHasBeenCancelled')}
                        </div>
                        <div>
                            {t('organization:subscription.expiresAt')}:  {moment(stripeSubscription.renewalDate).format('LL')}
                        </div>
                    </div>
                    <Button loading={isResumingSubscription} color={"primary"} onClick={resumeSubscription}>{t('organization:subscription.resumeSubscription')}</Button>
                </FlexRow>
            )}
            {stripeSubscription?.hasLegacyPricing && (
                <FlexRow justify={"space-between"} className={styles.alert}>
                    <div style={{maxWidth: '70%'}}>
                        <div>
                            <b>
                                {t('organization:subscription.legacyPriceWarningTitle')}
                            </b>
                        </div>
                        <div>
                            {t('organization:subscription.legacyPriceWarningDescription')}
                        </div>
                    </div>
                    <Button loading={isLoadingStripePortal} color={"primary"} onClick={openStripeCustomerPortal}>{t('organization:subscription.updateYourSubscription')}</Button>
                </FlexRow>
            )}
            <div className={`${stripeSubscription?.cancelAtPeriodEnd && styles.overlay} w-100`}>
                <Row gutter={[20, 10]}>
                    <Col xs={24} sm={24} md={24} lg={24} xxl={16}>
                        <FlexRow justify={"center"}>
                            <div className={styles.switch}>
                                <div className={`${styles.item} ${billingPeriod === 'Yearly' && styles.active}`} onClick={() => setBillingPeriod('Yearly')}>
                                    {t('organization:subscription.yearly')}
                                </div>
                                <div className={`${styles.item} ${billingPeriod === 'Monthly' && styles.active}`} onClick={() => setBillingPeriod('Monthly')}>
                                    {t('organization:subscription.monthly')}
                                </div>
                            </div>
                        </FlexRow>
                    </Col>
                    <Col md={0} lg={0} xxl={6}>
                    </Col>
                    <Col xs={24} sm={24} md={24} lg={12} xxl={8}>
                        <div>
                            <div className={styles.box}>
                                {!stripeSubscription && (
                                    <div className={`${styles.hasPackageRibbon} ${styles.free}`}>{t('organization:subscription.hasPackageRibbon')}</div>
                                )}
                                <FlexRow>
                                    <div className={styles.priceCol}>
                                        <div className={`${styles.totalPrice} ${styles.free}`}>
                                            0,-
                                        </div>
                                        <FlexRow align={"center"}>
                                            <User height={20} /> {getUserCount()} {t('users')}
                                        </FlexRow>
                                    </div>
                                    <div className={styles.priceCol}>
                                        <div className={`${styles.unitPrice}`}>
                                            0,-
                                        </div>
                                        <FlexRow align={"center"}>
                                            <User height={20} /> {t('organization:subscription.prUser')}
                                        </FlexRow>
                                    </div>
                                </FlexRow>
                                <hr/>
                                <div className={`${styles.packageName} ${styles.free}`}>
                                    TimeOps Free
                                </div>
                                <FlexRow justify={"space-between"} className={`${styles.packageDescription}`}>
                                    <div>
                                        {t('organization:subscription.freeDescription')}
                                    </div>
                                </FlexRow>
                                <div className={styles.userCountBox}>
                                    {t('users')}: {getUserCount()}
                                </div>
                            </div>
                            <Space direction={"vertical"}>
                                <div className={`${styles.featureTitle} ${styles.free}`}>
                                    {t('organization:subscription.freeFeaturesTitle')}
                                </div>
                                <FeatureListItem package={"Free"} text={t('organization:subscription.freeFeature1')} />
                                <FeatureListItem package={"Free"} text={t('organization:subscription.freeFeature2')} />
                                <FeatureListItem package={"Free"} text={t('organization:subscription.freeFeature3')} />
                                <FeatureListItem package={"Free"} text={t('organization:subscription.freeFeature4')} />
                                <FeatureListItem package={"Free"} text={t('organization:subscription.freeFeature5')} />
                                <FeatureListItem package={"Free"} text={t('organization:subscription.freeFeature6')} />
                                <FeatureListItem package={"Free"} text={t('organization:subscription.freeFeature7')} />
                            </Space>
                        </div>
                    </Col>
                    <Col xs={24} sm={24} md={24} lg={12} xxl={8}>
                        <div>
                            <div className={styles.box}>
                                {!!stripeSubscription && (
                                    <div className={`${styles.hasPackageRibbon} ${styles.premium}`}>{t('organization:subscription.hasPackageRibbon')}</div>
                                )}
                                <FlexRow>
                                    <div className={styles.priceCol}>
                                        <div className={`${styles.totalPrice} ${styles.premium}`}>
                                            {getTotalPrice()},-
                                        </div>
                                        <FlexRow align={"center"}>
                                            <User height={20} /> {getUserCount()} {t('users')}
                                        </FlexRow>
                                    </div>
                                    <div className={styles.priceCol}>
                                        <div className={`${styles.unitPrice}`}>
                                            {getPremiumUnitPrice()},-
                                        </div>
                                        <FlexRow align={"center"}>
                                            <User height={20} /> {t('organization:subscription.prUser')} / {t('organization:subscription.month')}
                                        </FlexRow>
                                    </div>
                                </FlexRow>
                                <hr/>
                                <div className={`${styles.packageName} ${styles.premium}`}>
                                    TimeOps Premium
                                </div>
                                <FlexRow justify={"space-between"} className={`${styles.packageDescription}`}>
                                    <div>
                                        {t('organization:subscription.premiumDescription')}
                                    </div>
                                    <div>
                                        {billingPeriod === 'Yearly' && (
                                            <div className={styles.discountBox}>
                                                12% {t('organization:subscription.discount')}
                                            </div>
                                        )}
                                    </div>
                                </FlexRow>
                                <div className={styles.userCountBox}>
                                    {t('users')}: {getUserCount()}
                                </div>
                                <Space className={"w-100"} direction={"vertical"}>
                                    <Button color={"primary"} onClick={createSession} loading={isCreatingSession} disabled={!!stripeSubscription} block>{stripeSubscription? t('organization:subscription.hasPackage') : t('organization:subscription.selectPackage')}</Button>
                                    {shouldShowChangeIntervalButton() && (
                                        <Button onClick={openChangeIntervalModal} loading={isFetchingUpcommingInvoice} block>{billingPeriod === "Monthly" ? t(`organization:subscription.changeIntervalToMonth`) : t(`organization:subscription.changeIntervalToYear`) }</Button>
                                    )}
                                </Space>
                            </div>
                            <Space direction={"vertical"}>
                                <div className={`${styles.featureTitle} ${styles.premium}`}>
                                    {t('organization:subscription.premiumFeaturesTitle')}
                                </div>
                                <FeatureListItem package={"Premium"} text={t('organization:subscription.premiumFeature1')} />
                                <FeatureListItem package={"Premium"} text={t('organization:subscription.premiumFeature2')} />
                                <FeatureListItem package={"Premium"} text={t('organization:subscription.premiumFeature3')} />
                                <FeatureListItem package={"Premium"} text={t('organization:subscription.premiumFeature4')} />
                                <FeatureListItem package={"Premium"} text={t('organization:subscription.premiumFeature5')} />
                                <FeatureListItem package={"Premium"} text={t('organization:subscription.premiumFeature6')} />
                                <FeatureListItem package={"Premium"} text={t('organization:subscription.premiumFeature7')} />
                                <FeatureListItem package={"Premium"} text={t('organization:subscription.premiumFeature8')} />
                            </Space>
                        </div>
                    </Col>
                </Row>
    
                <div className={styles.box} style={{padding: 20, marginTop: 30}}>
                    <FlexRow justify={"space-between"}>
                        <div>
                            {t('organization:subscription.needYoUpdateYourSubscription')}
                        </div>
                        <Button loading={isLoadingStripePortal} color={"primary"} onClick={openStripeCustomerPortal}>{t('organization:subscription.updateYourSubscription')}</Button>
                    </FlexRow>
                </div>
            </div>
            
            <Modal
                title={t('organization:subscription.changeInterval')}
                visible={showChangeIntervalModal}
                onCancel={() => setShowChangeIntervalModal(false)}
            >
                <Space className={"w-100"} direction={"vertical"} size={"large"}>
                    {billingPeriod === "Yearly" ? (
                        <>
                            <div>
                                {t('organization:subscription.toYearly.text1')}
                            </div>
                            <div>
                                {t('organization:subscription.toYearly.text2')}
                            </div>
    
                            <div>
                                <b>
                                    {t('organization:subscription.toYearly.text3')} DKK {amountDue} {t('organization:subscription.toYearly.text4')}
                                </b>
                            </div>
                        </>
                    ) : (
                        <>
                            <div>
                                {t('organization:subscription.toMonthly.text1')}
                            </div>
                            
                            <div>
                                <b>
                                    {t('organization:subscription.toMonthly.text2')} DKK {amountDue} {t('organization:subscription.toMonthly.text3')}
                                </b>
                            </div>
                        </>
                    )}
                    <Space>
                        <Checkbox value={hasAcceptedCharge} onClick={() => setHasAcceptedCharge(value => !value)}>
                            {t('organization:subscription.iAcceptTheCharge')}
                        </Checkbox>
                    </Space>
                    
                    <FlexRow justify={"end"}>
                        <Space>
                            <Button onClick={closeChangeIntervalModal}>{t('cancel')}</Button>
                            <Button disabled={!hasAcceptedCharge} loading={isMovingToMonthly || isMovingToYearly} onClick={changeInterval} color={"primary"}>{t('organization:subscription.changeInterval')}</Button>
                        </Space>
                    </FlexRow>
                </Space>
            </Modal>
        </div>
    )
}

export default SubscriptionPage
