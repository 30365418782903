import * as React from "react"
import { SVGProps } from "react"

const SignOut = (props: SVGProps<SVGSVGElement>) => (
    <svg xmlns="http://www.w3.org/2000/svg" width="30px" height="30px" viewBox={"0 0 573 573"} {...props}>
        <path
            data-name="Path 682"
            d="M555.533 268.807H328.539a17.921 17.921 0 0 1 0-35.841h226.994a17.921 17.921 0 0 1 0 35.841Zm0 0"
        />
        <path
            data-name="Path 683"
            d="M465.93 358.409a17.932 17.932 0 0 1-12.665-30.609l76.939-76.936-76.939-76.94a17.926 17.926 0 0 1 25.352-25.35l89.6 89.6a17.926 17.926 0 0 1 0 25.35l-89.6 89.6a17.8 17.8 0 0 1-12.687 5.285Zm0 0"
        />
        <path
            data-name="Path 684"
            d="M191.153 573.453a49.24 49.24 0 0 1-14.814-2.222l-143.8-47.907A48.228 48.228 0 0 1 0 477.879V47.792A47.838 47.838 0 0 1 47.789 0 49.281 49.281 0 0 1 62.6 2.222L206.4 50.13a48.222 48.222 0 0 1 32.542 45.444v430.09a47.834 47.834 0 0 1-47.789 47.789ZM47.789 35.841a11.984 11.984 0 0 0-11.948 11.951v430.09a12.418 12.418 0 0 0 8.291 11.51l143.122 47.7a12.976 12.976 0 0 0 3.9.525 11.98 11.98 0 0 0 11.946-11.953V95.574a12.418 12.418 0 0 0-8.291-11.515L51.683 36.366a12.969 12.969 0 0 0-3.894-.525Zm0 0"
        />
        <path
            data-name="Path 685"
            d="M364.381 191.152a17.927 17.927 0 0 1-17.92-17.92V65.709a29.907 29.907 0 0 0-29.864-29.869H47.789a17.92 17.92 0 1 1 0-35.841h268.807a65.761 65.761 0 0 1 65.705 65.71v107.523a17.927 17.927 0 0 1-17.92 17.92Zm0 0"
        />
        <path
            data-name="Path 686"
            d="M316.596 501.773h-95.579a17.92 17.92 0 1 1 0-35.84h95.579a29.907 29.907 0 0 0 29.864-29.87V328.541a17.921 17.921 0 1 1 35.841 0v107.522a65.761 65.761 0 0 1-65.705 65.71Zm0 0"
        />
    </svg>
)

export default SignOut
