import * as React from "react"
import { SVGProps } from "react"

const RoundingIcon = (props: SVGProps<SVGSVGElement>) => (
    <svg xmlns="http://www.w3.org/2000/svg" width="1em" height="1em" viewBox="0 0 36.245 36.245" {...props}>
        <g data-name="005-up-arrow">
            <g data-name="Group 371">
                <g data-name="Group 370" fill={props.fill ?? "#bed5ff"}>
                    <path
                        data-name="Rectangle 342"
                        d="M17.384 1.138h1.477v5.169h-1.477z"
                    />
                    <path
                        data-name="Rectangle 342 - Outline"
                        d="M16.984.738h2.277v5.969h-2.277Zm1.477.8h-.677v4.369h.677Z"
                    />
                </g>
            </g>
            <g data-name="Group 373">
                <g data-name="Group 372" fill={props.fill ?? "#bed5ff"}>
                    <path
                        data-name="Rectangle 343"
                        d="M29.938 17.384h5.169v1.477h-5.169z"
                    />
                    <path
                        data-name="Rectangle 343 - Outline"
                        d="M29.538 16.984h5.969v2.277h-5.969Zm5.169.8h-4.369v.677h4.369Z"
                    />
                </g>
            </g>
            <g data-name="Group 375">
                <g data-name="Group 374" fill={props.fill ?? "#bed5ff"}>
                    <path
                        data-name="Path 489"
                        d="M18.123.4a17.723 17.723 0 1 0 17.722 17.723A17.723 17.723 0 0 0 18.123.4Zm0 33.968a16.246 16.246 0 1 1 16.245-16.245 16.246 16.246 0 0 1-16.245 16.245Z"
                    />
                    <path
                        data-name="Path 489 - Outline"
                        d="M18.123 0a18.126 18.126 0 0 1 7.051 34.815A18.124 18.124 0 0 1 11.068 1.424 18.009 18.009 0 0 1 18.123 0Zm0 35.445A17.323 17.323 0 0 0 30.372 5.874a17.324 17.324 0 1 0-24.5 24.5 17.209 17.209 0 0 0 12.251 5.071Zm0-33.968a16.646 16.646 0 0 1 11.77 28.416A16.646 16.646 0 0 1 6.352 6.352a16.537 16.537 0 0 1 11.771-4.875Zm0 32.491a15.838 15.838 0 0 0 11.2-27.045 15.842 15.842 0 1 0-22.4 22.4 15.742 15.742 0 0 0 11.2 4.645Z"
                    />
                </g>
            </g>
            <g data-name="Group 377">
                <g data-name="Group 376" fill={props.fill ?? "#bed5ff"}>
                    <path
                        data-name="Path 490"
                        d="M18.122 5.569A12.553 12.553 0 0 0 5.569 18.122a.738.738 0 0 0 .738.738h4.125l4.214 4.214a.739.739 0 0 0 1.044 0l7.384-7.384-1.044-1.044-6.862 6.862-3.908-3.906a.738.738 0 0 0-.522-.216H7.07a11.077 11.077 0 1 1 11.052 11.816v1.477a12.553 12.553 0 1 0 0-25.107Z"
                    />
                    <path
                        data-name="Path 490 - Outline"
                        d="M18.122 5.169a12.954 12.954 0 1 1 0 25.907h-.4v-2.274h.4a10.677 10.677 0 1 0-10.62-11.818h3.232a1.13 1.13 0 0 1 .8.334l3.626 3.626 6.862-6.862 1.61 1.61-7.667 7.667a1.139 1.139 0 0 1-1.61 0l-4.1-4.1H6.307a1.14 1.14 0 0 1-1.138-1.138A12.953 12.953 0 0 1 18.122 5.169Zm.4 25.1A12.154 12.154 0 1 0 5.969 18.122a.339.339 0 0 0 .338.338h4.295l4.331 4.331a.339.339 0 0 0 .479 0l7.1-7.1-.478-.478-6.862 6.862-4.191-4.191a.336.336 0 0 0-.239-.1h-4.1l.029-.427a11.477 11.477 0 1 1 11.851 12.235Z"
                    />
                </g>
            </g>
            <g data-name="Group 379">
                <g data-name="Group 378" fill={props.fill ?? "#bed5ff"}>
                    <path
                        data-name="Path 491"
                        d="M22.554 14.43h-4.431v1.477h3.692v3.692h1.477v-4.431a.738.738 0 0 0-.738-.738Z"
                    />
                    <path
                        data-name="Path 491 - Outline"
                        d="M17.723 14.03h4.831a1.138 1.138 0 0 1 1.138 1.139v4.83h-2.277v-3.692h-3.692Zm5.169 5.169v-4.031a.338.338 0 0 0-.338-.338h-4.031v.677h3.692v3.692Z"
                    />
                </g>
            </g>
        </g>
    </svg>
)

export default RoundingIcon
