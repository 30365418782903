import React, {useEffect, useState} from 'react';
import Header from "../../Components/Header";
import Col from "antd/lib/col";
import Row from "antd/lib/row";
import Space from "antd/lib/space";
import {Organization} from "Apis/Models/Organization.type";
import {useDeleteOrganization, useOrganization, useSaveOrganization } from "Apis/OrganizationApi.ts";
import { Button } from 'Components/Button/Button';
import {useTranslation} from "react-i18next";
import { useForm } from "react-hook-form";
import {openErrorNotification, openSuccessNotification} from "Utils/NotificationUtils";
import ControlledInput from "Components/InputV2/ControlledInput";
import CountrySelect from "Components/CountrySelect";
import {useAuthProvider} from "../../Providers/AuthProvider";
import RequiresPremiumModal from 'Components/RequiresPremiumModal';
import FlexRow from 'Components/FlexRow';
import Modal from "Components/Modal";
import {auth} from "../../FirebaseSetup/auth";
import {useQueryClient} from "react-query";

declare var gist: any;

const ShowOrganizationPage = () => {

    const { t } = useTranslation();

    const [showDeleteModal, setShowDeleteModal] = useState<boolean>(false)

    const { userContext } = useAuthProvider();

    const queryClient = useQueryClient();
    const { data: organization } = useOrganization();
    const { mutateAsync: saveOrganizationMutation, isLoading: isSavingOrganization } = useSaveOrganization();
    const { mutateAsync: deleteOrganizationMutation, isLoading: isDeletingOrganization } = useDeleteOrganization();
    
    const { control, errors, handleSubmit, reset} = useForm<Organization>();
    const { control: controlDelete, handleSubmit: handleDeleteSubmit, errors: errorsDelete} = useForm<Organization>();

    useEffect(() => {
        if (organization) {
            reset(organization)
        }
    }, [organization, reset])
    
    const save = async (data: Organization) => {
        await saveOrganizationMutation(data);
        
        openSuccessNotification(t('success'), t('organization:organization.updated'))
    }

    const deleteOrganization = async (formdata: { organizationName: string}) => {
        await deleteOrganizationMutation(formdata);

        await auth.signOut();
        queryClient.clear();
        gist.chat('shutdown');
    }

    return (
        <div>
            <FlexRow justify={"space-between"}>
                <Header text={t('organization:organization.name')} />
                <Button color={"danger"} onClick={() => setShowDeleteModal(true)}>{t('deleteOrganization')}</Button>
            </FlexRow>
            <form onSubmit={handleSubmit(save)}>
                <Space direction={"vertical"} className={"w-100"}>
                    <ControlledInput placeholder={t('workspaceName')} control={control} name={"name"} errors={errors} />
                    <ControlledInput placeholder={t('vatNumber')} control={control} name={"vatNumber"} errors={errors} />
                    <CountrySelect placeholder={t('country')} errors={errors} name={"country"} defaultValue={"DK"} control={control} />
                    <ControlledInput placeholder={t('address')} control={control} name={"address"} errors={errors} />
            
                    <Row gutter={10}>
                        <Col span={12}>
                            <ControlledInput placeholder={t('zipCode')} control={control} name={"zipCode"} errors={errors} />
                        </Col>
                        <Col span={12}>
                            <ControlledInput placeholder={t('city')} control={control} name={"city"} errors={errors} />
                        </Col>
                    </Row>

                    <RequiresPremiumModal enabled={!userContext?.hasActiveLicense} title={t('registrations:lockTimeEntries')}>
                        <ControlledInput disabled={!userContext?.hasActiveLicense} placeholder={t('lockEntriesOlderThanDays')} control={control} name={"lockEntriesOlderThanDays"} errors={errors} />
                    </RequiresPremiumModal>

                    <Button loading={isSavingOrganization} color={"primary"} block type={"submit"}><span style={{fontSize: '1.5em', fontWeight: 600}}>{t('save')}</span></Button>
                </Space>
            </form>

            <Modal visible={showDeleteModal}
                   title={t('deleteOrganizationHeader')}
                   onCancel={() => setShowDeleteModal(false)}
            >
                <form onSubmit={handleDeleteSubmit(deleteOrganization, () => openErrorNotification(t('errors:anErrorOccurred'), t('organizationNameMustMatchExactly')))}>
                    <Space direction={"vertical"} className={"w-100"}>
                        <div style={{fontWeight: 600}}>
                            {t('organization:deleteOrganizationWarning')}
                        </div>

                        <div>
                            {t('organization:deleteOrganizationExplainer')}
                        </div>

                        <div style={{backgroundColor: '#9AAAC9', padding: 10, color: 'white', borderRadius: 3}}>
                            {t('organization:organizationName')}: {organization?.name}
                        </div>

                        <ControlledInput placeholder={t('deleteOrganizationWriteName')} name={"organizationName"} errors={errorsDelete} rules={{required: true, validate: (value) => value === organization?.name || t('organizationNameMustMatchExactly')}}
                                         control={controlDelete} />

                        <FlexRow justify={"end"}>
                            <Button color={"danger"} loading={isDeletingOrganization} disabled={isDeletingOrganization}
                                    type={"submit"}>{t('deleteOrganization')}</Button>
                        </FlexRow>
                    </Space>
                </form>
            </Modal>
        </div>
    )
}

export default ShowOrganizationPage;
