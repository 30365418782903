import * as React from "react"

const TimeOps = (props: React.SVGProps<SVGSVGElement>) => {
    return (
        <svg
            xmlns="http://www.w3.org/2000/svg"
            width={33.217}
            height={32.231}
            viewBox="0 0 33.217 32.231"
            {...props}
        >
            <g data-name="Tegnebr\xE6t 2">
                <path
                    data-name="Path 27"
                    d="M16.936 23.66h-1.24a.733.733 0 01-.7-.7v-1.438a4.687 4.687 0 01-1.015-.429l-.609.609-.383.383a.747.747 0 01-.541.226.7.7 0 01-.519-.226l-.18-.18-1.084-1.083a.735.735 0 01-.226-.519.7.7 0 01.226-.519c.18-.18.361-.383.564-.564l.429-.429a5.079 5.079 0 01-.429-1.015H9.763a.778.778 0 01-.519-.226.762.762 0 01-.2-.564V15.27a.744.744 0 01.2-.541.724.724 0 01.5-.226h1.444a6.167 6.167 0 01.428-1.015l-.609-.609-.383-.383a.735.735 0 01-.226-.519.7.7 0 01.226-.541l1.15-1.15.135-.135a.715.715 0 01.519-.2.787.787 0 01.541.226l.992.992a5.02 5.02 0 011.015-.406v-1.4a.722.722 0 01.406-.677.853.853 0 01.451-.09h1.713a.735.735 0 01.7.7v1.466a6.5 6.5 0 011.015.429l.992-.992a.735.735 0 01.519-.226.654.654 0 01.519.226c.429.429.857.835 1.263 1.263a.634.634 0 01.2.519.7.7 0 01-.226.519l-.135.135-.857.862a10.548 10.548 0 01.429 1.015h1.444a.735.735 0 01.519.226.744.744 0 01.2.541v1.737a.744.744 0 01-.2.541.641.641 0 01-.5.226h-1.466a5.7 5.7 0 01-.429 1.015l.992.992a.735.735 0 01.226.519.7.7 0 01-.226.541c-.429.429-.835.857-1.263 1.263a.715.715 0 01-.519.2.787.787 0 01-.541-.226l-.992-.992a5.081 5.081 0 01-1.015.429v1.4a.711.711 0 01-.361.654.873.873 0 01-.519.113h-.068c.072-.031-.176-.031-.311-.031z"
                />
                <path
                    data-name="Path 34"
                    d="M32.746 10.195a1.231 1.231 0 00-.744-.248 1.272 1.272 0 00-.857.338 15.978 15.978 0 00-6.608-7.917.494.494 0 00-.113-.068 17.264 17.264 0 00-2.233-1.128 15.6 15.6 0 00-5.684-1.173h-.383a15.472 15.472 0 00-2.707.226c-.18.023-.338.068-.519.09a.1.1 0 00-.068.023c-.113.023-.226.045-.316.068a6.052 6.052 0 00-.609.158c-.18.045-.383.113-.564.158a15.046 15.046 0 00-3.428 1.534 15.385 15.385 0 00-4.082 3.451 16.221 16.221 0 00-3.767 8.977 15.991 15.991 0 001.489 8.323 16.259 16.259 0 007.308 7.511 16.014 16.014 0 007.195 1.714h.045a1.2 1.2 0 00.947-.451 1.241 1.241 0 00.248-1.083 1.149 1.149 0 00-.158-.383 1.263 1.263 0 00-1.06-.564 13.307 13.307 0 01-5.233-1.06 13.873 13.873 0 01-4.894-3.469 13.706 13.706 0 01-2.729-4.624 13.339 13.339 0 01-.474-7.285 13.7 13.7 0 014.759-7.808 13.546 13.546 0 017.691-3.022c.293-.023.609-.023.925-.023a13.666 13.666 0 0112.54 8.255h-.135a1.229 1.229 0 00-.857.338 1.083 1.083 0 00-.361.88 1.247 1.247 0 00.361.857c.429.429.835.857 1.263 1.286l.812.835a1.419 1.419 0 001.038.451 1.579 1.579 0 00.564-.113.494.494 0 00.113-.068 1.423 1.423 0 00.677-.812c.271-.744.519-1.489.789-2.233l.18-.519a1.155 1.155 0 00-.361-1.421z"

                />
                <path
                    data-name="Path 35"
                    d="M15.492 8.864a1.455 1.455 0 01.451-.068h1.624a.532.532 0 01.5.5v1.621a5.14 5.14 0 011.263.519c.361-.383.744-.744 1.105-1.105a.53.53 0 01.744 0l1.282 1.286a.53.53 0 010 .744l-1.105 1.105a6.873 6.873 0 01.541 1.263h1.6a.51.51 0 01.5.541v1.737a.51.51 0 01-.5.541h-1.624a5.526 5.526 0 01-.519 1.263l1.105 1.105a.53.53 0 010 .744l-1.286 1.286a.53.53 0 01-.744 0c-.383-.361-.744-.744-1.105-1.105a5.14 5.14 0 01-1.263.519v1.557a.559.559 0 01-.248.474.841.841 0 01-.474.068h-1.624a.532.532 0 01-.5-.5v-1.622a6.2 6.2 0 01-1.263-.519c-.361.383-.744.744-1.105 1.105a.53.53 0 01-.744 0c-.429-.429-.857-.835-1.263-1.263a.53.53 0 010-.744c.361-.383.744-.744 1.105-1.128a5.14 5.14 0 01-.519-1.263h-1.6a.51.51 0 01-.5-.541v-1.737a.51.51 0 01.5-.541h1.6a6.389 6.389 0 01.519-1.286c-.383-.361-.744-.744-1.105-1.105a.53.53 0 010-.744l1.263-1.263a.53.53 0 01.744 0c.383.361.744.744 1.105 1.105a4.8 4.8 0 011.263-.519V9.338a.451.451 0 01.277-.474z"

                />
                <path
                    data-name="Path 36"
                    d="M16.167 7.015a.677.677 0 00.677-.677V4.714a.7.7 0 00-.361-.541.636.636 0 00-.5-.068 1.553 1.553 0 00-.18.068.643.643 0 00-.338.429c-.045.586-.023 1.15-.023 1.737a.688.688 0 00.722.677z"
                />
                <path
                    data-name="Path 37"
                    d="M10.643 7.985a.726.726 0 00.947.271.677.677 0 00.271-.947c-.248-.451-.519-.88-.767-1.331a.792.792 0 00-.519-.361.925.925 0 00-.248.023.692.692 0 00-.474.992c.272.451.52.902.79 1.353z"
                />
                <path
                    data-name="Path 38"
                    d="M20.657 8.21a.7.7 0 00.992-.2c.248-.429.519-.88.767-1.331a.7.7 0 00.09-.519.417.417 0 00-.045-.158.7.7 0 00-.767-.383c-.023 0-.023 0-.045.023a.677.677 0 00-.451.361l-.744 1.286a.677.677 0 00.203.921z"
                />
                <path
                    data-name="Path 39"
                    d="M5.995 11.074c.429.248.88.519 1.331.767a.717.717 0 00.947-.248.688.688 0 00-.2-.947c-.383-.226-.789-.451-1.173-.677a1.073 1.073 0 00-.564-.2.286.286 0 00-.135.023.677.677 0 00-.519.609.638.638 0 00.09.406.451.451 0 00.223.267z"
                />
                <path
                    data-name="Path 40"
                    d="M25.011 11.819c.429-.248.88-.5 1.331-.767a.543.543 0 00.226-.226.721.721 0 00.068-.609.7.7 0 00-.677-.474.5.5 0 00-.18.023c-.271.113-.519.293-.789.429a8.554 8.554 0 00-.767.451.708.708 0 00.789 1.173z"
                />
                <path
                    data-name="Path 41"
                    d="M7.031 16.014a.7.7 0 00-.767-.586c-.564.023-1.105-.045-1.669.023a.711.711 0 00-.429.925.692.692 0 00.474.429.5.5 0 00.18.023h1.556a.695.695 0 00.654-.812z"
                />
                <path
                    data-name="Path 42"
                    d="M27.626 15.427c-.045 0-.09-.023-.135-.023h-1.376a1.056 1.056 0 00-.383.045.72.72 0 00-.361 1.06.778.778 0 00.744.316h1.443a.677.677 0 00.609-.7.7.7 0 00-.541-.698z"
                />
                <path
                    data-name="Path 43"
                    d="M7.395 20.39c-.474.248-.925.519-1.376.789h-.023a.7.7 0 00.654 1.24l.135-.068c.406-.226.812-.474 1.218-.7a.713.713 0 00.293-.88.663.663 0 00-.9-.383z"
                />
                <path
                    data-name="Path 44"
                    d="M26.319 21.179c-.045-.023-.068-.045-.113-.068-.406-.248-.812-.474-1.218-.7a.722.722 0 00-.609-.068.706.706 0 00-.2 1.2c.361.248.744.451 1.128.677a.472.472 0 01.135.09.945.945 0 00.474.18.677.677 0 00.7-.519.659.659 0 00-.297-.792z"
                />
                <path
                    data-name="Path 45"
                    d="M11.028 23.953a.722.722 0 00-.429.383c-.2.361-.406.7-.609 1.038-.045.068-.09.158-.135.226a.692.692 0 00.271.925.74.74 0 00.947-.226c.045-.09.09-.158.135-.248.2-.361.406-.722.632-1.06a.717.717 0 000-.789.706.706 0 00-.812-.249z"
                />
                <path
                    data-name="Path 46"
                    d="M22.304 25.352c-.2-.383-.429-.744-.654-1.128a.717.717 0 00-.835-.293.677.677 0 00-.451.677 1.107 1.107 0 00.226.564c.18.293.338.586.519.88a.932.932 0 00.158.248.695.695 0 00.97.18.71.71 0 00.226-.925.361.361 0 01-.159-.203z"
                />
                <path
                    data-name="Path 47"
                    d="M16.011 25.238a.7.7 0 00-.564.654v1.579a.711.711 0 001.421.023v-1.579a.738.738 0 00-.857-.677z"
                />
            </g>
        </svg>
    )
}

export default TimeOps
