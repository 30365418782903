import {DateUtils} from "Utils/DateUtils";
import { useContext, useEffect, useState } from "react";
import moment from "moment";
import Duration from "Components/Duration";
import { CalendarRegistrationContext } from "Pages/Registrations/Components/Providers/CalendarRegistrationProvider";

const DateCompareFormat = 'YYYY/MM/DD';

const WeekCalendarHeader = (props) => {
    
    const { registrations } = useContext(CalendarRegistrationContext);
    
    const [totalDuration, setTotalDuration] = useState<number>(0);
    
    useEffect(() => {
        if (registrations && registrations.length > 0) {
            const today = moment(props.date).format(DateCompareFormat);
            
            const todaysRegistrations = registrations?.filter(x => moment(x.startedAt).format(DateCompareFormat) === today);
            
            if (todaysRegistrations && todaysRegistrations.length > 0) {
                const allDurations = todaysRegistrations?.map(x => x.duration);
    
                setTotalDuration(allDurations?.reduce((value, current) => (value ?? 0) + (current ?? 0)) ?? 0)
            } else {
                setTotalDuration(0)
            }
        } else {
            setTotalDuration(0)
        }
    }, [props.date, registrations])
    
    return (
        <div style={styles.container}>
            <div className="padding15" style={{...styles.durationBox, flexDirection: 'column'}}>
                <b>
                    {DateUtils.getDayAsString(props.date)}
                </b>
                {DateUtils.getDateAsString(props.date)}
                <Duration duration={totalDuration}  />
            </div>
        </div>
    )
}

const styles = {
    container: {
        height: 60,
        width: '100%',
        display: 'flex',
        justifyContent: 'space-around',
        alignItems: 'center',
    },
    durationBox: {
        minWidth: '30%',
        color: 'black',
        display: 'flex',
        alignItems: 'center',
        paddingTop: 10,
        paddingBottom: 10,
        justifyContent: 'center',
    }
}

export default WeekCalendarHeader
