import {DineroInvoiceMailoutStatus} from "Pages/Dinero/Api/Models/DineroInvoiceModel";
import { RegistrationUtils } from "Utils/RegistrationUtils";
import { ReportModel } from "Apis/Models/ReportModel";
import { ReportSearchParams } from "Hooks/UseReporting";
import moment from "moment";
import { DefaultInvoiceSettings } from "Pages/Dinero/Api/Models/DefaultInvoiceSettings";

const DRAFT_DINERO_INVOICE_NUMBER = 9223372036854776000;

export const getInvoiceStatusAsText = (status: string) => {

    switch (status) {
        case "Draft":
            return "Kladde";
        case "Booked":
            return "Godkendt";
        case "Paid":
            return "Betalt";
        case "OverPaid":
            return "Over betalt";
        case "Overdue":
            return "Forfalden";
    }

}

export const getInvoiceNumber = (number: number, defaultValue: string = '') => {
    return number === DRAFT_DINERO_INVOICE_NUMBER ? defaultValue : number;
}


export const getInvoiceMailoutStatus = (status: DineroInvoiceMailoutStatus) => {

    switch (status) {
        case "Failed":
            return "Fejlet";
        case "NotSent":
            return "Ikke sendt";
        case "Sent":
            return "Sendt";
        case "SeenByCustomer":
            return "Set af kunden";
    }
}

export const createInvoiceLineFromReport = (report: ReportModel, selectedEntries: number[], searchParams: ReportSearchParams, defaultInvoiceSettings: DefaultInvoiceSettings) => {
    const invoiceLines: any[] = [];
    
    if (searchParams.groupBy) {
        report?.groupedRegistrations.filter(x => selectedEntries.includes(x.groupKey)).forEach(x => {
            invoiceLines.push({
                description: x.label,
                comments: searchParams.groupBy === 'projectWithDescriptions' ? x.registrations.map(x => `${moment(x.startedAt).format('DD/MM/YYYY')}: ${x.description}: ${RegistrationUtils.getFormattedDurationString(x.duration ?? 0)}`).join('\r\n') : '',
                unit: "hours",
                quantity: RegistrationUtils.getDurationAsHoursValue(x.totalDuration),
                baseAmountValue: x.avgUnitRate ?? 0,
                accountNumber: defaultInvoiceSettings.defaultAccountNumber,
                discount: 0,
            })
        })
    } else {
        report?.registrations.filter(x => selectedEntries.includes(x.id!)).forEach(x => {
            invoiceLines.push({
                description: x.project?.name ? `${x.project?.name}: ${x.description}` : x.description,
                comments: moment(x.startedAt).format('DD/MM/YYYY'),
                unit: "hours",
                quantity: RegistrationUtils.getDurationAsHoursValue(x.duration ?? 0),
                baseAmountValue: x.rate  ?? 0,
                accountNumber: defaultInvoiceSettings.defaultAccountNumber,
                discount: 0,
            })
        })
    }
    
    return invoiceLines;
}
