import { useEffect, useState } from "react";
import { useDineroIntegration } from "Pages/Dinero/Api/DineroIntegrationApi";
import Steps from "antd/lib/steps";
import { useTranslation } from "react-i18next";
import SelectDineroIntegration from "Pages/Dinero/Onboarding/SelectDineroIntegration";
import FullPageSpinner from "Components/FullPageSpinner";
import LoadingData from "Pages/Dinero/Onboarding/LoadingData";
import DineroSettings from "Pages/Dinero/Components/DineroSettings";
import DineroCustomerSelector from "Pages/Dinero/Components/DineroCustomerSelector";
import DineroSetupFinished from "Pages/Dinero/Onboarding/DineroSetupFinished";

const OnboardingPage = () => {
    
    const { t } = useTranslation();
    const { data: dineroIntegration, isLoading, refetch } = useDineroIntegration();
    
    const [currentStep, setCurrentStep] = useState<number>(0);
    
    useEffect(() => {
        if (!dineroIntegration?.dineroOrganizationId) {
            setCurrentStep(0);
        } else if (dineroIntegration?.integrationStatus === 1) {
            setCurrentStep(1);
            const interval = setInterval(() => {
                refetch();
            }, 1000);
            
            return () => clearInterval(interval);
        } else if (dineroIntegration?.integrationStatus === 2 && dineroIntegration.customerImportSetting === null) {
            setCurrentStep(2)
        } else if (dineroIntegration?.integrationStatus === 2) {
            setCurrentStep(3)
        } else {
            setCurrentStep(4)
        }
    }, [dineroIntegration, refetch])
    
    if (isLoading) {
        return <FullPageSpinner />
    }
    
    return (
        <>
            <Steps current={currentStep}>
                <Steps.Step title={t('dinero:settings.selectOrganization')} />
                <Steps.Step title={t('dinero:settings.synchronizingData')} />
                <Steps.Step title={t('dinero:settings.settings')} />
                <Steps.Step title={t('dinero:settings.selectCustomers')} />
                <Steps.Step title={t('dinero:settings.finish')} />
            </Steps>
            
            <div style={{paddingTop: 30}} >
                {currentStep === 0 && (
                    <SelectDineroIntegration />
                )}
    
                {currentStep === 1 && (
                    <LoadingData />
                )}
    
                {currentStep === 2 && (
                    <DineroSettings />
                )}
    
                {currentStep === 3 && (
                    <DineroCustomerSelector />
                )}
    
                {currentStep === 4 && (
                    <DineroSetupFinished />
                )}
                
            </div>
            
        </>
    )
}

export default OnboardingPage
