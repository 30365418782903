import { Navigate, Route, Routes } from "react-router-dom";
import LoginPage from "Pages/Auth/LoginPage";
import SignupPage from "Pages/Auth/SignupPage";
import AuthActionsPage from "Pages/Auth/AuthActionsPage";
import ResetPasswordPage from "Pages/Auth/ResetPasswordPage";
import RedirectIfLoggedIn from "Components/RedirectIfLoggedIn";
import RequireAuthentication from "Components/RequireAuthentication";
import CreateOrganizationPage from "Pages/Auth/CreateOrganizationPage";
import SignupPageV2 from "Pages/Auth/SignupPageV2";

const AuthRoutes = () => {
    
    return (
        <Routes>
            <Route path={`login`} element={
                <RedirectIfLoggedIn>
                    <LoginPage />
                </RedirectIfLoggedIn>
            } />
    
            <Route path={`signup`} element={
                <RedirectIfLoggedIn>
                    <SignupPageV2 />
                </RedirectIfLoggedIn>
            } />
    
            <Route path={`signupold`} element={
                <RedirectIfLoggedIn>
                    <SignupPage />
                </RedirectIfLoggedIn>
            } />
    
            <Route path={`actions`} element={
                <AuthActionsPage />
            } />
    
            <Route path={`resetPassword`} element={
                <RedirectIfLoggedIn>
                    <ResetPasswordPage />
                </RedirectIfLoggedIn>
            } />
    
            <Route path={"organization/create"} element={
                <RequireAuthentication>
                    <CreateOrganizationPage />
                </RequireAuthentication>
            } />
            
            <Route path={"*"} element={<Navigate to={'/auth/login'} />} />
        </Routes>
    )
}

export default AuthRoutes
