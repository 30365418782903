import NoResultsIcon from "Assets/Icons/NoResultsIcon";
import FlexRow from "Components/FlexRow";

interface Props {
    text: string;
    padding?: number
}
const NoResults = (props: Props) => {
    
    return (
        <FlexRow direction={"column"} style={{padding: props.padding ?? '20px'}}>
            <NoResultsIcon />
            <div style={{paddingTop: 20}}>
                {props.text}
            </div>
        </FlexRow>
    )
}

export default NoResults
