import Modal from "Components/Modal";
import { default as UncontrolledInput } from "Components/InputV2/InputV2";
import { Controller, useForm } from "react-hook-form";
import { Registration } from "Apis/Models/Registration.type";
import { useTranslation } from "react-i18next";
import SelectV2 from "Components/SelectV2";
import React, { Fragment, useEffect, useMemo, useState } from "react";
import { useCustomers } from "Apis/CustomerApi";
import DatePicker from "Components/DatePicker";
import Space from "Components/Space";
import TimeField from "react-simple-timefield";
import moment from "moment/moment";
import Button from "Components/Button";
import FlexRow from "Components/FlexRow";
import { RegistrationUtils } from "Utils/RegistrationUtils";
import { useAuthProvider } from "Providers/AuthProvider";
import ControlledInput from "Components/InputV2";
import RequiresPremiumModal from "Components/RequiresPremiumModal";
import Tooltip from "antd/lib/tooltip";
import BillableActive from "Assets/Icons/BillableActive";
import BillableInactive from "Assets/Icons/BillableInactive";
import { useTags } from "Apis/TagApi";
import { useUsers } from "Apis/UsersApi";
import { useDeleteRegistration, useSaveRegistration } from "Apis/RegistrationsApi";
import { openErrorNotification } from "Utils/NotificationUtils";
import PopConfirm from "Components/PopConfirm";

interface IProps {
    visible: boolean;
    registration?: Registration;
    onOk?: Function;
    onCancel: () => void;
    enableUserSelection?: boolean;
}

const RegistrationModal = (props: IProps) => {
    
    const { visible, registration, onCancel, enableUserSelection } = props;
    
    const { t } = useTranslation();
    
    const { userContext } = useAuthProvider();
    
    const { data: customers } = useCustomers();
    const { data: users } = useUsers();
    const { data: tags } = useTags();
    
    const { mutateAsync: saveRegistrationMutation, isLoading: isSavingRegistration } = useSaveRegistration();
    const { mutateAsync: deleteRegistrationMutation, isLoading: isDeletingRegistration } = useDeleteRegistration();
    
    const { handleSubmit, setValue, register, reset, getValues, control } = useForm<Registration>();
    
    const [userSearchFilter, setUserSearchFilter] = useState<string>('');
    const [projectSearchFilter, setProjectSearchFilter] = useState<string>('');
    
    useEffect(() => {
        reset(registration);
    }, [reset, registration])
    
    const tagsOptions = useMemo(() => tags?.map((tag) => {
        return <SelectV2.Option label={tag.text} value={tag.id} />
    }), [tags])
    
    const userOptions = useMemo(() => {
        const lowerCase = userSearchFilter.toLowerCase();
        
        const filteredUsers = users?.filter(x => x.name?.toLowerCase().includes(lowerCase) || x.eMail.toLowerCase().includes(lowerCase)) ?? [];
        
        return filteredUsers.map((user) => {
            return <SelectV2.Option label={user.name} key={user.id} value={user.id} />
        })
    }, [userSearchFilter, users])
    
    const projectOptions = useMemo(() => customers?.map((customer) => {
        
        let unfinishedProjects = customer.projects?.filter(x => !x.finishedAt) ?? [];
        const lowerCase = projectSearchFilter.toLowerCase();
        
        if (projectSearchFilter !== '') {
            unfinishedProjects = unfinishedProjects.filter(x => x.customer!.name.toLowerCase().includes(lowerCase) || x.name.toLowerCase().includes(lowerCase));
        }
        
        if (unfinishedProjects.length === 0) {
            return <Fragment key={customer.id} />;
        }
        
        const options = unfinishedProjects.sort((a,b) => a.name > b.name ? 1 : -1).map((project) => {
            return (
                <SelectV2.Option label={`${project.name} - ${customers?.find(x => x.id === project.customerId)?.name}`} key={project.id} value={project.id} />
            )
        })
        
        if (options.length === 0) {
            return <></>
        }
        
        return (
            <SelectV2.Group key={customer.id} label={customer.name}>
                {options}
            </SelectV2.Group>
        )
    }), [projectSearchFilter, customers])
    
    const updateRegistrationSetStartedAt = (timeString: string) => {
        const registration = {
            ...getValues(),
        }

        RegistrationUtils.setStartedAtTimeFromString(registration, timeString);
        
        setValue('startedAt', registration.startedAt);
    }
    
    const updateRegistrationSetStoppedAt = (timeString: string) => {
        const registration = {
            ...getValues(),
        }

        RegistrationUtils.setStoppedAtTimeFromString(registration, timeString);
        
        setValue('stoppedAt', registration.stoppedAt);
    }
    
    const updateRegistrationSetDate = (date: any) => {
        const registration = {
            ...getValues(),
        }

        registration.startedAt = moment(registration.startedAt).toDate();
        registration.stoppedAt = moment(registration.stoppedAt).toDate();
        
        RegistrationUtils.setNewDate(registration, moment(date));
        
        setValue('startedAt', registration.startedAt);
        setValue('stoppedAt', registration.stoppedAt);
    }
    
    const projectSelected = (event) => {
        const project = customers?.flatMap(x => x.projects).find(x => x.id === event);
        
        if (project && userContext?.hasActiveLicense) {
            setValue('billable', project.billable)
        }
    }
    
    const saveRegistration = async (data: Registration, closeModal: boolean = true) => {
        if (!data.startedAt) {
            openErrorNotification(t('errors:waitAMinute'), t('registrations:noStartDate'))
            return;
        }

        let registration = {...props.registration, ...data};

        if (!props.registration) {
            registration.id = undefined;
        }

        if (!registration.tags) {
            registration.tags = [];
        }


        await saveRegistrationMutation(registration);

        if (props.onOk) {
            props.onOk();
        }
        
        onCancelInternal(closeModal);
    }
    
    const deleteRegistration = async () => {
        await deleteRegistrationMutation(props.registration!.id!);
        
        onCancelInternal();
    }
    
    const onCancelInternal = (close: boolean = true) => {
        
        reset({})
        
        if (close && props.onCancel) {
            props.onCancel();
        }
    }
    
    return (
        <Modal visible={visible}
               title={registration?.id ? t('registrations:updateRegistration') : t('registrations:createRegistration')}
               onCancel={onCancel}
        >
            <form onSubmit={handleSubmit(data => saveRegistration(data, true))}>
                <input style={{display: 'none'}} name={"id"} defaultValue={registration?.id} ref={register} />

                <Space direction={"vertical"} size={"large"}>

                    {enableUserSelection && (
                        <RequiresPremiumModal enabled={!userContext?.hasActiveLicense} title={t('registrations:addTimeForOtherUser')}>
                            <Controller defaultValue={userContext?.userId} name={"userId"} control={control} render={(field) => (
                                <SelectV2 disabled={!userContext?.hasActiveLicense} onFilter={setUserSearchFilter} placeholder={t('employee')} name={"userId"} onChange={e => {
                                    field.onChange(e);
                                }
                                } value={field.value}>
                                    {userOptions}
                                </SelectV2>
                            )} />
                        </RequiresPremiumModal>
                    )}
                    
                    <ControlledInput placeholder={t('description')} name={"description"} control={control} autoFocus />
                    
                    <FlexRow>
                        <Controller defaultValue={null} name={"projectId"} control={control} render={(field) => (
                            <SelectV2 onFilter={setProjectSearchFilter} placeholder={t('project')} name={"projectId"} onChange={e => {
                                projectSelected(e)
                                field.onChange(e);
                            }
                            } value={field.value}>
                                {projectOptions}
                            </SelectV2>
                        )} />
                        <div style={{width: 60}}>
                            <Controller name={"billable"} defaultValue={false} control={control} render={_ => (
                                <RequiresPremiumModal enabled={!userContext?.hasActiveLicense} title={t('registrations:billableRegistrations')}>
                                    <Tooltip title={_.value ? t('registrations:billable') : t('registrations:notBillable')} >
                                        <FlexRow className={"clickable"} justify={"center"} onClick={() => {
                                            if (userContext?.hasActiveLicense) {
                                                _.onChange(!_.value);
                                            }
                                        }}>
                                            {_.value ? (
                                                <BillableActive />
                                            ) : (
                                                <BillableInactive />
                                            )}
                                        </FlexRow>
                                    </Tooltip>
                                </RequiresPremiumModal>
                            )} />
                        </div>
                    </FlexRow>
                    
                    <Controller defaultValue={null} name={"tags"} control={control} render={(field) => (
                        <SelectV2 allowClear multiple={true} onFilter={setProjectSearchFilter} placeholder={t('tags')} name={"tags"} onChange={field.onChange} value={field.value}>
                            {tagsOptions}
                        </SelectV2>
                    )} />
                    
                    <Controller name={"startedAt"} defaultValue={new Date()} control={control} render={(field) => (
                        <Space direction={"vertical"} size={"large"}>
                            <DatePicker name={"startedAt"} onChange={updateRegistrationSetDate} singleValue={moment(field.value).toDate() ?? undefined} placeholder={t('date')} mode={"single"}  />
                            
                            <FlexRow justify={"space-between"}>
                                <div style={{width: '48.5%'}}>
                                    <TimeField
                                        value={field.value ? moment(field.value).format("HH:mm") : undefined}
                                        onChange={(e, value) => updateRegistrationSetStartedAt(value)}
                                        input={<UncontrolledInput placeholder={t('start')} onChangeUseEvent />}
                                    />
                                </div>
                                <div style={{width: '48.5%'}}>
                                    <Controller name={"stoppedAt"} control={control} defaultValue={undefined} render={(field) => (
                                        <>
                                            {!field.value ? (
                                                <Button
                                                    style={{backgroundColor: 'white', minHeight: '3.5rem', lineHeight: 2.4, border: '1px solid rgba(2, 17, 47, 0.4)'}}
                                                    block
                                                    onClick={() => updateRegistrationSetStoppedAt(moment().format('HH:mm'))}
                                                    onFocus={() => updateRegistrationSetStoppedAt(moment().format('HH:mm'))}
                                                >{t('stop')}</Button>
                                            ) : (
                                                <TimeField
                                                    value={field.value ? moment(field.value).format("HH:mm") : undefined}
                                                    onChange={(e, value) => updateRegistrationSetStoppedAt(value)}
                                                    input={<UncontrolledInput  placeholder={t('stop')} onChangeUseEvent />}
                                                />
                                            )}
                                        </>
                                    )} />
                                </div>
                            </FlexRow>
                        </Space>
                    )} />
                    
                    <div style={{display: 'flex', justifyContent: 'space-between'}}>
                        <div>
                            {props.registration?.id && (
                                <PopConfirm title={t('areYouSure')} onConfirm={deleteRegistration}>
                                    <Button loading={isDeletingRegistration} color="danger">{t('delete')}</Button>
                                </PopConfirm>
                            )}
                        </div>
                        <Space direction={"horizontal"}>
                            <Button key="cancel" onClick={props.onCancel}>{t('cancel')}</Button>
                            {!props.registration?.id && (
                                <Button
                                    loading={isSavingRegistration}
                                    onClick={handleSubmit(data => saveRegistration(data, false))}
                                >
                                    {t('saveAndNew')}
                                </Button>
                            )}
                            <Button
                                color="primary"
                                loading={isSavingRegistration}
                                type={"submit"}
                            >
                                {t('save')}
                            </Button>
                        </Space>
                    </div>
                
                </Space>
            </form>
        </Modal>
    )
}

export default RegistrationModal
