import * as React from "react"
import { SVGProps } from "react"

const Integrations = (props: SVGProps<SVGSVGElement>) => (
    <svg
        data-name="Integration icon"
        xmlns="http://www.w3.org/2000/svg"
        width={32.4}
        height={32.4}
        viewBox="0 0 32.4 32.4"
        {...props}
    >
        <path
            data-name="Path 496"
            d="m16.33 16.33-2.394 2.394-2.659-2.659 2.394-2.394-1.243-1.243-2.394 2.394L8.2 12.99l-4.547 4.547a5.619 5.619 0 0 0-.181 7.747L0 28.758l1.243 1.238 3.474-3.474a5.612 5.612 0 0 0 7.747-.181l4.547-4.547-1.832-1.832 2.394-2.394Zm-5.109 8.773a3.855 3.855 0 0 1-5.452 0l-.872-.872a3.859 3.859 0 0 1 0-5.452l3.3-3.3 6.323 6.323Z"
        />
        <path
            data-name="Path 497"
            d="M30 1.243 28.757 0l-3.474 3.474a5.612 5.612 0 0 0-7.747.181L12.989 8.2l8.809 8.809 4.547-4.547a5.619 5.619 0 0 0 .181-7.747Zm-4.9 9.978-3.3 3.3L15.475 8.2l3.3-3.3a3.855 3.855 0 0 1 5.452 0l.872.872a3.859 3.859 0 0 1 .003 5.449Z"
        />
    </svg>
)

export default Integrations
