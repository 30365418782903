interface Config {
    baseUrl: string,
    firebaseConfig: FirebaseConfig;
    stripe: StripeConfig
}

interface FirebaseConfig {
    apiKey: string,
    authDomain: string,
    projectId: string,
    appId?: string
}

interface StripeConfig {
    key: string;
}

let config: Config

if (process.env.REACT_APP_ENV === 'Production') {
    config = {
        baseUrl: 'https://app.timeops.dk/api',
        firebaseConfig: {
            apiKey: "AIzaSyCrxihD9RQQVG8C2NXFsFS8C4cYPeHzBzw",
            authDomain: "timeops.firebaseapp.com",
            projectId: "timeops",
            appId: "1:864957041392:web:28bb80643d80a21d5e1035",
        },
        stripe: {
            key: "pk_live_DnQk6TUVcPUEVd4IL2wlYzTt"
        }
    }
}
else if (process.env.REACT_APP_ENV === 'Staging') {
    config = {
        baseUrl: 'http://localhost:3000',
        firebaseConfig: {
            apiKey: "AIzaSyB70qA1Xscg5Kd0nYEPAPEllAGwEkWv528",
            authDomain: "timeops-staging.firebaseapp.com",
            projectId: "timeops-staging",
        },
        stripe: {
            key: "pk_test_p8neoE24f0Xn7t26T37orr9p"
        }
    }
}
else {
    config = {
        baseUrl: 'http://localhost:3000/api',
        firebaseConfig: {
            apiKey: "AIzaSyB70qA1Xscg5Kd0nYEPAPEllAGwEkWv528",
            authDomain: "timeops-staging.firebaseapp.com",
            projectId: "timeops-staging",
        },
        stripe: {
            key: "pk_test_p8neoE24f0Xn7t26T37orr9p"
        }
    }
}

export default config;
