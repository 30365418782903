import styles from './TabContent.module.scss';
import CaretLeftIcon from "Assets/Icons/CaretLeftIcon";
import CaretRightIcon from "Assets/Icons/CaretRightIcon";

interface Props {
    key: string;
    children: any;
    hasPrevious: boolean;
    hasNext: boolean;
    scrollToPrevious: Function;
    scrollToNext: Function;
}
const TabContent = (props: Props) => {
    
    return (
        <div className={styles.container}>
            <div className={styles.flexContainer}>
                {props.hasPrevious && (
                    <div style={{marginRight: 10, justifyContent: 'center', alignContent: 'center', display: "flex"}}>
                        <CaretLeftIcon style={{cursor: 'pointer'}} width={20} height={20} onClick={() => props.scrollToPrevious()} />
                    </div>
                )}
                
                <div className={styles.contentContainer}>
                    {props.children}
                </div>
        
                {props.hasNext && (
                    <div style={{marginLeft: 10, justifyContent: 'center', alignContent: 'center', display: "flex"}}>
                        <CaretRightIcon style={{cursor: 'pointer'}} width={20} height={20} onClick={() => props.scrollToNext()} />
                    </div>
                )}
            </div>
        </div>
    )
}

export default TabContent
