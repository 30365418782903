import { useMutation, useQuery, useQueryClient } from "react-query";
import { request } from "Apis/BaseApi";
import { Tag } from "Apis/Models/Tag";

export const TAG_CACHE_KEY = 'TAG_CACHE_KEY';
export const useTags = () => {
    return useQuery(TAG_CACHE_KEY, async () => {
        return await request<Tag[]>({
            url: `/tag`,
            method: 'GET'
        });
    })
}

export const useSaveTag = () => {
    const queryClient = useQueryClient();
    
    return useMutation(async (tag: Tag): Promise<Tag> => {
        if (tag.id) {
            return await request<Tag>({
                url: `/tag/${tag.id}`,
                method: 'PUT',
                data: tag
            })
        } else {
            return await request<Tag>({
                url: `/tag`,
                method: 'POST',
                data: tag
            })
        }
    }, {
        onSuccess: () => {
            queryClient.invalidateQueries(TAG_CACHE_KEY)
        }
    })
}


export const useDeleteTag = () => {
    const queryClient = useQueryClient();
    
    return useMutation(async (tag: Tag) => {
        return await request<Tag>({
            url: `/tag/${tag.id}`,
            method: 'DELETE'
        })
    }, {
        onSuccess: () => {
            queryClient.invalidateQueries(TAG_CACHE_KEY);
        }
    })
}

