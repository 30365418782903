import * as React from "react"
import { SVGProps } from "react"

const CloseIcon = (props: SVGProps<SVGSVGElement>) => (
    <svg xmlns="http://www.w3.org/2000/svg" width="15px" height="15px" {...props}>
        <g
            data-name="Group 577"
            fill="none"
            stroke={props.fill ?? "#9aaac9"}
            strokeLinecap="round"
            strokeWidth={2}
        >
            <path data-name="Line 77" d="M10.45 10.449 1.415 1.414" />
            <path data-name="Line 78" d="m1.415 10.449 9.035-9.035" />
        </g>
    </svg>
)

export default CloseIcon
