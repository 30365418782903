import * as React from "react"
import { SVGProps } from "react"

const BillableIcon = (props: SVGProps<SVGSVGElement>) => (
    <svg
        data-name="Group 1026"
        xmlns="http://www.w3.org/2000/svg"
        width="1em"
        height="1em"
        viewBox="0 0 35.307 35.445"
        {...props}
    >
        <path
            data-name="Path 472"
            d="M10.385 30.945v-.4a2.2 2.2 0 0 0 1.731-2.143v-1.154a2.195 2.195 0 0 0-2.192-2.192H8.769a.116.116 0 0 1-.115-.115v-1.154a.115.115 0 0 1 .115-.115h1.154a.115.115 0 0 1 .115.115 1.039 1.039 0 1 0 2.077 0 2.2 2.2 0 0 0-1.731-2.143v-.4a1.039 1.039 0 1 0-2.077 0v.4a2.2 2.2 0 0 0-1.73 2.148v1.154a2.195 2.195 0 0 0 2.192 2.192h1.154a.116.116 0 0 1 .115.115v1.154a.115.115 0 0 1-.115.115H8.769a.116.116 0 0 1-.115-.115 1.039 1.039 0 1 0-2.077 0 2.2 2.2 0 0 0 1.731 2.143v.4a1.039 1.039 0 0 0 2.077 0Z"
            fill={props.fill ?? "#bed5ff"}
        />
        <path
            data-name="Path 473"
            d="M35 7.227 28.08.3a1.038 1.038 0 0 0-.734-.3H11.423a3.119 3.119 0 0 0-3.116 3.115v13.7a9.346 9.346 0 0 0 1.038 18.634h22.846a3.119 3.119 0 0 0 3.115-3.115V7.962a1.038 1.038 0 0 0-.3-.734Zm-6.616-3.682 1.689 1.689 1.689 1.689h-3.378ZM2.077 26.1a7.269 7.269 0 1 1 7.269 7.269A7.277 7.277 0 0 1 2.077 26.1Zm30.115 7.269H15.213a9.342 9.342 0 0 0 3.421-6.231h10.788a1.039 1.039 0 1 0 0-2.077H18.634a9.288 9.288 0 0 0-1.521-4.154h12.309a1.039 1.039 0 1 0 0-2.077H15.213a9.3 9.3 0 0 0-4.829-2.019V3.115a1.04 1.04 0 0 1 1.038-1.038h14.885v5.884A1.038 1.038 0 0 0 27.345 9h5.885v23.33a1.04 1.04 0 0 1-1.039 1.038Z"
            fill={props.fill ?? "#bed5ff"}
        />
        <path
            data-name="Path 474"
            d="M29.423 12.6h-15.23a1.039 1.039 0 1 0 0 2.077h15.23a1.039 1.039 0 1 0 0-2.077Z"
            fill={props.fill ?? "#bed5ff"}
        />
    </svg>
)

export default BillableIcon
