import { useEffect, useState } from 'react';
import {Registration} from "Apis/Models/Registration.type";
import Duration from "Components/Duration";
import RegistrationCardListItemRow from "./RegistrationCardListItemRow";
import styles from './RegistrationCardListItem.module.scss';
import moment from "moment";
import Row from "antd/lib/row";
import Skeleton from "antd/lib/skeleton";
import Group from "Assets/Icons/Group";

type Props = {
    date?: string,
    registrations?: Registration[],
    loading?: boolean;
}
const RegistrationCardListItem = (props: Props) => {
    
    const [ momentDate ] = useState<moment.Moment>(moment(props.date));
    
    const [ totalDuration, setTotalDuration ] = useState(0)
    
    useEffect(() => {
        if (props.registrations) {
            setTotalDuration(props.registrations?.map(x => x.duration ?? 0).reduce((a, b) => a + b));
        }
    }, [props.registrations])
    
    const registrations = props.registrations?.map((registration, index) =>
        <RegistrationCardListItemRow
            key={registration.id}
            registration={registration}
        />
    )
    
    return (
        <div className={`w-100 box padding15 ${styles.box}`}>
            <Skeleton active loading={props.loading}>
                <div className={`${styles.boxHeader} ${styles.paddingBottom}`}>
                    <div>
                        <div className={styles.day}>
                            {momentDate.format('dddd')}
                        </div>
                        <div className={styles.date}>
                            {momentDate.format('D. MMMM')}
                        </div>
                    </div>
                    <div className={`${styles.durationContainer}`}>
                        <Group style={{marginRight: 15}} />
                        <Duration duration={totalDuration} style={{color: '#123067', fontSize: 14, fontWeight: 600}} />
                    </div>
                </div>
                <div className={styles.registrationContainer}>
                    <Row align={"middle"} >
                        {registrations}
                    </Row>
                </div>
            </Skeleton>
        </div>
    )
}

export default RegistrationCardListItem;
