import React from "react";

const BillableActive = (props: React.SVGProps<SVGSVGElement>) => {
    
    return (
        <svg
            xmlns="http://www.w3.org/2000/svg"
            width={24.902}
            height={25}
            viewBox="0 0 24.902 25"
            {...props}
        >
            <g data-name="Group 341" fill="#c6c6c6">
                <path
                    data-name="Path 472"
                    d="M7.325 21.826v-.279a1.549 1.549 0 001.221-1.511v-.814A1.548 1.548 0 007 17.676h-.814a.082.082 0 01-.081-.081v-.814a.082.082 0 01.081-.081H7a.082.082 0 01.081.081.733.733 0 101.465 0 1.549 1.549 0 00-1.221-1.511v-.279a.733.733 0 00-1.465 0v.279a1.549 1.549 0 00-1.221 1.511v.814a1.548 1.548 0 001.546 1.546h.814a.082.082 0 01.081.081v.814a.082.082 0 01-.081.081h-.814a.082.082 0 01-.081-.081.733.733 0 10-1.465 0 1.549 1.549 0 001.221 1.511v.279a.733.733 0 001.465 0z"
                />
                <path
                    data-name="Path 473"
                    d="M24.688 5.1L19.805.215A.732.732 0 0019.287 0H8.057a2.2 2.2 0 00-2.2 2.2v9.66A6.592 6.592 0 006.592 25h16.113a2.2 2.2 0 002.2-2.2V5.615a.732.732 0 00-.215-.518zM20.02 2.5l1.191 1.191L22.4 4.883h-2.38zM1.465 18.408a5.127 5.127 0 115.127 5.127 5.133 5.133 0 01-5.127-5.127zm21.24 5.127H10.73a6.589 6.589 0 002.413-4.395h7.609a.733.733 0 100-1.465h-7.609a6.551 6.551 0 00-1.073-2.93h8.682a.733.733 0 100-1.465H10.73a6.562 6.562 0 00-3.406-1.424V2.2a.733.733 0 01.732-.732h10.5v4.15a.732.732 0 00.732.732h4.15V22.8a.733.733 0 01-.733.735z"
                />
                <path
                    data-name="Path 474"
                    d="M20.752 8.887H10.009a.733.733 0 000 1.465h10.742a.733.733 0 000-1.465z"
                />
            </g>
        </svg>
    )
}

export default BillableActive
