import * as React from "react"
import { SVGProps } from "react"

const CalendarIcon = (props: SVGProps<SVGSVGElement>) => (
    <svg xmlns="http://www.w3.org/2000/svg" width="1em" height="1em" {...props} viewBox="0 0 35.445 35.445">
        <g data-name="Group 967">
            <g data-name="Group 966">
                <path
                    data-name="Path 1152"
                    d="M31.647 3.8h-3.8V1.266a1.266 1.266 0 1 0-2.532 0V3.8H10.127V1.266a1.266 1.266 0 1 0-2.532 0V3.8H3.8A3.8 3.8 0 0 0 0 7.6v24.047a3.8 3.8 0 0 0 3.8 3.8h27.85a3.8 3.8 0 0 0 3.8-3.8V7.6a3.8 3.8 0 0 0-3.803-3.8Zm1.266 27.85a1.266 1.266 0 0 1-1.266 1.266H3.8a1.266 1.266 0 0 1-1.266-1.266V15.191h30.379Zm0-18.988H2.532V7.6A1.266 1.266 0 0 1 3.8 6.329h3.8v2.532a1.266 1.266 0 1 0 2.532 0V6.329h15.186v2.532a1.266 1.266 0 1 0 2.532 0V6.329h3.8A1.266 1.266 0 0 1 32.913 7.6v5.064Z"
                    fill={props.fill ?? "#bed5ff"}
                />
            </g>
        </g>
        <g data-name="Group 969">
            <g data-name="Group 968">
                <path
                    data-name="Path 1153"
                    d="M10.039 17.766H7.588a1.226 1.226 0 1 0 0 2.451h2.451a1.226 1.226 0 1 0 0-2.451Z"
                    fill={props.fill ?? "#bed5ff"}
                />
            </g>
        </g>
        <g data-name="Group 971">
            <g data-name="Group 970">
                <path
                    data-name="Path 1154"
                    d="M18.948 17.766h-2.451a1.226 1.226 0 1 0 0 2.451h2.451a1.226 1.226 0 1 0 0-2.451Z"
                    fill={props.fill ?? "#bed5ff"}
                />
            </g>
        </g>
        <g data-name="Group 973">
            <g data-name="Group 972">
                <path
                    data-name="Path 1155"
                    d="M27.856 17.766h-2.451a1.226 1.226 0 1 0 0 2.451h2.451a1.226 1.226 0 0 0 0-2.451Z"
                    fill={props.fill ?? "#bed5ff"}
                />
            </g>
        </g>
        <g data-name="Group 975">
            <g data-name="Group 974">
                <path
                    data-name="Path 1156"
                    d="M10.039 22.842H7.588a1.226 1.226 0 1 0 0 2.451h2.451a1.226 1.226 0 1 0 0-2.451Z"
                    fill={props.fill ?? "#bed5ff"}
                />
            </g>
        </g>
        <g data-name="Group 977">
            <g data-name="Group 976">
                <path
                    data-name="Path 1157"
                    d="M18.948 22.842h-2.451a1.226 1.226 0 1 0 0 2.451h2.451a1.226 1.226 0 1 0 0-2.451Z"
                    fill={props.fill ?? "#bed5ff"}
                />
            </g>
        </g>
        <g data-name="Group 979">
            <g data-name="Group 978">
                <path
                    data-name="Path 1158"
                    d="M27.856 22.842h-2.451a1.226 1.226 0 1 0 0 2.451h2.451a1.226 1.226 0 0 0 0-2.451Z"
                    fill={props.fill ?? "#bed5ff"}
                />
            </g>
        </g>
        <g data-name="Group 981">
            <g data-name="Group 980">
                <path
                    data-name="Path 1159"
                    d="M10.039 28.092H7.588a1.226 1.226 0 1 0 0 2.451h2.451a1.226 1.226 0 1 0 0-2.451Z"
                    fill={props.fill ?? "#bed5ff"}
                />
            </g>
        </g>
        <g data-name="Group 983">
            <g data-name="Group 982">
                <path
                    data-name="Path 1160"
                    d="M18.948 28.092h-2.451a1.226 1.226 0 0 0 0 2.451h2.451a1.226 1.226 0 1 0 0-2.451Z"
                    fill={props.fill ?? "#bed5ff"}
                />
            </g>
        </g>
        <g data-name="Group 985">
            <g data-name="Group 984">
                <path
                    data-name="Path 1161"
                    d="M27.856 28.092h-2.451a1.226 1.226 0 1 0 0 2.451h2.451a1.226 1.226 0 0 0 0-2.451Z"
                    fill={props.fill ?? "#bed5ff"}
                />
            </g>
        </g>
    </svg>
)

export default CalendarIcon
