import { useEffect } from 'react';
import { useLocation, useNavigate } from "react-router-dom";
import Spinner from "Components/Spinner";
import {useEconomicTokenCallback} from "Apis/EconomicApi";

const EconomicCallback = () => {

    const navigate = useNavigate();

    const {mutateAsync: sendTokenToServerMutation} = useEconomicTokenCallback();

    let token = new URLSearchParams(useLocation().search).get('token');


    useEffect(() => {
        if (token) {
            sendTokenToServerMutation(token).then(() => {
                navigate('/integrations/economic')
            });
        }
    }, [token, sendTokenToServerMutation, navigate])

    return (
        <Spinner />
    )
}

export default EconomicCallback
