import { CSSProperties } from "react";
import { useTranslation } from "react-i18next";
import { useTags } from "Apis/TagApi";
import Select from "antd/lib/select";

interface Props {
    value: number[];
    onChange: any;
    style?: CSSProperties;
    bordered?: boolean;
    className?: string;
    fullWidth?: boolean;
    placeholder?: string;
}
const TagSelect = (props: Props) => {
    
    const { t } = useTranslation();
    
    const { data: tags, isLoading: isLoadingTags } = useTags();
    
    const filterOption = (value, option) => {
        const { children } = option;
        
        if (option.options) {
            return option.options.includes((child) => child.props.children.toLowerCase().indexOf(value.toLowerCase()) >= 0);
        }
        
        return children.toLowerCase().indexOf(value.toLowerCase()) >= 0;
    };
    
    const selectProps = {
        allowClear: true,
        showSearch: true,
        filterOption: filterOption
    }
    
    return (
        <Select
            style={props.style}
            placeholder={props.placeholder ?? t('selectTags')}
            value={props.value}
            {...selectProps}
            onChange={(value) => {
                props.onChange(value);
            }}
            className={props.className}
            bordered={props.bordered}
            loading={isLoadingTags}
            mode={"multiple"}
        >
            {tags?.map((tag) => (
                <Select.Option value={tag.id} key={tag.id}>
                    {tag.text}
                </Select.Option>
            ))}
        </Select>
    )
}

export default TagSelect
