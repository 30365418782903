import { SVGProps } from "react";

const NoResultsIcon = (props: SVGProps<SVGSVGElement>) => {
    
    return (
        <svg xmlns="http://www.w3.org/2000/svg" width="130px" height="130px" {...props}>
            <defs>
                <clipPath id="a">
                    <circle
                        data-name="Ellipse 81"
                        cx={61.5}
                        cy={61.5}
                        r={61.5}
                        fill="#fff"
                        stroke="#707070"
                    />
                </clipPath>
            </defs>
            <g data-name="Group 685">
                <circle data-name="Ellipse 82" cx={61.5} cy={61.5} r={61.5} fill="#fff" />
                <g data-name="Mask Group 27" clipPath="url(#a)">
                    <g data-name="Group 683">
                        <g data-name="Layer 8">
                            <path
                                data-name="Path 703"
                                d="M-32.317 67.508s7.982-2.213 23.982.851c16.7 3.2 25.531-6.127 39.744-6.3 14.626-.175 32.738 7.106 43.684 5.276 8.17-1.366 16.144-6.027 27.7-5.851 11.05.169 18.716 6.721 27.084 7.595 4.894.511 11.564-2.255 11.564-2.255v55.846H-32.317Z"
                                fill="#c4d0ea"
                            />
                        </g>
                        <g data-name="Layer 5">
                            <path
                                data-name="Path 704"
                                d="M141.438 76.017v46.655H-32.317V77.891s3.848-2.437 12.407-2.638c7.234-.17 13.012 3.117 22.808.468 13.892-3.756 18.042-6.692 31.02-6.638 10.666.044 21.094 4.723 38.072 6.255 15.386 1.388 16.919-2.763 27.415-4.085 8.617-1.086 13.737 4.24 24.957 5.234 10.838.959 8.5.73 17.076-.47Z"
                                fill="#bbc7e0"
                            />
                        </g>
                        <g data-name="Layer 4">
                            <path
                                data-name="Path 705"
                                d="M-32.317 86.162s3.771.784 16.638-.176c11.283-.841 13.533-4.358 25.088-6.7 10.085-2.04 19.156 3.067 33.135 4.9 7.021.919 13.945-1.418 22.012-2.851 13.659-2.425 16.2 5.857 36.115.878 7.339-1.835 18.017-3.09 27.527.2 11.179 3.862 13.238 2.969 13.238 2.969v37.293H-32.317Z"
                                fill="#b0bdd8"
                            />
                        </g>
                        <g data-name="Layer 2">
                            <path
                                data-name="Path 706"
                                d="M-32.317 102.912a103.108 103.108 0 0 0 21.6.638c15.276-1.532 33.559-13.518 60.594-1.915 0 0 13.659 6.127 39.318-2.828 21.678-7.566 34.349-.835 39.074 1.109a47.529 47.529 0 0 0 13.169 3.634v19.124H-32.317Z"
                                fill="#a6b5d3"
                            />
                        </g>
                        <g data-name="Layer 1">
                            <path
                                data-name="Path 707"
                                d="M-32.317 114.272s29.6-9.851 50.534-4.1 25.709 4.547 36.343 3.492 33.318-7.814 41.017-7.821c17.957-.017 25.191 9.234 45.861 5.745v11.081H-32.317Z"
                                fill="#9aaac9"
                            />
                        </g>
                    </g>
                </g>
                <g data-name="Ellipse 83" fill="none" stroke="#e4e8f0" strokeWidth={5}>
                    <circle cx={61.5} cy={61.5} r={61.5} stroke="none" />
                    <circle cx={61.5} cy={61.5} r={59} />
                </g>
            </g>
        </svg>
    )
}

export default NoResultsIcon
