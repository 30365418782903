import { useEffect, useState } from "react";
import {useNavigate, useLocation} from "react-router-dom";

export type DashboardType = 'Grid' | 'WeekCalendar' | 'DayCalendar' | 'List'
export const DashboardTypeKey = 'DashboardTypeV1';

const Dashboard = () => {

    const navigate = useNavigate();
    const [query] = useState(new URLSearchParams(useLocation().search));

    useEffect(() => {
        let key = localStorage.getItem(DashboardTypeKey)

        if (!key) {
            key = 'List';
        }

        navigate(`/registrations/dashboard/${key}${query.toString() ? `?${query.toString()}` : ''}`)
    }, [navigate, query])

    return (
        <></>
    );
}

export default Dashboard;
