import { createContext, useEffect, useState } from "react";
import _ from "lodash";

type TimeSlotProps = {
    numberOfSlots: number,
    biggerTimeSlots: any,
    smallerTimeSlots: any,
}
export const TimeSlotContextProvider = createContext<TimeSlotProps>({
    numberOfSlots: 12,
    biggerTimeSlots: null,
    smallerTimeSlots: null
});

const NUMBER_OF_SLOTS_KEY = 'CALENDAR_NUMBER_OF_SLOTS';
const DEFAULT_NUMBER_OF_SLOTS = 12

const AVAILABLE_SLOTS = [
    2,
    3,
    6,
    12,
    24
]

export const TimeSlotProvider = ({children}) => {

    const [numberOfSlots, setNumberOfSlots] = useState<number>(DEFAULT_NUMBER_OF_SLOTS);
    
    useEffect(() => {
        let localNumberOfSlots = parseInt(localStorage.getItem(NUMBER_OF_SLOTS_KEY) ?? '');
        
        if (localNumberOfSlots) {
            setNumberOfSlots(localNumberOfSlots)
        }
        
    }, [])
    
    const setNumberOfSlotsInternal = (number: number) => {
        setNumberOfSlots(number);
        localStorage.setItem(NUMBER_OF_SLOTS_KEY, `${number}`);
    }

    const biggerTimeSlots = () => {
        const currentIndex = AVAILABLE_SLOTS.indexOf(numberOfSlots);
        
        const nextNumberOfSlLots = _.get(AVAILABLE_SLOTS, `${currentIndex + 1}`)
        
        if (nextNumberOfSlLots) {
            setNumberOfSlotsInternal(nextNumberOfSlLots);
        }
    }

    const smallerTimeSlots = () => {
        const currentIndex = AVAILABLE_SLOTS.indexOf(numberOfSlots);
    
        const nextNumberOfSlLots = _.get(AVAILABLE_SLOTS, `${currentIndex - 1}`)
    
        if (nextNumberOfSlLots) {
            setNumberOfSlotsInternal(nextNumberOfSlLots);
        }
    }

    return (
        <TimeSlotContextProvider.Provider value={{numberOfSlots, biggerTimeSlots, smallerTimeSlots}}>
            {children}
        </TimeSlotContextProvider.Provider>
    );
}
