import FlexRow from "Components/FlexRow";
import Spinner from "Components/Spinner";

const FullPageSpinner = () => {
    
    return (
        <div className={"w-100 flex column justify-center h-100"}>
            <FlexRow justify={'center'}>
                <Spinner size={"large"} />
            </FlexRow>
        </div>
    )
}

export default FullPageSpinner
