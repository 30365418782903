import { getInputClasses } from "Utils/FormUtils";
import { Controller, useForm } from "react-hook-form";
import TextArea from "antd/lib/input/TextArea";
import Button from "Components/Button/Button";
import { useEffect } from "react";
import { useTranslation } from "react-i18next";
import { DineroInvoiceEmailTemplateModel } from "Pages/Dinero/Api/Models/DineroInvoiceModel";
import { useInvoiceEmailTemplate, useSendInvoice } from "Pages/Dinero/Api/DineroInvoiceApi";
import { openSuccessNotification } from "Utils/NotificationUtils";
import Modal from "Components/Modal";
import Space from "Components/Space";

interface Props {
    invoiceGuid: string;
    visible: boolean;
    close: () => void;
}
const SendInvoiceModal = ({invoiceGuid, visible, close}: Props) => {
    
    const { t } = useTranslation();
    
    const { data: invoiceEmailTemplate } = useInvoiceEmailTemplate(invoiceGuid);
    
    const { mutateAsync: sendInvoiceMutation, isLoading: isSendingInvoice } = useSendInvoice();
    
    const { control, register, reset, handleSubmit, errors } = useForm<DineroInvoiceEmailTemplateModel>();
    
    useEffect(() => {
        if (invoiceEmailTemplate) {
            reset(invoiceEmailTemplate)
        }
    }, [ invoiceEmailTemplate, reset ])
    
    const sendInvoice = async(data) => {
        await sendInvoiceMutation({ guid: invoiceGuid, emailOptions: data })
        
        openSuccessNotification(t('success'), t('dinero:edit.invoiceSent'))
        close()
    }
    
    return (
        <Modal
            title={t('dinero:edit.sendInvoice')}
            visible={visible}
            onCancel={close}
        >
            <form onSubmit={handleSubmit(sendInvoice)}>
                <Space direction={"vertical"}>
                    <div>
                        <label>{t('dinero:edit.sender')}</label>
                        <input className={getInputClasses(errors.sender)} name="sender" placeholder={t('dinero:edit.sender')} ref={register({ required: true })}/>
                    </div>
                
                    <div>
                        <label>{t('dinero:edit.receiver')}</label>
                        <input className={getInputClasses(errors.receiver)} name="receiver" placeholder={t('dinero:edit.receiver')} ref={register({ required: true })}/>
                    </div>
                
                    <div>
                        <label>{t('dinero:edit.subject')}</label>
                        <input className={getInputClasses(errors.subject)} name="subject" placeholder={t('dinero:edit.subject')} ref={register({ required: true })}/>
                    </div>
                
                    <div>
                        <label>{t('dinero:edit.message')}</label>
                        <Controller
                            name={"message"}
                            control={control}
                            defaultValue={''}
                            render={(props) => (
                                <TextArea
                                    value={props.value}
                                    onChange={props.onChange}
                                    rows={5}
                                />
                            )}/>
                    </div>
                    
                    <Space>
                        <label>{t('dinero:edit.addVoucherAsPdfAttachment')}</label>
                        <Controller
                            name={"addVoucherAsPdfAttachment"}
                            control={control}
                            defaultValue={false}
                            render={(props) => (
                                <input
                                    type="checkbox"
                                    checked={props.value}
                                    onChange={props.onChange}
                                />
                            )}/>
                    </Space>
                
                    <div style={{display: 'flex', justifyContent: 'flex-end'}}>
                        <Space direction={"horizontal"}>
                            <Button onClick={() => close()}>{t('cancel')}</Button>
                            <Button loading={isSendingInvoice} color={"primary"} type={"submit"}>{t('dinero:edit.sendInvoice')}</Button>
                        </Space>
                    </div>
                </Space>
            </form>
        </Modal>
    )
}

export default SendInvoiceModal
