import * as React from "react"

function Group(props) {
    return (
      <svg width={20} height={20} {...props}>
          <g data-name="Group 664">
              <circle data-name="Ellipse 49" cx={10} cy={10} r={10} fill="#fff" />
              <g data-name="Group 663">
                  <g data-name="Group 662" fill="#0d60f8">
                      <path
                        data-name="Path 688"
                        d="M4.567 8.422l5.5 3.5a.5.5 0 00.537 0l5.5-3.5a.5.5 0 000-.843l-5.5-3.5a.5.5 0 00-.537 0l-5.5 3.5a.5.5 0 000 .843z"
                      />
                      <path
                        data-name="Path 689"
                        d="M16.104 11.578l-1.548-.986-3.414 2.173a1.5 1.5 0 01-1.609 0l-3.416-2.173-1.548.986a.5.5 0 000 .843l5.5 3.5a.5.5 0 00.537 0l5.5-3.5a.5.5 0 000-.843z"
                      />
                  </g>
              </g>
          </g>
      </svg>
    )
}

export default Group
