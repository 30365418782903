const TimeOpsLogoWhite = () => {
    
    return (
        <svg xmlns="http://www.w3.org/2000/svg" width="145.939" height="35.921" viewBox="0 0 145.939 35.921">
            <g id="TimeOps_-_Logo_-_Full_Size_-_White_-_Transparrent-04" data-name="TimeOps -_Logo - Full Size - White - Transparrent-04" transform="translate(-1.895 -1.755)">
                <path id="Path_2" data-name="Path 2" d="M448.571,70.67h-1.1a.649.649,0,0,1-.618-.618V68.775a4.144,4.144,0,0,1-.9-.379l-.539.539-.339.339a.661.661,0,0,1-.479.2.615.615,0,0,1-.459-.2l-.16-.16-.957-.957a.649.649,0,0,1-.2-.459.615.615,0,0,1,.2-.459c.16-.16.319-.339.5-.5l.379-.379a4.5,4.5,0,0,1-.379-.9h-1.3a.687.687,0,0,1-.459-.2.673.673,0,0,1-.18-.5V63.25a.657.657,0,0,1,.18-.479.641.641,0,0,1,.439-.2h1.276a5.453,5.453,0,0,1,.379-.9l-.539-.539-.339-.339a.649.649,0,0,1-.2-.459.619.619,0,0,1,.2-.479L444,58.842l.12-.12a.632.632,0,0,1,.459-.18.7.7,0,0,1,.479.2l.878.878a4.439,4.439,0,0,1,.9-.359V58.024a.638.638,0,0,1,.359-.6.753.753,0,0,1,.4-.08h1.516a.649.649,0,0,1,.618.618v1.3a5.748,5.748,0,0,1,.9.379l.878-.878a.649.649,0,0,1,.459-.2.578.578,0,0,1,.459.2c.379.379.758.738,1.117,1.117a.561.561,0,0,1,.18.459.615.615,0,0,1-.2.459l-.12.12-.758.758a9.343,9.343,0,0,1,.379.9H454.3a.649.649,0,0,1,.459.2.657.657,0,0,1,.18.479v1.536a.657.657,0,0,1-.18.479.566.566,0,0,1-.439.2h-1.3a5.042,5.042,0,0,1-.379.9l.878.878a.649.649,0,0,1,.2.459.619.619,0,0,1-.2.479c-.379.379-.738.758-1.117,1.117a.632.632,0,0,1-.459.18.7.7,0,0,1-.479-.2l-.878-.878a4.492,4.492,0,0,1-.9.379v1.237a.629.629,0,0,1-.319.578.771.771,0,0,1-.459.1h-.06C448.91,70.67,448.691,70.67,448.571,70.67Z" transform="translate(-351.996 -44.502)" fill="#fff"/>
                <path id="Path_3" data-name="Path 3" d="M571.489,49.2c1.257,1.576,1.875,3.909,1.875,7a12.069,12.069,0,0,1-1.1,5.305,8.124,8.124,0,0,1-7.659,4.807,13.985,13.985,0,0,1-3.53-.578v8.038h-5.066V54.347a26.438,26.438,0,0,0-.219-4.009,4.912,4.912,0,0,0-.9-2.134,9.16,9.16,0,0,1,2.034-.858,7.612,7.612,0,0,1,2.034-.3,1.5,1.5,0,0,1,1.157.479A3.285,3.285,0,0,1,560.8,49a6.792,6.792,0,0,1,5.126-2.154A6.629,6.629,0,0,1,571.489,49.2Zm-4.368,12.087a9.523,9.523,0,0,0,1.117-5.086,10.229,10.229,0,0,0-.818-4.667,2.747,2.747,0,0,0-2.593-1.5,4.662,4.662,0,0,0-2.114.519,4.742,4.742,0,0,0-1.616,1.257V62.4a6.829,6.829,0,0,0,2.812.618A3.508,3.508,0,0,0,567.121,61.288Z" transform="translate(-442.703 -36.097)" fill="#fff"/>
                <path id="Path_4" data-name="Path 4" d="M660.028,65.3a2.994,2.994,0,0,1-1.935-2.633,2.2,2.2,0,0,1,.658-1.616,2.854,2.854,0,0,1,1.935-.738,5.05,5.05,0,0,0,1.855,2.054,5.544,5.544,0,0,0,2.892.678c2.014,0,3.012-.758,3.012-2.254a1.5,1.5,0,0,0-.858-1.4,17.946,17.946,0,0,0-2.812-1.137,31.891,31.891,0,0,1-3.271-1.257,6.383,6.383,0,0,1-2.214-1.8,4.793,4.793,0,0,1-.917-3.052,4.206,4.206,0,0,1,1.017-2.812,6.382,6.382,0,0,1,2.673-1.855,10.1,10.1,0,0,1,3.61-.638,9.461,9.461,0,0,1,4.368.957,7.391,7.391,0,0,1,2.932,2.573,4.846,4.846,0,0,1-1.217,1.5,2.345,2.345,0,0,1-1.376.638,1.3,1.3,0,0,1-.578-.12,1.694,1.694,0,0,1-.479-.4,4.444,4.444,0,0,0-3.53-1.915,3.35,3.35,0,0,0-1.995.5,1.726,1.726,0,0,0-.7,1.5,1.8,1.8,0,0,0,.937,1.616,14.748,14.748,0,0,0,2.952,1.137,20.767,20.767,0,0,1,3.171,1.077,5.566,5.566,0,0,1,2.114,1.675,4.624,4.624,0,0,1,.878,2.932,5.132,5.132,0,0,1-2.034,4.368,9.557,9.557,0,0,1-5.824,1.516A12.479,12.479,0,0,1,660.028,65.3Z" transform="translate(-525.318 -36.104)" fill="#fff"/>
                <path id="Path_5" data-name="Path 5" d="M19.287,24.251a3.037,3.037,0,0,1-.379,1.715,1.377,1.377,0,0,1-1.2.539H13.124V46.689H7.918V26.525H1.895v-3.77H19.128A6.957,6.957,0,0,1,19.287,24.251Z" transform="translate(0 -16.812)" fill="#fff"/>
                <path id="Path_6" data-name="Path 6" d="M101.1,4.946V4.428a2.368,2.368,0,0,1,2.673-2.673h.479a2.368,2.368,0,0,1,2.673,2.673v.519a2.368,2.368,0,0,1-2.673,2.673h-.479A2.368,2.368,0,0,1,101.1,4.946Zm.379,6.123h5.066V29.878h-5.066Z" transform="translate(-79.414)" fill="#fff"/>
                <path id="Path_7" data-name="Path 7" d="M175.766,48.505a6.018,6.018,0,0,1,1.556,4.388V66.057h-5.086V52.993a4.03,4.03,0,0,0-.459-2.274,2.111,2.111,0,0,0-1.755-.6,4.069,4.069,0,0,0-3.291,1.715V66.077h-5.066v-13.4a3.561,3.561,0,0,0-.4-1.975,1.682,1.682,0,0,0-1.456-.539c-1.237,0-2.473.738-3.73,2.214V66.1h-5.066V54.03a24.189,24.189,0,0,0-.419-5.984,6.66,6.66,0,0,1,1.635-.638,6.862,6.862,0,0,1,1.815-.259,1.69,1.69,0,0,1,1.376.539,3.487,3.487,0,0,1,.6,1.8,7.8,7.8,0,0,1,2.573-1.935,7.192,7.192,0,0,1,2.972-.6,5.644,5.644,0,0,1,2.732.638,4.155,4.155,0,0,1,1.775,1.835,6.451,6.451,0,0,1,5.405-2.453A5.467,5.467,0,0,1,175.766,48.505Z" transform="translate(-119.042 -36.179)" fill="#fff"/>
                <path id="Path_8" data-name="Path 8" d="M320.948,57.426H308.841a7.475,7.475,0,0,0,1.257,4.029,3.356,3.356,0,0,0,2.812,1.436,3.658,3.658,0,0,0,1.316-.2,3.42,3.42,0,0,0,1.1-.738,16.226,16.226,0,0,0,1.5-1.735.81.81,0,0,1,.718-.279,3.575,3.575,0,0,1,1.416.4,10.323,10.323,0,0,1,1.8,1.117q-2.214,4.787-7.838,4.787a8.848,8.848,0,0,1-6.761-2.613,10.032,10.032,0,0,1-2.453-7.16,11.335,11.335,0,0,1,1.117-5.166,7.967,7.967,0,0,1,3.171-3.371,9.815,9.815,0,0,1,4.907-1.177,8.107,8.107,0,0,1,6.083,2.214,8.638,8.638,0,0,1,2.154,6.283C321.127,55.591,321.067,56.309,320.948,57.426ZM316,54.554c0-3.052-1.077-4.587-3.211-4.587a3.267,3.267,0,0,0-2.633,1.157,6.219,6.219,0,0,0-1.237,3.411H316Z" transform="translate(-241.606 -36.025)" fill="#fff"/>
                <path id="Path_9" data-name="Path 9" d="M430.463,28.27a1.088,1.088,0,0,0-.658-.219,1.125,1.125,0,0,0-.758.3,14.129,14.129,0,0,0-5.844-7,.436.436,0,0,0-.1-.06,15.264,15.264,0,0,0-1.975-1,13.8,13.8,0,0,0-5.026-1.037h-.339a13.683,13.683,0,0,0-2.393.2c-.16.02-.3.06-.459.08a.087.087,0,0,0-.06.02c-.1.02-.2.04-.279.06a5.348,5.348,0,0,0-.539.14c-.16.04-.339.1-.5.14a13.306,13.306,0,0,0-3.032,1.356,13.605,13.605,0,0,0-3.61,3.052,14.344,14.344,0,0,0-3.331,7.938,14.141,14.141,0,0,0,1.316,7.36,14.378,14.378,0,0,0,6.462,6.642,14.158,14.158,0,0,0,6.362,1.516h.04a1.058,1.058,0,0,0,.838-.4,1.1,1.1,0,0,0,.219-.957,1.016,1.016,0,0,0-.14-.339,1.117,1.117,0,0,0-.937-.5h0a11.771,11.771,0,0,1-4.627-.937,12.268,12.268,0,0,1-4.328-3.072,12.121,12.121,0,0,1-2.413-4.089,11.8,11.8,0,0,1-.419-6.442,12.117,12.117,0,0,1,4.208-6.9,11.979,11.979,0,0,1,6.8-2.673c.259-.02.539-.02.818-.02a12.085,12.085,0,0,1,11.09,7.3h-.12a1.087,1.087,0,0,0-.758.3.958.958,0,0,0-.319.778,1.1,1.1,0,0,0,.319.758c.379.379.738.758,1.117,1.137l.718.738a1.254,1.254,0,0,0,.917.4,1.389,1.389,0,0,0,.5-.1.435.435,0,0,0,.1-.06,1.258,1.258,0,0,0,.6-.718c.239-.658.459-1.316.7-1.975l.16-.459a1.021,1.021,0,0,0-.319-1.257Z" transform="translate(-319.907 -14.009)" fill="#fff"/>
                <path id="Path_10" data-name="Path 10" d="M448.337,58.315a1.286,1.286,0,0,1,.4-.06h1.436a.471.471,0,0,1,.439.439V60.13a4.545,4.545,0,0,1,1.117.459c.319-.339.658-.658.977-.977a.469.469,0,0,1,.658,0l1.137,1.137a.469.469,0,0,1,0,.658l-.977.977A6.081,6.081,0,0,1,454,63.5h1.416a.451.451,0,0,1,.439.479v1.536a.451.451,0,0,1-.439.479h-1.436a4.887,4.887,0,0,1-.459,1.117l.977.977a.469.469,0,0,1,0,.658l-1.137,1.137a.469.469,0,0,1-.658,0c-.339-.319-.658-.658-.977-.977a4.545,4.545,0,0,1-1.117.459v1.376a.494.494,0,0,1-.219.419.744.744,0,0,1-.419.06h-1.436a.472.472,0,0,1-.439-.439V69.344a5.48,5.48,0,0,1-1.117-.459c-.319.339-.658.658-.977.977a.469.469,0,0,1-.658,0c-.379-.379-.758-.738-1.117-1.117a.469.469,0,0,1,0-.658c.319-.339.658-.658.977-1a4.546,4.546,0,0,1-.459-1.117h-1.416a.451.451,0,0,1-.439-.479V63.959a.451.451,0,0,1,.439-.479h1.416a5.651,5.651,0,0,1,.459-1.137c-.339-.319-.658-.658-.977-.977a.469.469,0,0,1,0-.658l1.117-1.117a.469.469,0,0,1,.658,0c.339.319.658.658.977.977a4.248,4.248,0,0,1,1.117-.459V58.734A.4.4,0,0,1,448.337,58.315Z" transform="translate(-353.039 -45.231)" fill="#fff"/>
                <path id="Path_11" data-name="Path 11" d="M470.613,39.946a.6.6,0,0,0,.6-.6V37.912a.622.622,0,0,0-.319-.479.562.562,0,0,0-.439-.06,1.377,1.377,0,0,0-.16.06.569.569,0,0,0-.3.379c-.04.519-.02,1.017-.02,1.536a.609.609,0,0,0,.638.6Z" transform="translate(-374.717 -28.498)" fill="#fff"/>
                <path id="Path_12" data-name="Path 12" d="M445.624,46.246a.642.642,0,0,0,.838.239.605.605,0,0,0,.239-.838c-.219-.4-.459-.778-.678-1.177a.7.7,0,0,0-.459-.319.818.818,0,0,0-.219.02.612.612,0,0,0-.419.878C445.165,45.448,445.385,45.847,445.624,46.246Z" transform="translate(-354.614 -33.94)" fill="#fff"/>
                <path id="Path_13" data-name="Path 13" d="M492.022,46.4a.621.621,0,0,0,.878-.18c.219-.379.459-.778.678-1.177a.619.619,0,0,0,.08-.459.37.37,0,0,0-.04-.14.616.616,0,0,0-.678-.339c-.02,0-.02,0-.04.02a.588.588,0,0,0-.4.319l-.658,1.137A.608.608,0,0,0,492.022,46.4Z" transform="translate(-392.157 -33.894)" fill="#fff"/>
                <path id="Path_14" data-name="Path 14" d="M426.96,63.709c.379.219.778.459,1.177.678a.635.635,0,0,0,.838-.219.609.609,0,0,0-.18-.838c-.339-.2-.7-.4-1.037-.6a.949.949,0,0,0-.5-.18.253.253,0,0,0-.12.02.594.594,0,0,0-.459.539.564.564,0,0,0,.08.359.388.388,0,0,0,.2.239Z" transform="translate(-340.058 -48.67)" fill="#fff"/>
                <path id="Path_15" data-name="Path 15" d="M508.56,64.287c.379-.219.778-.439,1.177-.678a.481.481,0,0,0,.2-.2.639.639,0,0,0,.06-.539.623.623,0,0,0-.6-.419.439.439,0,0,0-.16.02c-.239.1-.459.259-.7.379-.219.14-.459.259-.678.4a.626.626,0,0,0,.7,1.037Z" transform="translate(-404.845 -48.59)" fill="#fff"/>
                <path id="Path_16" data-name="Path 16" d="M422.361,88.144a.619.619,0,0,0-.678-.519c-.5.02-.977-.04-1.476.02h0a.628.628,0,0,0-.379.818.613.613,0,0,0,.419.379.439.439,0,0,0,.16.02h1.376a.615.615,0,0,0,.578-.718Z" transform="translate(-334.542 -68.737)" fill="#fff"/>
                <path id="Path_17" data-name="Path 17" d="M515.6,87.567c-.04,0-.08-.02-.12-.02h-1.217a.933.933,0,0,0-.339.04.636.636,0,0,0-.319.937.688.688,0,0,0,.658.279h1.276a.608.608,0,0,0,.539-.618A.619.619,0,0,0,515.6,87.567Z" transform="translate(-409.566 -68.679)" fill="#fff"/>
                <path id="Path_18" data-name="Path 18" d="M428.066,109.44c-.419.219-.818.459-1.217.7h-.02a.62.62,0,0,0,.578,1.1l.12-.06c.359-.2.718-.419,1.077-.618a.631.631,0,0,0,.259-.778.586.586,0,0,0-.8-.339Z" transform="translate(-339.928 -86.165)" fill="#fff"/>
                <path id="Path_19" data-name="Path 19" d="M509.713,110.057c-.04-.02-.06-.04-.1-.06-.359-.219-.718-.419-1.077-.618a.639.639,0,0,0-.539-.06.625.625,0,0,0-.18,1.057c.319.219.658.4,1,.6a.417.417,0,0,1,.12.08.835.835,0,0,0,.419.16.6.6,0,0,0,.618-.459A.582.582,0,0,0,509.713,110.057Z" transform="translate(-404.841 -86.083)" fill="#fff"/>
                <path id="Path_20" data-name="Path 20" d="M445.948,125.3a.638.638,0,0,0-.379.339c-.18.319-.359.618-.539.917-.04.06-.08.14-.12.2a.612.612,0,0,0,.239.818.655.655,0,0,0,.838-.2c.04-.08.08-.14.12-.219.18-.319.359-.638.558-.937a.634.634,0,0,0,0-.7.625.625,0,0,0-.718-.219Z" transform="translate(-354.599 -98.877)" fill="#fff"/>
                <path id="Path_21" data-name="Path 21" d="M493.508,126.477c-.18-.339-.379-.658-.578-1a.635.635,0,0,0-.738-.259.606.606,0,0,0-.4.6.979.979,0,0,0,.2.5c.16.259.3.519.459.778a.824.824,0,0,0,.14.219.614.614,0,0,0,.858.16.629.629,0,0,0,.2-.818A.318.318,0,0,1,493.508,126.477Z" transform="translate(-392.186 -98.813)" fill="#fff"/>
                <path id="Path_22" data-name="Path 22" d="M470.494,131.119a.614.614,0,0,0-.5.578v1.4a.639.639,0,0,0,.638.618.631.631,0,0,0,.618-.6v-1.4a.652.652,0,0,0-.758-.6Z" transform="translate(-374.737 -103.555)" fill="#fff"/>
            </g>
        </svg>
    )
}

export default TimeOpsLogoWhite
