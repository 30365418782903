interface Props {
    text: string | undefined;
    theme?: 'Light' | 'Dark',
    style?: any
}
const Header = (props: Props) => {

    let styles = {
        fontSize: '2em',
        color: '#485059',
        fontWeight: 600,
        display: 'flex',
        marginBottom: '10px',
        ...props.style,
    }

    return (
        <span style={styles}>
            {props.text}
        </span>
    )
}

export default Header;
