import styles from "Components/InputV2/ControlledInput.module.scss";
import _ from "lodash";
import React, { CSSProperties, Ref } from "react";

interface Props {
    value?: string | number;
    placeholder: string;
    name?: string
    errors?: any,
    containerStyle?: CSSProperties;
    inputStyle?: CSSProperties;
    number?: boolean;
    inputRef?: Ref<HTMLInputElement>;
    suffix?: string;
    rules?: any,
    onChange?: (value: string) => void;
    onKeyDown?: (e: React.KeyboardEvent<HTMLInputElement>) => void;
    type?: 'text' | 'password' | 'number';
    onChangeUseEvent?: boolean;
}
const InputV2 = (props: Props) => {
    
    const onChange = (newValue) => {
        if (props.onChange) {
            props.onChange(newValue);
        }
    }
    
    return (
        <div className={`${styles.container} ${styles.active} ${props.name && _.get(props.errors, props.name) ? styles.error : ''}`} style={props.containerStyle}>
            <label className={`${styles.label} text-ellipsis ${styles.fieldActive}`}>
                {props.placeholder}
            </label>
            <input
                className={`${styles.input} ${styles.floatingLabel}`}
                onKeyDown={props.onKeyDown}
                onChange={(value) => {
                    if (props.onChangeUseEvent) {
                        onChange(value);
                    } else if (props.number) {
                        onChange(value.currentTarget.value.replace(',', '.'));
                    } else {
                        onChange(value.currentTarget.value);
                    }
                }}
                value={props.value}
                type={props.type}
                style={props.inputStyle}
                ref={props.inputRef}
            />
            {props.suffix && (
                <div className={styles.suffix}>
                    {props.suffix}
                </div>
            )}
        </div>
    )
}

export default InputV2
