import { Link, useMatch } from "react-router-dom";
import { useState } from "react";
import styles from 'Navigation/MenuLink.module.scss';

type Props = {
    to: string;
    icon: any;
    children: any;
    onClick?: any;
    subLinks?: any[];
    enableActive?: boolean;
    exact?: boolean;
}
const MenuLink = ({to, icon, children, onClick, subLinks, enableActive = true, exact = false}: Props) => {

    const [submenuOpen, setSubmenuOpen] = useState<boolean>(false)
    
    let match = useMatch({ path: to, end: exact});

    return (
        <div onClick={() => setSubmenuOpen(true)} className={styles.container}>
            <Link to={to} onClick={onClick}>
                <div className={match && enableActive ? `${styles.menuLink} ${styles.active}`: styles.menuLink}>
                    <div className={styles.menuLinkIcon}>
                        {icon}
                    </div>
                    <div className={styles.menuLinkContent}>
                        {children}
                    </div>
                </div>
            </Link>
            {subLinks && (
                <div className={styles.submenu} style={match || (match && submenuOpen) ? {maxHeight: 35 * subLinks?.length} : {}}>
                    {subLinks?.map(link => link)}
                </div>
            )}
        </div>
    )
}

export default MenuLink;
