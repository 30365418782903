import Empty from "Components/Empty";
import { useEffect, useState } from "react";
import { useOwnRegistrations } from "Apis/RegistrationsApi";
import RegistrationCard from "./RegistrationCard";
import Header from "Components/Header";
import moment from "moment";
import {DashboardTypeKey} from "Pages/Registrations/Dashboard";
import {useTranslation} from "react-i18next";
import Button from "Components/Button/Button";
import styles from './RegistrationCardGrid.module.scss';

const RegistrationCardGrid = () => {

    const { t } = useTranslation();
    
    const [cards, setCards] = useState<React.ReactElement[]>();
    
    const {data: ownRegistrations, fetchNextPage, isLoading, isFetchingNextPage, hasNextPage} = useOwnRegistrations();
    
    useEffect(() => {
        localStorage.setItem(DashboardTypeKey, 'Grid');
    }, [])
    
    useEffect(() => {
        if (ownRegistrations) {
            const newCards:any[] = [];
        
            const groupedBydate = []
        
            ownRegistrations?.pages.forEach(group => {
                group.registrationList.forEach(x => {
                    const startedAt = moment(x.startedAt).startOf('date').toISOString()
                
                    if (!groupedBydate[startedAt]) {
                        groupedBydate[startedAt] = [];
                    }
                
                    groupedBydate[startedAt].push(x)
                })
            })
        
            const groupedByMonth: any = [];
        
            Object.keys(groupedBydate).forEach(group => {
                const month = moment(group).startOf('month').toISOString().toString();
            
                if (!groupedByMonth[month]) {
                    groupedByMonth[month] = [];
                }
            
                groupedByMonth[month].push(group)
            })
        
            for (let month in groupedByMonth) {
                if (groupedByMonth.hasOwnProperty(month)) {
                    let cards: any[] = [];
                    let date;
                
                    groupedByMonth[month].forEach(dateString => {
                        date = new Date(dateString);
                        cards.push(
                            <RegistrationCard key={dateString} date={date} registrations={groupedBydate[dateString]} />
                        );
                    });
                
                    if (date) {
                        newCards.push((
                            <div key={month} style={{width: '100%', marginBottom: 30}}>
                                <Header text={moment(date).format('MMMM')}/>
                                <div className={styles.grid}>
                                    {cards}
                                </div>
                            </div>
                        ))
                    }
                }
            }
        
            setCards(newCards);
        } else {
            setCards([])
        }
    }, [ownRegistrations])
    
    return (
        <>
            <div className={"w-100"}>
                {isLoading && (
                    <div style={{width: '100%'}}>
                        <div>
                            <Header text={t('loading')}/>
                        </div>
                        <div className={styles.grid}>
                            <RegistrationCard loading />
                            <RegistrationCard loading />
                            <RegistrationCard loading />
                            <RegistrationCard loading />
                        </div>
                    </div>
                )}
                {!isLoading && cards?.length === 0 && (
                    <div className="box white dont-hover padding30">
                        <Empty children={t('registrations:noRegistrations')}/>
                    </div>
                )}
                
                {cards}
    
                {hasNextPage && (
                    <div className={"flex justify-center w-100 padding30"}>
                        <Button loading={isFetchingNextPage} onClick={fetchNextPage}>{t('registrations:loadMore')}</Button>
                    </div>
                )}
            </div>
        </>
    )
}

export default RegistrationCardGrid;
