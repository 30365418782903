import { useState, useEffect } from "react";
import * as React from "react";
import { auth } from "FirebaseSetup/auth";
import { Link, Navigate, useNavigate, useLocation } from "react-router-dom";
import Button from "../../Components/Button/Button";
import {useTranslation} from "react-i18next";
import Layout from "Pages/Auth/Components/Layout";
import ControlledInput from "Components/InputV2/ControlledInput";
import { useForm } from "react-hook-form";
import styles from './Components/Layout.module.scss';
import { openErrorNotification, openSuccessNotification } from "Utils/NotificationUtils";
import PageLoader from "Components/PageLoader";
import Space from "Components/Space";

interface ResetPasswordFormFields {
    password: string;
}

const AuthActionsPage = () => {

    const { t } = useTranslation();
    const navigate = useNavigate();
    
    let query = new URLSearchParams(useLocation().search);
    let mode = query.get('mode');
    let oobCode = query.get('oobCode') ?? '';

    
    const { control, handleSubmit, errors } = useForm<ResetPasswordFormFields>();
    
    const [codeValidated, setCodeValidated] = useState(false);
    const [loading, setLoading] = useState(false);
    const [resettingPassword, setResettingPassword] = useState(false);
    const [validatingCodeFailed, setValidatingCodeFailed] = useState(false);
    const [error, setError] = useState('');

    useEffect(() => {

        if (mode === 'resetPassword' && !codeValidated) {
            setLoading(true);

            auth.verifyPasswordResetCode(oobCode).then(() => {
                setCodeValidated(true);
            }).catch((e) => {
                setValidatingCodeFailed(c => true);
            }).finally(() => {
                setLoading(false);
            })

        } else if (mode === 'verifyEmail') {
            setLoading(true);

            auth.applyActionCode(oobCode).then(() => {
                setError(t('auth:emailVerified'))
            }).catch(e => {
                setError(t('auth:unexpectedError'));
            }).finally(() => {
                setLoading(false)
            })
        }
    }, [mode, oobCode, codeValidated, t]);
    
    const gotoFrontPage = () => {
        navigate('/')
    }

    const resetPassword = (data: ResetPasswordFormFields) => {
        setError('');
        setResettingPassword(true)

        auth.confirmPasswordReset(oobCode, data.password).then(
            () => {
                setError(t('auth:passwordUpdated'));
                openSuccessNotification(t('success'), t('auth:passwordUpdated'), 'topLeft');
            },
            (e) => {
                let error;
                
                if (e.code === 'auth/weak-password') {
                    error = t('auth:weekPassword');
                }
                if (e.code === 'auth/network-request-failed') {
                    error = t('auth:unexpectedError');
                }
    
                openErrorNotification(t('errors:anErrorOccurred'), error, 'topLeft');
            }
        ).catch((e) => {
            let error;
    
            if (e.code === 'auth/weak-password') {
                error = t('auth:weekPassword');
            }
            if (e.code === 'auth/network-request-failed') {
                error = t('auth:unexpectedError');
            }
    
            openErrorNotification(t('errors:anErrorOccurred'), error, 'topLeft');
        }).finally(() => setResettingPassword(false))
    };

    if (loading) {
        return (
            <PageLoader shown={true} children={null} />
        )
    }

    if (!validatingCodeFailed) {

        if (mode === 'resetPassword') {
            return (
                <Layout
                    preHeader={''}
                    header={t('auth:resetPassword')}
                    imgUrl={"/images/appMockupV2.png"}
                >
                    {error ? (
                        <Space direction={"vertical"} size={"large"}>
                            <span>{error}</span>
                            <Button block color={"primary"} onClick={gotoFrontPage}>
                                <span style={{fontSize: '1.5em', fontWeight: 600}}>
                                    {t('auth:gotoFrontPage')}
                                </span>
                            </Button>
                        </Space>
                    ) : (
                        <>
                            <form onSubmit={handleSubmit(resetPassword)}>
                                
                                <span className={styles.errorMessage}>
                                    {error}
                                </span>
                    
                                <Space size="large" direction="vertical">
                        
                                    <ControlledInput name="password" control={control} placeholder={t('auth:newPassword')} type="password" errors={errors} rules={{required: true, minLength: 6}} />
                        
                                    <Button type="submit" block color='primary' loading={resettingPassword}><span style={{fontSize: '1.5em', fontWeight: 600}}>{t('auth:updatePassword')}</span></Button>
                        
                                    <div className={styles.link}>{t('auth:alreadyHaveAnAccount')} <Link to={"/auth/login"}>{t('auth:login')}</Link></div>
                                </Space>
                            </form>
                        </>
                    )}
                </Layout>
            )
        }

        if (mode === 'verifyEmail') {
            return (
                <Layout
                    preHeader={''}
                    header={t('auth:emailVerified')}
                    imgUrl={"/images/appMockupV2.png"}
                >
                    <Button block color={"primary"} onClick={gotoFrontPage}>
                        <span style={{fontSize: '1.5em', fontWeight: 600}}>
                            {t('auth:gotoFrontPage')}
                        </span>
                    </Button>
                </Layout>
            )
        }
    }
    
    return (
        <Navigate to={'/'} />
    )
}

export default AuthActionsPage;
