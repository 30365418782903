import {Project} from "./Project.type";
import { User } from "Apis/Models/User.type";

export interface Registration {
    id?: number;
    description?: string;
    projectId?: number;
    startedAt?: Date | null;
    stoppedAt?: Date | null;
    project?: Project | null,
    duration?: number,
    userId?: number;
    billable?: boolean;
    tags?: number[];
    user?: User;
}

export interface SortedRegistrations {
    from: Date,
    to: Date,
    registrations: RegistrationsByDate
    registrationList: Registration[]
}

export interface RegistrationsByDate {
    [key: string]: Registration[];
}

export const deserialize = (registration: Registration): Registration => {
    const startedAt = registration?.startedAt ? new Date(registration.startedAt) : null;
    const stoppedAt = registration?.stoppedAt ? new Date(registration.stoppedAt) : null;

    return {...registration, startedAt, stoppedAt}
}
