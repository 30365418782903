import styles from './ReportGroupListItem.module.scss';
import GroupsIcon from "Assets/Icons/GroupsIcon";
import { GroupedRegistrationsModel } from "Apis/Models/ReportModel";
import { useTranslation } from "react-i18next";
import FlexRow from "Components/FlexRow";
import Duration from 'Components/Duration';
import moment from "moment";
import { getNumberAsCurrency } from "Utils/NumberUtils";
import StopWatchIcon from "Assets/Icons/StopWatchIcon";import MoneyBagIcon from "Assets/Icons/MoneyBagIcon";
import {useAuthProvider} from "../../../Providers/AuthProvider";
import RequiresPremiumModal from 'Components/RequiresPremiumModal';
import {Registration} from "../../../Apis/Models/Registration.type";

interface Props {
    group: GroupedRegistrationsModel;
    open: boolean;
    onClick: () => void;
    itemOnClick: (item: Registration) => void;
    timeFormat: 'Hours' | 'Decimal';
}
const ReportGroupListItem = (props: Props) => {
    
    const { t } = useTranslation();

    const { userContext } = useAuthProvider();

    return (
        <div className={`${styles.container} ${props.open ? styles.open : ''}`} onClick={props.onClick}>
            <div className={styles.header} onClick={props.onClick}>
                <FlexRow style={{gap: 10}} justify={"start"}>
                    <div style={{width: 40, height: 40}}>
                        <GroupsIcon width={40} height={40} fill={props.open ? '#0D60F8' : '#CCD2DD'} />
                    </div>
                    <FlexRow style={{width: 'fit-content'}} direction={"column"} align={"start"}>
                        <div className={styles.label}>
                            {props.group.subLabel}
                        </div>
                        <div className={styles.groupLabel} style={{color: props.open ? '#0D60F8' : '#4D576D'}}>
                            {props.group.label ? props.group.label :  t('report:noLabel')}
                        </div>
                    </FlexRow>
                </FlexRow>
                <FlexRow direction={"column"} align={"start"}>
                    <div className={styles.label}>
                        {t('report:groupCount')}
                    </div>
                    <div className={styles.p}>
                        {props.group.registrations.length}
                    </div>
                </FlexRow>
                <div>
                    <FlexRow className={"h-100"} align={"start"} justify={"space-between"}>
                        <FlexRow direction={"column"} align={"start"} justify={"center"}>
                            <div className={styles.label}>
                                {t('report:totalBillable')}
                            </div>
                            <FlexRow justify={"start"} align={"center"}>
                                <MoneyBagIcon height={15} width={15} fill={"#0D60F8"} style={{marginRight: 5}} />
                                <span style={{lineHeight: '16px', fontSize: 16, fontWeight: 800, color: '#0D60F8'}}>
                                    <RequiresPremiumModal enabled={!userContext?.hasActiveLicense} title={t('registrations:billableRates')}>
                                        {(userContext?.hasActiveLicense && userContext?.accessLevel === 200) ? (getNumberAsCurrency(props.group.registrations.map(x => x.totalBillable).reduce((a, b) => a + b, 0))) : '-'}
                                    </RequiresPremiumModal>
                                </span>
                            </FlexRow>
                        </FlexRow>
                        <FlexRow direction={"column"} align={"start"} justify={"center"}>
                            <div className={styles.label}>
                                {t('report:totalTime')}
                            </div>
                            <FlexRow justify={"start"}>
                                <StopWatchIcon height={15} width={15} style={{marginRight: 5}} />
                                <Duration style={{lineHeight: '16px', fontSize: 16, fontWeight: 800, color: '#0D60F8'}} duration={props.group.totalDuration} timeType={props.timeFormat} />
                            </FlexRow>
                        </FlexRow>
                    </FlexRow>
                </div>
            </div>
            <div className={`${styles.table} ${props.open ? styles.open: ''}`}>
                <div className={`${styles.tr} ${styles.tHead}`}>
                    <div className={styles.th}>
                    
                    </div>
                    <div className={styles.th}>
                        {t('report:date')}
                    </div>
                    <div className={styles.th}>
                        {t('report:customer')}
                    </div>
                    <div className={styles.th}>
                        {t('report:project')}
                    </div>
                    <div className={styles.th}>
                        {t('report:description')}
                    </div>
                    <div className={styles.th}>
                        {t('report:user')}
                    </div>
                    <div className={styles.th}>
                        {t('report:startStop')}
                    </div>
                    <div className={styles.th}>
                        {t('report:total')}
                    </div>
                </div>
                
                {props.group.registrations.map(x =>
                    <div key={x.id} className={styles.tr} onClick={() => props.itemOnClick(x)}>
                        <div className={styles.td}>
                        
                        </div>
                        <div className={styles.td}>
                            {moment(x.startedAt).format('L')}
                        </div>
                        <div className={styles.td}>
                            {x.project?.customer?.name}
                        </div>
                        <div className={styles.td}>
                            {x.project?.name}
                        </div>
                        <div className={styles.td}>
                            {x.description}
                        </div>
                        <div className={styles.td}>
                            {x.user?.name ?? x.user?.eMail}
                        </div>
                        <div className={styles.td}>
                            {moment(x.startedAt).format('HH:mm')} - {moment(x.stoppedAt).format('HH:mm')}
                        </div>
                        <div className={styles.td}>
                            <FlexRow justify={"end"}>
                                <Duration style={{fontWeight: 600}} duration={x.duration} timeType={props.timeFormat} />
                            </FlexRow>
                        </div>
                    </div>
                )}
            </div>
        </div>
    )
}

export default ReportGroupListItem
