import styles from './Option.module.scss';
import { useContext } from "react";
import { SelectV2Context } from "Components/SelectV2/SelectV2";
import CheckmarkIcon from "Assets/Icons/CheckmarkIcon";

interface Props {
    value: any;
    label: string;
}

const Option = ({label, value}: Props) => {
    
    const context = useContext(SelectV2Context);
    
    const isSelected = context.isValueSelected(value);
    
    const onChange = () => {
        context.onChange(value, !isSelected);
    }
    
    return (
        <div className={`${styles.label} ${(isSelected || context.focusedKey === value) && styles.selected}`} onClick={onChange}>
            {label}
            {isSelected && (
                <CheckmarkIcon />
            )}
        </div>
    )
}

Option.displayName = 'Option'

export default Option
