import * as React from "react"
import { SVGProps } from "react"
const PaperPDFIcon = (props: SVGProps<SVGSVGElement>) => (
    <svg
        xmlns="http://www.w3.org/2000/svg"
        width={16}
        height={16}
        viewBox={"0 0 16 16"}
        {...props}
    >
        <path
            fill={props.fill ?? "#4d576d"}
            d="M3.846 6.768H2.818a.486.486 0 0 0-.486.487l.007 3.69a.486.486 0 1 0 .972 0v-1.12h.535a1.528 1.528 0 1 0 0-3.055Zm0 2.084h-.538V7.738h.541a.556.556 0 1 1 0 1.112Z"
            data-name="Path 1217"
        />
        <path
            fill={props.fill ?? "#4d576d"}
            d="M7.977 6.768H6.962a.486.486 0 0 0-.486.487s.007 3.57.007 3.583a.486.486 0 0 0 .486.484c.031 0 .754 0 1.044-.008a2.059 2.059 0 0 0 1.894-2.269 2.011 2.011 0 0 0-1.93-2.277Zm.021 3.575-.544.005V7.74h.528c.892 0 .961 1 .961 1.305-.005.638-.295 1.286-.944 1.298Z"
            data-name="Path 1218"
        />
        <path
            fill={props.fill ?? "#4d576d"}
            d="M13.111 7.703a.486.486 0 0 0 0-.972h-1.485a.486.486 0 0 0-.486.486v3.665a.486.486 0 1 0 .972 0V9.501h.882a.486.486 0 1 0 0-.972h-.882v-.826Z"
            data-name="Path 1219"
        />
        <path
            fill={props.fill ?? "#4d576d"}
            d="M14.541 4.7h-.356v-.143a2.419 2.419 0 0 0-.666-1.671L11.5.758A2.439 2.439 0 0 0 9.738 0H3.271a1.459 1.459 0 0 0-1.457 1.457V4.7h-.356A1.459 1.459 0 0 0 0 6.153v5.829a1.459 1.459 0 0 0 1.457 1.457h.356v1.684A1.459 1.459 0 0 0 3.27 16.58h9.456a1.459 1.459 0 0 0 1.457-1.457V13.44h.356A1.459 1.459 0 0 0 16 11.982V6.153A1.459 1.459 0 0 0 14.541 4.7ZM2.785 1.457a.486.486 0 0 1 .486-.486h6.467a1.463 1.463 0 0 1 1.058.455l2.018 2.128a1.451 1.451 0 0 1 .4 1V4.7H2.785Zm10.428 13.667a.486.486 0 0 1-.486.486H3.271a.486.486 0 0 1-.486-.486V13.44h10.428Zm1.814-3.141a.486.486 0 0 1-.486.486H1.457a.486.486 0 0 1-.486-.486v-5.83a.486.486 0 0 1 .486-.486h13.084a.486.486 0 0 1 .486.486Z"
            data-name="Path 1220"
        />
    </svg>
)
export default PaperPDFIcon
