import { Navigate } from "react-router-dom";
import { useAuthProvider } from "Providers/AuthProvider";

const RedirectIfLoggedIn = ({ children }) => {

    const user = useAuthProvider();
    
    return user.firebaseUser === null ? children : <Navigate to={"/"} />;
}

export default RedirectIfLoggedIn;
