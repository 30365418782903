import * as React from "react"
import { SVGProps } from "react"

const CaretLeftIcon = (props: SVGProps<SVGSVGElement>) => (

    <svg xmlns="http://www.w3.org/2000/svg" width="1em" height="1em" viewBox="0 0 20.201 20.2" {...props}>
        <path
            data-name="Path 1064"
            d="M9.393 19.494-.001 10.1 9.395.705a1 1 0 0 1 1.414 1.415l-7.98 7.979 7.98 7.979a1 1 0 0 1-1.414 1.414Z"
            fill={props.fill ?? "#E4E8F0"}
        />
    </svg>
)

export default CaretLeftIcon
