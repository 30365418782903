import CustomerSelector from "Components/Integrations/Economic/CustomerSelector";
import Header from "Components/Header";
import Button from "Components/Button/Button";
import {useStartEconomicIntegration} from "Apis/EconomicApi";
import {useTranslation} from "react-i18next";
import PremiumTrialAnnouncement from "Components/PremiumTrialAnnouncement";

const EconomicIntegrationSettings = () => {

    const { t } = useTranslation();
    const { mutateAsync: startIntegrationMutation, isLoading: isStartingIntegration} = useStartEconomicIntegration();

    const startIntegrationHandler = async () => {
        await startIntegrationMutation()
    }
    
    return (
        <div>
            <Header text={t('economic:economic')} />
            <PremiumTrialAnnouncement>
                {t('premiumAnnouncements.integrationsIsAPartOfTimeOpsPremium')}
            </PremiumTrialAnnouncement>
            <div className={"box white padding30 dont-hover"}>
                <CustomerSelector />
                <div className={"flex justify-end"}>
                    <Button
                        loading={isStartingIntegration}
                        disabled={isStartingIntegration}
                        onClick={startIntegrationHandler} color={"primary"}>{t('economic:settings.start')}</Button>
                </div>
            </div>
        </div>
    )
}

export default EconomicIntegrationSettings
