import { Fragment } from "react";
import {hasAccess} from "Utils/AccessUtils";
import {Navigate} from "react-router-dom";
import { useAuthProvider } from "Providers/AuthProvider";

const AccessLevelRoute = ({requiredAccessLevel, children}) => {

    const { userContext } = useAuthProvider();

    if (!userContext) {
        return <Fragment />;
    }

    if (!hasAccess(requiredAccessLevel, userContext)) {
        return (
            <Navigate to={"/registrations/dashboard"} />
        )
    }

    return children;
}

export default AccessLevelRoute;
