import * as React from "react"
import { SVGProps } from "react"

const CaretRightIcon = (props: SVGProps<SVGSVGElement>) => (
    <svg xmlns="http://www.w3.org/2000/svg" width="1em" height="1em" viewBox="0 0 20.201 20.2" {...props}>
        <path
            data-name="Path 1179"
            d="m10.807.707 9.394 9.393-9.396 9.396a1 1 0 0 1-1.414-1.415l7.98-7.979-7.98-7.979A1 1 0 0 1 10.805.71Z"
            fill={props.fill ?? "#e4e8f0"}
        />
    </svg>
)

export default CaretRightIcon
