import Spin from "antd/lib/spin";
import {LoadingOutlined} from "@ant-design/icons/lib";

type Props = {
    size?: 'default' | 'large' | 'small'
    style?: any
}
const Spinner = (props: Props) => {
    const antIcon = <LoadingOutlined spin />;

    return (
        <Spin {...props} size={props.size} indicator={antIcon} />
    )
}

export default Spinner;
