import FullPageSpinner from "Components/FullPageSpinner";
import FlexRow from "Components/FlexRow";
import { useTranslation } from "react-i18next";

const LoadingData = () => {
    
    const { t } = useTranslation();
    
    return (
        <FlexRow justify={"center"} direction={"column"}>
            {t('dinero:settings.synchronizingDate')}
            <div className={"padding30"}>
                <FullPageSpinner />
            </div>
        </FlexRow>
    )
}

export default LoadingData
