import styles from './Components/Layout.module.scss';
import Button from "Components/Button/Button";
import { Link } from 'react-router-dom';
import ControlledInput from "Components/InputV2/ControlledInput";
import { useForm } from "react-hook-form";
import { useState } from "react";
import { auth} from 'FirebaseSetup/auth';
import { useTranslation } from "react-i18next";
import { openErrorNotification, openSuccessNotification } from "Utils/NotificationUtils";
import Layout from "Pages/Auth/Components/Layout";
import Space from "Components/Space";
interface ResetPasswordFormFields {
    email: string;
}
const ResetPasswordPage = () => {
    
    const { t } = useTranslation();
    
    const { handleSubmit, control, errors } = useForm<ResetPasswordFormFields>();
    
    const [error, setError] = useState('');
    const [isResettingPassword, setIsResettingPassword] = useState<boolean>(false);
    
    const onSubmit = (data: ResetPasswordFormFields) => {
        setIsResettingPassword(true)
    
        auth.sendPasswordResetEmail(data.email).then((result) => {
            setError(t('auth:checkYourEmail'));
            
            openSuccessNotification(t('success'), t('auth:checkYourEmail'), 'topLeft')
            
            setIsResettingPassword(false)
        }).catch(e => {
            let error;
            
            if (e.code === 'auth/email-already-in-use') {
                error = t('auth:userExists');
            }
            if (e.code === 'auth/weak-password') {
                error = t('auth:weekPassword');
            }
            if (e.code === 'auth/invalid-email') {
                error = t('auth:invalidEmail');
            }
            if (e.code === 'auth/network-request-failed') {
                error = t('auth:unexpectedError');
            }
    
            openErrorNotification(t('errors:anErrorOccurred'), error, 'topLeft');
            
            setIsResettingPassword(false)
        });
    }
    
    return (
        <Layout
            preHeader={''}
            header={t('auth:resetPassword')}
            imgUrl={"/images/appMockupV2.png"}
        >
            {error ? (
                <span>{error}</span>
            ) : (
                <>
                    <form onSubmit={handleSubmit(onSubmit)}>
                        <Space size={"large"} direction={"vertical"}>
                            <ControlledInput name={"email"} control={control} errors={errors} placeholder={t('auth:emailLabel')} rules={{required: true}} suffix={'@'} />
                    
                            <Button loading={isResettingPassword} type={"submit"} color={"primary"} block><span style={{fontSize: '1.5em', fontWeight: 600}}>{t('auth:resetPassword')}</span></Button>
                        </Space>
                    </form>
                    
                    <div className={styles.link}>{t('auth:alreadyHaveAnAccount')} <Link to={"/auth/login"}>{t('auth:login')}</Link></div>
                </>
            )}
            
        </Layout>
    )
}

export default ResetPasswordPage
