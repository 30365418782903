import styles from './Layout.module.scss';
import FlexRow from "Components/FlexRow";
import React from "react";

interface Props {
    preHeader: string;
    header: string;
    children: React.ReactNode;
    imgUrl: string;
    steps?: React.ReactNode
}
const Layout = (props: Props) => {
    
    return (
        <div className={styles.container}>
            <div className={styles.loginContainer}>
                {props.steps && props.steps}
                <div className={styles.inner}>
                    <div className={"w-100"}>
                        <FlexRow direction={"column"} align={"start"} justify={"start"}>
                            <div className={styles.preHeader}>
                                {props.preHeader}
                            </div>
                            <div className={styles.header}>
                                {props.header}
                            </div>
                        </FlexRow>
                        <div className={styles.form}>
                            {props.children}
                        </div>
                    </div>
                </div>
            </div>
        
            <div className={styles.background} style={{backgroundImage: 'url("/images/background.svg")'}} >
                <div style={{display: 'block', width: '250px', height: '100px', backgroundRepeat: 'no-repeat', backgroundImage: 'url("/timeops_logo_white.svg")'}} />
                <img alt={""} className={`${styles.mockupImg}`} src={props.imgUrl} />
            </div>
        </div>
    )
}

export default Layout
