import { useEffect, useState } from 'react';
import {Registration} from "Apis/Models/Registration.type";
import RegistrationCardRow from "./RegistrationCardRow";
import styles from './RegistrationCard.module.scss';
import FlexRow from "Components/FlexRow";
import moment from "moment";
import Skeleton from "antd/lib/skeleton";
import Duration from "Components/Duration";
import Group from "Assets/Icons/Group";

type Props = {
    date?: Date,
    registrations?: Registration[],
    loading?: boolean;
}

const RegistrationCard = (props: Props) => {
    
    const [ momentDate ] = useState<moment.Moment>(moment(props.date));
    
    const [ totalDuration, setTotalDuration ] = useState(0)
    
    useEffect(() => {
        if (props.registrations) {
            setTotalDuration(props.registrations?.map(x => x.duration ?? 0).reduce((a, b) => a + b));
        }
    }, [props.registrations])
    
    const renderItem = (registration: Registration) => {
        return (
            <RegistrationCardRow
                key={registration.id}
                registration={registration}
            />
        )
    }

    return (
        <FlexRow direction={"column"} className={`box padding15 ${styles.box}`}>
            <Skeleton active loading={props.loading}>
                <div className={`${styles.boxHeader} ${styles.paddingBottom}`}>
                    <div>
                        <div className={styles.day}>
                            {momentDate.format('dddd')}
                        </div>
                        <div className={styles.date}>
                            {momentDate.format('D. MMMM')}
                        </div>
                    </div>
                    <div className={`${styles.durationContainer}`}>
                        <Group style={{marginRight: 15}} />
                        <Duration duration={totalDuration} style={{color: '#123067', fontSize: 14, fontWeight: 600}} />
                    </div>
                </div>
                <div className={styles.registrationContainer}>
                    {props.registrations?.map(x => renderItem(x))}
                </div>
            </Skeleton>
        </FlexRow>
    )
}


export default RegistrationCard;
