import { Navigate } from "react-router-dom";
import { useAuthProvider } from "Providers/AuthProvider";

const RequireAuthentication = ({ children }) => {

    const user = useAuthProvider();
    
    return user?.firebaseUser !== null ? children : <Navigate to={"/auth/login"} />;
}

export default RequireAuthentication;
