import React, { CSSProperties, Ref } from 'react';
import { Controller } from 'react-hook-form';
import styles from './ControlledInput.module.scss';
import _ from "lodash";

interface Props extends React.DetailedHTMLProps<React.InputHTMLAttributes<HTMLInputElement>, HTMLInputElement> {
    errors?: any,
    name: string,
    rules?: any,
    control: any,
    suffix?: string;
    number?: boolean;
    containerStyle?: CSSProperties;
    inputStyle?: CSSProperties;
    inputRef?: Ref<HTMLInputElement>
}
const ControlledInput = (props: Props)  => {
    
    const deactivateField = (event) => {
        if (props.onBlur) {
            props.onBlur(event)
        }
    }
    
    return (
        <Controller
            name={props.name}
            rules={props.rules}
            control={props.control}
            defaultValue={props.defaultValue ?? ''}
            render={(
                { onChange, value },
            ) => (
                <div className={`${styles.container} ${_.get(props.errors, props.name) ? styles.error : ''}`} style={props.containerStyle}>
                    <label className={`${styles.label} text-ellipsis ${styles.fieldActive}`}>
                        {props.placeholder}
                    </label>
                    <input
                        className={`${styles.input} ${styles.floatingLabel}`}
                        onChange={(value) => {
                            if (props.number) {
                                onChange(value.currentTarget.value.replace(',', '.'));
                            } else {
                                onChange(value.currentTarget.value);
                            }
                            
                            if (props.onChange) {
                                props.onChange(value)
                            }
                        }}
                        autoFocus={props.autoFocus}
                        value={value}
                        onBlur={deactivateField}
                        type={props.type}
                        style={props.inputStyle}
                        ref={props.inputRef}
                    />
                    {props.suffix && (
                        <div className={styles.suffix}>
                            {props.suffix}
                        </div>
                    )}
                </div>
            )}
        />
    )
}

export default ControlledInput
