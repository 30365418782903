import * as React from "react";
import styles from './ListText.module.scss';

interface Props {
    children: any;
    compact?: boolean;
}
const ListText = (props: Props) => {
    
    return (
        <span className={`${styles.text} ${props.compact && styles.compact}`}>
            {props.children}
        </span>
    )
}

export default React.memo(ListText)
