import { Navigate, Route, Routes } from "react-router-dom";
import { ReportPage } from "./ReportPage";

const ReportRoutes = () => {
    
    return (
        <Routes>
            <Route path={`/`} element={
                <ReportPage />
            }/>
            
            <Route path={"*"} element={<Navigate to={"/"}/>}/>
        </Routes>
    )
}

export default ReportRoutes
