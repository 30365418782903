import React from "react";

const BillableActive = (props: React.SVGProps<SVGSVGElement>) => {
    
    return (
        <svg
            xmlns="http://www.w3.org/2000/svg"
            width={25}
            height={25}
            viewBox="0 0 25 25"
            {...props}
        >
            <g data-name="Group 343" transform="translate(-1583 -51)">
                <circle
                    data-name="Ellipse 46"
                    cx={6.156}
                    cy={6.156}
                    r={6.156}
                    transform="translate(1583.684 63.311)"
                    fill="rgba(13,96,248,0.28)"
                />
                <g data-name="Group 340">
                    <g data-name="Group 342" fill="#0d60f8">
                        <path
                            data-name="Path 472"
                            d="M1590.325 72.826v-.279a1.549 1.549 0 001.221-1.511v-.814a1.548 1.548 0 00-1.546-1.546h-.814a.082.082 0 01-.081-.081v-.814a.082.082 0 01.081-.081h.814a.082.082 0 01.081.081.733.733 0 101.465 0 1.549 1.549 0 00-1.221-1.511v-.279a.733.733 0 00-1.465 0v.279a1.549 1.549 0 00-1.221 1.511v.814a1.548 1.548 0 001.546 1.546h.814a.082.082 0 01.081.081v.814a.082.082 0 01-.081.081h-.814a.082.082 0 01-.081-.081.733.733 0 10-1.465 0 1.549 1.549 0 001.221 1.511v.279a.733.733 0 001.465 0z"
                        />
                        <path
                            data-name="Path 473"
                            d="M1607.688 56.1l-4.883-4.885a.732.732 0 00-.518-.215h-11.23a2.2 2.2 0 00-2.2 2.2v9.66a6.592 6.592 0 00.735 13.14h16.113a2.2 2.2 0 002.2-2.2V56.615a.732.732 0 00-.215-.518zm-4.668-2.6l1.191 1.191 1.189 1.192h-2.38zm-18.555 15.908a5.127 5.127 0 115.127 5.127 5.133 5.133 0 01-5.127-5.127zm21.24 5.127h-11.975a6.589 6.589 0 002.413-4.395h7.609a.733.733 0 100-1.465h-7.609a6.551 6.551 0 00-1.073-2.93h8.682a.733.733 0 100-1.465h-10.022a6.562 6.562 0 00-3.406-1.424V53.2a.733.733 0 01.732-.732h10.5v4.15a.732.732 0 00.732.732h4.15V73.8a.733.733 0 01-.733.735z"
                        />
                        <path
                            data-name="Path 474"
                            d="M1603.752 59.887h-10.743a.733.733 0 000 1.465h10.742a.733.733 0 000-1.465z"
                        />
                    </g>
                </g>
            </g>
        </svg>
    )
}

export default BillableActive
