import {Routes, Route, Navigate} from "react-router-dom";
import ShowOrganizationPage from "./ShowOrganizationPage";
import ProfilePage from "Pages/Organization/ProfilePage";
import AccessLevelRoute from "../../Components/AccessLevelRoute";
import {hasAccess} from "Utils/AccessUtils";
import UsersPage from "Pages/Organization/UsersPage";
import { useAuthProvider } from "Providers/AuthProvider";
import SubscriptionPage from "Pages/Organization/SubscriptionPage";

const OrganizationRoutes = () => {
    
    const { userContext } = useAuthProvider();

    return (
        <Routes>
            <Route path={`show`} element={
                <AccessLevelRoute requiredAccessLevel={200}>
                    <ShowOrganizationPage />
                </AccessLevelRoute>
            } />
    
            <Route path={`subscription/v2`} element={
                <AccessLevelRoute requiredAccessLevel={200}>
                    <SubscriptionPage />
                </AccessLevelRoute>
            } />
    
            <Route path={`subscription`} element={
                <AccessLevelRoute requiredAccessLevel={200}>
                    <SubscriptionPage />
                </AccessLevelRoute>
            } />
    
            <Route path={`users/list`} element={
                <AccessLevelRoute requiredAccessLevel={200}>
                    <UsersPage />
                </AccessLevelRoute>
            } />
            
            <Route path={`users/me`} element={<ProfilePage />} />

            <Route path={"*"} element={<Navigate to={hasAccess(200, userContext!) ? '/organization/show' : '/organization/users/me'} />} />
        </Routes>
    )
}

export default OrganizationRoutes;
