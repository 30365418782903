import { saveAs } from 'file-saver';
import {Controller, useForm} from "react-hook-form";
import { useEffect, useState } from "react";
import {useCustomers} from "Apis/CustomerApi";
import {useOrganization} from "Apis/OrganizationApi.ts";
import {getAdvancedReportPdf, useReport} from "Apis/ReportsApi";
import _ from "lodash";
import Col from "antd/lib/col";
import DatePicker from "antd/lib/date-picker";
import Input from "antd/lib/input";
import Row from "antd/lib/row";
import Select from "antd/lib/select";
import Header from "Components/Header";
import moment from "moment";
import {Customer} from "Apis/Models/Customer.type";
import {User} from "Apis/Models/User.type";
import {ReportModel} from "Apis/Models/ReportModel";
import RequiresPremiumModal from "Components/RequiresPremiumModal";
import {useTranslation} from "react-i18next";
import { RegistrationUtils } from "Utils/RegistrationUtils";
import { DateUtils } from "Utils/DateUtils";
import useQuickDateRanges from "Hooks/UseQuickDateRanges";
import { useAuthProvider } from "Providers/AuthProvider";
import TagSelect from "Components/TagSelect";

const { RangePicker } = DatePicker;

export type ReportBillable = 'All' | 'Billable' | 'NotBillable'
export interface ReportFormState {
    customerId?: number;
    projectId?: number;
    userId?: number;
    groupBy?: string;
    rounding?: number;
    billable?: ReportBillable;
    dateRange?: moment.Moment[];
    tags?: number[];
    reverseTagSearch?: boolean
}
export interface ReportSearchParams extends ReportFormState {
    fromDate?: string;
    toDate?: string;
}
export interface UseReportingFields {
    reportMarkup: any;
    searchParams: ReportSearchParams;
    report?: ReportModel;
    isLoadingReport: boolean;
    customers?: Customer[],
    users?: User[];
    refetchReport: Function;
    setReportValue: Function;
    isDownloadingReportPdf: boolean;
    downloadReportPdf: Function;
    downloadReportCsv: Function;
}

// Default date range is last month if today is before the 7. of the month
const defaultDateRange = new Date().getDate() < 7 ?
    [moment().subtract(1, 'months').startOf('month'), moment().subtract(1, 'months').endOf('month')] :
    [moment().startOf('month'), moment().endOf('month')];

const defaultParams: ReportSearchParams = {
    dateRange: defaultDateRange,
    fromDate: DateUtils.getDateOnlyString(moment().startOf('month').toDate()),
    toDate: DateUtils.getDateOnlyString(moment().endOf('month').toDate()),
    billable: "All",
    tags: [],
    reverseTagSearch: false,
}
export const useReporting = (initialSearchParams?: ReportSearchParams): UseReportingFields => {

    const { t } = useTranslation();
    const { userContext } = useAuthProvider();
    const { control, getValues, watch, setValue } = useForm<ReportFormState>();
    const [searchParams, setSearchParams] = useState<ReportSearchParams>({
        ...defaultParams,
        ...initialSearchParams
    });
    const quickDateRanges = useQuickDateRanges();
    const [isDownloadingReportPdf, setIsDownloadingReport] = useState<boolean>(false);
    
    const { data: customers, isLoading: isLoadingCustomers } = useCustomers();
    const { data: organization, isLoading: isLoadingOrganization } = useOrganization();
    const { data: report, isLoading: isLoadingReport, refetch} = useReport({
        fromDate: searchParams.fromDate,
        toDate: searchParams.toDate,
        customerId: searchParams.customerId,
        projectId: searchParams.projectId,
        userId: searchParams.userId,
        billable: searchParams.billable,
        groupBy: searchParams.groupBy,
        rounding: searchParams.rounding,
        tags: searchParams.tags,
        reverseTagSearch: searchParams.reverseTagSearch,
    });
    
    const data = watch();

    useEffect(() => {
        const newParams = {
            ...data,
            fromDate: DateUtils.getDateOnlyString(_.get(data, 'dateRange[0]')?.toDate()),
            toDate: DateUtils.getDateOnlyString(_.get(data, 'dateRange[1]')?.toDate()),
        }
        
        if (!newParams.fromDate ||!newParams.toDate) {
            return;
        }

        if (!_.isEqual(searchParams, newParams)) {
            setSearchParams(newParams)
        }

    }, [data, searchParams])

    const getRoundingValues = () => {
        let number = 0;

        const values: any[] = [];

        while (number <= 60) {
            values.push(<Select.Option value={number} key={number}>{number === 0 ? t('registrations:reporting.noRounding') : `${number} ${t('registrations:reporting.minutes')}`}</Select.Option>);
            number++;
        }

        return values;
    }

    const getProjectOptions = () => {
        const customerId = getValues('customerId');

        if (customerId) {
            return customers?.filter(c => c.id === customerId).map((customer) => (
                <Select.OptGroup key={customer.id} label={customer.name}>
                    {customer.projects.map((project) => (
                        <Select.Option value={project.id} key={project.id} title={project.name}>
                            {project.name}
                        </Select.Option>
                    ))}
                </Select.OptGroup>
            ))
        }

        return customers?.filter(x => x.projects.length !== 0).map((customer) => (
            <Select.OptGroup key={customer.id} label={customer.name}>
                {customer.projects.map((project) => (
                    <Select.Option value={project.id} key={project.id}>
                        {project.name}
                    </Select.Option>
                ))}
            </Select.OptGroup>
        ))
    }

    const customerSelected = (value) => {
        setValue('projectId', undefined)
    }

    const filterOption = (value, option) => {
        const { children } = option;

        if (option.options) {
            return option.options.includes((child) => child.props.children.toLowerCase().indexOf(value.toLowerCase()) >= 0);
        }

        return children.toLowerCase().indexOf(value.toLowerCase()) >= 0;
    };

    const selectProps = {
        allowClear: true,
        showSearch: true,
        filterOption: filterOption
    }

    const setReportValue = (field: 'customerId', value: any) => {
        setValue(field, value);
    }

    const downloadPdf = async (language, detailed: boolean) => {
        setIsDownloadingReport(true)

        try {
            let fileName = `${t('registrations:reporting.entries')}`

            if (searchParams?.customerId) {
                fileName = `${fileName} - ${customers?.find(x => x.id === searchParams.customerId)!.name}`
            }
    
            if (searchParams?.projectId) {
                const project = customers?.flatMap(x => x.projects).find(x => x.id === searchParams.projectId);
                fileName = `${fileName} - ${project!.name}`
            }

            fileName = `${fileName}.pdf`;

            await getAdvancedReportPdf({ ...searchParams, language, detailed }, fileName);
        }
        catch (e) {
        
        }
        finally {
            setIsDownloadingReport(false);
        }
    }
    
    const downloadCsv = () => {
        const headers = [
            t('registrations:report.description'),
            t('registrations:report.project'),
            t('registrations:report.customer'),
            t('registrations:report.user'),
            t('registrations:report.date'),
            t('registrations:report.startedAt'),
            t('registrations:report.stoppedAt'),
            t('registrations:report.duration'),
        ].join(';') + '\r\n';
    
        const csv = report!.registrations.map(registration => {
            return [
                registration.description,
                registration.project?.name,
                customers!.find((c) => c.id === registration.project?.customerId)?.name,
                organization?.users!.find((c) => c.id === registration.userId)?.name ?? organization?.users!.find((c) => c.id === registration.userId)?.eMail,
                moment(registration.startedAt).format('YYYY-MM-DD'),
                moment(registration.startedAt).format('HH:mm'),
                moment(registration.stoppedAt).format('HH:mm'),
                RegistrationUtils.getFormattedDurationString(registration.duration!),
            ].join(';');
        });
    
        const csvArray = csv.join('\r\n');
    
        const blob = new Blob([headers, csvArray], {type: 'text/csv;charset=UTF-8' });
    
        let fileName = `${t('registrations:reporting.entries')}`
    
        if (searchParams?.customerId) {
            fileName = `${fileName} - ${customers?.find(x => x.id === searchParams.customerId)!.name}`
        }
    
        if (searchParams?.projectId) {
            const project = customers?.flatMap(x => x.projects).find(x => x.id === searchParams.projectId);
            fileName = `${fileName} - ${project!.name}`
        }
    
        fileName = `${fileName}.csv`;
        
        saveAs(blob, fileName);
    }


    const reportMarkup = (
        <>
            <div className={`box white padding15 dont-hover`}>
                <Row gutter={[20, 15]}>
                    <Col xs={24} sm={24} md={24} lg={12}>
                        <Row gutter={[20, 5]}>
                            <Col span={24}>
                                <span>
                                    {t('registrations:reporting.timeSpan')}
                                </span>
                                <Controller
                                    name={"dateRange"}
                                    control={control}
                                    defaultValue={searchParams.dateRange}
                                    render={(props) => (
                                        <RangePicker
                                            {...props}
                                            style={{width: '100%'}}
                                            picker="date"
                                            format="DD-MM/YYYY"
                                            allowClear={true}
                                            allowEmpty={[true, true]}
                                            ranges={quickDateRanges}
                                        />
                                    )} />
                            </Col>
                            <Col xs={24} sm={24} md={12} lg={12}>
                                <span>
                                    {t('registrations:reporting.customer')}
                                </span>
                                <Controller name={"customerId"} control={control} defaultValue={searchParams.customerId ?? null} render={(props) => (
                                    <Select
                                        placeholder={t('registrations:reporting.selectCustomer')}
                                        value={props.value}
                                        {...selectProps}
                                        onChange={(value) => {
                                            props.onChange(value);
                                            customerSelected(value)
                                        }}
                                        className={"w-100"} loading={isLoadingCustomers} >
                                            {customers?.map((customer) => (
                                                <Select.Option value={customer.id} key={customer.id}>
                                                    {customer.name}
                                                </Select.Option>
                                            ))}
                                    </Select>
                                )} />
                            </Col>
                            <Col xs={24} sm={24} md={12} lg={12}>
                                <span>
                                    {t('registrations:reporting.project')}
                                </span>
                                <Controller name={"projectId"} control={control} defaultValue={searchParams.projectId ?? null} render={(props) => (
                                    <Select
                                        placeholder={t('registrations:reporting.selectProject')}
                                        {...props}
                                        className={"w-100"}
                                        {...selectProps}
                                        loading={isLoadingCustomers}>
                                        {getProjectOptions()}
                                    </Select>
                                )} />
                            </Col>
                        </Row>
                    </Col>
                    <Col xs={24} sm={24} md={24} lg={12}>
                        <Row gutter={[20, 5]}>
                            <Col xs={24} sm={24} md={12} lg={12}>
                                <span>
                                    {t('registrations:reporting.user')}
                                </span>
                                <Controller name={"userId"} control={control} defaultValue={searchParams.userId ?? null} render={(props) => (
                                    <Select
                                        placeholder={t('registrations:reporting.selectUser')}
                                        {...props}
                                        className={"w-100"}
                                        {...selectProps}
                                        loading={isLoadingOrganization}>
                                        <Select.Option value={''}>{t('registrations:reporting.noUser')}</Select.Option>
                                        {organization?.users?.map((user) => (
                                            <Select.Option value={user.id} key={user.id} title={user.name ?? user.eMail}>
                                                {user.name ?? user.eMail}
                                            </Select.Option>
                                        ))}
                                    </Select>
                                )} />
                            </Col>
                            <Col xs={24} sm={24} md={12} lg={12}>
                                <span>
                                    {t('registrations:billable')}
                                </span>
                                <RequiresPremiumModal enabled={!userContext?.hasActiveLicense} title={t('registrations:billableRegistrations')}>
                                    <Controller name={"billable"} control={control} defaultValue={searchParams.billable ?? 'All'} render={(props) => (
                                        <Select
                                            disabled={!userContext?.hasActiveLicense}
                                            placeholder={t('registrations:reporting.billable')}
                                            {...props}
                                            className={"w-100"}
                                            {...selectProps}
                                        >
                                            <Select.Option value={'All'}>{t('registrations:reporting.all')}</Select.Option>
                                            <Select.Option value={'Billable'}>{t('registrations:reporting.billable')}</Select.Option>
                                            <Select.Option value={'NotBillable'}>{t('registrations:reporting.notBillable')}</Select.Option>
                                        </Select>
                                    )} />
                                </RequiresPremiumModal>
                            </Col>
                            <Col xs={24} sm={24} md={12} lg={12}>
                                <span>
                                    {t('registrations:tags')}
                                </span>
                                <Input.Group compact className={"w-100"}>
                                    <Controller name={"reverseTagSearch"} control={control} defaultValue={"false"} render={(props) => (
                                        <Select {...props} style={{width: '30%'}} >
                                            <Select.Option value={"false"}>{t('has')}</Select.Option>
                                            <Select.Option value={"true"}>{t('doesntHave')}</Select.Option>
                                        </Select>
                                    )} />
                                    <Controller name={"tags"} control={control} defaultValue={[]} render={(props) => (
                                        <TagSelect value={props.value} onChange={props.onChange} style={{width: '70%'}} />
                                    )} />
                                </Input.Group>
                            </Col>
                        </Row>
                    </Col>
                    <Col xs={24} sm={24} md={24} lg={12}>
                        <Header text={t('registrations:reporting.group')} style={{color: '#B2B7C0'}} />
                        <Row gutter={[20, 20]}>
                            <Col xs={24} sm={24} md={24} lg={12}>
                                <span>
                                    {t('registrations:reporting.groupBy')}
                                </span>
                                <RequiresPremiumModal enabled={!userContext?.hasActiveLicense} title={t('registrations:reporting.groupingInReports')}>
                                    <Controller name={"groupBy"} control={control} defaultValue={searchParams.groupBy ?? null} render={(props) => (
                                        <Select
                                            disabled={!userContext?.hasActiveLicense}
                                            placeholder={t('registrations:reporting.selectGroup')}
                                            {...props}
                                            className={"w-100"}
                                            {...selectProps}
                                            loading={isLoadingCustomers}>
                                            <Select.Option value={''}>{t('registrations:reporting.noGroup')}</Select.Option>
                                            <Select.Option value={'user'}>{t('registrations:reporting.user')}</Select.Option>
                                            <Select.Option value={'project'}>{t('registrations:reporting.project')}</Select.Option>
                                            <Select.Option value={'projectWithDescriptions'}>{t('registrations:reporting.projectWithDescriptions')}</Select.Option>
                                            <Select.Option value={'customer'}>{t('registrations:reporting.customer')}</Select.Option>
                                            <Select.Option value={'description'}>{t('registrations:reporting.description')}</Select.Option>
                                            <Select.Option value={'descriptionWithProject'}>{t('registrations:reporting.descriptionAndProject')}</Select.Option>
                                            <Select.Option value={'date'}>{t('registrations:reporting.date')}</Select.Option>
                                        </Select>
                                    )} />
                                </RequiresPremiumModal>
                            </Col>
                        </Row>
                    </Col>
    
                    <Col xs={24} sm={24} md={24} lg={12}>
                        <Header text={t('registrations:reporting.rounding')} style={{color: '#B2B7C0'}} />
                        <Row gutter={[20, 20]}>
                            <Col xs={24} sm={24} md={24} lg={12}>
                                <span>
                                    {t('registrations:reporting.rounding')}
                                </span>
                                <RequiresPremiumModal enabled={!userContext?.hasActiveLicense} title={t('registrations:reporting.roundingInReports')}>
                                    <Controller name={"rounding"} control={control} defaultValue={searchParams.rounding ?? null} render={(props) => (
                                        <Select
                                            disabled={!userContext?.hasActiveLicense}
                                            placeholder={t('registrations:reporting.selectRounding')}
                                            {...props}
                                            className={"w-100"}
                                            {...selectProps}
                                            loading={isLoadingCustomers}>
                                            {getRoundingValues()}
                                        </Select>
                                    )} />
                                </RequiresPremiumModal>
                            </Col>
                        </Row>
                    </Col>
                </Row>
            </div>
        </>
    )

    return {
        reportMarkup,
        searchParams,
        report,
        isLoadingReport,
        customers,
        users: organization?.users,
        refetchReport: refetch,
        setReportValue,
        isDownloadingReportPdf,
        downloadReportPdf: downloadPdf,
        downloadReportCsv: downloadCsv
    }

}
