import Duration from "Components/Duration";
import { memo, useEffect, useState } from "react";
import moment from "moment";
import { Registration } from "Apis/Models/Registration.type";
import { RegistrationUtils } from "Utils/RegistrationUtils";

declare var document;

interface Props {
    runningRegistration?: Registration;
}
const TimerDuration = ({runningRegistration}: Props) => {
    
    const [duration, setDuration] = useState<number>(0);
    
    useEffect(() => {
        if (runningRegistration && runningRegistration.startedAt != null && runningRegistration.stoppedAt == null) {
            let localDuration = Math.floor((moment().diff(moment(runningRegistration.startedAt))) / 1000);
            
            if (localDuration < 0) {
                setDuration(0)
            } else {
                setDuration(localDuration)
            }
        }

        let interval;

        if (runningRegistration && runningRegistration.startedAt != null) {
            interval = setInterval(() => {
                const localDuration = Math.floor((moment().diff(moment(runningRegistration.startedAt))) / 1000);

                if (localDuration > 0) {

                    setDuration(localDuration)

                    let title = RegistrationUtils.getFormattedDurationString(localDuration)

                    if (runningRegistration.project) {
                        title = `${title} - ${runningRegistration.project.name}, ${runningRegistration.project?.customer?.name}`
                    }

                    title = `${title}`

                    document.title = title;
                }
            }, 1000);
        } else {
            setDuration(0);
            document.title = 'TimeOps';
        }


        return () => clearInterval(interval);
    }, [runningRegistration])
    
    return (
        <Duration showSpinner style={{fontSize: '1.5em', fontWeight: 700, color: '#4D576D'}} duration={duration}/>
    )
}

export default memo(TimerDuration);
