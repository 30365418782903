import {request} from "./BaseApi";
import { useMutation, useQuery, useQueryClient } from "react-query";
import {UserContext} from "Apis/Models/UserContext.type";
import { User } from "Apis/Models/User.type";
import { USERS_CACHE_KEY } from "Apis/UsersApi";

export const USER_CONTEXT_CACHE_KEY = 'USER_CONTEXT_CACHE_KEY';
export const useUserContext = (enabled: boolean, refetchOnFocus: boolean = false) => {
    
    return useQuery<UserContext, any>(USER_CONTEXT_CACHE_KEY, async () => {
        return await request<UserContext>({
            url: `/user/usercontext`,
            method: 'GET',
    })}, {
        enabled,
        refetchOnWindowFocus: refetchOnFocus
    });
}


export const useUpgradeUser = () => {
    const queryClient = useQueryClient();
    
    return useMutation(async (email) => {
        return await request<User>({
            url: `/user/upgrade`,
            method: 'POST',
            data: {
                email: email
            }
        })
    }, {
        onSuccess: () => {
            queryClient.invalidateQueries(USERS_CACHE_KEY);
            queryClient.invalidateQueries(USER_CONTEXT_CACHE_KEY);
        }
    })
}
