export const getInputClasses = (hasError?: any, includeInput = true) => {
    if (includeInput) {
        return `input w-100 ${hasError ? 'error' : ''}`
    }
    
    return `${hasError ? 'error w-100' : 'w-100'}`
};

export const hasErrors = (errors) => {
    return Object.keys(errors).length > 0;
}
