import { useEffect, useMemo, useRef, useState } from 'react';
import Header from "Components/Header";
import { useCreateInvoice, useDefaultInvoiceSettings, useInvoices } from "Pages/Dinero/Api/DineroInvoiceApi";
import {useTranslation} from "react-i18next";
import FindRegistrationsModal from "Pages/Dinero/Components/FindRegistrationsModal";
import FlexRow from "Components/FlexRow";
import Button from "Components/Button/Button";
import { ReportModel } from "Apis/Models/ReportModel";
import { ReportSearchParams } from "Hooks/UseReporting";
import { openErrorNotification } from "Utils/NotificationUtils";
import CaretRight from "Assets/Icons/CaretRight";
import List from "Components/List/List";
import moment from "moment";
import { createInvoiceLineFromReport, getInvoiceNumber } from "Pages/Dinero/Utils/InvoiceUtils";
import { getNumberAsCurrency } from "Utils/NumberUtils";
import Copy from "Assets/Icons/Copy";
import { useDineroContacts } from "Pages/Dinero/Api/DineroIntegrationApi";
import useQueryParam from "Hooks/UseQueryParam";
import { useAddTagsToRegistrations } from "Apis/RegistrationsApi";
import PremiumTrialAnnouncement from "Components/PremiumTrialAnnouncement";
import { useNavigate } from "react-router-dom";
import InputV2 from "Components/InputV2/InputV2";

const InvoicesPage = () => {

    const { t } = useTranslation();
    const navigate = useNavigate();
    
    const [showReportingModal, setShowReportingModal] = useState<boolean>(false);
    
    const { data: invoices, isLoading: isLoadingInvoices } = useInvoices();
    const { data: contacts } = useDineroContacts();
    const { data: defaultInvoiceSettings } = useDefaultInvoiceSettings();
    
    const { mutateAsync: createInvoiceMutation, isLoading: isCreatingInvoice } = useCreateInvoice();
    const { mutateAsync: addTagsToRegistrationsMutation } = useAddTagsToRegistrations();
    
    const searchRef = useRef<HTMLInputElement>(null);
    const [searchFilter, setSearchFilter] = useQueryParam('query');
    
    useEffect(() => {
        if (searchRef.current) {
            searchRef.current.focus();
        }
    }, [searchRef])
    
    const createNewInvoice = async (report: ReportModel, selectedEntries: number[], searchParams: ReportSearchParams, tags: number[]) => {
        if (!searchParams.customerId) {
            openErrorNotification(t('errors:anErrorOccurred'), t('dinero:noCustomerSelected'))
            return;
        }
        
        const dineroContact = contacts!.find(x => x.customerId === searchParams.customerId);
        
        if (!dineroContact) {
            openErrorNotification(t('errors:anErrorOccurred'), t('dinero:customerNotSynchronized'))
            return;
        }
    
        const invoiceLines = createInvoiceLineFromReport(report, selectedEntries, searchParams, defaultInvoiceSettings!)
        
        if (invoiceLines.length === 0) {
            openErrorNotification(t('errors:anErrorOccurred'), t('dinero:noLinesSelected'))
            return;
        }
    
        if (invoiceLines.filter(x => !x.description).length > 0) {
            openErrorNotification(t('errors:anErrorOccurred'), t('dinero:linesMustHaveDescription'))
            return;
        }
        
        const response = await createInvoiceMutation({
            contactGuid: dineroContact.id,
            productLines: invoiceLines,
        });
        
        
        if (!response?.guid) {
            openErrorNotification(t('errors:unknownErrorOccurred'), '')
            return;
        }
    
        if (tags?.length > 0) {
            let timeEntries = selectedEntries;
        
            if (searchParams.groupBy) {
                timeEntries = report.groupedRegistrations.filter(x => selectedEntries.includes(x.groupKey)).flatMap(group => group.registrations.map(registration => registration.id!)) ?? [];
            }
            
            await addTagsToRegistrationsMutation({
                registrationIds: timeEntries,
                tagIds: tags
            });
        }
        
        navigate(`/dinero/invoices/${response.guid}`);
    }
    
    const closeReportingModel = () => {
        setShowReportingModal(false)
    }


    const getInvoices = () => {
        let filteredInvoices = invoices?.collection;
        
        if (searchFilter && filteredInvoices) {
            const lowerCaseSearch = searchFilter.toLowerCase();
            
            filteredInvoices = invoices?.collection?.filter(x => x.description.toLowerCase().includes(lowerCaseSearch) || x.contactName?.toLowerCase().includes(lowerCaseSearch) || x.comment?.toLowerCase().includes(lowerCaseSearch) || x.number?.toString().includes(lowerCaseSearch))
        }
        
        return filteredInvoices;
    }
    
    const columns = useMemo(() => [
        {
            size: 'min-content',
            icon: <Copy />,
            title: t('dinero:invoiceNumber'),
            textRender: (_, record) => `${getInvoiceNumber(record.number, '---')}`
        },
        {
            size: 'min-content',
            title: t('dinero:description'),
            textRender: (_, record) => `${record.description}`
        },
        {
            title: t('dinero:customer'),
            dataIndex: 'contactName',
        },
        {
            title: t('dinero:invoiceDate'),
            textRender: (_, record) => {
                return `${moment(record.date).format('DD/MM/YYYY')}`
            }
        },
        {
            title: t('dinero:totalExVat'),
            textRender: (_, record) => {
                return `${getNumberAsCurrency(record.totalExclVat)} ${record.currency}`
            }
        },
        {
            size: 'min-content',
            customRender: (_, record) => {
                return (
                    <div className={"flex w-100 align-center justify-end clickable"} onClick={() => navigate(`/dinero/invoices/${record.guid}`)}>
                        <CaretRight height={15} width={15} fill={"#bdbdbd"} />
                    </div>
                )
            }
        }
    ], [navigate, t])

    return (
        <>
            <Header text={t('dinero:invoices')} />
    
            <PremiumTrialAnnouncement>
                {t('premiumAnnouncements.integrationsIsAPartOfTimeOpsPremium')}
            </PremiumTrialAnnouncement>
            
            <FlexRow justify={"space-between"} style={{marginBottom: 10}}>
                <div style={{width: '100%', maxWidth: '400px'}}>
                    <InputV2 inputRef={searchRef} placeholder={t('dinero:invoiceSearch')} type="text" value={searchFilter} onChange={e => setSearchFilter(e)} containerStyle={{backgroundColor: 'white'}} />
                </div>
    
                <Button color={"primary"} onClick={() => setShowReportingModal(true)}>+ {t('dinero:createInvoice')}</Button>
            </FlexRow>
    
            <List loading={isLoadingInvoices} data={getInvoices()} emptyText={t('dinero:noDraftInvoicesFound')} keyDataIndex={'guid'} columns={columns} />
    
            <FindRegistrationsModal visible={showReportingModal} onOk={createNewInvoice} onCancel={closeReportingModel} isOkLoading={isCreatingInvoice} initialValues={{ billable: 'Billable' }} />
        </>
    )
}

export default InvoicesPage
