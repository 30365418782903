import * as React from "react"
import { SVGProps } from "react"

const TagIcon = (props: SVGProps<SVGSVGElement>) => (
    <svg xmlns="http://www.w3.org/2000/svg" width="1em" height="1em" viewBox="0 0 35.445 35.448" {...props}>
        <path
            data-name="003-price-tag"
            d="M15.195 35.448a4.127 4.127 0 0 1-2.936-1.219L1.211 23.169a4.161 4.161 0 0 1 0-5.87L16.098 2.384A8.067 8.067 0 0 1 21.846 0h9.445a4.159 4.159 0 0 1 4.154 4.154v9.416a8.067 8.067 0 0 1-2.379 5.745L18.13 34.233a4.128 4.128 0 0 1-2.935 1.215Zm6.651-32.679a5.316 5.316 0 0 0-3.787 1.571L3.171 19.256a1.387 1.387 0 0 0 0 1.957l11.044 11.059a1.375 1.375 0 0 0 .979.406 1.376 1.376 0 0 0 .978-.4l14.934-14.922a5.316 5.316 0 0 0 1.57-3.786V4.154a1.386 1.386 0 0 0-1.385-1.385Zm3.56 11.285A4.154 4.154 0 1 1 29.56 9.9a4.159 4.159 0 0 1-4.154 4.154Zm0-5.539A1.385 1.385 0 1 0 26.791 9.9a1.386 1.386 0 0 0-1.385-1.384Zm0 0"
            fill={props.fill ?? "#bed5ff"}
        />
    </svg>
)

export default TagIcon
