import * as React from "react"
import { SVGProps } from "react"

const GroupsIcon = (props: SVGProps<SVGSVGElement>) => (
    <svg xmlns="http://www.w3.org/2000/svg" width={46.226} height={35.445} viewBox="0 0 46.226 35.445" {...props}>
        <g data-name="Group 380" fill={props.fill ?? "#bed5ff"}>
            <path
                data-name="Path 492"
                d="M.749 11.027 22.63 20.69a1.177 1.177 0 0 0 .484.1 1.207 1.207 0 0 0 .484-.1l21.88-9.663a1.286 1.286 0 0 0-.047-2.357L23.551.084a1.191 1.191 0 0 0-.876 0L.8 8.67a1.286 1.286 0 0 0-.046 2.357Zm22.364-8.4 18.592 7.3-18.592 8.212L4.521 9.928Z"
            />
            <path
                data-name="Path 493"
                d="m.748 15.871 22.364 9.877 22.364-9.877a1.286 1.286 0 0 0 .648-1.669 1.217 1.217 0 0 0-1.617-.669l-21.4 9.45-21.4-9.45a1.218 1.218 0 0 0-1.617.669 1.287 1.287 0 0 0 .658 1.669Z"
            />
            <path
                data-name="Path 494"
                d="m.748 20.483 22.364 9.877 22.364-9.877a1.286 1.286 0 0 0 .648-1.669 1.217 1.217 0 0 0-1.617-.669l-21.4 9.45-21.4-9.449a1.218 1.218 0 0 0-1.617.669 1.286 1.286 0 0 0 .658 1.668Z"
            />
            <path
                data-name="Path 495"
                d="m.748 25.568 22.364 9.877 22.364-9.877a1.286 1.286 0 0 0 .648-1.669 1.218 1.218 0 0 0-1.617-.669l-21.4 9.45-21.4-9.45a1.219 1.219 0 0 0-1.617.669 1.286 1.286 0 0 0 .658 1.669Z"
            />
        </g>
    </svg>
)

export default GroupsIcon
