import * as React from "react"

const Copy = (props: React.SVGProps<SVGSVGElement>) => {
    return (
        <svg
            xmlns="http://www.w3.org/2000/svg"
            width={29.7}
            height={32.4}
            viewBox="0 0 29.7 32.4"
            {...props}
        >
            <g>
                <path
                    data-name="Path 57"
                    d="M26.325 32.4h-16.2a3.379 3.379 0 01-3.375-3.375V8.775A3.379 3.379 0 0110.125 5.4h16.2A3.379 3.379 0 0129.7 8.775v20.25a3.379 3.379 0 01-3.375 3.375zm-16.2-25.65A2.027 2.027 0 008.1 8.775v20.25a2.027 2.027 0 002.025 2.025h16.2a2.027 2.027 0 002.025-2.025V8.775a2.027 2.027 0 00-2.025-2.025z"
                />
                <path
                    data-name="Path 58"
                    d="M4.725 28.35h-1.35A3.379 3.379 0 010 24.975v-21.6A3.379 3.379 0 013.375 0h16.2a3.379 3.379 0 013.375 3.375.675.675 0 01-1.35 0 2.027 2.027 0 00-2.025-2.025h-16.2A2.027 2.027 0 001.35 3.375v21.6A2.027 2.027 0 003.375 27h1.35a.675.675 0 010 1.35z"
                />
                <path
                    data-name="Path 59"
                    d="M23.625 22.95h-10.8a.675.675 0 110-1.35h10.8a.675.675 0 110 1.35z"
                />
                <path
                    data-name="Path 60"
                    d="M23.625 28.35h-10.8a.675.675 0 110-1.35h10.8a.675.675 0 110 1.35z"
                />
                <path
                    data-name="Path 61"
                    d="M23.625 17.55h-10.8a.675.675 0 110-1.35h10.8a.675.675 0 110 1.35z"
                />
                <path
                    data-name="Path 62"
                    d="M23.625 12.15h-10.8a.675.675 0 110-1.35h10.8a.675.675 0 110 1.35z"
                />
            </g>
        </svg>
    )
}

export default Copy
