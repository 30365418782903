import Select from "Components/Select";
import React from "react";
import {useTranslation} from "react-i18next";

interface Props {
    control: any,
    name: string,
    placeholder: string;
    defaultValue?: any,
    errors: any,
    rules?: any
}
const MonthSelect = ({control, name, placeholder, errors, defaultValue, rules}: Props) => {
    
    const { t } = useTranslation();
    
    return (
        <Select name={name} placeholder={placeholder} control={control} errors={errors} defaultValue={defaultValue} rules={rules} >
            <option value="">{t('select')}</option>
            <option value="AF">{t('countries:AF')}</option>
            <option value="AX">{t('countries:AX')}</option>
            <option value="AL">{t('countries:AL')}</option>
            <option value="DZ">{t('countries:DZ')}</option>
            <option value="AD">{t('countries:AD')}</option>
            <option value="AO">{t('countries:AO')}</option>
            <option value="AI">{t('countries:AI')}</option>
            <option value="AQ">{t('countries:AQ')}</option>
            <option value="AG">{t('countries:AG')}</option>
            <option value="AR">{t('countries:AR')}</option>
            <option value="AM">{t('countries:AM')}</option>
            <option value="AW">{t('countries:AW')}</option>
            <option value="AC">{t('countries:AC')}</option>
            <option value="AU">{t('countries:AU')}</option>
            <option value="AT">{t('countries:AT')}</option>
            <option value="AZ">{t('countries:AZ')}</option>
            <option value="BS">{t('countries:BS')}</option>
            <option value="BH">{t('countries:BH')}</option>
            <option value="BD">{t('countries:BD')}</option>
            <option value="BB">{t('countries:BB')}</option>
            <option value="BY">{t('countries:BY')}</option>
            <option value="BE">{t('countries:BE')}</option>
            <option value="BZ">{t('countries:BZ')}</option>
            <option value="BJ">{t('countries:BJ')}</option>
            <option value="BM">{t('countries:BM')}</option>
            <option value="BT">{t('countries:BT')}</option>
            <option value="BO">{t('countries:BO')}</option>
            <option value="BA">{t('countries:BA')}</option>
            <option value="BW">{t('countries:BW')}</option>
            <option value="BV">{t('countries:BV')}</option>
            <option value="BR">{t('countries:BR')}</option>
            <option value="IO">{t('countries:IO')}</option>
            <option value="VG">{t('countries:VG')}</option>
            <option value="BN">{t('countries:BN')}</option>
            <option value="BG">{t('countries:BG')}</option>
            <option value="BF">{t('countries:BF')}</option>
            <option value="BI">{t('countries:BI')}</option>
            <option value="KH">{t('countries:KH')}</option>
            <option value="CM">{t('countries:CM')}</option>
            <option value="CA">{t('countries:CA')}</option>
            <option value="CV">{t('countries:CV')}</option>
            <option value="BQ">{t('countries:BQ')}</option>
            <option value="KY">{t('countries:KY')}</option>
            <option value="CF">{t('countries:CF')}</option>
            <option value="TD">{t('countries:TD')}</option>
            <option value="CL">{t('countries:CL')}</option>
            <option value="CN">{t('countries:CN')}</option>
            <option value="CO">{t('countries:CO')}</option>
            <option value="KM">{t('countries:KM')}</option>
            <option value="CG">{t('countries:CG')}</option>
            <option value="CD">{t('countries:CD')}</option>
            <option value="CK">{t('countries:CK')}</option>
            <option value="CR">{t('countries:CR')}</option>
            <option value="CI">{t('countries:CI')}</option>
            <option value="HR">{t('countries:HR')}</option>
            <option value="CW">{t('countries:CW')}</option>
            <option value="CY">{t('countries:CY')}</option>
            <option value="CZ">{t('countries:CZ')}</option>
            <option value="DK">{t('countries:DK')}</option>
            <option value="DJ">{t('countries:DJ')}</option>
            <option value="DM">{t('countries:DM')}</option>
            <option value="DO">{t('countries:DO')}</option>
            <option value="EC">{t('countries:EC')}</option>
            <option value="EG">{t('countries:EG')}</option>
            <option value="SV">{t('countries:SV')}</option>
            <option value="GQ">{t('countries:GQ')}</option>
            <option value="ER">{t('countries:ER')}</option>
            <option value="EE">{t('countries:EE')}</option>
            <option value="SZ">{t('countries:SZ')}</option>
            <option value="ET">{t('countries:ET')}</option>
            <option value="FK">{t('countries:FK')}</option>
            <option value="FO">{t('countries:FO')}</option>
            <option value="FJ">{t('countries:FJ')}</option>
            <option value="FI">{t('countries:FI')}</option>
            <option value="FR">{t('countries:FR')}</option>
            <option value="GF">{t('countries:GF')}</option>
            <option value="PF">{t('countries:PF')}</option>
            <option value="TF">{t('countries:TF')}</option>
            <option value="GA">{t('countries:GA')}</option>
            <option value="GM">{t('countries:GM')}</option>
            <option value="GE">{t('countries:GE')}</option>
            <option value="DE">{t('countries:DE')}</option>
            <option value="GH">{t('countries:GH')}</option>
            <option value="GI">{t('countries:GI')}</option>
            <option value="GR">{t('countries:GR')}</option>
            <option value="GL">{t('countries:GL')}</option>
            <option value="GD">{t('countries:GD')}</option>
            <option value="GP">{t('countries:GP')}</option>
            <option value="GU">{t('countries:GU')}</option>
            <option value="GT">{t('countries:GT')}</option>
            <option value="GG">{t('countries:GG')}</option>
            <option value="GN">{t('countries:GN')}</option>
            <option value="GW">{t('countries:GW')}</option>
            <option value="GY">{t('countries:GY')}</option>
            <option value="HT">{t('countries:HT')}</option>
            <option value="HN">{t('countries:HN')}</option>
            <option value="HK">{t('countries:HK')}</option>
            <option value="HU">{t('countries:HU')}</option>
            <option value="IS">{t('countries:IS')}</option>
            <option value="IN">{t('countries:IN')}</option>
            <option value="ID">{t('countries:ID')}</option>
            <option value="IQ">{t('countries:IQ')}</option>
            <option value="IE">{t('countries:IE')}</option>
            <option value="IM">{t('countries:IM')}</option>
            <option value="IL">{t('countries:IL')}</option>
            <option value="IT">{t('countries:IT')}</option>
            <option value="JM">{t('countries:JM')}</option>
            <option value="JP">{t('countries:JP')}</option>
            <option value="JE">{t('countries:JE')}</option>
            <option value="JO">{t('countries:JO')}</option>
            <option value="KZ">{t('countries:KZ')}</option>
            <option value="KE">{t('countries:KE')}</option>
            <option value="KI">{t('countries:KI')}</option>
            <option value="XK">{t('countries:XK')}</option>
            <option value="KW">{t('countries:KW')}</option>
            <option value="KG">{t('countries:KG')}</option>
            <option value="LA">{t('countries:LA')}</option>
            <option value="LV">{t('countries:LV')}</option>
            <option value="LB">{t('countries:LB')}</option>
            <option value="LS">{t('countries:LS')}</option>
            <option value="LR">{t('countries:LR')}</option>
            <option value="LY">{t('countries:LY')}</option>
            <option value="LI">{t('countries:LI')}</option>
            <option value="LT">{t('countries:LT')}</option>
            <option value="LU">{t('countries:LU')}</option>
            <option value="MO">{t('countries:MO')}</option>
            <option value="MG">{t('countries:MG')}</option>
            <option value="MW">{t('countries:MW')}</option>
            <option value="MY">{t('countries:MY')}</option>
            <option value="MV">{t('countries:MV')}</option>
            <option value="ML">{t('countries:ML')}</option>
            <option value="MT">{t('countries:MT')}</option>
            <option value="MQ">{t('countries:MQ')}</option>
            <option value="MR">{t('countries:MR')}</option>
            <option value="MU">{t('countries:MU')}</option>
            <option value="YT">{t('countries:YT')}</option>
            <option value="MX">{t('countries:MX')}</option>
            <option value="MD">{t('countries:MD')}</option>
            <option value="MC">{t('countries:MC')}</option>
            <option value="MN">{t('countries:MN')}</option>
            <option value="ME">{t('countries:ME')}</option>
            <option value="MS">{t('countries:MS')}</option>
            <option value="MA">{t('countries:MA')}</option>
            <option value="MZ">{t('countries:MZ')}</option>
            <option value="MM">{t('countries:MM')}</option>
            <option value="NA">{t('countries:NA')}</option>
            <option value="NR">{t('countries:NR')}</option>
            <option value="NP">{t('countries:NP')}</option>
            <option value="NL">{t('countries:NL')}</option>
            <option value="NC">{t('countries:NC')}</option>
            <option value="NZ">{t('countries:NZ')}</option>
            <option value="NI">{t('countries:NI')}</option>
            <option value="NE">{t('countries:NE')}</option>
            <option value="NG">{t('countries:NG')}</option>
            <option value="NU">{t('countries:NU')}</option>
            <option value="MK">{t('countries:MK')}</option>
            <option value="NO">{t('countries:NO')}</option>
            <option value="OM">{t('countries:OM')}</option>
            <option value="PK">{t('countries:PK')}</option>
            <option value="PS">{t('countries:PS')}</option>
            <option value="PA">{t('countries:PA')}</option>
            <option value="PG">{t('countries:PG')}</option>
            <option value="PY">{t('countries:PY')}</option>
            <option value="PE">{t('countries:PE')}</option>
            <option value="PH">{t('countries:PH')}</option>
            <option value="PN">{t('countries:PN')}</option>
            <option value="PL">{t('countries:PL')}</option>
            <option value="PT">{t('countries:PT')}</option>
            <option value="PR">{t('countries:PR')}</option>
            <option value="QA">{t('countries:QA')}</option>
            <option value="RE">{t('countries:RE')}</option>
            <option value="RO">{t('countries:RO')}</option>
            <option value="RU">{t('countries:RU')}</option>
            <option value="RW">{t('countries:RW')}</option>
            <option value="WS">{t('countries:WS')}</option>
            <option value="SM">{t('countries:SM')}</option>
            <option value="ST">{t('countries:ST')}</option>
            <option value="SA">{t('countries:SA')}</option>
            <option value="SN">{t('countries:SN')}</option>
            <option value="RS">{t('countries:RS')}</option>
            <option value="SC">{t('countries:SC')}</option>
            <option value="SL">{t('countries:SL')}</option>
            <option value="SG">{t('countries:SG')}</option>
            <option value="SX">{t('countries:SX')}</option>
            <option value="SK">{t('countries:SK')}</option>
            <option value="SI">{t('countries:SI')}</option>
            <option value="SB">{t('countries:SB')}</option>
            <option value="SO">{t('countries:SO')}</option>
            <option value="ZA">{t('countries:ZA')}</option>
            <option value="GS">{t('countries:GS')}</option>
            <option value="KR">{t('countries:KR')}</option>
            <option value="SS">{t('countries:SS')}</option>
            <option value="ES">{t('countries:ES')}</option>
            <option value="LK">{t('countries:LK')}</option>
            <option value="BL">{t('countries:BL')}</option>
            <option value="SH">{t('countries:SH')}</option>
            <option value="KN">{t('countries:KN')}</option>
            <option value="LC">{t('countries:LC')}</option>
            <option value="MF">{t('countries:MF')}</option>
            <option value="PM">{t('countries:PM')}</option>
            <option value="VC">{t('countries:VC')}</option>
            <option value="SR">{t('countries:SR')}</option>
            <option value="SJ">{t('countries:SJ')}</option>
            <option value="SE">{t('countries:SE')}</option>
            <option value="CH">{t('countries:CH')}</option>
            <option value="TW">{t('countries:TW')}</option>
            <option value="TJ">{t('countries:TJ')}</option>
            <option value="TZ">{t('countries:TZ')}</option>
            <option value="TH">{t('countries:TH')}</option>
            <option value="TL">{t('countries:TL')}</option>
            <option value="TG">{t('countries:TG')}</option>
            <option value="TK">{t('countries:TK')}</option>
            <option value="TO">{t('countries:TO')}</option>
            <option value="TT">{t('countries:TT')}</option>
            <option value="TA">{t('countries:TA')}</option>
            <option value="TN">{t('countries:TN')}</option>
            <option value="TR">{t('countries:TR')}</option>
            <option value="TM">{t('countries:TM')}</option>
            <option value="TC">{t('countries:TC')}</option>
            <option value="TV">{t('countries:TV')}</option>
            <option value="UG">{t('countries:UG')}</option>
            <option value="UA">{t('countries:UA')}</option>
            <option value="AE">{t('countries:AE')}</option>
            <option value="GB">{t('countries:GB')}</option>
            <option value="US">{t('countries:US')}</option>
            <option value="UY">{t('countries:UY')}</option>
            <option value="UZ">{t('countries:UZ')}</option>
            <option value="VU">{t('countries:VU')}</option>
            <option value="VA">{t('countries:VA')}</option>
            <option value="VE">{t('countries:VE')}</option>
            <option value="VN">{t('countries:VN')}</option>
            <option value="WF">{t('countries:WF')}</option>
            <option value="EH">{t('countries:EH')}</option>
            <option value="YE">{t('countries:YE')}</option>
            <option value="ZM">{t('countries:ZM')}</option>
            <option value="ZW">{t('countries:ZW')}</option>
        </Select>
    )
}

export default MonthSelect
