import Space from "antd/lib/space";
import { useState } from "react";
import { useNavigate } from "react-router-dom";
import {useTranslation} from "react-i18next";
import FlexRow from "Components/FlexRow";
import Button from "Components/Button/Button";
import Modal from "Components/Modal";

interface Props {
    enabled: boolean,
    title: string,
    children: any
}
const RequiredPremiumModal = (props: Props) => {

    const { t } = useTranslation();
    const navigate = useNavigate();

    const [visible, setVisible] = useState<boolean>(false);

    const open = () => {
        setVisible(true)
    }

    const gotoSubscription = () => {
        setVisible(false);
        navigate('/organization/subscription')
    }

    const cancel = () => {
        setVisible(false);
    }

    if (!props.enabled) {
        return props.children;
    } else {
        return (
            <>
                <div className={"w-100"} onClick={open}>
                    {props.children}
                </div>
                <Modal
                    visible={visible}
                    onCancel={cancel}
                >
                    <h1>
                        {t('premium.title')} {props.title}
                    </h1>

                    <p>
                        {t('premium.description1')}
                    </p>
                    <p>
                        {t('premium.description2')} <b> {t('premium.pricePrUser')} </b>
                    </p>
                    <p>
                        {t('premium.description3')}
                    </p>
                    
                    <FlexRow justify={"end"}>
                        <Space>
                            <Button onClick={cancel}>{t('premium.close')}</Button>
                            <Button color={"primary"} onClick={gotoSubscription}>{t('premium.showMePremium')}</Button>
                        </Space>
                    </FlexRow>
                </Modal>
            </>
        )
    }
}

export default RequiredPremiumModal;
