import FlexRow from "Components/FlexRow";
import CheckmarkFilled from "Assets/Icons/CheckmarkFilled";

interface Props {
    text: string
    package: 'Free' | 'Premium'
}
const FeatureListItem = (props: Props) => {
    
    return (
        <FlexRow style={{fontWeight: 700}}>
            <CheckmarkFilled height={25} width={25} fill={props.package === "Premium" ? "#05245D" : ''} />
            <div style={{paddingLeft: 10}}>
                {props.text}
            </div>
        </FlexRow>
    )
}

export default FeatureListItem
