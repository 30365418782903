import React from "react";
import styles from './Group.module.scss';

interface Props {
    label: string;
    icon?: any;
    children?: React.ReactNode;
}

const Group = ({icon, label, children}: Props) => {
    
    return (
        <div className={styles.container}>
            <div className={styles.label}>
                {icon && (
                    <span className={styles.icon}>
                        {icon}
                    </span>
                )}
                <span className={styles.text}>
                    {label}
                </span>
            </div>
            <div className={styles.content}>
                {children}
            </div>
        </div>
    )
}

Group.displayName = 'Group'

export default Group
