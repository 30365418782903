import moment from "moment";
import { useEffect, useState } from "react";
import { useTranslation } from "react-i18next";

const useQuickDateRanges = () => {
    
    const { t } = useTranslation();
    const [ranges, setRanges] = useState<any>([]);
    
    useEffect(() => {
        const quickDateRanges = []
        quickDateRanges[t('today')] = [moment(), moment()]
        quickDateRanges[t('thisWeek')] = [moment().startOf('week'), moment().endOf('week')];
        quickDateRanges[t('lastWeek')] = [moment().startOf('week').subtract(1, 'week'), moment().endOf('week').subtract(1, 'week')];
        quickDateRanges[t('thisMonth')] = [moment().startOf('month'), moment().endOf('month')];
        quickDateRanges[t('lastMonth')] = [moment().subtract(1, 'months').startOf('month'), moment().subtract(1, 'months').endOf('month')];
        quickDateRanges[t('thisYear')] = [moment().startOf('year'), moment().endOf('year')];
        quickDateRanges[t('lastYear')] = [moment().subtract(1, 'year').startOf('year'), moment().subtract(1, 'year').endOf('year')];
        
        setRanges(quickDateRanges)
    }, [t])
    
    return ranges;
}

export default useQuickDateRanges
