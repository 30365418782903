import * as React from "react"

const User = (props: React.SVGProps<SVGSVGElement>) => {
    return (
        <svg
            data-name="user (1)"
            xmlns="http://www.w3.org/2000/svg"
            width={26.859}
            height={32.193}
            viewBox="0 0 26.859 32.193"
            {...props}
        >
            <path
                data-name="Path 53"
                d="M13.226 15.507a7.5 7.5 0 005.483-2.272 7.5 7.5 0 002.271-5.482 7.5 7.5 0 00-2.272-5.482 7.752 7.752 0 00-10.965 0 7.5 7.5 0 00-2.272 5.482 7.5 7.5 0 002.27 5.483 7.505 7.505 0 005.485 2.271zm-4.148-11.9a5.865 5.865 0 018.3 0 5.606 5.606 0 011.719 4.148 5.606 5.606 0 01-1.722 4.145 5.865 5.865 0 01-8.3 0 5.6 5.6 0 01-1.719-4.148 5.605 5.605 0 011.722-4.147zm0 0"
            />
            <path
                data-name="Path 54"
                d="M26.794 24.755a19.151 19.151 0 00-.261-2.034 16.028 16.028 0 00-.5-2.045 10.1 10.1 0 00-.841-1.907 7.19 7.19 0 00-1.268-1.652 5.59 5.59 0 00-1.821-1.144 6.294 6.294 0 00-2.325-.421 2.36 2.36 0 00-1.26.534c-.378.246-.82.531-1.313.846a7.522 7.522 0 01-1.7.748 6.6 6.6 0 01-4.155 0 7.5 7.5 0 01-1.7-.748c-.489-.312-.931-.6-1.314-.847a2.357 2.357 0 00-1.26-.534 6.286 6.286 0 00-2.325.421 5.585 5.585 0 00-1.821 1.144 7.192 7.192 0 00-1.267 1.652 10.121 10.121 0 00-.841 1.908 16.066 16.066 0 00-.5 2.045 19.021 19.021 0 00-.261 2.035 27.393 27.393 0 00-.064 1.9A5.346 5.346 0 001.585 30.7a5.715 5.715 0 004.091 1.492h15.5a5.715 5.715 0 004.091-1.492 5.344 5.344 0 001.588-4.044 25.607 25.607 0 00-.061-1.901zm-2.824 4.579a3.848 3.848 0 01-2.79.972H5.679a3.848 3.848 0 01-2.79-.972 3.491 3.491 0 01-1-2.678c0-.6.02-1.2.06-1.771a17.156 17.156 0 01.236-1.832 14.169 14.169 0 01.44-1.8 8.24 8.24 0 01.684-1.551 5.331 5.331 0 01.932-1.221 3.708 3.708 0 011.212-.753 4.341 4.341 0 011.486-.287c.066.035.184.1.374.226.388.253.835.541 1.329.857a9.346 9.346 0 002.132.953 8.481 8.481 0 005.316 0 9.357 9.357 0 002.133-.953c.506-.323.94-.6 1.328-.856.191-.124.308-.191.374-.226a4.344 4.344 0 011.486.287 3.713 3.713 0 011.211.753 5.316 5.316 0 01.932 1.221 8.212 8.212 0 01.685 1.551 14.135 14.135 0 01.44 1.8 17.3 17.3 0 01.236 1.833c.04.57.06 1.165.06 1.77a3.49 3.49 0 01-1.005 2.677zm0 0"
            />
        </svg>
    )
}

export default User
