import AccessLevelRoute from "../../Components/AccessLevelRoute";
import { Route, Routes } from "react-router-dom";
import EconomicCallback from "Components/Integrations/Economic/EconomicCallback";
import EconomicIntegrationSettings from "Components/Integrations/Economic/EconomicIntegrationSettings";
import IntegrationsPage from "Pages/Organization/IntegrationsPage";
import ZapierPage from "Pages/Organization/ZapierPage";

const IntegrationRoutes = () => {
    
    return (
        <Routes>
            <Route path={`economic`} element={
                <AccessLevelRoute requiredAccessLevel={200} >
                    <EconomicIntegrationSettings />
                </AccessLevelRoute>
            } />
    
            <Route path={`economic/callback`} element={
                <AccessLevelRoute requiredAccessLevel={200}>
                    <EconomicCallback />
                </AccessLevelRoute>
            } />
            
            <Route path={`zapier`} element={
                <AccessLevelRoute requiredAccessLevel={200}>
                    <ZapierPage />
                </AccessLevelRoute>
            } />
            
            <Route path={`*`} element={
                <AccessLevelRoute requiredAccessLevel={200}>
                    <IntegrationsPage />
                </AccessLevelRoute>
            } />
        </Routes>
    )
}

export default IntegrationRoutes;
