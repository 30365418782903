import * as React from "react"

const Edit = (props: React.SVGProps<SVGSVGElement>) => {
    return (
        <svg
            xmlns="http://www.w3.org/2000/svg"
            width={24}
            height={23.999}
            viewBox="0 0 24 23.999"
            {...props}
        >
            <g data-name="003-edit-1" opacity={0.261}>
                <path
                    data-name="Path 166"
                    d="M19 11.999a1 1 0 00-1 1v8a1 1 0 01-1 1H3a1 1 0 01-1-1v-14a1 1 0 011-1h8a1 1 0 000-2H3a3 3 0 00-3 3v14a3 3 0 003 3h14a3 3 0 003-3v-8a1 1 0 00-1-1z"
                />
                <path
                    data-name="Path 167"
                    d="M9.376 11.088a.506.506 0 00-.137.255l-.707 3.536a.5.5 0 00.491.6.473.473 0 00.1-.01l3.535-.707a.5.5 0 00.256-.137l7.912-7.912-3.537-3.537z"
                />
                <path
                    data-name="Path 168"
                    d="M23.268.731a2.5 2.5 0 00-3.535 0l-1.384 1.384 3.535 3.535 1.384-1.384a2.5 2.5 0 000-3.535z"
                />
            </g>
        </svg>
    )
}

export default Edit
