import Select from "antd/lib/select";
import { CSSProperties } from "react";
import { useCustomers } from "Apis/CustomerApi";
import { useTranslation } from "react-i18next";

interface Props {
    value: number | string;
    onChange: any;
    bordered?: boolean;
    showArrow?: boolean;
    showClear?: boolean;
    fullWidth?: boolean;
    style?: CSSProperties;
    className?: string;
    includeNull?: boolean;
}
const CustomerSelect = (props: Props) => {
    
    const { t } = useTranslation();
    const { data: customers, isLoading: isLoadingCustomers } = useCustomers();
    
    const filterOption = (value, option) => {
        const { children } = option;
        
        if (option.options) {
            return option.options.includes((child) => child.props.children.toLowerCase().indexOf(value.toLowerCase()) >= 0);
        }
        
        return children.toLowerCase().indexOf(value.toLowerCase()) >= 0;
    };
    
    const selectProps = {
        allowClear: true,
        showSearch: true,
        filterOption: filterOption
    }
    
    return (
        <Select
            style={props.style}
            placeholder={t('selectCustomer')}
            value={props.value}
            {...selectProps}
            onChange={(value) => {
                props.onChange(value);
            }}
            bordered={props.bordered}
            className={props.className ? props.className : props.fullWidth ? "w-100" : ""}
            loading={isLoadingCustomers}
            showArrow={props.showArrow}
            allowClear={props.showClear}
        >
            {props.includeNull && <Select.Option value={''}>{t('all')}</Select.Option>}
            {customers?.map((customer) => (
                <Select.Option value={customer.id} key={customer.id}>
                    {customer.name}
                </Select.Option>
            ))}
        </Select>
    )
}

CustomerSelect.defautProps = {
    bordered: true,
    fullWidth: true
}

export default CustomerSelect
