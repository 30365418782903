import { useContext } from 'react';
import { TimeSlotContextProvider } from "Pages/Registrations/Components/WeekCalendarTimeSlotProvider";

const WeekCalendarTImeGutterHeader = () => {
    
    const timeSlotProvider = useContext(TimeSlotContextProvider);
    
    

    return (
        <>
            <button type={"button"} style={styles.button} onClick={timeSlotProvider.smallerTimeSlots}>
                <span style={styles.content}>-</span>
            </button>
            <button type={"button"} style={styles.button} onClick={timeSlotProvider.biggerTimeSlots}>
                <span style={styles.content}>+</span>
            </button>
        </>
    )
}

const styles = {
    button: {
        display: 'contents',
    },
    content: {
        cursor: 'pointer',
        borderColor: '#DDD',
        borderWidth: 1,
        borderStyle: 'solid',
        height: 30,
        borderRadius: 3,
        display: 'flex',
        justifyContent: 'center',
        alignItems: 'center',
        margin: 1,
        padding: 4
    }
}

export default WeekCalendarTImeGutterHeader
