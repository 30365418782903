import {Navigate, Route, Routes} from "react-router-dom";
import Dashboard from './Dashboard';
import RegistrationCardGrid from "Pages/Registrations/Components/RegistrationCardGrid/RegistrationCardGrid";
import RegistrationCardList from "Pages/Registrations/Components/RegistrationCardList/RegistrationCardList";
import {WeekCalendarPage} from "Pages/Registrations/Components/DayCalendarPage";
import { ReportPage as ReportPageV2 } from "Pages/Reports/ReportPage";

const RegistrationPage = () => {
    
    return (
            <Routes>
                <Route path={`dashboard`} element={<Dashboard />} />
    
                <Route path={`dashboard/grid`} element={<RegistrationCardGrid />} />
    
                <Route path={`dashboard/list`} element={<RegistrationCardList />} />
    
                <Route path={`dashboard/day`} element={<Navigate to={"/registrations/dashboard/week"} />} />
    
                <Route path={`dashboard/week`} element={<WeekCalendarPage />} />
    
                <Route path={`reports`} element={<ReportPageV2 />} />
                
                <Route path={"*"} element={<Navigate to={"/registrations/dashboard"} />} />
            </Routes>
    )
}

export default RegistrationPage;
