import React from "react";

const CheckmarkUnfilled = (props: React.SVGProps<SVGSVGElement>) => {
    return (
        <svg
            xmlns="http://www.w3.org/2000/svg"
            width={38}
            height={38}
            viewBox="0 0 38 38"
            {...props}
        >
            <g data-name="Group 338">
                <circle data-name="Ellipse 43" cx={19} cy={19} r={19} fill="#e3e3e3" />
            </g>
        </svg>
    )
}

export default CheckmarkUnfilled
