import {CvrSearchResult} from "./Models/CvrSearchResult";
import { useQuery } from "react-query";
import { request } from "Apis/BaseApi";
import { VatInformationModel } from "Apis/Models/VatInformationModel";

export const lookupCVR = async (searchParam: string): Promise<CvrSearchResult[]> => {

    return [];
    /*
    const client = axios.create({
        baseURL: 'https://cvrservice.dinero.dk/v2/search'
    });

    const response = await client({
        method: 'GET',
        url: `?q=${searchParam}`
    });

    return response.data;
     */
}


export const VATINFORMATION_SEARCH_CACHE_KEY = "VATINFORMATION_SEARCH_CACHE_KEY"
export const useVatInformationSearch = (query: string) => {
    return useQuery([VATINFORMATION_SEARCH_CACHE_KEY, query], async () => {
        return request<VatInformationModel[]>({
            url: '/vatinformation?query=' + query,
            method: 'GET'
        })
    }, {
        enabled: !!query
    })
}
