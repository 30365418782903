import React, {useEffect, useState} from "react";
import {Controller, useWatch} from "react-hook-form";
import styles from "Components/InputV2/ControlledInput.module.scss";

interface Props extends React.DetailedHTMLProps<React.InputHTMLAttributes<HTMLSelectElement>, HTMLSelectElement> {
    errors?: any,
    name: string,
    rules?: any,
    control: any,
    children: any,
}
const Select = (props: Props) => {
    
    const watch = useWatch({
        control: props.control,
        name: props.name,
        defaultValue: ''
    });
    
    useEffect(() => {
        if (watch || props.defaultValue) {
            setActive(true)
        }
    }, [watch, props.defaultValue])
    
    const [active, setActive] = useState<boolean>();
    
    const activateField = () => {
        setActive(true)
    }
    
    const deactivateField = () => {
        if (!watch) {
            setActive(false)
        }
    }
    
    return (
        <Controller
            name={props.name}
            rules={props.rules}
            defaultValue={props.defaultValue ?? ''}
            control={props.control}
            render={(
                { onChange, value },
            ) => (
                <div className={`${styles.container} ${props.errors && props.errors[props.name ?? ''] ? styles.error : ''}`}>
                    <label className={`${styles.label} text-ellipsis ${active ? styles.fieldActive : ''}`}>
                        {props.placeholder}
                    </label>
                    <select
                        className={`${styles.input} ${styles.floatingLabel}`}
                        onChange={onChange}
                        value={value ?? ''}
                        onFocus={activateField}
                        onBlur={deactivateField}
                    >
                        {props.children}
                    </select>
                </div>
            )}
        />
    )
}

export default Select
