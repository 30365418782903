import {request} from "./BaseApi";
import {DateUtils} from "Utils/DateUtils";
import {deserialize, Registration, RegistrationsByDate, SortedRegistrations} from "./Models/Registration.type";
import { useInfiniteQuery, useMutation, useQuery, useQueryClient } from "react-query";
import {openErrorNotification, openSuccessNotification} from "Utils/NotificationUtils";
import {useTranslation} from "react-i18next";
import moment from "moment";
import { AddTagsToRegistrationsModel } from "Apis/Models/AddTagsToRegistrationsModel";

export const RUNNING_REGISTRATION_CACHE_KEY = 'RUNNING_REGISTRATION_CACHE_KEY';
export const useRunningRegistration = () => {
    return useQuery(RUNNING_REGISTRATION_CACHE_KEY, async () => {
        try {
            const response =  await request<Registration>({
                url: `/registration/v2/running`,
                method: 'GET'
            });

            return deserialize(response);
        } catch (e) {
            const registration:Registration = {
                description: '',
                startedAt: null,
                stoppedAt: null,
                billable: false
            }
            return registration
        }
    })
}

export const OWN_REGISTRATIONS_CACHE_KEY = 'OWN_REGISTRATIONS_CACHE_KEY';
export const useOwnRegistrations = () => {
    const pageSize = 30;
    
    return useInfiniteQuery(OWN_REGISTRATIONS_CACHE_KEY, async ({ pageParam = moment().add('3', 'months').toDate() }: any) => {
        const response = await request<SortedRegistrations>({
            url: `/registration/sorted?from=${DateUtils.getDateOnlyString(pageParam)}&pageSize=${pageSize}`,
            method: 'GET'
        })

        let registrations: RegistrationsByDate = {};

        Object.keys(response.registrations).forEach((index) => {
            registrations[index] = response.registrations[index].map((registration) => deserialize(registration));
        })

        return {
            ...response,
            registrations,
            registrationList: response.registrationList.map((registration) => deserialize(registration)),
            to: new Date(response.to),
            from: new Date(response.from)
        };
    }, {
        getNextPageParam: (lastGroup, allGroups) => {
            let count = 0;
            
            Object.keys(lastGroup.registrations).forEach(x => count += lastGroup.registrations[x].length)

            return count >= pageSize ? moment(lastGroup.from).subtract(1, 'day').toDate() : false;
        },
    })
}

export const OWN_REGISTRATIONS_BY_PERIOD_CACHE_KEY = 'OWN_REGISTRATIONS_BY_PERIOD_CACHE_KEY';
export const useOwnRegistrationsByPeriod = (from: Date, to: Date) => {
    return useQuery([OWN_REGISTRATIONS_BY_PERIOD_CACHE_KEY, from, to], async () => {
        const response =  await request<Registration[]>({
            url: `/registration/period?from=${DateUtils.getDateOnlyString(from)}&to=${DateUtils.getDateOnlyString(to)}`,
            method: 'GET'
        })

        return response.map(x => deserialize(x))
    })
}

export const useCloneRegistration = () => {
    const queryClient = useQueryClient();
    
    return useMutation(async (registration: Registration) => {
         return await request<Registration>({
            url: `/registration/clone/${registration.id}`,
            method: 'POST'
        });
    }, {
        onSuccess: () => {
            queryClient.invalidateQueries(RUNNING_REGISTRATION_CACHE_KEY)
            queryClient.invalidateQueries(OWN_REGISTRATIONS_CACHE_KEY)
            queryClient.invalidateQueries(OWN_REGISTRATIONS_BY_PERIOD_CACHE_KEY)
        }
    })
}

export const useSaveRegistration = () => {
    
    const queryClient = useQueryClient();
    const { t } = useTranslation();
    
    return useMutation(async (registration: Registration) => {
        if (registration.id && registration.id !== 0) {
            await request<Registration>({
                url: `/registration`,
                method: 'PUT',
                data: registration
            })
        } else {
            await request<Registration>({
                url: `/registration`,
                method: 'POST',
                data: registration
            })
        }
    }, {
        onMutate: (snapShot) => {
            const previousRunning = queryClient.getQueryData(RUNNING_REGISTRATION_CACHE_KEY);

            queryClient.setQueryData(RUNNING_REGISTRATION_CACHE_KEY, snapShot)

            return { previousRunning }
        },
        onSettled: () => {
            queryClient.invalidateQueries(RUNNING_REGISTRATION_CACHE_KEY)
            queryClient.invalidateQueries(OWN_REGISTRATIONS_CACHE_KEY)
            queryClient.invalidateQueries(OWN_REGISTRATIONS_BY_PERIOD_CACHE_KEY)
        },
        onError: (error: any, variables, context: any) => {
            if (variables.stoppedAt == null) {
                queryClient.setQueryData(RUNNING_REGISTRATION_CACHE_KEY, context.previousRunning)
            }

            if (error.status === 400) {
                openErrorNotification(t('errors:anErrorOccurred'), t(error.data.message));
            }
        }
    })
}

export const useDeleteRegistration = () => {

    const { t } = useTranslation();
    const queryClient = useQueryClient();
    
    return useMutation(async (id: number) => {
        await request<Registration>({
            url: `/registration/${id}`,
            method: 'DELETE',
        })
    }, {
        onSuccess: () => {
            queryClient.invalidateQueries(RUNNING_REGISTRATION_CACHE_KEY)
            queryClient.invalidateQueries(OWN_REGISTRATIONS_CACHE_KEY)
            queryClient.invalidateQueries(OWN_REGISTRATIONS_BY_PERIOD_CACHE_KEY)
            openSuccessNotification(t('success'), t('registrations:registrationDeleted'))
        }
    })
}

export const useAddTagsToRegistrations = () => {
    return useMutation(async (model: AddTagsToRegistrationsModel) => {
        await request<Registration>({
            url: `/registration/tags`,
            method: 'PUT',
            data: model
        })
    })
}
