import { useState } from 'react';
import styles from './Menu.module.scss';
import {auth} from "FirebaseSetup/auth";
import MenuLink from "Navigation/MenuLink";
import {hasAccess} from "Utils/AccessUtils";
import TimeOps from "Assets/Icons/TimeOps";
import Copy from "Assets/Icons/Copy";
import Cog from "Assets/Icons/Cog";
import User from "Assets/Icons/User";
import SubMenuLink from "Navigation/SubMenuLink";
import { Link, useNavigate } from "react-router-dom";
import FlexRow from "Components/FlexRow";
import DineroIcon from "Assets/Icons/Dinero";
import {useTranslation} from "react-i18next";
import { useQueryClient } from "react-query";
import TimeOpsLogoWhite from "Assets/Icons/TimeOpsLogoWhite";
import { useAuthProvider } from "Providers/AuthProvider";
import Integrations from "Assets/Icons/Integrations";
import SignOut from "Assets/Icons/SignOut";
import TagInactive from "Assets/Icons/TagInactive";
import Button from "Components/Button";

declare var gist: any;
declare var window: any;

const LanguageKey = 'i18nextLng';

const Menu = () => {
    
    const { t, i18n } = useTranslation();
    const { userContext } = useAuthProvider();
    const [open, setOpen] = useState<boolean>(false);
    const queryClient = useQueryClient();
    const navigate = useNavigate();

    const changeLanguage = () => {
        const languages = [
            'da',
            'en'
        ]
        
        const newLanguage = languages.filter(x => x !== i18n.language)[0];

        localStorage.setItem(LanguageKey, newLanguage);

        i18n.changeLanguage(newLanguage);
        window.location.reload();
    }

    const organizationMenuItems = () => {
        if (hasAccess(200, userContext!)) {
            return [
                <SubMenuLink onClick={closeMenu} key={'Organization'} to={"/organization/show"}>{t('menu.organizationInfo')}</SubMenuLink>,
                <SubMenuLink onClick={closeMenu} key={'Subscription'} to={"/organization/subscription"}>{t('menu.subscription')}</SubMenuLink>,
                <SubMenuLink onClick={closeMenu} key={'Users'} to={"/organization/users/list"}>{t('menu.users')}</SubMenuLink>,
                <SubMenuLink onClick={closeMenu} key={'Profile'} to={"/organization/users/me"}>{t('menu.myProfile')}</SubMenuLink>,
            ]
        } else {
            return [
                <SubMenuLink onClick={closeMenu} key={'Profile'} to={"/organization/users/me"}>{t('menu.myProfile')}</SubMenuLink>,
            ]
        }
    }
    
    const closeMenu = () => {
        setOpen(false)
    }
    
    const signOut = async () => {
        await auth.signOut();
        queryClient.clear();
        gist.chat('shutdown');
    }

    return (
        <div className={`${styles.menu} ${open ? styles.open : ''}`}>
            <div className={`${styles.menuBackground}`}>
                <div className={styles.menuContainer}>
                    <div className={styles.logoContainer}>
                        <div className={styles.logo}>
                            <TimeOpsLogoWhite />
                        </div>
                        <div className={styles.toggle} onClick={() => setOpen(!open)}>
                            <div className={styles.burger} />
                            <div className={styles.burger} />
                            <div className={styles.burger} />
                        </div>

                        <div className={styles.separator} />
                    </div>
                </div>


                <div className={styles.content}>
                    <MenuLink onClick={closeMenu} icon={<TimeOps />} to="/registrations/dashboard" subLinks={[
                        <SubMenuLink onClick={closeMenu} key={'Grid'} to={"/registrations/dashboard/Grid"}>{t('menu.grid')}</SubMenuLink>,
                        <SubMenuLink onClick={closeMenu} key={'List'} to={"/registrations/dashboard/List"}>{t('menu.list')}</SubMenuLink>,
                        <SubMenuLink onClick={closeMenu} key={'Week'} to={"/registrations/dashboard/week"}>{t('menu.weekCalender')}</SubMenuLink>,
                    ]}>{t('menu.dashboard')}</MenuLink>

                    {hasAccess(100, userContext!) && (
                        <MenuLink onClick={closeMenu} icon={<Copy />} to="/registrations/reports">{t('menu.reports')}</MenuLink>
                    )}

                    {hasAccess(150, userContext!) && (
                        <MenuLink onClick={closeMenu} icon={<User />} to="/customers" subLinks={[
                            <SubMenuLink onClick={closeMenu} key={'1'} exact to={"/customers"}>{t('menu.customers')}</SubMenuLink>,
                            <SubMenuLink onClick={closeMenu} key={'2'} to={"/customers/projects"}>{t('menu.projects')}</SubMenuLink>,
                        ]}>{t('menu.customers')}</MenuLink>
                    )}

                    {hasAccess(200, userContext!) && (
                        <MenuLink onClick={closeMenu} icon={<TagInactive includeFill={false} />} to="/tags">{t('menu.tags')}</MenuLink>
                    )}

                    {userContext?.hasDineroIntegration && hasAccess(200, userContext!) && (
                        <MenuLink onClick={closeMenu}  icon={<DineroIcon />} to="/dinero" subLinks={[
                            <SubMenuLink onClick={closeMenu} key={'1'} to={"/dinero/invoices"}>{t('menu.dinero.invoices')}</SubMenuLink>,
                            <SubMenuLink onClick={closeMenu} key={'2'} to={"/dinero/contacts"}>{t('menu.dinero.contacts')}</SubMenuLink>,
                            <SubMenuLink onClick={closeMenu} key={'3'} to={"/dinero/settings"}>{t('menu.dinero.settings')}</SubMenuLink>,
                        ]}>{t('menu.dinero.title')}</MenuLink>
                    )}

                    {hasAccess(200, userContext!) && (
                        <MenuLink onClick={closeMenu}  icon={<Integrations />} to="/integrations" subLinks={[
                            <SubMenuLink onClick={closeMenu} exact key={'1'} to={"/integrations"}>{t('menu.integrations')}</SubMenuLink>,
                            <SubMenuLink onClick={closeMenu} key={'2'} to={"/integrations/zapier"}>Zapier</SubMenuLink>,
                        ]}>{t('menu.integrations')}</MenuLink>
                    )}

                    {hasAccess(100, userContext!) && (
                        <MenuLink icon={<Cog />} to="/organization" subLinks={organizationMenuItems()}>
                            {t('menu.settings')}
                        </MenuLink>
                    )}

                    <MenuLink icon={<SignOut />} onClick={signOut} to={"/auth/login"}>{t('menu.signOut')}</MenuLink>
                </div>

                <FlexRow justify={"center"} direction={"column"} className={styles.footer} >

                    <div className={"clickable"} onClick={changeLanguage}>
                        {t('menu.changeLanguage')}
                    </div>

                    {(userContext?.licenseType === "Trial" || !userContext?.hasActiveLicense) && (
                        <Button onClick={() => navigate('/organization/subscription')} style={{padding: '5px 10px', marginTop: 24}} color={"primary"}>{t('menu.getPremiumButton')}</Button>
                    )}

                    <Link to={"/organization/subscription"} className={styles.versionTag}>
                        TimeOps {userContext?.hasActiveLicense ? 'Premium' : 'Free'}
                    </Link>
                </FlexRow>
            </div>
        </div>
    )
}

export default Menu
