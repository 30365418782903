import * as React from "react"
import { SVGProps } from "react"

const CheckmarkIcon = (props: SVGProps<SVGSVGElement>) => (
    <svg xmlns="http://www.w3.org/2000/svg" width="1em" height="1em" {...props} viewBox="0 0 17.514 12.741">
        <path
            data-name="Path 1208"
            d="M6.273 12.741a1.5 1.5 0 0 1-1.061-.439L.439 7.53a1.5 1.5 0 0 1 0-2.121 1.5 1.5 0 0 1 2.121 0l3.712 3.712 8.68-8.68a1.5 1.5 0 0 1 2.121 0 1.5 1.5 0 0 1 0 2.121l-9.741 9.741a1.5 1.5 0 0 1-1.059.438Z"
            fill={props.fill ?? "#0c5ef5"}
        />
    </svg>
)

export default CheckmarkIcon
