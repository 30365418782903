import { createContext, useContext, useState } from "react";
import RegistrationModalV2 from "Components/RegistrationModalV2";
import { Registration } from "Apis/Models/Registration.type";

type Props = {
  selectRegistration: Function
}

export const EditRegistrationProvider = createContext<Props>({
  selectRegistration: (registration: Registration) => {}
});

const EditRegistrationModalProvider = ({children}) => {
  
  const [modalVisible, setModalVisible] = useState<boolean>(false);
  const [selectedRegistration, setSelectedRegistration] = useState<Registration>();
  
  const selectRegistration = (registration: Registration) => {
    setSelectedRegistration(registration);
    setModalVisible(true);
  }
  
  const closeModal = () => {
      setSelectedRegistration(undefined);
      setModalVisible(false);
  }
  
  return (
      <EditRegistrationProvider.Provider value={{selectRegistration}}>
        {children}
        <RegistrationModalV2 visible={modalVisible} registration={selectedRegistration} onCancel={closeModal} />
      </EditRegistrationProvider.Provider>
  );
}

export default EditRegistrationModalProvider;

export const useEditRegistrationModal = () => {
  return useContext(EditRegistrationProvider);
}
