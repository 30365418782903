import Button from "Components/Button/Button";
import {useNavigate} from "react-router-dom";
import RequiresPremiumModal from "Components/RequiresPremiumModal";
import FlexRow from "Components/FlexRow";
import {useTranslation} from "react-i18next";
import Spinner from "Components/Spinner";
import { useAuthorizationUrl, useDineroIntegration } from "Pages/Dinero/Api/DineroIntegrationApi";
import { useAuthProvider } from "Providers/AuthProvider";

const DineroIntegrationOverview = () => {
    
    const { t } = useTranslation();
    const navigate = useNavigate();
    const { data: integration, isLoading } = useDineroIntegration()
    const { userContext } = useAuthProvider();
    
    const { mutateAsync: authorizationUrlMutation, isLoading: isLoadingUrl } = useAuthorizationUrl();
    
    const initialConnection = async () => {
        const response = await authorizationUrlMutation();
        
        window.location.href = response!.url;
    }
    
    let button;
    
    if (isLoading) {
        button = (
            <Spinner />
        )
    } else if (!integration) {
        button = (
            <RequiresPremiumModal enabled={!userContext?.hasActiveLicense} title={t('dinero:overview.title')}>
                <Button block loading={isLoadingUrl} onClick={initialConnection} color={"primary"}>{t('dinero:overview.connectToDinero')}</Button>
            </RequiresPremiumModal>
        )
    } else {
        button = (
            <Button block color={"primary"} onClick={() => navigate('/dinero/settings')}>
                {!integration.dineroOrganizationId ? t('dinero:overview.finishSetup') : t('dinero:overview.updateIntegration')}
            </Button>
        )
    }
    
    return (
        <FlexRow className="box white dont-hover padding30 h-100" justify={"space-between"} direction={"column"}>
            <img src={"/icons/dinero-clip.svg"} alt={"Dinero logo"} style={{paddingBottom: '30px', maxWidth: '100px'}} />
            <div className={"w-100"}>
                <b>{t('dinero:overview.title')}</b>
                <p>
                    {t('dinero:overview.description')}
                </p>
            </div>
            {button}
        </FlexRow>
    )
}

export default DineroIntegrationOverview;
