import { useEffect, useState } from 'react';
import {
    Routes,
    useLocation,
    Route, Navigate
} from "react-router-dom";
import RequireAuthentication from "Components/RequireAuthentication";
import DefaultLayout from 'Layouts/Default/Default';
import AccessLevelRoute from "Components/AccessLevelRoute";
import {
    isMobile,
    isAndroid,
    isIOS
} from "react-device-detect";
import Modal from "Components/Modal";
import DineroRoutes from "Pages/Dinero/DineroRoutes";
import {useTranslation} from "react-i18next";
import ErrorBoundary from "ErrorBoundary";
import AuthRoutes from "Pages/Auth/AuthRoutes";
import { useAuthProvider } from "Providers/AuthProvider";
import IntegrationRoutes from "Pages/Organization/IntegrationRoutes";
import TagRoutes from "Pages/Tags/TagRoutes";
import FlexRow from "Components/FlexRow";
import Button from "Components/Button/Button";
import RegistrationsPage from "Pages/Registrations/RegistrationsPage";
import CustomerRoutes from "Pages/Customers/CustomerRoutes";
import OrganizationRoutes from "Pages/Organization/OrganizationRoutes";
import ReportRoutes from "Pages/Reports/ReportRoutes";
import EconomicCallback from "Components/Integrations/Economic/EconomicCallback";
import SystemError from "Components/SystemError";
import Space from "Components/Space";

declare var ga: any;
declare var window: any;
declare var gist: any;

const TimeOpsRoutes = () => {

    const location = useLocation();
    const { t } = useTranslation();

    const { userContext, hasSystemError } = useAuthProvider();
    const [showDownloadApp, setShowDownloadApp] = useState<boolean>(false);

    useEffect(() => {
        if (!window.location.hostname.includes('localhost')) {
            gist.trackPageView();
            ga('set', 'page', location.pathname);
            ga('send', 'pageview');
        }
    }, [location.pathname])
    
    useEffect(() => {
        setShowDownloadApp(!location.search.includes("noPopup") && isMobile && !!userContext?.organization)
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [])

    const openAppStore = () => {
        
        if (isAndroid) {
            window.location.href = 'https://play.google.com/store/apps/details?id=org.TimeOps.MobileApp';
        }

        if (isIOS) {
            window.location.href = 'https://apps.apple.com/us/app/timeops/id1505073053';
        }
    }
    

    return (
        <ErrorBoundary>
            <Modal
                visible={showDownloadApp}
                onCancel={() => setShowDownloadApp(false)}
                title={t('downloadApp.title')}
            >
                <Space direction={"vertical"} size={"large"}>
                    {t('downloadApp.description')}
                    
                    <FlexRow justify={"end"}>
                        <Space direction={"horizontal"}>
                            <Button onClick={() => setShowDownloadApp(false)}>{t('downloadApp.continue')}</Button>
                            <Button color={"primary"} onClick={openAppStore}>{t('downloadApp.ok')}</Button>
                        </Space>
                    </FlexRow>
                </Space>
            </Modal>
    
            {hasSystemError ? (
                <SystemError />
            ) : (
                <Routes>
                    <Route path="/auth/*" element={<AuthRoutes />} />
        
                    <Route path={"*"} element={
                        <RequireAuthentication>
                            {userContext?.organization ? (
                                <DefaultLayout>
                                    <Routes>
                                        <Route path="/registrations/*" element={<RegistrationsPage/>} />
    
                                        <Route path="/reports/*" element={<ReportRoutes />} />
                    
                                        <Route path="/customers/*" element={<CustomerRoutes />} />
                    
                                        <Route path="/tags/*" element={<TagRoutes />} />
                    
                                        <Route path="/dinero/*" element={
                                            <AccessLevelRoute requiredAccessLevel={200}>
                                                <DineroRoutes />
                                            </AccessLevelRoute>
                                        } />
                    
                                        <Route path="/integrations/*" element={
                                            <AccessLevelRoute requiredAccessLevel={200}>
                                                <IntegrationRoutes />
                                            </AccessLevelRoute>
                                        } />
                                        
                                        <Route path={"/organization/integrations/economic/callback"} element={
                                            <AccessLevelRoute requiredAccessLevel={200}>
                                                <EconomicCallback />
                                            </AccessLevelRoute>
                                        } />
                                        
                                        <Route path="/organization/*" element={<OrganizationRoutes />} />
                    
                                        <Route path="*" element={<Navigate to="/registrations/dashboard"/>} />
                                    </Routes>
                                </DefaultLayout>
                            ) : (
                                <Routes>
                                    <Route path="*" element={<Navigate to={"/auth/organization/create"} />} />
                                </Routes>
                            )}
                        </RequireAuthentication>
                    } />
                </Routes>
            )}
        </ErrorBoundary>
    );
}

export default TimeOpsRoutes;
