import styles from './Announcement.module.scss';
import { useAuthProvider } from "Providers/AuthProvider";

interface Props {
    children;
}
const PremiumTrialAnnouncement = ({children}: Props) => {
    
    const { userContext } = useAuthProvider();
    
    if (userContext!.licenseType !== "Trial") {
        return null;
    }
    
    return (
        <div className={styles.container}>
            {children}
        </div>
    )
}

export default PremiumTrialAnnouncement
