import Space from "antd/lib/space";import { useTranslation } from "react-i18next";
import { useAuthorizationUrl } from "Pages/Dinero/Api/DineroIntegrationApi";
import Button from "Components/Button/Button";
import FlexRow from "Components/FlexRow";
import DineroConnectionLost from "Assets/Icons/DineroConnectionLost";

const DineroReconnectPage = () => {
    
    const { t } = useTranslation();
    
    const { mutateAsync: authorizationUrlMutation, isLoading } = useAuthorizationUrl();
    
    const reconnect = async () => {
        const response = await authorizationUrlMutation();
        
        window.location.href = response!.url;
    }
    
    return (
        <FlexRow justify={"center"} className={"h-100"}>
            <Space direction={"vertical"} align={"center"} size={"large"}>
                <DineroConnectionLost width={400} />
                
                <div>
                    {t('dinero:settings.disconnectedDescription')}
                </div>
        
                <Button onClick={reconnect} loading={isLoading} color={"primary"}>
                    {t('dinero:settings.reconnect')}
                </Button>
            </Space>
        </FlexRow>
    )
}

export default DineroReconnectPage
