import * as Popover from '@radix-ui/react-popover';
import React from "react";
import styles from "./PopConfirm.module.scss";
import Button from "../Button";
import { useTranslation } from 'react-i18next';
import Space from 'Components/Space';

interface IProps {
    children: React.ReactNode;
    onConfirm: () => void;
    title?: string;
}
const PopConfirm = (props: IProps) => {

    const { t } = useTranslation();

    return (
        <Popover.Root>
            <Popover.Trigger>
                {props.children}
            </Popover.Trigger>

            <Popover.Portal>
                <Popover.Content className={styles.content} align={"start"} >
                    <div style={{ display: 'flex', flexDirection: 'column', gap: 10 }}>
                        <Popover.Arrow style={{fill: 'white'}} />
                        <div style={{color: '#9AAAC9', fontSize: 18}}>
                            {props.title}
                        </div>
                        <Space direction={"horizontal"}>
                            <Button onClick={props.onConfirm}>{t('no')}</Button>
                            <Button style={{}} color={"danger"} onClick={props.onConfirm}>{t('yes')}</Button>
                        </Space>
                    </div>
                </Popover.Content>
            </Popover.Portal>
        </Popover.Root>
    )
}

export default PopConfirm