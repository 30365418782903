import { useMutation, useQuery, useQueryClient } from "react-query";
import {request} from "Apis/BaseApi";
import { StripePortalSessionModeL, StripeSessionModeL, StripeSubscriptionModel, SubscriptionUpdatedModel } from "Apis/Models/StripeSessionModel";
import {openErrorNotification} from "Utils/NotificationUtils";
import {useTranslation} from "react-i18next";


export const STRIPE_SUBSCRIPTION_CACHE_KEY = 'STRIPE_SUBSCRIPTION_CACHE_KEY';
export const useStripeSubscription = (refetchInterval: false | number = false) => {
    return useQuery<StripeSubscriptionModel>(STRIPE_SUBSCRIPTION_CACHE_KEY, async () => {
        return await request<StripeSubscriptionModel>({
            url: '/stripe',
            method: 'GET',
        })
    }, {
        refetchInterval
    })
}

export const useCreateStripeSession = () => {

    const { t } = useTranslation();

    return useMutation(async (billingPeriod: string) => {
        return await request<StripeSessionModeL>({
            url: `/stripe/session?billingPeriod=${billingPeriod}`,
            method: 'POST',
        })
    }, {
        onError: (e: any) => {
            openErrorNotification(t('somethingWentWrong'), e.data)
        }
    })
}

export const useCreateStripePortalSession = () => {
    return useMutation(async () => {
        return await request<StripePortalSessionModeL>({
            url: '/stripe/portal',
            method: 'GET',
        })
    })
}

export const useCancelStripeSubscription = () => {
    const queryClient = useQueryClient();
    
    return useMutation(async () => {
        return await request({
            url: '/stripe/cancel',
            method: 'PUT',
        })
    }, {
        onSuccess: () => queryClient.invalidateQueries(STRIPE_SUBSCRIPTION_CACHE_KEY)
    })
}

export const useResumeStripeSubscription = () => {
    const queryClient = useQueryClient();
    
    return useMutation(async () => {
        return await request({
            url: '/stripe/resume',
            method: 'PUT',
        })
    }, {
        onSuccess: () => queryClient.invalidateQueries(STRIPE_SUBSCRIPTION_CACHE_KEY)
    })
}

export const useMoveToYearlyPrice = () => {
    const queryClient = useQueryClient();
    
    return useMutation(async () => {
        return await request<SubscriptionUpdatedModel>({
            url: '/stripe/price/yearly',
            method: 'PUT',
        })
    }, {
        onSuccess: () => queryClient.invalidateQueries(STRIPE_SUBSCRIPTION_CACHE_KEY)
    })
}

export const useMoveToMonthlyPrice = () => {
    const queryClient = useQueryClient();
    
    return useMutation(async () => {
        return await request<SubscriptionUpdatedModel>({
            url: '/stripe/price/monthly',
            method: 'PUT',
        })
    }, {
        onSuccess: () => queryClient.invalidateQueries(STRIPE_SUBSCRIPTION_CACHE_KEY)
    })
}

export const useUpcommingInvoice = () => {
    const queryClient = useQueryClient();
    
    return useMutation(async (billingPeriod: string) => {
        return await request<any>({
            url: `/stripe/upcommingInvoice?billingPeriod=${billingPeriod}`,
            method: 'POST',
        })
    }, {
        onSuccess: () => queryClient.invalidateQueries(STRIPE_SUBSCRIPTION_CACHE_KEY)
    })
}

