import { createContext, useCallback, useContext, useEffect, useState } from "react";
import * as React from "react";
import { auth } from "FirebaseSetup/auth";
import PageLoader from "Components/PageLoader";
import firebase from 'firebase';
import { useUserContext } from "Apis/UserContextApi";
import { reportConversion } from "Utils/GoogleAdsUtils";
import { UserContext } from "Apis/Models/UserContext.type";

//
declare var profitwell: any;
declare var window: any;
declare var ga: any;
declare var gist: any;

type ContextProps = {
    firebaseUser: firebase.User | null,
    userContext: UserContext | null | undefined,
    forceReload: Function;
    signIn: Function;
    signUp: Function;
    hasSystemError: boolean;
}
export const AuthContextProvider = createContext<ContextProps>({
    firebaseUser: null,
    userContext: undefined,
    hasSystemError: false,
    forceReload: () => {
        throw new Error("Method not implemented")
    },
    signIn: () => {
        throw new Error("Method not implemented")
    },
    signUp: () => {
        throw new Error("Method not implemented")
    },
});
interface Props {
    children: React.ReactNode;
}
const AuthProvider = (props: Props) => {
    
    const [loading, setLoading] = useState<boolean>(true);
    const [isCreatingUser, setIsCreatingUser] = useState<boolean>(false);
    const [firebaseUser, setFirebaseUser] = useState<firebase.User | null>(null);
    const [hasSystemError, setHasSystemError] = useState<boolean>(false);
    
    const {data: userContext, error, refetch, isLoading: isLoadingUserContext, isFetched} = useUserContext(!!firebaseUser, hasSystemError);
    
    useEffect(() => {
        auth.onAuthStateChanged((user) => {
            setFirebaseUser(user);
            setLoading(false)
        })
    }, [])
    
    useEffect(() => {
        setHasSystemError(error && error.status > 401)
    }, [error])
    
    const signIn = async (data) => {
        await auth.signInWithEmailAndPassword(data.email, data.password);
        
        await refetch();
    }
    
    const signUp = async (data) => {
        try {
            setIsCreatingUser(true);
    
            const signUpResult = await auth.createUserWithEmailAndPassword(data.email, data.password)
    
            await signUpResult.user!.updateProfile({
                displayName: data.name
            })
    
            await auth.currentUser?.getIdToken(true);
    
            await refetch();
    
            if (!window.location.hostname.includes('localhost')) {
                reportConversion("CreateUser");
        
                ga('send', {
                    hitType: 'event',
                    eventCategory: 'account',
                    eventAction: 'created',
                });
            }
        }
        catch (e) {
            throw e;
        } finally {
            setIsCreatingUser(false)
        }
    }
    
    const forceReload = useCallback(async () => {
        await auth.currentUser?.getIdToken(true);
        
        await refetch();
    }, [refetch]);
    
    useEffect(() => {
        if (userContext && !window.location.hostname.includes('localhost')) {
            if (userContext.shouldShowWootric && window.wootric) {
                window.wootricSettings = {
                    email: userContext.email,
                    external_id: userContext.userId,
                    account_token: 'NPS-4562a363',
                    created_at: userContext.createdAtTimeStamp,
                    properties: {
                        hasActiveLicense: userContext.hasActiveLicense
                    }
                };
                window.wootric('run');
            }
            
            profitwell('start', { 'user_id': userContext.organization?.stripeCustomerId });
        }
        
        if (userContext) {

            gist.identify(userContext.userId, {
                "email": userContext.email,
                "name": userContext.name,
                "organizationId": userContext.organization?.id,
                "organizationName": userContext.organization?.name,
                "organizationOwner": userContext.isOrganizationOwner,
                "organizationAccessLevel": userContext.accessLevel,
                "organizationLicenseType": userContext.licenseType,
                "organizationPlan": userContext.hasActiveLicense ? "TimeOps Premium" : "TimeOps Free",
                "plan": userContext.hasActiveLicense ? "TimeOps Premium" : "TimeOps Free",
                "created_at": userContext.createdAtTimeStamp,
                "signed_up_at": userContext.createdAtTimeStamp,
                "company_name": userContext.organization?.name,
                "company": {
                    "companyId": userContext.organization?.id,
                    "name": userContext.organization?.name,
                }
            });
        }
    }, [userContext])
    
    useEffect(() => {
        if (!!firebaseUser) {
            forceReload();
        }
    }, [firebaseUser, forceReload])
    
    return (
        <AuthContextProvider.Provider value={{firebaseUser, userContext, forceReload, signIn, signUp, hasSystemError}}>
            <PageLoader shown={(!isCreatingUser && (loading || (!isFetched && isLoadingUserContext)))}>
                {props.children}
            </PageLoader>
        </AuthContextProvider.Provider>
    )
}

export default AuthProvider

export const useAuthProvider = () => {
    return useContext(AuthContextProvider);
}
