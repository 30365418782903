import { useMutation, useQuery, useQueryClient } from "react-query";
import { request } from "Apis/BaseApi";
import { DineroIntegrationReadModel } from "Pages/Dinero/Api/Models/DineroIntegrationReadModel";
import { DineroOrganizationReadModel } from "Pages/Dinero/Api/Models/DineroOrganizationReadModel";
import { DineroContactReadModel } from "Pages/Dinero/Api/Models/DineroContactReadModel";
import { DineroAuthorizationUrlModel } from "Pages/Dinero/Api/Models/DineroAuthorizationUrlModel";

export const DINERO_INTEGRATION_CACHE_KEY = 'DINERO_INTEGRATION_CACHE_KEY';
export const useDineroIntegration = () => {
    return useQuery(DINERO_INTEGRATION_CACHE_KEY, async () => {
        return await request<DineroIntegrationReadModel>({
            url: `/integration/dinero`,
            method: 'GET',
        })
    })
}

export const DINERO_INTEGRATION_ORGANIZATIONS_CACHE_KEY = 'DINERO_INTEGRATION_ORGANIZATIONS_CACHE_KEY';
export const useDineroOrganizations = () => {
    return useQuery(DINERO_INTEGRATION_ORGANIZATIONS_CACHE_KEY, async () => {
        return await request<DineroOrganizationReadModel[]>({
            url: `/integration/dinero/organizations`,
            method: 'GET',
        })
    })
}

export const DINERO_CONTACTS_CACHE_KEY = 'DINERO_CONTACTS_CACHE_KEY';
export const useDineroContacts = () => {
    return useQuery<DineroContactReadModel[]>(DINERO_CONTACTS_CACHE_KEY, async () => {
        return await request<DineroContactReadModel[]>({
            url: `/integration/dinero/contacts`,
            method: 'GET',
        })
    })
}

export const useSelectDineroOrganization = () => {
    
    const queryClient = useQueryClient();
    
    return useMutation(async (id: number) => {
        return await request({
            url: `/integration/dinero/setDineroId`,
            method: 'PUT',
            data: {
                dineroOrganizationId: id
            }
        })
    }, {
        onSuccess: () => queryClient.invalidateQueries(DINERO_INTEGRATION_CACHE_KEY)
    })
}

export const useSaveDineroIntegration = () => {
    
    const queryClient = useQueryClient();
    
    return useMutation(async (integration: DineroIntegrationReadModel) => {
        return await request({
            url: `/integration/dinero`,
            method: 'PUT',
            data: {
                customerImportSetting: integration.customerImportSetting,
                autoTagRegistrations: integration.autoTagRegistrations
            }
        })
    }, {
        onSuccess: () => queryClient.invalidateQueries(DINERO_INTEGRATION_CACHE_KEY)
    })
}

export const useChangeShouldBeSynchronized = () => {
    
    const queryClient = useQueryClient();
    
    return useMutation(async (contactId: string) => {
        return await request<any>({
            url: `/integration/dinero/contacts/${contactId}/shouldBeSynchronized`,
            method: 'PUT'
        })
    }, {
        onSuccess: () => queryClient.invalidateQueries(DINERO_CONTACTS_CACHE_KEY)
    })
}

export const useStartDineroIntegration = () => {
    
    const queryClient = useQueryClient();
    
    return useMutation(async () => {
        return await request<any>({
            url: `/integration/dinero/start`,
            method: 'PUT',
        })
    }, {
        onSuccess: () => queryClient.invalidateQueries(DINERO_INTEGRATION_CACHE_KEY)
    })
}

export const useAuthorizationUrl = () => {
    return useMutation(async ( ) => {
        return await request<DineroAuthorizationUrlModel>({
            url: `/integration/dinero/authorize`,
            method: 'GET',
        })
    })
}
