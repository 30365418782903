import { useEffect } from 'react';
import Header from "../../Components/Header";
import Space from "antd/lib/space";
import { Button } from 'Components/Button/Button';
import {User} from "Apis/Models/User.type";
import {useTranslation} from "react-i18next";
import { useProfile, useSaveProfile } from "Apis/ProfileApi";
import { openSuccessNotification } from "Utils/NotificationUtils";
import { useForm } from "react-hook-form";
import FlexRow from "Components/FlexRow";
import FullPageSpinner from "Components/FullPageSpinner";
import ControlledInput from "Components/InputV2";

const ProfilePage = () => {

    const { t } = useTranslation();
    
    const { data: profile, isLoading: isLoadingProfile } = useProfile();
    const { mutateAsync: saveProfileMutation, isLoading: isSavingProfile } = useSaveProfile();
    
    const { control, errors, handleSubmit, reset} = useForm<User>();
    
    useEffect(() => {
        if (profile) {
            reset(profile)
        }
    }, [profile, reset])
    
    const save = async (data: User) => {
        await saveProfileMutation(data);
        
        openSuccessNotification(t('success'), t('organization:profile.updated'))
    }
    

    if (isLoadingProfile) {
        return (
            <FullPageSpinner />
        )
    }

    return (
        <>
            <Header text={t('organization:profile.title')} />
            <form onSubmit={handleSubmit(save)}>
                <Space direction={"vertical"} size="large" style={{width: '100%'}}>

                    <div style={{display: 'flex', flexDirection: 'column'}}>
                        <label>{t('name')}</label>
                        <ControlledInput name={"name"} control={control} placeholder={t('name')} rules={{required: true}} errors={errors.name} />
                    </div>

                    <FlexRow justify={"end"}>
                        <Button color="primary" loading={isSavingProfile} type="submit">{t('save')}</Button>
                    </FlexRow>
                </Space>
            </form>
        </>
    )
}

export default ProfilePage;
