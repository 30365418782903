import { useEffect, useMemo, useRef, useState } from "react";
import styles from './Timer.module.scss';
import Col from "antd/lib/col";
import Row from "antd/lib/row";
import Tooltip from "Components/Tooltip";
import { useTranslation } from "react-i18next";
import FlexRow from "Components/FlexRow";
import { useRunningRegistration, useSaveRegistration } from "Apis/RegistrationsApi";
import { Registration } from "Apis/Models/Registration.type";
import { MoreOutlined } from "@ant-design/icons";
import debounce from 'lodash.debounce';
import ProjectSelect from "Components/ProjectSelect";
import TimerDuration from "Components/TimerDuration";
import { useCustomers } from "Apis/CustomerApi";
import RequiresPremiumModal from "Components/RequiresPremiumModal";
import BillableActive from "Assets/Icons/BillableActive";
import BillableInactive from "Assets/Icons/BillableInactive";
import { useAuthProvider } from "Providers/AuthProvider";
import TagDropdown from "Components/TagDropdown";
import { useEditRegistrationModal } from "Pages/Registrations/Components/Providers/EditRegistrationModalProvider";
import Space from "Components/Space";

const Timer = () => {
    
    const { t } = useTranslation();
    const { userContext } = useAuthProvider();
    
    const descriptionInputRef = useRef<any>();
    
    const { selectRegistration } = useEditRegistrationModal();
    
    const [localRegistration, setLocalRegistration] = useState<Registration>({});
    
    const { data: runningRegistration } = useRunningRegistration();
    const { data: customers } = useCustomers();
    
    const { mutateAsync: saveRegistrationMutation, isLoading: isSavingRegistration } = useSaveRegistration();
    
    useEffect(() => {
        if (runningRegistration) {
            setLocalRegistration(runningRegistration)
        }
    }, [runningRegistration])
    
    useEffect(() => {
        descriptionInputRef?.current?.focus();
    }, [])
    
    
    const debouncedSaveRegistration = useMemo(() => debounce(async (registration) => {
        if (!registration?.startedAt) {
            return;
        }
        
        await saveRegistrationMutation(registration);
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, 1000), []);
    
    const saveRegistration = async (registration) => {
        if (isSavingRegistration) {
            return;
        }
        
        if (registration?.startedAt) {
            await saveRegistrationMutation(registration);
        }
    };
    
    const startRegistration = async () => {
        const data: Registration = {
            ...localRegistration,
            startedAt: new Date(),
            stoppedAt: null
        }
        
        await saveRegistrationMutation(data);
    }
    
    const stopRegistration = async () => {
        const data = {
            ...runningRegistration!,
            stoppedAt: new Date()
        }
        
        debouncedSaveRegistration(data)
        
        setLocalRegistration({})
    }
    
    const onKeyPress = async (e) => {
        if (runningRegistration?.startedAt) {
            return;
        }
        
        if (e.key === 'Enter'){
            await startRegistration();
        }
    }
    
    const projectSelected = (event) => {
        const project = customers?.flatMap(x => x.projects).find(x => x.id === event);
        
        const clone = { ...localRegistration };
    
        clone.projectId = event;
        
        if (project && userContext?.hasActiveLicense) {
            clone.billable = project.billable;
        }
        
        setLocalRegistration(clone)
        
        saveRegistration(clone)
    }
    
    const tagSelected = (tags) => {
        const clone = { ...localRegistration };
    
        clone.tags = tags;
        
        setLocalRegistration(clone)
        
        saveRegistration(clone)
    }
    
    const toggleBillable = () => {
        if (userContext?.hasActiveLicense) {
            const clone = { ...localRegistration };
            
            clone.billable = !localRegistration.billable;
            
            setLocalRegistration(clone);
            
            saveRegistration(clone);
        }
    }
    
    return (
        <div className={styles.container}>
            <Row gutter={[20, 10]} align={"middle"}>
                <Col xs={24} sm={24} md={24} lg={12} xl={5} xxl={5}>
                    <FlexRow justify={"center"} align={"start"} direction={"column"}>
                        <div className={styles.inputLabel}>
                            {t('selectProject')}
                        </div>
                        <ProjectSelect bordered={false} size={"small"} value={localRegistration.projectId} onChange={e => projectSelected(e)} style={{padding: 0}} className={styles.projectSelect} />
                    </FlexRow>
                </Col>
                <Col flex={"auto"}>
                    <FlexRow justify={"center"} align={"start"} direction={"column"} style={{flexShrink: 1}}>
                        <div className={styles.inputLabel}>
                            {t('registrations:timer.description')}
                        </div>
                        <input name="description"
                               ref={e => {
                                   descriptionInputRef.current = e;
                               }}
                               value={localRegistration.description ?? ''}
                               onChange={e => {
                                   setLocalRegistration({...localRegistration, description: e.currentTarget.value})
                                   debouncedSaveRegistration({...localRegistration, description: e.currentTarget.value});
                               }}
                               onBlur={() => debouncedSaveRegistration({...localRegistration})}
                               onKeyPress={onKeyPress}
                               className={styles.input} placeholder={t('registrations:timer.descriptionPlaceHolder')} />
                    </FlexRow>
                </Col>
                <Col xs={24} sm={24} md={24} lg={24} xl={10} xxl={5} >
                    <FlexRow align={"center"} justify={"end"}>
                        <Space size={"large"} direction={"horizontal"} align={"center"}>
                            <div className={"clickable"}>
                                <FlexRow>
                                    <TagDropdown value={localRegistration.tags ?? []} onChange={tagSelected} />
                                </FlexRow>
                            </div>
                            <RequiresPremiumModal enabled={!userContext?.hasActiveLicense} title={t('registrations:billableRegistrations')}>
                                <div className={"clickable"} onClick={toggleBillable}>
                                    <Tooltip title={localRegistration.billable ? t('registrations:billable') : t('registrations:notBillable')} >
                                        <FlexRow>
                                            {localRegistration.billable ? (
                                                <BillableActive />
                                            ) : (
                                                <BillableInactive />
                                            )}
                                        </FlexRow>
                                    </Tooltip>
                                </div>
                            </RequiresPremiumModal>
                            <TimerDuration runningRegistration={localRegistration} />
                            {runningRegistration?.startedAt && runningRegistration?.stoppedAt == null && (
                                <>
                                    <Tooltip title={t('registrations:timer.stop')}>
                                        <div className={`clickable ${styles.iconCircle} ${styles.stopIcon}`} onClick={stopRegistration}>
                                            <svg id="stop" fill={"white"} stroke={"white"} xmlns="http://www.w3.org/2000/svg" width="40" height="40" viewBox="0 0 31.923 31.923">
                                                <g id="Group_3" data-name="Group 3" transform="translate(0)">
                                                    <path id="Path_64" data-name="Path 64" d="M15.962,0A15.962,15.962,0,1,0,31.923,15.962,15.934,15.934,0,0,0,15.962,0ZM22.23,20.4a1.786,1.786,0,0,1-1.791,1.791H11.524A1.786,1.786,0,0,1,9.733,20.4l.039-8.876a1.786,1.786,0,0,1,1.791-1.791h8.876a1.786,1.786,0,0,1,1.791,1.791Z" transform="translate(0)" fill="red"/>
                                                </g>
                                            </svg>
                                        </div>
                                    </Tooltip>
                                    <Tooltip title={t('registrations:timer.editRegistration')}>
                                        <div style={{width: '40px', height: '40px', display: 'flex', alignItems: 'center', justifyContent: 'center'}}>
                                            <MoreOutlined onClick={() => selectRegistration(runningRegistration)} className={"clickable"} style={{fontSize: '1.8em'}}/>
                                        </div>
                                    </Tooltip>
                                </>
                            )}
            
                            {!runningRegistration?.startedAt && (
                                <>
                                    <Tooltip title={t('registrations:timer.start')}>
                                        <svg xmlns="http://www.w3.org/2000/svg" width="32.004" height="32.004" viewBox="0 0 32.004 32.004" className={`clickable ${styles.iconCircle}`} onClick={startRegistration}>
                                            <g id="play-button" transform="translate(-1)">
                                                <g id="Group_2" data-name="Group 2" transform="translate(1)">
                                                    <path id="Path_63" data-name="Path 63" d="M16,0A16,16,0,1,0,32,16,16.02,16.02,0,0,0,16,0Zm6.361,16.563-9.334,6A.667.667,0,0,1,12,22V10a.667.667,0,0,1,1.027-.561l9.334,6a.667.667,0,0,1,0,1.121Z" fill="#1951b9"/>
                                                </g>
                                            </g>
                                        </svg>
                                    </Tooltip>
                                    <Tooltip title={t('registrations:timer.createManual')}>
                                        <svg xmlns="http://www.w3.org/2000/svg" width="31.666" height="31.666" viewBox="0 0 31.666 31.666" onClick={() => selectRegistration(runningRegistration)} className={`clickable ${styles.iconCircle}`}>
                                            <path id="plus_1_" data-name="plus (1)" d="M15.833,0A15.833,15.833,0,1,0,31.666,15.833,15.851,15.851,0,0,0,15.833,0ZM22.76,17.153H17.153V22.76a1.319,1.319,0,1,1-2.639,0V17.153H8.906a1.319,1.319,0,1,1,0-2.639h5.608V8.906a1.319,1.319,0,1,1,2.639,0v5.608H22.76a1.319,1.319,0,1,1,0,2.639Zm0,0" fill="rgba(0,0,0,0.7)"/>
                                        </svg>
                                    </Tooltip>
                                </>
                            )}
                        </Space>
                    </FlexRow>
                </Col>
            </Row>
            
        </div>
    )
}

export default Timer
