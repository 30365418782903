import {request} from "./BaseApi";
import { useMutation, useQuery, useQueryClient } from "react-query";
import { User } from "Apis/Models/User.type";
import { USERS_CACHE_KEY } from "Apis/UsersApi";

export const PROFILE_CACHE_KEY = 'PROFILE_CACHE_KEY';
export const useProfile = () => {
    return useQuery(PROFILE_CACHE_KEY, async () => {
        return await request<User>({
            url: `/user`,
            method: 'GET'
        });
    })
}

export const useSaveProfile = () => {
    
    const queryClient = useQueryClient();
    
    return useMutation(async (user: User): Promise<User> => {
        return await request({
            url: '/user',
            method: 'PUT',
            data: user
        })
    }, {
        onSuccess: () => {
            queryClient.invalidateQueries(USERS_CACHE_KEY);
            queryClient.invalidateQueries(PROFILE_CACHE_KEY);
        }
    })
}
