import * as React from "react"

const DineroIcon = (props: React.SVGProps<SVGSVGElement>) => {
    return (
        <svg
            data-name="Group 57"
            xmlns="http://www.w3.org/2000/svg"
            width={30.953}
            height={34.312}
            viewBox="0 0 30.953 34.312"
            {...props}
        >
            <path
                data-name="Path 115"
                d="M23.079 5.345v-4a1.315 1.315 0 10-2.629 0v2.814a15.559 15.559 0 102.629 1.186"
                fill="#3ca5dc"
            />
            <path
                data-name="Path 116"
                d="M21.779.272a1.074 1.074 0 011.072 1.086v21.221c0 4.33-3.687 6.588-7.345 6.588a6.735 6.735 0 01-7-6.588l.014-9.232a5.143 5.143 0 0110.275-.014v9.189a2.963 2.963 0 01-3.03 2.915 3.079 3.079 0 01-3.162-2.915v-6.4a1.072 1.072 0 112.144 0v6.4c0 .386.486.757 1 .757a.834.834 0 00.886-.757v-9.188a2.935 2.935 0 00-3.03-2.729 2.844 2.844 0 00-2.93 2.744l-.014 9.232a4.538 4.538 0 004.844 4.414c2.5 0 5.187-1.386 5.187-4.43V1.358A1.1 1.1 0 0121.779.272"
                fill="#fff"
            />
        </svg>
    )
}

export default DineroIcon
