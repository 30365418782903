import Space from "antd/lib/space";import { getInputClasses } from "Utils/FormUtils";
import { useForm } from "react-hook-form";
import Button from "Components/Button/Button";
import { useEffect } from "react";
import { useTranslation } from "react-i18next";
import { useDineroContact, useSendInvoiceWithEan } from "Pages/Dinero/Api/DineroInvoiceApi";
import { openSuccessNotification } from "Utils/NotificationUtils";
import { DineroSendInvoiceWithEanModel } from "Pages/Dinero/Api/Models/DineroSendInvoiceWithEanModel";
import Modal from "Components/Modal";

interface Props {
    invoiceGuid: string;
    visible: boolean;
    close: () => void;
    contactGuid: string
}
const SendInvoiceWithEANModal = ({invoiceGuid, contactGuid, visible, close}: Props) => {
    
    const { t } = useTranslation();
    
    const { data: contact } = useDineroContact(contactGuid);
    
    const { mutateAsync: sendInvoiceMutation, isLoading: isSendingInvoice } = useSendInvoiceWithEan();
    
    const { register, reset, handleSubmit, errors } = useForm<DineroSendInvoiceWithEanModel>();
    
    useEffect(() => {
        if (contact) {
            reset({
                receiverEanNumber: contact.eanNumber,
                attPerson: contact.attPerson
            })
        }
    }, [ contact, reset ])
    
    const sendInvoice = async(data) => {
        await sendInvoiceMutation({ guid: invoiceGuid, emailOptions: data })
        
        openSuccessNotification(t('success'), t('dinero:edit.invoiceSent'))
        close()
    }
    
    return (
        <Modal
            title={t('dinero:edit.sendInvoice')}
            visible={visible}
            onCancel={close}
        >
            <form onSubmit={handleSubmit(sendInvoice)}>
                <Space className={"w-100"} direction={"vertical"}>
                    <div>
                        <label>{t('dinero:edit.eanNumber')}</label>
                        <input className={getInputClasses(errors.receiverEanNumber)} name="receiverEanNumber" placeholder={t('dinero:edit.sender')} ref={register({ required: true })}/>
                    </div>
                
                    <div>
                        <label>{t('dinero:edit.attPerson')}</label>
                        <input className={getInputClasses(errors.attPerson)} name="attPerson" placeholder={t('dinero:edit.receiver')} ref={register({ required: true })}/>
                    </div>
                
                    <div>
                        <label>{t('dinero:edit.orderReference')}</label>
                        <input className={getInputClasses(errors.orderReference)} name="orderReference" placeholder={t('dinero:edit.subject')} ref={register}/>
                    </div>
                
                    <div style={{display: 'flex', justifyContent: 'flex-end'}}>
                        <Space>
                            <Button onClick={() => close()}>{t('cancel')}</Button>
                            <Button loading={isSendingInvoice} color={"primary"} type={"submit"}>{t('dinero:edit.sendInvoice')}</Button>
                        </Space>
                    </div>
                </Space>
            </form>
        </Modal>
    )
}

export default SendInvoiceWithEANModal
