import { useEffect, useRef } from "react";
import { useTranslation } from "react-i18next";
import Switch from "antd/lib/switch";
import List from "Components/List/List";
import FlexRow from "Components/FlexRow";
import ListTitle from "Components/List/ListTitle";
import useQueryParam from "Hooks/UseQueryParam";
import Button from "Components/Button/Button";
import { openSuccessNotification } from "Utils/NotificationUtils";
import { useChangeShouldBeSynchronized, useDineroContacts, useDineroIntegration, useStartDineroIntegration } from "Pages/Dinero/Api/DineroIntegrationApi";
import InputV2 from "Components/InputV2/InputV2";


const DineroCustomerSelector = () => {
    
    const { t } = useTranslation();
    
    const searchRef = useRef<HTMLInputElement>(null);
    const [searchFilter, setSearchFilter] = useQueryParam('query');
    
    const { data: dineroIntegration } = useDineroIntegration();
    const { data: contacts, isLoading: isLoadingContact} = useDineroContacts();
    
    const { mutateAsync: startIntegrationMutation, isLoading: isStartingIntegration} = useStartDineroIntegration();
    
    const { mutateAsync: toggleShouldBeSynchronizedMutation, isLoading: isSaving} = useChangeShouldBeSynchronized();
    
    useEffect(() => {
        if (searchRef.current) {
            searchRef.current.focus();
        }
    }, [searchRef])
    
    const getContacts = () => {
        let filteredCustomers = contacts;
        
        if (searchFilter) {
            const lowerCaseSearch = searchFilter.toLowerCase();
            
            filteredCustomers = contacts?.filter(x => x.name.toLowerCase().includes(lowerCaseSearch) || x.vatNumber?.toString().includes(lowerCaseSearch))
        }
    
        return filteredCustomers?.sort((a, b) => a.id > b.id ? 1 : -1).sort((a,b) => a.name.toLowerCase()! > b.name.toLowerCase()! ? 1 : -1)
    }
    
    const shouldBeSynchronizedHandler = async (contact) => {
        await toggleShouldBeSynchronizedMutation(contact.id);
    }
    
    const startIntegration = async () => {
        await startIntegrationMutation();
        
        openSuccessNotification(t('success'), t('dinero:onboarding.integrationStarted'))
    }
    
    return (
        <div>
            <p>
                {t('dinero:contacts.description')}
            </p>
            <FlexRow style={{marginBottom: 10}}>
                <div  style={{width: '100%', maxWidth: '280px'}}>
                    <InputV2 inputRef={searchRef} placeholder={t('dinero:contacts.contactSearch')} type="text" value={searchFilter} onChange={e => setSearchFilter(e)} containerStyle={{backgroundColor: 'white'}} />
                </div>
            </FlexRow>
            <List
                loading={isLoadingContact}
                data={getContacts()}
                keyDataIndex={"id"}
                columns={[
                    {
                        title: t('name'),
                        dataIndex: "name"
                    },
                    {
                        title: t('vatNumber'),
                        dataIndex: "vatNumber"
                    },
                    {
                        title: t('dinero:contacts.isCustomer'),
                        dataIndex: "isDebitor",
                        textRender: (text) => text ? t('yes') : t('no')
                    },
                    {
                        title: t('dinero:contacts.isSupplier'),
                        dataIndex: "isCreditor",
                        textRender: (text) => text ? t('yes') : t('no')
                    },
                    {
                        title: t('dinero:contacts.match'),
                        dataIndex: "customerId",
                        customRender: (text) => (
                            <div>
                                <ListTitle>{t('dinero:contacts.match')}</ListTitle>
                                <div>
                                    {text ? <b>{t('dinero:contacts.matchFound')}</b> : t('dinero:contacts.noMatch')}
                                </div>
                            </div>
                        )
                    },
                    {
                        size: 'min-content',
                        title: t('dinero:contacts.shouldBeSynchronized'),
                        dataIndex: 'shouldBeSynchronized',
                        customRender: (shouldBeSynchronized, record) => (
                            <FlexRow direction={"column"} justify={"center"} align={"end"}>
                                <Switch loading={isSaving} disabled={isSaving} checked={shouldBeSynchronized} onChange={_ => shouldBeSynchronizedHandler(record)} />
                            </FlexRow>
                        )
                    }
                ]}
            />
    
            {dineroIntegration?.integrationStatus !== 3 && (
                <FlexRow justify={"end"}>
                    <Button loading={isStartingIntegration} disabled={isStartingIntegration} onClick={startIntegration} color={"primary"}>{t('dinero:settings.start')}</Button>
                </FlexRow>
            )}
        </div>
    )
}

export default DineroCustomerSelector
